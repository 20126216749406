import {Profile, User} from '../../models';
import {profiles, users} from '../mockDB';
import {API} from '../types';

interface Params {
  identifier: string;
  otp: string;
}

export interface LoginResponse {
  token: string;
  refreshToken: string;
  profile: Profile;
  user: User;
}

export function authLoginAPI(params: Params): API<LoginResponse> {
  return {
    getData: async client => {
      const {data} = await client({
        url: '/auth/verify-otp',
        method: 'POST',
        data: params,
      });
      return {data: data};
    },
    getMockData: () => {
      if (params.otp == 'asd') {
        const user = users.find(u => u.identifier == params.identifier)!;
        const profile = profiles.find(u => u.email == params.identifier)!;
        return {
          token: 'qweASD123',
          refreshToken: 'qweASD123',
          profile: {...profile, user},
          user,
        };
      }
      throw 'Wrong username or password';
    },
  };
}
