import styles from './Reviews.module.scss';
import {Table, Select} from '../../../components';
import {ReactComponent as WorkIcon} from '../../../assets/img/work.svg';
import {strings} from '../../../services/language';
import {Centre, Skill} from '../../../services/models';
import {useReviewsLogic} from './logics';

type ReviewsProps = {skills: Skill[]; centre: Centre};

export default function Reviews({skills, centre}: ReviewsProps) {
  const {
    setRole,
    columns,
    data,
    skillOptions,
    manualFilterSort,
    total,
    _limit,
    fetchData,
  } = useReviewsLogic(skills, centre);

  return (
    <div className={styles.container}>
      <h3>{`${total} ${strings('Ratings')}`}</h3>
      <div className={styles.filtersContainer}>
        <div className={styles.buttonContainer}>
          <div className={styles.selectFilters}>
            <Select
              fixedLabel={
                <WorkIcon
                  style={{
                    stroke: 'var(--blue-accent)',
                    width: '16px',
                    height: '16px',
                  }}
                />
              }
              defaultLabel={strings('Roles')}
              optionList={skillOptions}
              multipleSelect
              allToggle
              allItem
              onSelectValue={values => setRole(values)}
            />
          </div>
        </div>
      </div>
      <Table
        columns={columns}
        data={data}
        rowClick
        manualFilterSort={manualFilterSort}
        pageCountNumber={Math.ceil(total / _limit)}
        fetchData={fetchData}
      />
    </div>
  );
}
