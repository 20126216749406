import moment from 'moment';
import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {
  authEmailAPI,
  authLoginAPI,
  authRegisterAPI,
  useAPI,
  useResult,
  LoginResponse,
  authSendOtpAPI,
} from '../../services/api';
import {useInput, useLoading, usePressEnter} from '../../services/hooks';
import {strings} from '../../services/language';
import {IdentifierStatus} from '../../services/models';
import {updateState} from '../../services/store';
import {validateEmail, validatePhone} from '../../services/utils';

export function useLoginLogic() {
  const {states: email, bind: bindEmail} = useInput('');
  const [emailChecked, setEmailChecked] = useState<
    IdentifierStatus | undefined
  >(undefined);
  const [isRemember, setIsRemember] = useState(true);
  const [otpString, setOtpString] = useState('');
  const [otpError, setOtpError] = useState<string | undefined>('');
  const [isSent, setIsSent] = useState(false);
  const [countDown, setCountDown] = useState(60);

  const history = useHistory();
  const emailApi = useAPI(authEmailAPI);
  const loginApi = useAPI(authLoginAPI);
  const registerApi = useAPI(authRegisterAPI);
  const sendOtpApi = useAPI(authSendOtpAPI);

  useResult(loginApi.data, d => {
    if (d.profile.role === 'HR') {
      handleLoginSuccess(d);
    }
  });

  useResult(registerApi.data, d => {
    handleLoginSuccess(d);
  });

  useResult(emailApi.data, d => {
    setEmailChecked(d.status);
    d.status === 'phoneLogin' && sendOtpApi.request({identifier: email.value});
    setIsSent(true);
  });

  useEffect(() => {
    if (!emailApi.loading) {
      email.setError(emailApi.error);
    }
  }, [emailApi.loading]);

  useEffect(() => {
    if (!loginApi.loading) {
      setOtpError(loginApi.error);
    }
  }, [loginApi.loading]);

  usePressEnter(
    () => handlePressEnter(),
    [email.value, otpString, emailChecked]
  );

  useEffect(() => {
    const timer = isSent
      ? setInterval(() => {
          setCountDown(countDown - 1);
        }, 1000)
      : undefined;

    countDown === 0 && (setIsSent(false), setCountDown(60));

    return () => clearInterval(timer!);
  }, [isSent, countDown]);

  function onSubmitEmail() {
    if (validateEmail(email.value) || validatePhone(email.value)) {
      emailApi.request({identifier: email.value, isMobile: false});
    } else {
      email.setError(strings('Invalid email address or phone number'));
    }
  }

  function onSubmitPassword() {
    loginApi.request({identifier: email.value, otp: otpString});
  }

  function onResendOTP() {
    sendOtpApi.request({identifier: email.value});
  }

  function handleLoginSuccess(auth: LoginResponse) {
    updateState({
      user: auth.user,
      profile: auth.profile,
      secure: {
        token: auth.token,
        refreshToken: auth.refreshToken,
        isRemember,
        expiry: moment().add(55, 'minute').unix(),
      },
    });
    sessionStorage.setItem('loggedIn', 'true');
    history.push('/');
  }

  function handlePressEnter() {
    if (otpString.length === 6) {
      onSubmitPassword();
      return;
    }
    if (email.value && !emailChecked) {
      onSubmitEmail();
      return;
    }
  }

  useLoading(
    emailApi.loading ||
      loginApi.loading ||
      registerApi.loading ||
      sendOtpApi.loading
  );

  return {
    email,
    bindEmail,
    emailChecked,
    emailApi,
    onSubmitPassword,
    onSubmitEmail,
    isRemember,
    setIsRemember,
    isSent,
    countDown,
    otpString,
    setOtpString,
    otpError,
    setOtpError,
    onResendOTP,
  };
}
