import styles from './CheckBox.module.scss';
import {ReactComponent as CheckIcon} from '../../assets/img/check.svg';

interface CheckBoxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  checked?: boolean;
  name: string;
  id: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function CheckBox({
  label,
  checked = false,
  id,
  name,
  handleChange,
  ...rest
}: CheckBoxProps) {
  return (
    <label htmlFor={name} className={styles.container}>
      <input
        type="checkbox"
        checked={checked}
        name={name}
        id={id}
        onChange={e => handleChange(e)}
        {...rest}
      />
      <div className={styles.checkbox}>
        <CheckIcon
          className={styles.checkIcon}
          style={{stroke: ' var(--white)'}}
        />
      </div>
      {label && <span className={styles.labelText}>{label}</span>}
    </label>
  );
}
