import {
  useLoading,
  useSyncCentres,
  useSyncSkills,
} from '../../../services/hooks';
import styles from './TimesheetList.module.scss';
import {Badge} from '../../../components';
import {useEffect, useMemo, useRef, useState} from 'react';
import {Cell, Column, TableInstance} from 'react-table';
import {ReactComponent as ChevronIcon} from '../../../assets/img/chevron.svg';
import {strings} from '../../../services/language';
import {useHistory} from 'react-router-dom';
import {downloadCsv, formatISOString} from '../../../services/utils';
import {
  timesheetStatusOptions,
  createPayrollMonthOptions,
  createSkillOptions,
  districtOptions,
} from '../../../services/helpers';
import {
  Application,
  Centre,
  Job,
  Payroll,
  Profile,
  Skill,
  Timesheet,
  TimesheetStatus,
} from '../../../services/models';
import {
  exportTimesheetsAPI,
  getTimesheetsAPI,
  useAPI,
  useResult,
} from '../../../services/api';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import {toRoute} from '../../../services/router';

const _limit = 8;

export function useTimesheetsLogic(payrollId: number | undefined) {
  const [total, setTotal] = useState(0);
  const [manualFilterSort, setManualFilterSort] = useState<any>(undefined);
  const [status, setStatus] = useState<string[]>([]);
  const [district, setDistrict] = useState<string[]>([]);
  const [role, setRole] = useState<number[]>([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [payrollMonth, setPayrollMonth] = useState<string[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchField, setSearchField] = useState('all');
  const {skills} = useSyncSkills();
  const {centres} = useSyncCentres();
  const [error, setError] = useState('');

  const resultModalRef = useRef<any>(null);

  useEffect(() => {
    if ((startDate && endDate) || (!startDate && !endDate)) {
      setManualFilterSort([]);
    }
  }, [
    role,
    status,
    district,
    startDate,
    endDate,
    searchField,
    payrollMonth,
    payrollId,
  ]);

  const searchFilterList = useMemo(
    () => [
      {
        content: strings('Centre'),
        value: 'centreName',
      },
      {
        content: strings('Relief Staff'),
        value: 'profileName',
      },
      {
        content: strings('Employee Code'),
        value: 'profileEmployeeCode',
      },
    ],
    []
  );

  const history = useHistory();

  const timesheetsApi = useAPI(getTimesheetsAPI);
  const exportTimesheetsApi = useAPI(exportTimesheetsAPI);

  function exportTimesheets() {
    exportTimesheetsApi.request({
      _start: 0,
      _limit: _limit,
      _sort: 'id:DESC',
      searchField,
      ...(searchValue && {query: searchValue}),
      ...(status.length > 0 && {statuses: status}),
      ...(district.length > 0 && {centreDistrictGroups: district}),
      ...(payrollMonth.length > 0 && {payrollPeriods: payrollMonth}),
      ...(role.length > 0 && {jobSkillIds: role}),
      ...(startDate && {startsAt_gte: startDate}),
      ...(endDate && {endsAt_lte: endDate}),
      ...(payrollId !== undefined && {payrollId}),
      export_csv: true,
    });
  }

  useResult(exportTimesheetsApi.data, data => {
    if (data.content) {
      downloadCsv(data.filename, data.content);
    }
  });

  useEffect(() => {
    if (exportTimesheetsApi.error && !exportTimesheetsApi.loading) {
      setError(strings('Please select a date range'));
      resultModalRef.current.showModal();
    }
  }, [exportTimesheetsApi.loading]);

  function fetchData(pageIndex: number) {
    timesheetsApi.request({
      _start: pageIndex * _limit,
      _limit: _limit,
      _sort: 'id:DESC',
      searchField,
      ...(searchValue && {query: searchValue}),
      ...(status.length > 0 && {statuses: status}),
      ...(district.length > 0 && {centreDistrictGroups: district}),
      ...(payrollMonth.length > 0 && {payrollPeriods: payrollMonth}),
      ...(role.length > 0 && {jobSkillIds: role}),
      ...(startDate && {startsAt_gte: startDate}),
      ...(endDate && {endsAt_lte: endDate}),
      ...(payrollId !== undefined && {payrollId}),
    });
  }

  useResult(timesheetsApi.data, data => {
    if (data.total !== total) {
      setTotal(data.total);
    }
  });

  useLoading(timesheetsApi.loading || exportTimesheetsApi.loading);

  const columns: Column[] = useMemo(() => createColumns(), []);
  const data: TimesheetData[] = useMemo(
    () =>
      createRowsData(timesheetsApi.data?.list || [], skills, centres, history),
    [timesheetsApi.data?.list, centres]
  );

  const skillOptions = useMemo(() => createSkillOptions(skills), [skills]);
  const payrollMonthOptions = useMemo(() => createPayrollMonthOptions(2), []);
  const removePayrollId = () => history.push(toRoute('/timesheets'));

  return {
    _limit,
    columns,
    data,
    total,
    manualFilterSort,
    setStatus,
    setDistrict,
    setPayrollMonth,
    setSearchField,
    setStartDate,
    setEndDate,
    setRole,
    searchValue,
    setSearchValue,
    searchFilterList,
    payrollMonthOptions,
    timesheetStatusOptions,
    districtOptions,
    skillOptions,
    fetchData,
    removePayrollId,
    exportTimesheets,
    resultModalRef,
    error,
  };
}

interface TimesheetData {
  date: string;
  job: JSX.Element;
  centre: string;
  district: string;
  status: TimesheetStatus;
  reliefStaff: JSX.Element;
  clockIn: string;
  clockOut: string;
  break: JSX.Element;
  workedHours: JSX.Element;
  rate: string;
  amount: string;
  payrollPayslip: JSX.Element;

  button: JSX.Element;
}

function createColumns() {
  return [
    {
      Header: strings('Date'),
      accessor: 'date',
    },
    {
      Header: strings('Job'),
      accessor: 'job',
    },
    {
      Header: strings('Centre'),
      accessor: 'centre',
    },
    {
      Header: strings('District'),
      accessor: 'district',
      filter: 'selectOptions',
    },
    {
      Header: strings('Status'),
      accessor: 'status',
      Cell: (props: TableInstance & Cell) => (
        <Badge type="timesheetStatus" status={props.value} />
      ),
      filter: 'selectOptions',
    },
    {
      Header: strings('Relief Staff'),
      accessor: 'reliefStaff',
    },
    {
      Header: strings('Clock In'),
      accessor: 'clockIn',
    },
    {
      Header: strings('Clock Out'),
      accessor: 'clockOut',
    },
    {
      Header: strings('Break'),
      accessor: 'break',
    },
    {
      Header: strings('Total Hours Worked'),
      accessor: 'workedHours',
    },
    {
      Header: strings('Rate'),
      accessor: 'rate',
    },
    {
      Header: strings('Total Amount'),
      accessor: 'amount',
    },
    {
      Header: strings('Payroll Month/ Payslip ID'),
      accessor: 'payrollPayslip',
    },
    {
      Header: '',
      accessor: 'button',
    },
  ];
}

function createRowsData(
  timesheets: (Timesheet & {
    application: Application & {job: Job; profile: Profile};
    payroll?: Payroll;
  })[],
  skills: Skill[],
  centres: Centre[],
  history: any
): TimesheetData[] {
  return timesheets.map(timesheet => {
    const dayId = timesheet.dayId;
    const date = dayId.slice(dayId.indexOf('-') + 1);
    const isWeekend = [0, 6].includes(
      momentTZ(date, 'YYYY-MM-DD')
        .tz('Asia/Singapore')
        .set('hour', 7)
        .get('day')
    ); // Sunday is 0 and saturday is 6

    const jobSkill = skills.filter(
      skill => skill.id === timesheet.application.job.skillId
    )[0];
    const rate = isWeekend ? jobSkill.rateWeekend : jobSkill.rateAmount;
    const centre = centres.filter(
      centre => centre.id === timesheet.application.job.centreId
    )[0];
    const breakDuration = timesheet.breakDuration
      ? timesheet.breakDuration / 60
      : 0;
    const profile = timesheet.application.profile;

    const hourDiff =
      moment(timesheet.endsAt)
        .millisecond(0)
        .second(0)
        .diff(moment(timesheet.startsAt).millisecond(0).second(0), 'minute') /
      60;

    const totalHours =
      hourDiff - breakDuration > 0
        ? Math.ceil((hourDiff - breakDuration) * 4) / 4
        : 0;

    const totalAmount = parseFloat((totalHours * rate).toFixed(2));

    return {
      date: formatISOString(
        timesheet.startsAt || timesheet.createdAt,
        'DD/MM/YY'
      ),
      job: (
        <div className={styles.flexCell}>
          <img
            className={styles.skillIcon}
            src={jobSkill ? jobSkill.icon : ''}
            alt={jobSkill ? jobSkill.name : ''}
            height={40}
          />
          <div className={styles.flexRight}>
            <div>{`${timesheet.application.job.vacancy} ${
              jobSkill ? jobSkill.name : ''
            }`}</div>
            <div className={styles.greyText}>
              {timesheet.application.job.id}
            </div>
          </div>
        </div>
      ),
      centre: centre ? centre.name : '',
      district: centre ? centre.districtGroup : '',
      status: timesheet.status,
      reliefStaff: (
        <div className={styles.flexCell}>
          <div
            className={styles.avatar}
            style={{
              backgroundImage: profile.photo ? `url(${profile.photo})` : 'none',
            }}
          ></div>
          <div className={styles.flexRight}>
            <div>{profile.name}</div>
            <div className={styles.greyText}>{profile.employeeCode || ''}</div>
          </div>
        </div>
      ),
      clockIn: timesheet.startsAt
        ? formatISOString(timesheet.startsAt, 'h:mmA')
        : '-',
      clockOut: timesheet.endsAt
        ? formatISOString(timesheet.endsAt, 'h:mmA')
        : '-',
      break: (
        <div className={styles.nowrap}>
          {breakDuration
            ? (Number.isInteger(breakDuration)
                ? breakDuration
                : parseFloat(breakDuration.toFixed(2))) + ' hrs'
            : '-'}
        </div>
      ),
      workedHours: (
        <div>
          {timesheet.startsAt && timesheet.endsAt ? (
            <span>{totalHours + ' hrs'}</span>
          ) : (
            <span className={styles.redText}>{strings('Incomplete')}</span>
          )}
        </div>
      ),
      rate: jobSkill ? `$${rate}/${jobSkill.rateUnit}` : '-',
      amount: totalAmount ? '$' + totalAmount : '-',
      payrollPayslip: (
        <div className={styles.flexRight}>
          {timesheet.payroll ? (
            <>
              <div>
                {moment(timesheet.payroll.period, 'YYYYMM').format('MMM YYYY')}
              </div>
              <div className={styles.greyText}>
                {timesheet.payroll.payslipId}
              </div>
            </>
          ) : (
            <div className={styles.redText}>
              {strings('Not fully approved yet')}
            </div>
          )}
        </div>
      ),
      button: (
        <div
          className={styles.buttonCell}
          onClick={e => {
            history.push('/jobs/' + timesheet.application.job.id);
            if (e) {
              e.stopPropagation();
            }
          }}
        >
          <ChevronIcon
            style={{stroke: 'var(--blue-accent)', transform: 'rotate(-90deg)'}}
          />
        </div>
      ),
    };
  });
}
