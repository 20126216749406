import {Dispatch, useEffect, useRef, useState} from 'react';
import moment from 'moment';
import {strings} from '../../services/language';
import {ReactComponent as ArrowIcon} from '../../assets/img/chevron-black.svg';
import {ReactComponent as ChevronIcon} from '../../assets/img/chevron.svg';
import {ReactComponent as CalenderIcon} from '../../assets/img/calender.svg';
import {ReactComponent as ErrorIcon} from '../../assets/img/error.svg';
import 'react-day-picker/lib/style.css';
import './PcfDatePicker.scss';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {
  DateUtils,
  DayModifiers,
  DayPickerProps,
  Modifier,
  RangeModifier,
  WeekdayElementProps,
} from 'react-day-picker';
import DayPickerMoment from 'react-day-picker/moment';

type PcfDatePickerProps = {
  type?: 'range' | 'multipleInWeek' | 'single';
  label?: string;
  placeholder?: string;
  clearButton?: boolean;
  selectedNumber?: boolean;
  value?: Date[] | RangeModifier | Date;
  error?: string;
  disabled?: boolean;
  sgTimeZone?: boolean;
  onSelectDate?: (selectedDate: any) => void;
};

export function PcfDatePicker({
  type = 'range',
  label,
  placeholder,
  clearButton = true,
  selectedNumber = false,
  value,
  error = '',
  disabled = false,
  sgTimeZone = false,
  onSelectDate = () => {},
}: PcfDatePickerProps) {
  const [fromTo, setFromTo] = useState<RangeModifier>({
    from: undefined,
    to: undefined,
  });
  const [enteredTo, setEnteredTo] = useState<Date | null | undefined>(
    undefined
  );
  const [showDatePicker, setShowDatePicker] = useState(0);
  const [selectedMultipleValue, setSelectedMultipleValue] =
    useState<string>('');
  const [selectedMultiple, setSelectedMultiple] = useState<Date[]>([]);
  const [disabledMultiple, setDisabledMultiple] = useState<
    Modifier | Modifier[]
  >({
    before: new Date(),
  });
  const [selectedSingleDate, setSelectedSingleDate] = useState<
    Date | undefined
  >(undefined);
  const [keyDate, setKeyDate] = useState(
    'datepicker-' + moment().toISOString()
  );

  const toRef = useRef<any>(null);
  const fromRef = useRef<any>(null);
  const multipleRef = useRef<any>(null);
  const singleRef = useRef<any>(null);

  useEffect(() => {
    if (value) {
      if (type === 'range') {
        setFromTo(value as RangeModifier);
      } else if (type === 'single') {
        setSelectedSingleDate(value as Date);
      } else if ((value as Date[]).length >= 0) {
        setSelectedMultiple(value as Date[]);
        initSelectedMultipleValue(value as Date[]);
      }
    } else {
      setSelectedSingleDate(undefined);
    }
  }, [value]);

  const isBeforeFirstDay = (day: Date) =>
    fromTo.from && DateUtils.isDayBefore(day, fromTo.from);

  const handleFromChange = (from: RangeModifier['from']) => {
    const sgFromDate = from ? new Date(from) : from;
    sgFromDate && sgFromDate.setHours(-1);
    const sgToDate = fromTo.to ? new Date(fromTo.to) : fromTo.to;
    sgToDate && sgToDate.setHours(22, 59, 59);
    setFromTo({...fromTo, from});
    sgTimeZone
      ? onSelectDate({from: sgFromDate, to: sgToDate})
      : onSelectDate({...fromTo, from});
  };

  const handleToChange = (to: RangeModifier['to']) => {
    const sgFromDate = fromTo.from ? new Date(fromTo.from) : fromTo.from;
    sgFromDate && sgFromDate.setHours(-1);
    const sgToDate = to ? new Date(to) : to;
    sgToDate && sgToDate.setHours(22, 59, 59);
    if (to) {
      if (!isBeforeFirstDay(to)) {
        setFromTo({...fromTo, to});
        sgTimeZone
          ? onSelectDate({from: sgFromDate, to: sgToDate})
          : onSelectDate({...fromTo, to});
        setEnteredTo(to);
      }
    } else {
      setFromTo({...fromTo, to});
      sgTimeZone
        ? onSelectDate({from: sgFromDate, to: sgToDate})
        : onSelectDate({...fromTo, to});
      setEnteredTo(to);
    }
    if (!fromTo.from) {
      fromRef.current.getInput().focus();
    }
  };

  const handleDayMouseEnter = (day: Date) => {
    if (!isBeforeFirstDay(day)) {
      setEnteredTo(day);
    }
  };

  const handleMultipleDayClick = (day: Date, modifiers: DayModifiers) => {
    if (!modifiers.disabled) {
      const selectedDays = selectedMultiple;
      let selectedValue = '';

      if (modifiers.selected) {
        const selectedIndex = selectedDays.findIndex((selectedDay: Date) =>
          DateUtils.isSameDay(selectedDay, day)
        );
        selectedDays.splice(selectedIndex, 1);
      } else {
        selectedDays.push(day);
      }
      if (selectedDays.length === 1) {
        setDisabledMultiple({
          before:
            moment(day).startOf('week') > moment()
              ? moment(day).startOf('week').toDate()
              : new Date(),
          after: moment(day).endOf('week').toDate(),
        });
      }
      if (selectedDays.length === 0) {
        setDisabledMultiple({
          before: new Date(),
        });
      }
      if (selectedDays.length > 0) {
        const sortedDays = selectedDays.sort((a, b) =>
          moment(a).diff(moment(b))
        );
        const formatedDays = sortedDays.map(day =>
          moment(day).format('DD MMM YYYY')
        );
        selectedValue = formatedDays.join(', ');
      }

      setSelectedMultiple(selectedDays);
      onSelectDate(selectedDays);
      setSelectedMultipleValue(selectedValue);
    }
  };

  const initSelectedMultipleValue = (days: Date[]) => {
    let selectedValue = '';

    setDisabledMultiple(
      days.length > 0
        ? {
            before:
              moment(days[0]).startOf('week') > moment()
                ? moment(days[0]).startOf('week').toDate()
                : new Date(),
            after: moment(days[0]).endOf('week').toDate(),
          }
        : {
            before: new Date(),
          }
    );

    const sortedDays = days.sort((a, b) => moment(a).diff(moment(b)));
    const formatedDays = sortedDays.map(day =>
      moment(day).format('DD MMM YYYY')
    );
    selectedValue = formatedDays.join(', ');

    setSelectedMultipleValue(selectedValue);
  };

  const modifiers = {
    start: fromTo.from ? fromTo.from : new Date(),
    end: enteredTo ? enteredTo : new Date(),
  };

  const dayPickerInputAttr = {
    placeholder: 'dd/mm/yyyy',
    format: 'DD/MM/YYYY',
    formatDate: DayPickerMoment.formatDate,
    parseDate: DayPickerMoment.parseDate,
    overlayComponent: (props: CustomOverlay) =>
      CustomOverlay(
        props,
        setFromTo,
        onSelectDate,
        clearButton,
        fromRef,
        toRef,
        setKeyDate,
        setEnteredTo,
        setShowDatePicker
      ),
    onDayPickerShow: () => {
      setShowDatePicker(showDatePicker + 1);
    },
    onDayPickerHide: () => {
      setShowDatePicker(showDatePicker - 1);
    },
  };

  const dayPickerProps: DayPickerProps = {
    modifiers,
    captionElement: ({date}) => monthYearLabel(date),
    navbarElement: ({onPreviousClick, onNextClick}) =>
      Navbar(onPreviousClick, onNextClick),
    weekdayElement: props => Weekday(props),
    renderDay: renderDay,
    showOutsideDays: true,
  };

  const rangePicker = (
    <div
      className="buttonWrapper"
      onClick={() => {
        if (!showDatePicker && !disabled) {
          if (fromTo.from && !fromTo.to) {
            toRef.current.getInput().focus();
          } else {
            fromRef.current.getInput().focus();
          }
        }
      }}
    >
      <div className="fixedLabel">
        <CalenderIcon
          style={{
            fill: disabled ? 'var(--grey-border-2)' : 'var(--blue-accent)',
          }}
        />
      </div>

      <div className="selectedValue">
        {
          <>
            {placeholder && (
              <div
                style={
                  !!showDatePicker || fromTo.from || fromTo.to
                    ? {display: 'none'}
                    : {}
                }
                className="defaultLabel"
              >
                {placeholder}
              </div>
            )}
            <div
              className={`InputFromTo ${
                !showDatePicker && !fromTo.from && !fromTo.to ? 'hideInput' : ''
              }`}
            >
              <DayPickerInput
                {...dayPickerInputAttr}
                ref={fromRef}
                value={fromTo.from ? fromTo.from : undefined}
                inputProps={{disabled: disabled}}
                dayPickerProps={{
                  ...dayPickerProps,
                  selectedDays: [fromTo.from ? fromTo.from : undefined, fromTo],
                  disabledDays: fromTo.to
                    ? {
                        after: fromTo.to,
                      }
                    : undefined,
                  toMonth: fromTo.to ? fromTo.to : undefined,
                  onDayClick: (_, modifiers) =>
                    !modifiers.disabled && toRef.current.getInput().focus(),
                }}
                onDayChange={handleFromChange}
              />{' '}
              -{' '}
              <span className="InputFromTo-to">
                <DayPickerInput
                  {...dayPickerInputAttr}
                  ref={toRef}
                  value={fromTo.to ? fromTo.to : undefined}
                  inputProps={{disabled: disabled}}
                  dayPickerProps={{
                    ...dayPickerProps,
                    selectedDays: [
                      fromTo.from ? fromTo.from : undefined,
                      {...fromTo, to: enteredTo},
                    ],
                    disabledDays: fromTo.from
                      ? {
                          before: fromTo.from,
                        }
                      : undefined,
                    month: fromTo.from ? fromTo.from : undefined,
                    fromMonth: fromTo.from ? fromTo.from : undefined,
                    onDayMouseEnter: handleDayMouseEnter,
                    onDayClick: () => {
                      toRef.current.getInput().blur();
                      if (!fromTo.from) {
                        fromRef.current.getInput().focus();
                      }
                    },
                  }}
                  onDayChange={handleToChange}
                  onDayPickerHide={() => {
                    setShowDatePicker(showDatePicker - 1);
                    setEnteredTo(fromTo.to || fromTo.from);
                  }}
                />
              </span>
            </div>
          </>
        }
      </div>
      <ChevronIcon
        style={{
          stroke: 'var(--blue-accent)',
        }}
        className={`arrowWrapper ${!!showDatePicker ? 'arrowUp' : ''}`}
      />
    </div>
  );

  const multiplePicker = (
    <div
      className={`buttonWrapper ${error !== '' ? 'error' : ''}`}
      onClick={() => {
        if (!disabled) {
          multipleRef.current.getInput().focus();
        }
      }}
    >
      <div className="InputMultiple">
        <DayPickerInput
          ref={multipleRef}
          placeholder={placeholder}
          value={selectedMultipleValue}
          hideOnDayClick={false}
          inputProps={{readOnly: true, disabled: disabled}}
          dayPickerProps={{
            ...dayPickerProps,
            selectedDays: selectedMultiple,
            disabledDays: disabledMultiple,
            onDayClick: handleMultipleDayClick,
          }}
        />
      </div>
      <div className="fixedLabel">
        <CalenderIcon
          style={{
            fill: disabled ? 'var(--grey-border-2)' : 'var(--blue-accent)',
          }}
        />
      </div>
    </div>
  );

  const singlePicker = (
    <div
      className={`buttonWrapper ${error !== '' ? 'error' : ''}`}
      onClick={() => {
        if (!disabled) {
          singleRef.current.getInput().focus();
        }
      }}
    >
      <div className="InputMultiple">
        <DayPickerInput
          ref={singleRef}
          placeholder={placeholder}
          value={selectedSingleDate}
          inputProps={{disabled: disabled}}
          format="DD/MM/YY"
          formatDate={DayPickerMoment.formatDate}
          parseDate={DayPickerMoment.parseDate}
          onDayChange={date => {
            setSelectedSingleDate(date);
            onSelectDate(date);
          }}
          dayPickerProps={{
            ...dayPickerProps,
            selectedDays: selectedSingleDate,
            onDayClick: date => {
              setSelectedSingleDate(date);
              onSelectDate(date);
            },
          }}
        />
      </div>
      <div className="fixedLabel">
        <CalenderIcon
          style={{
            fill: disabled ? 'var(--grey-border-2)' : 'var(--blue-accent)',
          }}
        />
      </div>
    </div>
  );

  return (
    <div
      key={keyDate}
      className={`PcfDatePicker ${type !== 'range' ? 'border' : ''} ${
        disabled ? 'disabled' : ''
      }`}
    >
      {(label || selectedNumber) && (
        <div className="labelWrapper">
          <span className="label">{label}</span>
          {selectedNumber && selectedMultiple.length > 0 && (
            <span className="selectedNumber">
              {strings('%0 days selected', selectedMultiple.length)}
            </span>
          )}
        </div>
      )}
      {type === 'range' && rangePicker}
      {type === 'multipleInWeek' && multiplePicker}
      {type === 'single' && singlePicker}
      {error !== '' && (
        <div className="errorWrapper">
          <ErrorIcon />
          <span>{error}</span>
        </div>
      )}
    </div>
  );
}

type CustomOverlay = {
  classNames: {
    [key: string]: string;
  };
  selectedDay: any;
  children: Node;
};

function CustomOverlay(
  {classNames, selectedDay, children, ...props}: CustomOverlay,
  setFromTo: Dispatch<any>,
  onSelectDate: (selectedDate: any) => void,
  clearButton: boolean,
  fromRef: React.MutableRefObject<any>,
  toRef: React.MutableRefObject<any>,
  setKeyDate: Dispatch<React.SetStateAction<string>>,
  setEnteredTo: Dispatch<React.SetStateAction<Date | null | undefined>>,
  setShowDatePicker: Dispatch<React.SetStateAction<number>>
) {
  return (
    <div className={classNames.overlayWrapper} {...props}>
      <div className={classNames.overlay}>
        {clearButton && (
          <div className="deselectWrapper">
            <span
              className={
                fromRef.current.getInput().value ||
                toRef.current.getInput().value
                  ? 'active'
                  : ''
              }
              onClick={() => {
                if (
                  fromRef.current.getInput().value ||
                  toRef.current.getInput().value
                ) {
                  if (
                    !moment(
                      fromRef.current.getInput().value,
                      'DD/MM/YYYY',
                      true
                    ).isValid() ||
                    !moment(
                      toRef.current.getInput().value,
                      'DD/MM/YYYY',
                      true
                    ).isValid()
                  ) {
                    setKeyDate('datepicker-' + moment().toISOString());
                    setShowDatePicker(0);
                  }
                  setEnteredTo(undefined);
                  setFromTo({
                    from: undefined,
                    to: undefined,
                  });
                  onSelectDate({
                    from: undefined,
                    to: undefined,
                  });
                  fromRef.current.getInput().focus();
                }
              }}
            >
              {strings('Deselect All')}
            </span>
          </div>
        )}
        {children}
      </div>
    </div>
  );
}

function monthYearLabel(date: Date) {
  return (
    <div className="monthYearLabel">{moment(date).format('MMM YYYY')}</div>
  );
}

function Navbar(onPreviousClick: () => void, onNextClick: () => void) {
  return (
    <div className="navBar">
      <button onClick={() => onPreviousClick()}>
        <ArrowIcon />
      </button>
      <button onClick={() => onNextClick()}>
        <ArrowIcon />
      </button>
    </div>
  );
}

function Weekday(props: WeekdayElementProps) {
  const weekdayName = props.localeUtils.formatWeekdayLong(
    props.weekday,
    props.locale
  );
  return (
    <div className={props.className} title={weekdayName}>
      {weekdayName.slice(0, 1)}
    </div>
  );
}

function renderDay(day: Date) {
  const date = day.getDate();
  return <div className="dayItem">{date}</div>;
}
