import {useEffect, useMemo, useRef, useState} from 'react';
import {strings} from '../../services/language';
import {
  useInput,
  useLoading,
  useMultipleSelect,
  usePickSingleDate,
  usePickSingleTime,
  useSyncSkills,
} from '../../services/hooks';
import {
  createSkillOptions,
  postalDistrictOptions,
} from '../../services/helpers';
import moment from 'moment';
import {editNotificationAPI, useAPI} from '../../services/api';
import {useHistory} from 'react-router-dom';
import {toRoute} from '../../services/router';
import {Notification} from '../../services/models';

export function useNotificationLogic(notification: Notification) {
  const history = useHistory();
  const {states: title, bind: bindTitle} = useInput(notification.title);
  const {states: publishDate, bind: bindPublishDate} = usePickSingleDate(
    moment(notification.scheduledAt).toDate()
  );
  const {states: publishTime, bind: bindPublishTime} = usePickSingleTime(
    notification.scheduledAt
  );
  const [publishNow, setPublishNow] = useState(false);
  const {states: role, bind: bindRole} = useMultipleSelect(
    notification.skillIds || []
  );
  const {states: district, bind: bindDistrict} = useMultipleSelect(
    notification.districtNumbers || []
  );
  const {states: previewText, bind: bindPreviewText} = useInput(
    notification.body
  );
  const [moreDetails, setMoreDetails] = useState(
    !!(notification.additionalInfo || notification.image)
  );
  const {states: additionalInfo, bind: bindAdditionalInfo} = useInput(
    notification.additionalInfo
  );
  const [file, setFile] = useState<File | undefined>(undefined);
  const [showRecipientsError, setShowRecipientsError] = useState(false);
  const [removedFile, setRemovedFile] = useState(false);

  const resultModalRef = useRef<any>(null);

  const {skills} = useSyncSkills();
  const editNotiApi = useAPI(editNotificationAPI);

  const skillOptions = useMemo(() => createSkillOptions(skills), [skills]);

  useEffect(() => {
    if (
      (editNotiApi.data?.notification || editNotiApi.error) &&
      !editNotiApi.loading
    ) {
      resultModalRef.current.showModal();
    }
  }, [editNotiApi.loading]);

  useEffect(() => {
    if (publishNow) {
      publishDate.setValue(undefined);
      publishDate.setError('');
      publishTime.setValue('');
      publishTime.setError('');
    }
  }, [publishNow]);

  useEffect(() => {
    if (!moreDetails) {
      additionalInfo.setValue('');
      setRemovedFile(true);
    }
  }, [moreDetails]);

  useEffect(() => {
    setShowRecipientsError(false);
  }, [role.value, district.value]);

  const detectChange =
    title.changed ||
    publishDate.changed ||
    publishTime.changed ||
    role.changed ||
    district.changed ||
    previewText.changed ||
    additionalInfo.changed ||
    (notification.thumbnail && removedFile) ||
    (!notification.thumbnail && file);

  const linkList = [
    {
      title: strings('Notifications Management'),
      route: '/notifications',
    },
    {
      title: strings('Notification Details'),
      active: true,
    },
  ];

  const validateForm = () => {
    title.value === ''
      ? title.setError(strings('Please enter a title'))
      : title.setError('');
    role.value.length > 0 || district.value.length > 0
      ? setShowRecipientsError(false)
      : setShowRecipientsError(true);

    if (!publishNow) {
      !publishDate.value
        ? publishDate.setError(strings('Please select a date'))
        : publishDate.setError('');
      !publishTime.value
        ? publishTime.setError(strings('Please select a time'))
        : publishTime.setError('');
    }

    return (
      !!title.value &&
      (role.value.length > 0 || district.value.length > 0) &&
      (publishNow || (!!publishDate.value && !!publishTime.value))
    );
  };

  function onSubmitForm() {
    const isValid = validateForm();

    if (isValid && detectChange) {
      editNotiApi.request({
        id: notification.id,
        title: title.value,
        scheduledAt: publishNow
          ? moment().toISOString()
          : concatDateAndTime(publishDate.value!, publishTime.value),
        ...(!!previewText.value && {body: previewText.value}),
        ...(!!additionalInfo.value && {additionalInfo: additionalInfo.value}),
        ...(role.value.length > 0 && {
          skillIds: role.value.filter(x => typeof x === 'number'),
        }),
        ...(district.value.length > 0 && {
          districtNumbers: district.value.filter(x => typeof x === 'number'),
        }),
        ...(file && {image: file}),
        ...(removedFile && !file && {image: 'null'}),
      });
    }
  }

  const goToNotifications = () => {
    history.push(toRoute('/notifications'));
  };

  useLoading(editNotiApi.loading);

  return {
    linkList,
    postalDistrictOptions,
    skillOptions,
    resultModalRef,
    editNotiApi,
    moreDetails,
    publishNow,
    bindTitle,
    bindAdditionalInfo,
    bindRole,
    bindDistrict,
    bindPublishDate,
    bindPublishTime,
    bindPreviewText,
    showRecipientsError,
    status: notification.status,
    thumbnail: notification.thumbnail,
    removedFile,
    detectChange,
    setRemovedFile,
    setMoreDetails,
    setFile,
    onSubmitForm,
    setPublishNow,
    goToNotifications,
  };
}

function concatDateAndTime(date: Date, time: string) {
  const dateMoment = moment(date);
  const timeMoment = moment(time);

  return moment({
    hour: timeMoment.hour(),
    minute: timeMoment.minute(),
    date: dateMoment.date(),
    month: dateMoment.month(),
    year: dateMoment.year(),
  }).toISOString();
}
