import {setScreen} from '../../services/router';
import {Breadcrumb} from '../../components';
import {useCreateStaffLogic} from './logics';
import {strings} from '../../services/language';
import StaffCreate from './StaffCreate/StaffCreate';

setScreen('/pcf-staffs/create-pcf-staff-profile', () => {
  const {breadcrumbList} = useCreateStaffLogic();

  return (
    <>
      <div className="main-header">
        <Breadcrumb linkList={breadcrumbList} />
        <h2 style={{padding: '20px 0'}}>
          {strings('Create PCF Staff Profile')}
        </h2>
      </div>
      <StaffCreate />
    </>
  );
});
