import {Job} from '../../models';
import {jobs} from '../mockDB';
import {API} from '../types';

type EditJobParams =
  | {
      id: string;
      vacancy: string;
      startsAt: string;
      endsAt: string;
      skillId: number;
      centreId: number;
      daysOff?: number[];
      additionalInfo?: string;
    }
  | {id: string; status: string};

type EditJobPayloads =
  | {
      id?: string;
      vacancy: string;
      startsAt: string;
      endsAt: string;
      skillId: number;
      centreId: number;
      daysOff?: number[];
      additionalInfo?: string;
    }
  | {id?: string; status: string};

interface Response {
  job: Job;
}

export function editJobAPI(params: EditJobParams): API<Response> {
  const payload: EditJobPayloads = {...params};
  delete payload.id;

  return {
    getData: async client => {
      const {data} = await client({
        url: `/jobs/${params.id}`,
        method: 'PUT',
        data: payload,
      });
      return {data: {job: data}};
    },
    getMockData: () => {
      return {job: jobs[0]};
    },
  };
}
