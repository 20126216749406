import {BooleanLiteral} from 'typescript';
import {Centre, Skill} from '../models';

export default interface State {
  user: {
    id?: number;
    identifier?: string;
    confirmed?: boolean;
    password_hashed?: string;
    confirmation_token?: string;
    createdAt?: string;
    updatedAt?: string;
  };
  profile: {
    id?: number;
    role?: string;
    name?: string;
    email?: string;
    phone?: string;
    photo?: string;
  };
  secure: {
    token?: string;
    refreshToken?: string;
    isRemember?: boolean;
    expiry?: number;
  };
  centres: {
    lastSync: string;
    list: Centre[];
  };
  skills: {
    lastSync: string;
    list: Skill[];
  };
}

export const initialState: State = {
  user: {},
  profile: {},
  secure: {},
  centres: {
    lastSync: new Date(1996, 1, 1).toISOString(),
    list: [],
  },
  skills: {
    lastSync: new Date(1996, 1, 1).toISOString(),
    list: [],
  },
};
