import styles from './Reviews.module.scss';
import {useEffect, useMemo, useState} from 'react';
import {Column} from 'react-table';
import {ReactComponent as ChevronIcon} from '../../../assets/img/chevron.svg';
import {strings} from '../../../services/language';
import {useHistory} from 'react-router-dom';
import {
  Centre,
  CentreReview,
  Job,
  Profile,
  Skill,
} from '../../../services/models';
import {createSkillOptions} from '../../../services/helpers';
import {useLoading} from '../../../services/hooks';
import {getCentreReviewsAPI, useAPI, useResult} from '../../../services/api';
import {formatISOString} from '../../../services/utils';

interface ReviewData {
  reviewer: JSX.Element;
  job: JSX.Element;
  message: string;
  publishDate: JSX.Element;

  button: JSX.Element;
}

const _limit = 8;

export function useReviewsLogic(skills: Skill[], centre: Centre) {
  const [role, setRole] = useState<number[]>([]);
  const [recordNumber, setRecordNumber] = useState(0);
  const [manualFilterSort, setManualFilterSort] = useState<any>(undefined);
  const [total, setTotal] = useState(0);
  const [reviews, setReviews] = useState<
    (CentreReview & {
      profile: Profile;
      job: Job;
    })[]
  >([]);

  const history = useHistory();

  useEffect(() => {
    setManualFilterSort([]);
  }, [role]);

  const getReviewsApi = useAPI(getCentreReviewsAPI);

  function fetchData(pageIndex: number) {
    getReviewsApi.request({
      _start: pageIndex * _limit,
      _limit: _limit,
      _sort: 'id:DESC',
      centreId: centre.id,
      ...(role.length > 0 && {profileSkillId: role}),
    });
  }

  useResult(getReviewsApi.data, data => {
    setReviews(data.list);
    if (data.total !== total) {
      setTotal(data.total);
    }
  });

  useLoading(getReviewsApi.loading);

  const columns: Column[] = useMemo(() => createColumns(), []);
  const data: ReviewData[] = useMemo(
    () => createRowsData(history, reviews, skills),
    [reviews]
  );
  const skillOptions = useMemo(() => createSkillOptions(skills), [skills]);

  return {
    role,
    setRole,
    recordNumber,
    setRecordNumber,
    columns,
    data,
    skillOptions,
    manualFilterSort,
    total,
    _limit,
    fetchData,
  };
}

function createColumns() {
  return [
    {
      Header: strings('Reviewer'),
      accessor: 'reviewer',
    },
    {
      Header: strings('Job'),
      accessor: 'job',
    },
    {
      Header: strings('Message'),
      accessor: 'message',
    },
    {
      Header: strings('Publish Date'),
      accessor: 'publishDate',
    },
    {
      Header: '',
      accessor: 'button',
    },
  ];
}

function createRowsData(
  history: any,
  reviews: (CentreReview & {
    profile: Profile;
    job: Job;
  })[],
  skills: Skill[]
): ReviewData[] {
  return reviews.map((review, index) => {
    const jobSkill = skills.filter(skill => skill.id === review.job.skillId)[0];

    return {
      reviewer: (
        <div className={styles.userCell}>
          <div
            className={styles.avatar}
            style={{
              backgroundImage: review.profile.photo
                ? `url(${review.profile.photo})`
                : 'none',
            }}
          ></div>
          <div className={styles.userRight}>
            <div>{review.profile.name}</div>
            <div className={styles.greyText}>
              {`${strings('User ID')}: ${review.profile.id}`}
            </div>
          </div>
        </div>
      ),
      job: (
        <div className={styles.userCell}>
          <img
            className={styles.skillIcon}
            src={jobSkill?.icon || ''}
            alt={jobSkill?.name || ''}
            height={40}
          />
          <div className={styles.userRight}>
            <div>{`${review.job.vacancy} ${jobSkill?.name || ''}`}</div>
            <div className={styles.greyText}>{review.jobId}</div>
          </div>
        </div>
      ),
      message: review.comments,
      publishDate: (
        <div className={styles.dateCell}>
          {formatISOString(review.createdAt, 'DD MMM YYYY')}
        </div>
      ),
      button: (
        <div
          className={styles.buttonCell}
          onClick={e => {
            history.push('/jobs/' + review.jobId);
            if (e) {
              e.stopPropagation();
            }
          }}
        >
          <ChevronIcon
            style={{stroke: 'var(--blue-accent)', transform: 'rotate(-90deg)'}}
          />
        </div>
      ),
    };
  });
}
