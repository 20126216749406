import styles from './RolesDetails.module.scss';
import {Skill} from '../../../services/models';
import {MarkdownEditor, Button} from '../../../components';
import {strings} from '../../../services/language';
import {useEffect, useState} from 'react';
import {editSkillAPI, useAPI, useResult} from '../../../services/api';
import {useLoading} from '../../../services/hooks';

type RolesDetailsProps = {
  skills: Skill[];
  skillsApi: any;
};

export default function RolesDetails({skills, skillsApi}: RolesDetailsProps) {
  const [roleValues, setRoleValues] = useState<{
    [key: string]: string;
  }>({});
  const [initialSkills, setInitialSkills] = useState<{
    [key: string]: string;
  }>({});
  const [changes, setChanges] = useState<{
    [key: string]: boolean;
  }>({});
  const [initialChanges, setInitialChanges] = useState<{
    [key: string]: boolean;
  }>({});

  const editSkillApi = useAPI(editSkillAPI);

  useResult(editSkillApi.data, data => {
    if (data.success) {
      setChanges(initialChanges);
      skillsApi.request();
      setInitialSkills(roleValues);
    }
  });

  useEffect(() => {
    const initialValue = {};
    const mapStates = skills.reduce(
      (obj, skill) => ({...obj, [skill.id]: skill.description}),
      initialValue
    );
    setRoleValues(mapStates);
    setInitialSkills(mapStates);

    const mapChanges = skills.reduce(
      (obj, skill) => ({...obj, [skill.id]: false}),
      {}
    );
    setChanges(mapChanges);
    setInitialChanges(mapChanges);
  }, []);

  const resetForm = () => {
    setRoleValues(initialSkills);
    setChanges(initialChanges);
  };

  const updateSkills = () => {
    const changedSkills = Object.keys(changes).filter(c => changes[c]);
    const updatedSkills = changedSkills.map(c => ({
      id: c,
      description: roleValues[c],
    }));

    updatedSkills.length > 0 && editSkillApi.request(updatedSkills);
  };

  useLoading(editSkillApi.loading || skillsApi.loading);

  return (
    <div className={styles.container}>
      {skills.map((skill, index) => (
        <div key={`role-${index}`} className={styles.roleItem}>
          <div className={styles.titleWrapper}>
            <img src={skill.icon} alt={skill.name} height={36} />
            <h3 className={styles.title}>{skill.name}</h3>
          </div>
          <MarkdownEditor
            id={skill.name}
            name={skill.name}
            label={strings('Job Description')}
            value={roleValues[skill.id]}
            handleChange={e => {
              setRoleValues({...roleValues, [skill.id]: e.target.value});
              setChanges({
                ...changes,
                [skill.id]: e.target.value !== initialSkills[skill.id],
              });
            }}
          />
        </div>
      ))}
      <div className={styles.buttonWrapper}>
        <Button
          label={strings('Discard Changes')}
          type="transparent"
          disabled={
            !skills.reduce(
              (changed, skill) => changed || changes[skill.id],
              false
            )
          }
          handleClick={() => resetForm()}
        />
        <Button
          label={strings('Save Changes')}
          type="danger"
          disabled={
            !skills.reduce(
              (changed, skill) => changed || changes[skill.id],
              false
            )
          }
          handleClick={updateSkills}
        />
      </div>
    </div>
  );
}
