import {setScreen} from '../../services/router';
import {strings} from '../../services/language';
import CentreList from './CentreList/CentreList';
import {useCentreList} from './logics';
import {useLoading} from '../../services/hooks';

setScreen('/centres', () => {
  const {centresApi, centres} = useCentreList();

  useLoading(centresApi.loading && centres.length === 0);

  return (
    <>
      <div className="main-header">
        <h1>{strings('Centres')}</h1>
      </div>
      <CentreList centres={centres} />
    </>
  );
});
