import styles from './StaffProfile.module.scss';
import {setScreen} from '../../services/router';
import {strings} from '../../services/language';
import {useStaffProfileLogic} from './logics';
import {Breadcrumb, NavTabs} from '../../components';
import JobHistory from './JobHistory/JobHistory';
import Reviews from './Reviews/Reviews';
import General from './General/General';
import {formatISOString} from '../../services/utils';

setScreen('/relief-staffs/:id', params => {
  const {breadcrumbList, skills, getProfileApi, profile} = useStaffProfileLogic(
    params.id
  );

  return profile ? (
    <>
      <div className="main-header">
        <Breadcrumb linkList={breadcrumbList} />
        <div className={styles.header}>
          <div
            className={styles.avatar}
            style={{
              backgroundImage: profile.photo ? `url(${profile.photo})` : 'none',
            }}
          ></div>
          <div className={styles.headerWrapper}>
            <div className={styles.headerRow}>
              <h2 className={styles.jobName}>{profile.name}</h2>
            </div>
            <div className={styles.headerRow}>
              <div className={styles.infoText}>{`${strings('Employee Code')}: ${
                profile.employeeCode || ''
              }`}</div>
              <div className={styles.infoText}>{`${strings('Joined')} ${
                profile.joinedAt
                  ? formatISOString(profile.joinedAt, 'DD/MM/YY')
                  : ''
              }`}</div>
            </div>
          </div>
        </div>
      </div>
      <NavTabs
        navTabList={[
          {
            id: 'jobHistory',
            label: strings('Job History'),
            content: <JobHistory profile={profile} skills={skills} />,
          },
          {
            id: 'reviews',
            label: strings('Reviews'),
            content: <Reviews profile={profile} skills={skills} />,
          },
          {
            id: 'general',
            label: strings('General'),
            content: <General profile={profile} skills={skills} />,
          },
        ]}
      />
    </>
  ) : (
    <div>{getProfileApi.error}</div>
  );
});
