import {setScreen} from '../../services/router';
import {strings} from '../../services/language';
import StaffList from './StaffList/StaffList';
import {useStaffListLogic} from './logics';
import {useEffect} from 'react';
import {useLoading} from '../../services/hooks';

setScreen('/relief-staffs', params => {
  const {skillsApi, skills} = useStaffListLogic();

  useLoading(skillsApi.loading);

  return (
    <>
      <div className="main-header">
        <h1>{strings('Relief Staff')}</h1>
      </div>
      {skills && skills.length > 0 && <StaffList skills={skills} />}
    </>
  );
});
