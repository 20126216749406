import {strings} from '../../services/language';
import {getProfileByIdAPI, useAPI} from '../../services/api';
import {useLoading, useSyncSkills} from '../../services/hooks';
import {useEffect} from 'react';

export function useStaffProfileLogic(profileId: number) {
  const breadcrumbList = [
    {
      title: strings('Relief Staff'),
      route: '/relief-staffs',
    },
    {
      title: strings('Staff Profile'),
      active: true,
    },
  ];

  const {skillsApi, skills} = useSyncSkills();
  const getProfileApi = useAPI(() => getProfileByIdAPI(profileId));

  useEffect(() => {
    getProfileApi.request();
  }, []);

  useLoading(skillsApi.loading);

  return {
    breadcrumbList,
    skills,
    getProfileApi,
    profile: getProfileApi.data,
  };
}
