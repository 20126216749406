import styles from './Breadcrumb.module.scss';
import {useHistory} from 'react-router-dom';
import {ReactComponent as ChevronIcon} from '../../assets/img/chevron-grey.svg';

type BreadcrumbProps = {
  linkList: {
    title: string;
    route?: string;
    active?: boolean;
  }[];
};

export function Breadcrumb({linkList}: BreadcrumbProps) {
  const history = useHistory();

  const goToCreateJob = (route: string) => {
    history.push(route);
  };

  const listItems = linkList.map((item, index) => (
    <span key={`link-${index}`} className={styles.itemWrapper}>
      {index !== 0 && <ChevronIcon className={styles.arrowIcon} />}
      <span
        className={`${styles.linkItem} ${item.active ? styles.active : ''}`}
        onClick={() => {
          if (item.route) {
            goToCreateJob(item.route);
          }
        }}
      >
        {item.title}
      </span>
    </span>
  ));
  return <div className={styles.container}>{listItems}</div>;
}
