import styles from './JobCreateContent.module.scss';
import {ReactComponent as SearchIcon} from '../../../assets/img/search.svg';
import {
  Breadcrumb,
  Input,
  Select,
  PcfDatePicker,
  PcfTimePicker,
  Button,
  Modal,
} from '../../../components';
import ReactMarkdown from 'react-markdown';
import {strings} from '../../../services/language';
import {useCreateJobLogic} from '../logics';
import {Job} from '../../../services/models';

type Props = {
  job?: Job;
};

export default function JobCreateContent({job}: Props) {
  const {
    linkList,
    centreList,
    bindCentre,
    role,
    bindRole,
    bindPaxNo,
    bindAdditionalInfo,
    schedule,
    workingHours,
    onSubmitJob,
    resetForm,
    goToJobs,
    skillOptions,
    selectedSkill,
    jobModalRef,
    resultModalRef,
    createJobApi,
    isWrongHour,
  } = useCreateJobLogic(job);

  return (
    <>
      <div className="main-header">
        <Breadcrumb linkList={linkList} />
        <h2 className={styles.header}>{strings('Create Job Posting')}</h2>
      </div>
      <div className={styles.container}>
        <div className={styles.inputItem}>
          <Select
            topLabel={strings('Centre')}
            border
            defaultLabel={strings('Search Centre')}
            rightIcon={
              <SearchIcon
                style={{
                  width: 20,
                  height: 20,
                  fill: 'var(--blue-accent)',
                }}
              />
            }
            divider
            searchable
            optionList={centreList}
            {...bindCentre}
          />
        </div>
        <div className={styles.inputItem}>
          <Select
            topLabel={strings('Role')}
            border
            defaultLabel={strings('Select Role')}
            optionList={skillOptions}
            {...bindRole}
          />
          {role.value && (
            <>
              <div
                className={styles.viewJob}
                onClick={() => jobModalRef.current.showModal()}
              >
                {strings('View Job Description')}
              </div>
              <Modal
                ref={jobModalRef}
                width={536}
                bodyMaxHeight={315}
                header={
                  selectedSkill ? (
                    <>
                      <div className={styles.jobModalTitle}>
                        <img
                          src={selectedSkill.icon}
                          alt={selectedSkill.name}
                          height={36}
                        />
                        <h2>{selectedSkill.name}</h2>
                      </div>
                      <div className={styles.jobModalLabel}>
                        {strings('Job Description')}
                      </div>
                    </>
                  ) : (
                    ''
                  )
                }
                body={
                  selectedSkill ? (
                    <div className={styles.jobModalContent}>
                      <ReactMarkdown
                        children={selectedSkill.description}
                      ></ReactMarkdown>
                    </div>
                  ) : (
                    ''
                  )
                }
                footer={
                  <Button
                    label={strings('Done')}
                    type="danger"
                    handleClick={() => jobModalRef.current.hideModal()}
                  />
                }
              />
            </>
          )}
        </div>
        <div className={styles.inputItem}>
          <Input
            id="paxNo"
            type="number"
            label={strings('Number of Pax')}
            placeholder={strings('Enter Pax No.')}
            {...bindPaxNo}
          />
        </div>
        <div className={styles.inputItem}>
          <PcfDatePicker
            label={strings('Schedule')}
            placeholder={strings('Select Dates')}
            type="multipleInWeek"
            clearButton={false}
            selectedNumber
            value={schedule.schedule}
            error={schedule.scheduleError}
            onSelectDate={selectedDate => schedule.setSchedule(selectedDate)}
          />
        </div>
        <div className={styles.inputItem}>
          <PcfTimePicker
            step={1}
            label={strings('Working Hours')}
            placeholder={strings('Start Time')}
            placeholderTo={strings('End Time')}
            error={workingHours.workingHoursErrorFrom}
            errorTo={workingHours.workingHoursErrorTo}
            fromValueInit={workingHours.workingHours.from}
            toValueInit={workingHours.workingHours.to}
            onSelectValue={value => workingHours.setWorkingHours(value)}
          />
        </div>
        <div className={styles.inputItem}>
          <Input
            id="additionalInfo"
            type="textarea"
            label={strings('Additional Information')}
            optionalLabel={strings('Optional')}
            placeholder={strings('Any important details?')}
            {...bindAdditionalInfo}
          />
        </div>
        <div className={styles.buttonWrapper}>
          <Button
            label={strings('Cancel')}
            type="transparent"
            handleClick={goToJobs}
          />
          <Button
            label={strings('Create')}
            type="danger"
            handleClick={onSubmitJob}
          />
        </div>

        <Modal
          ref={resultModalRef}
          header={undefined}
          body={
            <div>
              <div>
                {createJobApi.error
                  ? isWrongHour
                    ? strings('You can create job minimum 3 hours in advance.')
                    : createJobApi.error
                  : createJobApi.data?.job
                  ? strings('Create Job Successfully')
                  : ''}
              </div>
            </div>
          }
          footer={
            <>
              <Button
                label={strings('Done')}
                type="danger"
                handleClick={() => {
                  !createJobApi.error && createJobApi.data?.job && resetForm();
                  resultModalRef.current.hideModal();
                }}
              />
            </>
          }
        />
      </div>
    </>
  );
}
