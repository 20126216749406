import {useEffect} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {getState, setState} from '../store';
import {getScreenProps as gsp} from './configs';
import {AuthRoute, MainRoute} from './utils';

export function RootStack() {
  const isRemember = getState().secure.isRemember;
  useEffect(() => {
    if (!isRemember && sessionStorage.getItem('loggedIn') !== 'true') {
      setState({user: {}, profile: {}, secure: {}});
    }
  }, []);

  return (
    <Router>
      <Switch>
        <AuthRoute {...gsp('/login')} />
        <AuthRoute {...gsp('/verify-email')} />
        <MainRoute {...gsp('/relief-staffs')} />
        <MainRoute {...gsp('/relief-staffs/:id')} />
        <MainRoute {...gsp('/jobs')} path={['/', '/jobs']} />
        <MainRoute {...gsp('/jobs/create-job-posting')} />
        <MainRoute {...gsp('/jobs/:id')} />
        <MainRoute {...gsp('/centres')} />
        <MainRoute {...gsp('/centres/:id')} />
        <MainRoute {...gsp('/payrolls')} />
        <MainRoute {...gsp('/pcf-staffs')} />
        <MainRoute {...gsp('/pcf-staffs/create-pcf-staff-profile')} />
        <MainRoute {...gsp('/pcf-staffs/:id')} />
        <MainRoute {...gsp('/notifications')} />
        <MainRoute {...gsp('/notifications/create-new-notification')} />
        <MainRoute {...gsp('/notifications/:id')} />
        <MainRoute {...gsp('/timesheets')} />
        <Route {...gsp('/notfound')} path="*" />
      </Switch>
    </Router>
  );
}
