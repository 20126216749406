import {Application, Job, Payroll, Profile, Timesheet} from '../../models';
import {profiles} from '../mockDB';
import {API} from '../types';

type Params = {
  _start: number;
  _limit: number;
  _sort: string;
  query?: string;
  searchField?: string;
  payrollPeriods?: string[];
  statuses?: string[];
  centreDistrictGroups?: string[];
  startsAt_gte?: string;
  endsAt_lte?: string;
  jobSkillIds?: number[];
  payrollId?: number;
};
interface Response {
  list: (Timesheet & {
    application: Application & {job: Job; profile: Profile};
    payroll?: Payroll;
  })[];
  total: number;
}

export function getTimesheetsAPI(params: Params): API<Response> {
  const urlParams = {
    ...params,
    ...(params.payrollPeriods && {
      payrollPeriods: params.payrollPeriods.join(','),
    }),
    ...(params.statuses && {statuses: params.statuses.join(',')}),
    ...(params.centreDistrictGroups && {
      centreDistrictGroups: params.centreDistrictGroups.join(','),
    }),
    ...(params.jobSkillIds && {jobSkillIds: params.jobSkillIds.join(',')}),
  };

  return {
    getData: async client => {
      const {data} = await client({
        url: '/timesheets',
        method: 'GET',
        params: urlParams,
      });
      return {data: data};
    },
    getMockData: () => {
      return {
        list: [
          {
            id: 94,
            dayId: '67-2021-12-07',
            status: 'notPresent',
            breakDuration: undefined,
            startsAt: undefined,
            endsAt: undefined,
            applicationId: 67,
            payrollId: undefined,
            createdAt: '2021-12-08T06:58:00.451Z',
            updatedAt: '2021-12-08T06:58:00.447Z',
            rejectReason: undefined,
            profileId: 54,
            application: {
              id: 78,
              status: 'approved',
              profileId: 69,
              jobId: 85,
              createdAt: '2021-12-08T07:31:31.033113+00:00',
              updatedAt: '2021-12-13T03:47:22.739+00:00',
              job: {
                id: 85,
                status: 'completed',
                vacancy: 1,
                hired: 1,
                startsAt: '2021-12-11T01:00:00+00:00',
                endsAt: '2021-12-11T15:00:00+00:00',
                daysOff: [],
                additionalInfo: 'expired medical',
                skillId: 4,
                centreId: 1,
                createdAt: '2021-12-08T07:20:53.978841+00:00',
              },
              profile: profiles[0],
            },
          },
        ],
        total: 1,
      };
    },
  };
}
