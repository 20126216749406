import styles from './Radio.module.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  checked: boolean;
  id: string;
  value: any;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export function Radio({
  label,
  checked,
  id,
  value,
  handleChange,
  ...rest
}: Props) {
  return (
    <label className={styles.container}>
      <input
        type="radio"
        checked={checked}
        name={id}
        value={value}
        onChange={e => handleChange(e)}
        {...rest}
      />
      <div className={styles.radio}>
        <div className={styles.dot}></div>
      </div>
      {label && <span className={styles.labelText}>{label}</span>}
    </label>
  );
}
