import {API} from '../types';

type Params = {
  id: number;
};

interface Response {
  success: boolean;
}

export function deletePayrollScheduleAPI(params: Params): API<Response> {
  return {
    getData: async client => {
      const {data} = await client({
        url: `/payroll-schedules/${params.id}`,
        method: 'DELETE',
      });
      return {data: data};
    },
    getMockData: () => {
      return {
        success: true,
      };
    },
  };
}
