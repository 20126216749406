import {Routes} from './routes';
import {Route, Redirect} from 'react-router-dom';
import {Sidebar} from './configs';
import {getState} from '../store';

export function toRoute<T extends keyof Routes>(route: T, params?: Routes[T]) {
  if (!params) {
    return route;
  }
  const searches: string[] = [];
  Object.entries(params).forEach(([k, val]) => {
    searches.push(k + '=' + JSON.stringify(val));
  });
  return route + '?' + searches.join('&');
}

export function MainRoute({children, ...rest}: any) {
  const token = getState().secure.token;

  return (
    <Route
      {...rest}
      exact
      render={({location}) =>
        token ? (
          location.pathname === '/' ? (
            <Redirect to={{pathname: '/jobs', state: {from: location}}} />
          ) : (
            <>
              {Sidebar && <Sidebar />}
              <div className="main-content">{children}</div>
            </>
          )
        ) : (
          <Redirect to={{pathname: '/login', state: {from: location}}} />
        )
      }
    />
  );
}

export function AuthRoute({children, ...rest}: any) {
  const token = getState().secure.token;

  return (
    <Route
      {...rest}
      exact
      render={({location}) =>
        token ? (
          <Redirect to={{pathname: '/jobs', state: {from: location}}} />
        ) : location.pathname === '/verify-email' ? (
          <Redirect to={{pathname: '/login', state: {from: location}}} />
        ) : (
          children
        )
      }
    />
  );
}
