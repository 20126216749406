import createStrings from './createStrings';

export default createStrings({
  '%0 days selected': {},
  '%0 out of %1 pages': {},
  '%0 past jobs': {},
  '%0 Payslips selected': {},
  '%0 Record selected': {},
  '%0 stars': {},
  'Accept Applicant?': {},
  'Accumulated Amount': {},
  'A date from this month has already been added': {},
  'Add Centres': {},
  'Add Centres on Profile': {},
  'Add Date and Time': {},
  'Additional Information': {},
  'Admin of CMS': {},
  'A month’s payroll would consist of the Timesheets that were approved by the EP from the start and end of the month. At 5pm of every first day of the next month, these timesheets would be auto submitted to the FTP to be processed.':
    {},
  'An email has been sent to %0. Please follow the instructions to reset your password.':
    {},
  'Any important details?': {},
  'Any addtional details?': {},
  'Approved by EP': {},
  'Approved by EPA': {},
  'Approved by System': {},
  'Auto-Submission Schedule': {},
  'Are you sure you want to log out?': {},
  'Are you sure you want to remove this job?': {},
  'Back to Log In': {},
  'By accepting, the job vacancies will be filled. All other applicants would be rejected. The job posting would also no longer accept any applications.':
    {},
  'Centre Detail': {},
  'Centre Principal': {},
  'Change Password': {},
  'Confirm New Password': {},
  'Clock In': {},
  'Clock Out': {},
  'Click to browse file': {},
  'Contact Number': {},
  'Create Job Posting': {},
  'Create Job Successfully': {},
  'Create New Notification': {},
  'Create Notification Successfully': {},
  'Create PCF Staff Profile': {},
  'Create PCF Staff Profile Successfully': {},
  'Date/Time': {},
  'Date CP Submitted': {},
  'Date Created': {},
  'Date Fully Approved': {},
  'Date Paid': {},
  'Date Published': {},
  'Dates Paid': {},
  'Date Submitted': {},
  'Delete Date and Time': {},
  'Delete Announcement': {},
  'Delete Announcement Successfully': {},
  'Delete PCF Staff': {},
  'Delete Profile?': {},
  'Delete Profile Successfully': {},
  'Delete Schedule?': {},
  'Deselect All': {},
  'Discard Changes': {},
  'Drag and Drop your image or': {},
  'Duplicate Job Posting': {},
  'Edit Announcement': {},
  'Edit Centres': {},
  'Edit Centres on Profile': {},
  'Edit Date and Time': {},
  'Edit Profile': {},
  'Email Address': {},
  'Email or Phone Number': {},
  'End Time': {},
  'Enter Email': {},
  'Enter Email or Phone Number': {},
  'Enter Password': {},
  'Enter the One Time Password (OTP) that was sent to your mobile number': {},
  'Enter New Password': {},
  'Enter Pax No.': {},
  'Enter your registered Email': {},
  'Enter Title': {},
  'Employee Code': {},
  'Executive Principal': {},
  'Executive Principal Admin': {},
  'Expiring Medical Reports': {},
  'Expired Session': {},
  'Forget your password?': {},
  'Files supported: .png, .jpg (Max. 25 mb)': {},
  'General Details': {},
  'General Location': {},
  'General Locations': {},
  'Go to FTP Records': {},
  'Go to page': {},
  'Hired Staff Details': {},
  'Human Resource': {},
  'Invalid email': {},
  'Invalid email address or phone number': {},
  'Job Description': {},
  'Job Details': {},
  'Job History': {},
  'Job ID': {},
  'Job Postings': {},
  'Last Updated': {},
  'Latest First': {},
  'Lead Teacher': {},
  'Log In': {},
  'Log Out': {},
  'Medical Report Date': {},
  'Resigned Date': {},
  'medical reports': {},
  'More Details': {},
  'Next %0 month': {},
  'Next %0 months': {},
  'New Password': {},
  'No. of Hired': {},
  'No. of Reviews': {},
  'No Expiry': {},
  'Not fully approved yet': {},
  'Not Submitted by CP': {},
  'Not Present': {},
  'Notification Preferences': {},
  'Notifications Management': {},
  'Notification Details': {},
  'Number of Pax': {},
  'Only Push Notification': {},
  'Pending EP Approval': {},
  'Preview Text': {},
  'Preview Text are displayed at the push notification.': {},
  'Passwords do not match': {},
  'Payroll Month': {},
  'Payroll Month/ Payslip ID': {},
  'Payroll Records': {},
  'Payslip ID': {},
  'Payroll ID': {},
  'PCF Staff': {},
  'Please check your mailbox.': {},
  'Please enter a end time': {},
  'Please enter a start time': {},
  'Please enter a name': {},
  'Please enter a valid email': {},
  'Please enter a valid number': {},
  'Please enter a title': {},
  'Please select a centre': {},
  'Please select a date range': {},
  'Please select a date': {},
  'Please select a time': {},
  'Please select a role': {},
  'Please select a role or a district': {},
  'Please select a month': {},
  'Please select only 1 centre': {},
  'Please select only 1 payroll period': {},
  'Publish Now': {},
  'Primary Role': {},
  'Publish Date': {},
  'Publish Time': {},
  'Published on': {},
  'Ratings - Highest first': {},
  'Ratings - Lowest first': {},
  'Re-type Password': {},
  'Resend OTP': {},
  'Resend OTP in %0 secs': {},
  'Receipient - Roles': {},
  'Receipient - Districts': {},
  'Reject Applicant?': {},
  'Relief Staff': {},
  'Relief Staff Code': {},
  'Remember me': {},
  'Remove Job Posting': {},
  'Resend confirmation email': {},
  'Resend Email': {},
  'Roles Details': {},
  'Schedule Date': {},
  'Scheduled Date': {},
  'Save Changes': {},
  'Save Password': {},
  'Search Centre': {},
  'Search Centres': {},
  'Search Job History': {},
  'Search Job Postings': {},
  'Search Name': {},
  'Search Announcements': {},
  'Search Name or Employee Code': {},
  'Search Payroll': {},
  'Search Timesheets': {},
  'Select All': {},
  'Select Date': {},
  'Select Dates': {},
  'Select Payslips to submit them': {},
  'Select Role': {},
  'Select Time': {},
  'Select Timesheet records to approve or reject them. Only timesheets with both Clock In and Out timings can be approved or rejected.':
    {},
  'Senior Teacher': {},
  'Send Email': {},
  'Showing %0 Records': {},
  'Staff Details': {},
  'Staff Code': {},
  'Staff Name': {},
  'Staff ID': {},
  'Staff Profile': {},
  'Start Time': {},
  'Submit Payslips?': {},
  'Submitted by CP': {},
  'There are %0 that are about to expire in the next 3 months or have already expired. Please review them.':
    {},
  'The password must contain at least 7 alphanumeric characters with minimum one uppercase character.':
    {},
  'This action cannot be undone.': {},
  'This medical report has expired.': {},
  'Total Amount': {},
  'Total Hours Worked': {},
  'User ID': {},
  'Upload Photo': {},
  'Update Job Successfully': {},
  'Update Profile Successfully': {},
  'Update Profile Picture Successfully': {},
  'Update Notification Successfully': {},
  'Use at least 7 characters with an upper case letter, a numeric character, and a special character.':
    {},
  'Verify your email': {},
  'Verification email has been sent!': {},
  'Vice Principal': {},
  'View Job Description': {},
  'Waiting Clock Out': {},
  'We have sent you a confirmation email.': {},
  'Working Hours': {},
  'Wrong OTP': {},
  'You are accepting %0 for the job ‘%1’. Please ensure that the requirements are met.':
    {},
  'You are deleting staff ‘%0’.': {},
  'You can create job minimum 3 hours in advance.': {},
  'You are deleting announcement ‘%0’.': {},
  'You are deleting schedule ‘%0’.': {},
  'You are rejecting %0 for the job ‘%1’.': {},
  'You are submitting': {},
  'You would no longer see this applicant for the job posting after rejecting.':
    {},
  'You will be redirected to login page in %0 seconds.': {},
  'Your email address or phone number is not connected to any account. Please try again.':
    {},
  'Your session has expired. Please log in again.': {},
  Accept: {},
  Add: {},
  Address: {},
  Admin: {},
  All: {},
  Alphabetical: {},
  Announcements: {},
  Applicants: {},
  Attachment: {},
  Break: {},
  Cancel: {},
  Centre: {},
  Centres: {},
  Completed: {},
  Confirmed: {},
  Content: {},
  CP: {},
  Create: {},
  Created: {},
  Date: {},
  Day: {},
  Delete: {},
  District: {},
  Districts: {},
  Done: {},
  Email: {},
  EP: {},
  EPA: {},
  Experience: {},
  Expired: {},
  Export: {},
  General: {},
  Hired: {},
  HR: {},
  Incomplete: {},
  Job: {},
  Jobs: {},
  Joined: {},
  LT: {},
  Message: {},
  Month: {},
  MISS: {},
  MR: {},
  MRS: {},
  MS: {},
  Name: {},
  Notifications: {},
  Ok: {},
  Ongoing: {},
  Open: {},
  Optional: {},
  Paid: {},
  Password: {},
  Past: {},
  Payroll: {},
  Payrolls: {},
  Payslips: {},
  Pending: {},
  Processing: {},
  Profile: {},
  Published: {},
  Queued: {},
  Qualifications: {},
  Rate: {},
  Rating: {},
  Ratings: {},
  Records: {},
  Reject: {},
  Rejected: {},
  Recipients: {},
  Removed: {},
  Review: {},
  Reviewer: {},
  Reviews: {},
  Role: {},
  Roles: {},
  Salutation: {},
  Save: {},
  Schedule: {},
  Selected: {},
  Settings: {},
  ST: {},
  Staff: {},
  Staffs: {},
  Status: {},
  Success: {},
  Submit: {},
  Time: {},
  Timesheets: {},
  Title: {},
  to: {},
  Upcoming: {},
  User: {},
  VP: {},
  Years: {},
});
