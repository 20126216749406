import {getFilenameFromHeaders} from '../../utils';
import {API} from '../types';

type Params = {
  _start: number;
  _limit: number;
  _sort: string;
  query?: string;
  searchField?: string;
  payrollPeriods?: string[];
  statuses?: string[];
  centreDistrictGroups?: string[];
  export_csv: boolean;
};
interface Response {
  content: string;
  filename: string;
}

export function exportPayrollsAPI(params: Params): API<Response> {
  const urlParams = {
    ...params,
    ...(params.payrollPeriods && {
      payrollPeriods: params.payrollPeriods.join(','),
    }),
    ...(params.statuses && {statuses: params.statuses.join(',')}),
    ...(params.centreDistrictGroups && {
      centreDistrictGroups: params.centreDistrictGroups.join(','),
    }),
  };

  return {
    getData: async client => {
      const {headers, data} = await client({
        url: '/payrolls',
        method: 'GET',
        params: urlParams,
      });

      const filename = getFilenameFromHeaders(headers);

      return {
        data: {
          content: data,
          filename,
        },
      };
    },
    getMockData: () => {
      return {content: 'content', filename: 'filename'};
    },
  };
}
