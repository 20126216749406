type Language = 'en';
type Input<T> = {[S in keyof T]: string | {[U in Language]?: string}};
type Output<T> = (key: keyof T, ...args: any[]) => string;

export default function createStrings<T>(strings: Input<T>): Output<T> {
  return (key, ...args) => {
    const lang = 'en';
    let v: any = strings[key];
    v = (typeof v === 'object' ? v[lang] || v.en : v) ?? key;
    args.forEach((a, i) => {
      v = (v as string).replace('%' + i, a);
    });
    return v;
  };
}
