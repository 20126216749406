import styles from './Modal.module.scss';
import {ReactComponent as CloseIcon} from '../../assets/img/cross.svg';
import {
  forwardRef,
  Ref,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import SimpleBar from 'simplebar-react';

type ImperativeType = {
  showModal: () => void;
  hideModal: () => void;
} | null;

type ModalProps = {
  width?: number | string;
  bodyMaxHeight?: number;
  header?: JSX.Element | string;
  body?: JSX.Element | string;
  footer?: JSX.Element | string;
  showClose?: boolean;
  onHideFunc?: () => void;
};

function Modal(
  {
    width,
    bodyMaxHeight,
    header,
    body,
    footer,
    showClose = true,
    onHideFunc,
  }: ModalProps,
  ref: Ref<ImperativeType>
): any {
  const [show, setShow] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => ({
    showModal() {
      setShow(true);
    },
    hideModal() {
      onHideFunc && onHideFunc();
      setShow(false);
    },
  }));

  useEffect(() => {
    if (show) {
      divRef.current?.classList.add(styles.show);
      if (document.body.scrollHeight > window.innerHeight) {
        document.body.classList.add('modal-show');
      }
    } else {
      divRef.current?.classList.remove(styles.show);
      document.body.classList.remove('modal-show');
    }
  }, [show]);

  return (
    <div ref={divRef} className={styles.container}>
      <div className={styles.wrapper} style={{width: width || 442}}>
        {showClose && (
          <CloseIcon
            className={styles.closeIcon}
            onClick={() => {
              onHideFunc && onHideFunc();
              setShow(false);
            }}
          />
        )}
        {header && <div className={styles.header}>{header}</div>}
        {body && (
          <div className={styles.body}>
            {bodyMaxHeight ? (
              <SimpleBar style={{maxHeight: bodyMaxHeight, width: 'auto'}}>
                {body}
              </SimpleBar>
            ) : (
              body
            )}
          </div>
        )}
        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </div>
  );
}

export default forwardRef(Modal);
