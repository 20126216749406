import {Skill} from '../../models';

export const skills: Skill[] = [
  {
    id: 1,
    name: 'Teacher',
    bgColor: '#E8DFEF',
    color: '#662D91',
    smallIcon: 'https://i.imgur.com/d3DAwdd.png',
    icon: 'https://i.imgur.com/oqxwNAF.png',
    description: `* list1
* list2
* list2
* list2
* list2
* list2
* list2
* list2
* list2
* list2
* list2
* list2
* list2
* list2
* list2
* list2
* list2
* list2`,
    requirements: ['a', 'b'],
    rateAmount: 2,
    rateWeekend: 3,
    rateUnit: 'HR',
  },
  {
    id: 2,
    name: 'Cook',
    bgColor: 'rgba(141, 198, 63, 0.15)',
    color: '#63AB00',
    smallIcon: 'https://i.imgur.com/d3DAwdd.png',
    icon: 'https://i.imgur.com/oqxwNAF.png',
    description: `* list1
* list2`,
    requirements: ['a', 'b'],
    rateAmount: 2,
    rateWeekend: 3,
    rateUnit: 'HR',
  },
  {
    id: 3,
    name: 'Assistant Teacher',
    bgColor: '#E8DFEF',
    color: '#662D91',
    smallIcon: 'https://i.imgur.com/d3DAwdd.png',
    icon: 'https://i.imgur.com/oqxwNAF.png',
    description: `* list1
* list2`,
    requirements: ['a', 'b'],
    rateAmount: 2,
    rateWeekend: 3,
    rateUnit: 'HR',
  },
];
