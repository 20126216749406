import {Payroll} from '../../models';
import {API} from '../types';

type Params = {
  payrollIds: number[];
};
interface Response {
  list: Payroll[];
}

export function submitPayrollsAPI(params: Params): API<Response> {
  return {
    getData: async client => {
      const {data} = await client({
        url: '/payrolls/adhoc',
        method: 'POST',
        data: params,
      });
      return {data: data};
    },
    getMockData: () => {
      return {
        list: [
          {
            id: 11,
            period: '2021-12',
            status: 'pending',
            amount: 15,
            workedDuration: 300,
            payrollRun: undefined,
            centreId: 1,
            profileId: 68,
            paidAt: undefined,
            sentAt: undefined,
            createdAt: '2021-12-13T03:44:08.838Z',
            updatedAt: '2021-12-13T03:44:08.838Z',
            periodStart: '2021-12-12',
            periodEnd: '2021-12-12',
            payslipId: undefined,
          },
        ],
      };
    },
  };
}
