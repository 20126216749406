import {useState} from 'react';
import styles from './NavTabs.module.scss';

type NavTabsProps = {
  navTabList: Array<{
    id: string;
    label: string;
    content?: JSX.Element;
  }>;
  activeIndex?: number;
};

export function NavTabs({navTabList, activeIndex}: NavTabsProps) {
  const [selectedTab, setSelectedTab] = useState(
    navTabList[activeIndex || 0].id
  );

  const tabLinkElements = navTabList.map(tab => (
    <li
      key={`navItem-${tab.id}`}
      id={tab.id}
      className={`${styles.navItem} ${
        tab.id === selectedTab ? styles.active : ''
      }`}
      onClick={() => setSelectedTab(tab.id)}
    >
      <h3>{tab.label}</h3>
    </li>
  ));

  const tabContentElements = navTabList.map(tab => (
    <div
      key={`navContent-${tab.id}`}
      className={`${styles.tabPane} ${
        tab.id === selectedTab ? styles.show : ''
      }`}
    >
      {tab.content}
    </div>
  ));

  return (
    <div className={styles.container}>
      <ul className={styles.tabList}>{tabLinkElements}</ul>
      <div className={styles.tabContent}>{tabContentElements}</div>
    </div>
  );
}
