import styles from './MarkdownEditor.module.scss';
import ReactMarkdown from 'react-markdown';
import {useEffect, useRef, useState} from 'react';

type MarkdownEditorProps = {
  label: string;
  value: string;
  id: string;
  name: string;
  disabled?: boolean;
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

export function MarkdownEditor({
  label,
  value,
  id,
  name,
  disabled = false,
  handleChange,
}: MarkdownEditorProps) {
  const [showInput, setShowInput] = useState(false);

  const inputRef = useRef<HTMLTextAreaElement | null>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.height = inputRef.current.scrollHeight + 'px';
      inputRef.current.value = '';
      inputRef.current.value = value;
      inputRef.current.focus();
    }
  }, [showInput]);

  return (
    <div>
      <div className={styles.label}>{label}</div>
      <textarea
        style={{display: showInput ? 'inline-block' : 'none'}}
        className={styles.inputArea}
        ref={inputRef}
        name={name}
        id={id}
        value={value}
        onChange={e => {
          e.target.style.height = 'inherit';
          e.target.style.height = e.target.scrollHeight + 'px';
          handleChange(e);
        }}
        onBlur={e => setShowInput(false)}
      ></textarea>
      {!showInput && (
        <div
          className={styles.displayArea}
          onClick={e => !disabled && setShowInput(true)}
        >
          <ReactMarkdown children={value}></ReactMarkdown>
        </div>
      )}
    </div>
  );
}
