import styles from './InputOTP.module.scss';
import {ReactComponent as ErrorIcon} from '../../assets/img/error.svg';
import {useEffect, useRef, useState} from 'react';

type InputOTPProps = {
  label?: string;
  error?: string;
  handleOTPChange: (value: string) => void;
};

export function InputOTP({label, error = '', handleOTPChange}: InputOTPProps) {
  const [value1, setValue1] = useState('');
  const [value2, setValue2] = useState('');
  const [value3, setValue3] = useState('');
  const [value4, setValue4] = useState('');
  const [value5, setValue5] = useState('');
  const [value6, setValue6] = useState('');

  const ref1 = useRef<any>(null);
  const ref2 = useRef<any>(null);
  const ref3 = useRef<any>(null);
  const ref4 = useRef<any>(null);
  const ref5 = useRef<any>(null);
  const ref6 = useRef<any>(null);

  useEffect(() => {
    const otpString = value1 + value2 + value3 + value4 + value5 + value6;
    handleOTPChange(otpString);
  }, [value1, value2, value3, value4, value5, value6]);

  const handleInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    setValue: React.Dispatch<React.SetStateAction<string>>,
    nextRef: React.MutableRefObject<any>
  ) => {
    const value = e.target.value.replace(/[^0-9]/g, '');
    setValue(value);
    if (value != '') {
      nextRef.current.focus();
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const text = e.clipboardData.getData('Text');
    text[0] && setValue1(text[0].replace(/[^0-9]/g, ''));
    text[1] && setValue2(text[1].replace(/[^0-9]/g, ''));
    text[2] && setValue3(text[2].replace(/[^0-9]/g, ''));
    text[3] && setValue4(text[3].replace(/[^0-9]/g, ''));
    text[4] && setValue5(text[4].replace(/[^0-9]/g, ''));
    text[5] && setValue6(text[5].replace(/[^0-9]/g, ''));
  };

  const handleDelete = (
    e: React.KeyboardEvent<HTMLInputElement>,
    preRef: React.MutableRefObject<any>
  ) => {
    const target = e.target as EventTarget & HTMLInputElement;
    if (e.key === 'Backspace' && target.value === '') {
      e.preventDefault();
      preRef.current.focus();
    }
  };

  return (
    <div className={styles.container}>
      {label && (
        <p className={styles.title}>
          <span>{label}</span>
        </p>
      )}
      <div className={styles.inputWrapper}>
        <input
          ref={ref1}
          value={value1}
          type="text"
          onChange={e => handleInput(e, setValue1, ref2)}
          onKeyDown={e => handleDelete(e, ref1)}
          onPaste={e => handlePaste(e)}
          maxLength={1}
        />
        <input
          ref={ref2}
          value={value2}
          type="text"
          onChange={e => handleInput(e, setValue2, ref3)}
          onKeyDown={e => handleDelete(e, ref1)}
          onPaste={e => handlePaste(e)}
          maxLength={1}
        />
        <input
          ref={ref3}
          value={value3}
          type="text"
          onChange={e => handleInput(e, setValue3, ref4)}
          onKeyDown={e => handleDelete(e, ref2)}
          onPaste={e => handlePaste(e)}
          maxLength={1}
        />
        <input
          ref={ref4}
          value={value4}
          type="text"
          onChange={e => handleInput(e, setValue4, ref5)}
          onKeyDown={e => handleDelete(e, ref3)}
          onPaste={e => handlePaste(e)}
          maxLength={1}
        />
        <input
          ref={ref5}
          value={value5}
          type="text"
          onChange={e => handleInput(e, setValue5, ref6)}
          onKeyDown={e => handleDelete(e, ref4)}
          onPaste={e => handlePaste(e)}
          maxLength={1}
        />
        <input
          ref={ref6}
          value={value6}
          type="text"
          onChange={e => handleInput(e, setValue6, ref6)}
          onKeyDown={e => handleDelete(e, ref5)}
          onPaste={e => handlePaste(e)}
          maxLength={1}
        />
      </div>
      {error !== '' && (
        <div className={styles.errorWrapper}>
          <ErrorIcon />
          <span>{error}</span>
        </div>
      )}
    </div>
  );
}
