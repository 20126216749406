import {useSyncCentres} from '../../services/hooks';

export function useCentreList() {
  const {centresApi, centres} = useSyncCentres();

  return {
    centresApi,
    centres,
  };
}
