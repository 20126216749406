import {strings} from '../../services/language';

export function useCreateStaffLogic() {
  const breadcrumbList = [
    {
      title: strings('PCF Staff'),
      route: '/pcf-staffs',
    },
    {
      title: strings('Create PCF Staff Profile'),
      active: true,
    },
  ];

  return {
    breadcrumbList,
  };
}
