import {getFilenameFromHeaders} from '../../utils';
import {API} from '../types';

type Params = {
  export_csv: boolean;
};
interface Response {
  content: string;
  filename: string;
}

export function exportCentresAPI(params: Params): API<Response> {
  return {
    getData: async client => {
      const {headers, data} = await client({
        url: '/centres',
        method: 'GET',
        params: {
          export_csv: params.export_csv,
        },
      });

      const filename = getFilenameFromHeaders(headers);

      return {
        data: {
          content: data,
          filename,
        },
      };
    },
    getMockData: () => {
      return {content: 'content', filename: 'filename'};
    },
  };
}
