import styles from './FileUpload.module.scss';
import {ReactComponent as AttachmentIcon} from '../../assets/img/attachment.svg';
import {ReactComponent as CheckIcon} from '../../assets/img/check.svg';
import {ReactComponent as CrossIcon} from '../../assets/img/cross.svg';
import {strings} from '../../services/language';
import {useMemo, useState} from 'react';

type FileUploadProps = {
  previewImage?: boolean;
  disabled?: boolean;
  onSelectFiles: (files: {[key: string]: File}) => void;
};

export function FileUpload({
  previewImage = false,
  disabled,
  onSelectFiles,
}: FileUploadProps) {
  const [files, setFiles] = useState<{[key: string]: File}>({});

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      const file = event.target.files[0];
      setFiles({
        [file.name]: file,
      });
      onSelectFiles({
        [file.name]: file,
      });
      event.target.value = '';
    }
  };

  const fileItems = useMemo(
    () =>
      Object.keys(files).map((fileName, index) => (
        <div key={index} className={styles.fileItem}>
          <div className={styles.fileWrapper}>
            <AttachmentIcon />
            <div className={styles.fileInfo}>
              <div>{fileName}</div>
              <div>{returnFileSize(files[fileName].size)}</div>
            </div>
            <div
              className={styles.removeIcon}
              onClick={() => removeFile(fileName)}
            >
              <CrossIcon />
            </div>
          </div>
          <div className={styles.completeIcon}>
            <CheckIcon style={{stroke: ' var(--white)'}} />
          </div>
        </div>
      )),
    [files]
  );

  const imagePreview = useMemo(() => {
    if (Object.keys(files).length > 0) {
      const src = URL.createObjectURL(files[Object.keys(files)[0]]);

      return (
        <div
          className={styles.previewImage}
          style={{backgroundImage: `url('${src}')`}}
        >
          <img src={src} />
        </div>
      );
    }
    return null;
  }, [files]);

  const removeFile = (fileName: string) => {
    delete files[fileName];
    setFiles({...files});
    onSelectFiles({...files});
  };

  return (
    <div className={`${styles.container} ${disabled ? styles.disabled : ''}`}>
      <div className={styles.wrapper}>
        <input
          className={styles.fileInput}
          type="file"
          accept="image/png, image/jpeg"
          title=""
          onChange={handleFileUpload}
          disabled={disabled}
        />

        {previewImage && Object.keys(files).length > 0 ? (
          imagePreview
        ) : (
          <div className={styles.dropArea}>
            <div>{strings('Drag and Drop your image or')}</div>
            <div>{strings('Click to browse file')}</div>
            <div>{strings('Files supported: .png, .jpg (Max. 25 mb)')}</div>
          </div>
        )}
      </div>

      {Object.keys(files).length > 0 && (
        <div className={styles.uploadedFiles}>{fileItems}</div>
      )}
    </div>
  );
}

function returnFileSize(size: number) {
  if (size < 1024) {
    return size + ' bytes';
  } else if (size >= 1024 && size < 1048576) {
    return (size / 1024).toFixed(1) + ' KB';
  } else if (size >= 1048576) {
    return (size / 1048576).toFixed(1) + ' MB';
  }
}
