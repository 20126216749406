import moment from 'moment';
import {Badge} from '../../components';
import {strings} from '../language';
import {JobStatus, Skill, Role} from '../models';

type jobStatusOptionsType = {
  content: string | JSX.Element;
  value: string | JobStatus;
  selectable?: boolean;
  class?: string;
}[];

type staffRolesType = {
  [key in Role]: string;
};

export const jobStatusOptions: jobStatusOptionsType = [
  {
    content: strings('Open'),
    value: '',
    selectable: false,
    class: 'jobGroupItem',
  },
  {
    content: <Badge type={'jobStatus'} status={'open'} />,
    value: 'open',
  },
  {
    content: strings('Confirmed'),
    value: '',
    selectable: false,
    class: 'jobGroupItem',
  },
  {
    content: <Badge type={'jobStatus'} status={'upcoming'} />,
    value: 'upcoming',
  },
  {
    content: <Badge type={'jobStatus'} status={'ongoing'} />,
    value: 'ongoing',
  },
  {
    content: strings('Past'),
    value: '',
    selectable: false,
    class: 'jobGroupItem',
  },
  {
    content: <Badge type={'jobStatus'} status={'completed'} />,
    value: 'completed',
  },
  {
    content: <Badge type={'jobStatus'} status={'removed'} />,
    value: 'removed',
  },
  {
    content: <Badge type={'jobStatus'} status={'expired'} />,
    value: 'expired',
  },
];

export function createSkillOptions(skills: Skill[]) {
  return skills.map(skill => ({
    content: <Badge type={'skill'} kind="skillIconText" skill={skill} />,
    value: skill.id,
  }));
}

export const payrollStatusOptions = [
  {
    content: <Badge type={'payrollStatus'} status={'pending'} />,
    value: 'pending',
  },
  {
    content: <Badge type={'payrollStatus'} status={'processing'} />,
    value: 'processing',
  },
  {
    content: <Badge type={'payrollStatus'} status={'paid'} />,
    value: 'paid',
  },
];

export const notiStatusOptions = [
  {
    content: <Badge type={'notificationStatus'} status={'queued'} />,
    value: 'queued',
  },
  {
    content: <Badge type={'notificationStatus'} status={'published'} />,
    value: 'published',
  },
];

export function createPayrollMonthOptions(yearDuration: number) {
  const options: any[] = [];

  for (let i = 0; i < yearDuration; i++) {
    const today = moment();
    const month = today.month();
    const year = today.year() - i;

    options.push({
      content: year.toString(),
      value: year.toString(),
      isGroup: true,
      class: 'optionGroupItem',
    });

    for (let j = i === 0 ? month : 11; j >= 0; j--) {
      const date = moment({month: j, year: year});

      options.push({
        content: date.format('MMM YY'),
        value: date.format('YYYYMM'),
        group: year.toString(),
      });
    }
  }

  return options;
}

export const timesheetStatusOptions = [
  {
    content: <Badge type={'timesheetStatus'} status={'waitingCP'} />,
    value: 'waitingCP',
  },
  {
    content: <Badge type={'timesheetStatus'} status={'waitingClockOut'} />,
    value: 'waitingClockOut',
  },
  {
    content: <Badge type={'timesheetStatus'} status={'waitingEP'} />,
    value: 'waitingEP',
  },
  {
    content: <Badge type={'timesheetStatus'} status={'approvedEP'} />,
    value: 'approvedEP',
  },
  {
    content: <Badge type={'timesheetStatus'} status={'notPresent'} />,
    value: 'notPresent',
  },
  {
    content: <Badge type={'timesheetStatus'} status={'rejected'} />,
    value: 'rejected',
  },
];

const staffRolesObj: staffRolesType = {
  HR: strings('Admin of CMS'),
  EP: strings('Executive Principal'),
  CP: strings('Centre Principal'),
  VP: strings('Vice Principal'),
  LT: strings('Lead Teacher'),
  ST: strings('Senior Teacher'),
  RS: strings('Relief Staff'),
};

export const staffRoles: {[key: string]: string} = staffRolesObj;

export const staffRoleOptions = Object.keys(
  staffRoles as {[key: string]: string}
)
  .filter(role => role !== 'RS')
  .map(role => ({
    content: (
      <div>
        <div>{staffRoles[role]}</div>
        <div className="greyText">{role}</div>
      </div>
    ),
    selectedContent: staffRoles[role],
    value: role,
  }));

export const staffRoleCreateOptions = Object.keys(
  staffRoles as {[key: string]: string}
)
  .filter(role => role !== 'RS' && role !== 'CP')
  .map(role => ({
    content: (
      <div>
        <div>{staffRoles[role]}</div>
        <div className="greyText">{role}</div>
      </div>
    ),
    selectedContent: staffRoles[role],
    value: role,
  }));

export * from './district';
export * from './location';
