import styles from './Timesheets.module.scss';
import {Table, Select, Button, Badge, PcfDatePicker} from '../../../components';
import {useEffect, useMemo, useState} from 'react';
import {Cell, Column, TableInstance} from 'react-table';
import {ReactComponent as PersonIcon} from '../../../assets/img/person.svg';
import {ReactComponent as TagIcon} from '../../../assets/img/tag.svg';
import {ReactComponent as SortIcon} from '../../../assets/img/sort.svg';
import {strings} from '../../../services/language';
import {useHistory} from 'react-router-dom';
import {formatISOString} from '../../../services/utils';
import {
  Application,
  TimesheetStatus,
  Timesheet,
  Profile,
} from '../../../services/models';
import {timesheetStatusOptions} from '../../../services/helpers';
import moment from 'moment';

type TimesheetsProps = {
  applications: Application[] | undefined;
};

interface TimesheetsData {
  reliefStaff: JSX.Element;
  clockIn: string;
  clockOut: string;
  break: string;
  totalWorked: JSX.Element;
  status: TimesheetStatus;
  dateUpdated: string;

  date: string;
  userId: number;
  userName: string;
  userPhoto: string;
  fromDate: Date;
  toDate: Date;
}

interface JobTimesheet extends Timesheet {
  profile: Profile;
  dateId?: string;
}

export default function Timesheets({applications}: TimesheetsProps) {
  const [sortBy, setSortBy] = useState('date');
  const [selectFilters, setSelectFilters] = useState<{
    [key: string]: string | (string | number)[];
  }>({
    status: [],
    userId: [],
    fromDate: '',
    toDate: '',
  });

  const jobTimesheets: JobTimesheet[] = useMemo(() => {
    let timesheets: JobTimesheet[] = [];
    if (applications) {
      applications.forEach(a => {
        const temp = a.timesheets
          ? a.timesheets.map(t => ({
              ...t,
              profile: a.profile,
            }))
          : [];
        timesheets = [...timesheets, ...temp];
      });
      return timesheets;
    } else return [];
  }, [applications]);

  const staffOptions = useMemo(
    () =>
      applications
        ? applications.map(a => ({
            content: (
              <div className={styles.userCell}>
                <div
                  className={`${styles.avatar} ${styles.small}`}
                  style={{
                    backgroundImage: a.profile.photo
                      ? `url(${a.profile.photo})`
                      : 'none',
                  }}
                ></div>
                <div className={styles.userRight}>
                  <div>{a.profile.name}</div>
                </div>
              </div>
            ),
            value: a.profile.id,
          }))
        : [],
    [applications]
  );

  const history = useHistory();
  const columns: Column[] = useMemo(
    () => createColumns(jobTimesheets),
    [sortBy]
  );
  const data: TimesheetsData[] = useMemo(
    () => createRowsData(jobTimesheets, sortBy, history),
    [sortBy, jobTimesheets]
  );

  const hiddenColumns = [
    'date',
    'userId',
    'userName',
    'userPhoto',
    'fromDate',
    'toDate',
  ];

  const sortList = [
    {
      content: strings('Day'),
      value: 'date',
    },
    {
      content: strings('Staff'),
      value: 'userId',
    },
  ];

  useEffect(() => {
    setSelectFilters({...selectFilters});
  }, [data]);

  return (
    <div className={styles.container}>
      <h3>{strings('Total Hours Worked')}</h3>
      <div className={styles.greyText}>
        {strings(
          'Select Timesheet records to approve or reject them. Only timesheets with both Clock In and Out timings can be approved or rejected.'
        )}
      </div>
      <div className={styles.filtersContainer}>
        <div className={styles.buttonContainer}>
          <div className={styles.selectFilters}>
            <PcfDatePicker
              placeholder={strings('Date')}
              sgTimeZone
              onSelectDate={value => {
                setSelectFilters({
                  ...selectFilters,
                  fromDate: value.from || '',
                  toDate: value.to || '',
                });
              }}
            />
            <Select
              fixedLabel={<PersonIcon />}
              defaultLabel={strings('Relief Staff')}
              optionList={staffOptions}
              multipleSelect
              allToggle
              allItem
              onSelectValue={values => {
                setSelectFilters({...selectFilters, userId: values});
              }}
            />
            <Select
              fixedLabel={<TagIcon />}
              defaultLabel={strings('Status')}
              optionList={timesheetStatusOptions}
              multipleSelect
              allToggle
              allItem
              onSelectValue={values => {
                setSelectFilters({...selectFilters, status: values});
              }}
            />
            <div className={styles.sortWrapper}>
              <Select
                fixedLabel={<SortIcon />}
                optionList={sortList}
                value={sortBy}
                onSelectValue={value => setSortBy(value[0])}
              />
            </div>
          </div>
        </div>
      </div>
      <Table
        columns={columns}
        data={data}
        hiddenColumns={hiddenColumns}
        pageNumber={data.length * 2}
        groupByColumns={[sortBy]}
        tbodyMaxHeight={678}
        columnsFilters={selectFilters}
      />
    </div>
  );
}

function createColumns(jobTimesheets: any[]) {
  return [
    {
      Header: strings('Relief Staff'),
      accessor: 'reliefStaff',
      aggregate: '',
      Aggregated: (props: any) => {
        if (props.row.groupByID === 'date') {
          return props.row.subRows[0]?.original?.dateId;
        } else {
          const user = props.row.subRows[0].original;

          return (
            <div className={styles.userCell}>
              <div
                className={styles.avatar}
                style={{
                  backgroundImage: user.userPhoto
                    ? `url(${user.userPhoto})`
                    : 'none',
                }}
              ></div>
              <div className={styles.userRight}>
                <div>{user.userName}</div>
                <div className={styles.greyText}>{user.userId}</div>
              </div>
            </div>
          );
        }
      },
    },
    {
      Header: strings('Clock In'),
      accessor: 'clockIn',
      aggregate: '',
      Aggregated: ' ',
    },
    {
      Header: strings('Clock Out'),
      accessor: 'clockOut',
      aggregate: '',
      Aggregated: ' ',
    },
    {
      Header: strings('Break'),
      accessor: 'break',
      aggregate: '',
      Aggregated: ' ',
    },
    {
      Header: strings('Total Hours Worked'),
      accessor: 'totalWorked',
      aggregate: '',
      Aggregated: ' ',
    },
    {
      Header: strings('Status'),
      accessor: 'status',
      aggregate: '',
      Aggregated: ' ',
      Cell: (props: TableInstance & Cell) => (
        <Badge type="timesheetStatus" status={props.value} />
      ),
      filter: 'selectOptions',
    },
    {
      Header: strings('Last Updated'),
      accessor: 'dateUpdated',
      aggregate: '',
      Aggregated: ' ',
    },
    {
      Header: '',
      accessor: 'date',
    },
    {
      Header: '',
      accessor: 'userId',
      filter: 'selectOptions',
    },
    {
      Header: '',
      accessor: 'userName',
    },
    {
      Header: '',
      accessor: 'userPhoto',
    },
    {
      Header: 'fromDate',
      accessor: 'fromDate',
      filter: 'sameOrAfter',
    },
    {
      Header: 'toDate',
      accessor: 'toDate',
      filter: 'sameOrBefore',
    },
  ];
}

function createRowsData(
  timesheetsMockDB: JobTimesheet[],
  sortBy: string,
  history: any
): TimesheetsData[] {
  return timesheetsMockDB.map((timesheet, index) => {
    const breakDuration = timesheet.breakDuration
      ? timesheet.breakDuration / 60
      : undefined;
    const profile = timesheet.profile;
    const tempDate = timesheet?.dayId?.split('-')?.slice(1).reverse().join('/');
    return {
      dateId: tempDate,
      reliefStaff:
        sortBy === 'userId' ? (
          <div>
            {timesheet.startsAt
              ? formatISOString(timesheet.startsAt!, 'DD/MM/YY')
              : tempDate}
          </div>
        ) : (
          <div className={styles.userCell}>
            <div
              className={styles.avatar}
              style={{
                backgroundImage: profile.photo
                  ? `url(${profile.photo})`
                  : 'none',
              }}
            ></div>
            <div className={styles.userRight}>
              <div>{profile.name}</div>
              <div className={styles.greyText}>{profile.id}</div>
            </div>
          </div>
        ),
      clockIn: timesheet.startsAt
        ? formatISOString(timesheet.startsAt, 'h:mmA')
        : '-',
      clockOut: timesheet.endsAt
        ? formatISOString(timesheet.endsAt, 'h:mmA')
        : '-',
      break: breakDuration
        ? (Number.isInteger(breakDuration)
            ? breakDuration
            : breakDuration.toFixed(1)) + ' hrs'
        : '-',
      totalWorked: (
        <div>
          {timesheet.startsAt && timesheet.endsAt ? (
            <span>
              {moment(timesheet.endsAt).diff(
                moment(timesheet.startsAt),
                'hour'
              ) + ' hrs'}
            </span>
          ) : (
            <span className={styles.redText}>{strings('Incomplete')}</span>
          )}
        </div>
      ),
      status: timesheet.status,
      dateUpdated: formatISOString(timesheet.updatedAt, 'DD/MM/YY'),
      date: timesheet.startsAt || timesheet.createdAt,
      userId: profile.id,
      userName: profile.name,
      userPhoto: profile.photo || '',
      fromDate: moment(timesheet.startsAt || timesheet.createdAt)
        .add(-1, 'day')
        .toDate(),
      toDate: new Date(timesheet.startsAt || timesheet.createdAt),
    };
  });
}
