import {Job} from '../../models';

export const job: Job = {
  id: 1,
  vacancy: 2,
  hired: 1,
  startsAt: '27/3/2021',
  endsAt: '22/4/2021',
  daysOff: [1, 2],
  createdAt: '3/3/2021',
  deletedAt: '11/20/2020',
  additionalInfo: 'curabitur',
  skillId: 1,
  centreId: 1,
  profileId: 65,
  applicationIds: [1, 2],
};
