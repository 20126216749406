import {Application, Job} from '../../models';
import {job} from '../mockDB';
import {API} from '../types';

interface Params {
  jobId: number;
  verifyArr: {
    applicationId: number;
    approve: boolean;
  }[];
}

interface Response {
  job: Job;
}

export function verifyApplication(params: Params): API<Response> {
  return {
    getData: async client => {
      const {data} = await client({
        url: `jobs/${params.jobId}/verify-applications`,
        method: 'POST',
        data: {
          list: params.verifyArr,
        },
      });
      return {
        data: {
          job: data,
        },
      };
    },
    getMockData: () => {
      return {job: job};
    },
  };
}
