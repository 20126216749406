export const districtOptions = [
  {
    content: 'Branch Operated Centre',
    value: 'Branch Operated Centre',
    isGroup: true,
    class: 'optionGroupItem',
  },
  {
    content: 'BOC AC',
    value: 'BOC AC',
    group: 'Branch Operated Centre',
  },
  {
    content: 'BOC CC',
    value: 'BOC CC',
    group: 'Branch Operated Centre',
  },
  {
    content: 'BOC SC',
    value: 'BOC SC',
    group: 'Branch Operated Centre',
  },
  {
    content: 'Central',
    value: 'Central',
    isGroup: true,
    class: 'optionGroupItem',
  },
  {
    content: 'CS01',
    value: 'CS01',
    group: 'Central',
  },
  {
    content: 'CS02',
    value: 'CS02',
    group: 'Central',
  },
  {
    content: 'CS03',
    value: 'CS03',
    group: 'Central',
  },
  {
    content: 'CS04',
    value: 'CS04',
    group: 'Central',
  },
  {
    content: 'CS05',
    value: 'CS05',
    group: 'Central',
  },
  {
    content: 'North East',
    value: 'North East',
    isGroup: true,
    class: 'optionGroupItem',
  },
  {
    content: 'NE01',
    value: 'NE01',
    group: 'North East',
  },
  {
    content: 'NE02',
    value: 'NE02',
    group: 'North East',
  },
  {
    content: 'NE03',
    value: 'NE03',
    group: 'North East',
  },
  {
    content: 'NE04',
    value: 'NE04',
    group: 'North East',
  },
  {
    content: 'NE05',
    value: 'NE05',
    group: 'North East',
  },
  {
    content: 'North West',
    value: 'North West',
    isGroup: true,
    class: 'optionGroupItem',
  },
  {
    content: 'NW01',
    value: 'NW01',
    group: 'North West',
  },
  {
    content: 'NW02',
    value: 'NW02',
    group: 'North West',
  },
  {
    content: 'NW03',
    value: 'NW03',
    group: 'North West',
  },
  {
    content: 'NW04',
    value: 'NW04',
    group: 'North West',
  },
  {
    content: 'NW05',
    value: 'NW05',
    group: 'North West',
  },
  {
    content: 'South East',
    value: 'South East',
    isGroup: true,
    class: 'optionGroupItem',
  },
  {
    content: 'SE01',
    value: 'SE01',
    group: 'South East',
  },
  {
    content: 'SE02',
    value: 'SE02',
    group: 'South East',
  },
  {
    content: 'South West',
    value: 'South West',
    isGroup: true,
    class: 'optionGroupItem',
  },
  {
    content: 'SW01',
    value: 'SW01',
    group: 'South West',
  },
  {
    content: 'SW02',
    value: 'SW02',
    group: 'South West',
  },
  {
    content: 'SW03',
    value: 'SW03',
    group: 'South West',
  },
  {
    content: 'SW04',
    value: 'SW04',
    group: 'South West',
  },
  {
    content: 'SW05',
    value: 'SW05',
    group: 'South West',
  },
];

export const postalDistrictOptions = [
  {
    content: 'Central',
    value: 'Central',
    isGroup: true,
    class: 'optionGroupItem',
  },
  {
    value: 1,
    content: "Raffles Place, Cecil, Marina, People's Park",
    group: 'Central',
  },
  {
    value: 2,
    content: 'Anson, Tanjong Pagar',
    group: 'Central',
  },
  {
    value: 3,
    content: 'Bukit Merah, Queenstown, Tiong Bahru',
    group: 'Central',
  },
  {
    value: 4,
    content: 'Telok Blangah, Harbourfront',
    group: 'Central',
  },
  {
    value: 6,
    content: 'High Street, Beach Road (part)',
    group: 'Central',
  },
  {
    value: 7,
    content: 'Middle Road, Golden Mile',
    group: 'Central',
  },
  {
    value: 8,
    content: 'Little India, Farrer Park, Jalan Besar, Lavender',
    group: 'Central',
  },
  {
    value: 9,
    content: 'Orchard, Cairnhill, River Valley',
    group: 'Central',
  },
  {
    value: 10,
    content: 'Ardmore, Bukit Timah, Holland Road, Tanglin',
    group: 'Central',
  },
  {
    value: 11,
    content: 'Watten Estate, Novena, Thomson',
    group: 'Central',
  },
  {
    value: 12,
    content: 'Balestier, Toa Payoh, Serangoon',
    group: 'Central',
  },
  {
    value: 13,
    content: 'Macpherson, Braddell',
    group: 'Central',
  },
  {
    value: 14,
    content: 'Geylang, Eunos, Aljunied',
    group: 'Central',
  },
  {
    value: 21,
    content: 'Upper Bukit Timah, Clementi Park, Ulu Pandan',
    group: 'Central',
  },
  {
    value: 26,
    content: 'Upper Thomson, Springleaf',
    group: 'Central',
  },
  {
    content: 'West',
    value: 'West',
    isGroup: true,
    class: 'optionGroupItem',
  },
  {
    value: 5,
    content: 'Pasir Panjang, Hong Leong Garden, Clementi New Town',
    group: 'West',
  },
  {
    value: 22,
    content: 'Penjuru, Jurong, Pioneer, Tuas',
    group: 'West',
  },
  {
    value: 23,
    content: 'Hillview, Dairy Farm, Bukit Panjang, Choa Chu Kang',
    group: 'West',
  },
  {
    content: 'North',
    value: 'North',
    isGroup: true,
    class: 'optionGroupItem',
  },
  {
    value: 24,
    content: 'Lim Chu Kang, Tengah',
    group: 'North',
  },
  {
    value: 25,
    content: 'Kranji, Woodgrove, Woodlands',
    group: 'North',
  },
  {
    value: 27,
    content: 'Yishun, Sembawang, Senoko',
    group: 'North',
  },
  {
    content: 'North East',
    value: 'North East',
    isGroup: true,
    class: 'optionGroupItem',
  },
  {
    value: 19,
    content: 'Serangoon Garden, Hougang, Punggol',
    group: 'North East',
  },
  {
    value: 20,
    content: 'Bishan, Ang Mo Kio',
    group: 'North East',
  },
  {
    value: 28,
    content: 'Seletar',
    group: 'North East',
  },
  {
    content: 'East',
    value: 'East',
    isGroup: true,
    class: 'optionGroupItem',
  },
  {
    value: 15,
    content: 'Katong, Joo Chiat, Amber Road',
    group: 'East',
  },
  {
    value: 16,
    content: 'Bedok, Upper East Cost, Eastwood, Kew Drive',
    group: 'East',
  },
  {
    value: 17,
    content: 'Loyang, Changi',
    group: 'East',
  },
  {
    value: 18,
    content: 'Simei, Tampines, Pasir Ris',
    group: 'East',
  },
];
