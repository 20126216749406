import {API} from '../types';

type Params = {
  files: File;
};

interface Response {
  photo: string;
}

export function uploadPhotoAPI(params: Params): API<Response> {
  const formData = new FormData();
  formData.append('photo', params.files);

  return {
    getData: async client => {
      const {data} = await client({
        url: '/profiles/my/upload-photo',
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return {data: data};
    },
    getMockData: () => {
      return {photo: '#'};
    },
  };
}
