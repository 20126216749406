import {API} from '../types';

interface Response {
  success: boolean;
}

export function deleteNotificationAPI(id: number): API<Response> {
  return {
    getData: async client => {
      const {data} = await client({
        url: `/notifications/${id}`,
        method: 'DELETE',
      });
      return {data: data};
    },
    getMockData: () => {
      return {success: false};
    },
  };
}
