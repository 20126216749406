import {API} from '../types';
import {getFilenameFromHeaders} from '../../utils';

type GetJobsParams = {
  _start: number;
  _limit: number;
  _sort?: any;
  query?: string;
  searchField?: string;
  skillIds?: number[];
  statuses?: string[];
  districtGroups?: string[];
  startsAt_lt?: string;
  endsAt_gt?: string;
  export_csv: boolean;
};

interface Response {
  content: string;
  filename: string;
}

export function exportJobsAPI(params: GetJobsParams): API<Response> {
  const urlParams = {
    ...params,
    ...(params.skillIds && {skillIds: params.skillIds.join(',')}),
    ...(params.statuses && {statuses: params.statuses.join(',')}),
    ...(params.districtGroups && {
      districtGroups: params.districtGroups.join(','),
    }),
  };

  return {
    getData: async client => {
      const {headers, data} = await client({
        url: '/jobs',
        method: 'GET',
        params: urlParams,
      });

      const filename = getFilenameFromHeaders(headers);

      return {
        data: {
          content: data,
          filename,
        },
      };
    },
    getMockData: () => {
      return {content: 'content', filename: 'filename'};
    },
  };
}
