import {strings} from '../../../services/language';
import {Skill} from '../../../services/models';
import {Table, Select, Button, PcfDatePicker, Modal} from '../../../components';
import styles from './JobPostings.module.scss';
import {ReactComponent as SearchIcon} from '../../../assets/img/search.svg';
import {ReactComponent as WorkIcon} from '../../../assets/img/work.svg';
import {ReactComponent as CalenderIcon} from '../../../assets/img/calender.svg';
import {ReactComponent as TagIcon} from '../../../assets/img/tag.svg';
import {ReactComponent as LocationIcon} from '../../../assets/img/location.svg';
import {jobStatusOptions, districtOptions} from '../../../services/helpers';
import {useJobPostingsLogic} from './logics';

type JobPostingsProps = {
  skills: Skill[];
};

export default function JobPostings({skills}: JobPostingsProps) {
  const {
    _limit,
    hiddenColumns,
    total,
    manualFilterSort,
    setRole,
    setStatus,
    setDistrict,
    setStartDate,
    setEndDate,
    searchValue,
    setSearchValue,
    setSearchField,
    searchFilterList,
    skillOptions,
    columns,
    data,
    goToCreateJob,
    fetchData,
    exportJobs,
    resultModalRef,
    error,
    monthOptions,
    setMonth,
  } = useJobPostingsLogic(skills);

  return (
    <div className={styles.container}>
      <div className={styles.filtersContainer}>
        <div className={styles.searchWrapper}>
          <div className={styles.searchInput}>
            <SearchIcon
              className={styles.searchIcon}
              style={{fill: 'var(--grey-text)'}}
            />
            <input
              type="text"
              name="searchQuery"
              id="searchQuery"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              placeholder={strings('Search Job Postings')}
            />
            <div className={styles.searchFilterWrapper}>
              <Select
                optionList={searchFilterList}
                allItem
                onSelectValue={values => {
                  values.length > 0
                    ? setSearchField(values[0])
                    : setSearchField('all');
                }}
              />
            </div>
          </div>
          <span className={styles.numberRecord}>{`${total} ${strings(
            'Records'
          )}`}</span>
        </div>
        <div className={styles.buttonContainer}>
          <div className={styles.selectFilters}>
            <Select
              fixedLabel={
                <WorkIcon
                  style={{
                    stroke: 'var(--blue-accent)',
                    width: '16px',
                    height: '16px',
                  }}
                />
              }
              defaultLabel={strings('Roles')}
              optionList={skillOptions}
              multipleSelect
              allToggle
              allItem
              onSelectValue={values => setRole(values)}
            />
            <Select
              fixedLabel={<TagIcon />}
              defaultLabel={strings('Status')}
              optionList={jobStatusOptions}
              multipleSelect
              allToggle
              allItem
              onSelectValue={values => setStatus(values)}
            />
            <Select
              fixedLabel={<LocationIcon />}
              defaultLabel={strings('District')}
              optionList={districtOptions}
              multipleSelect
              searchable
              allToggle
              allItem
              divider
              onSelectValue={values => setDistrict(values)}
            />
            <PcfDatePicker
              placeholder={strings('Schedule')}
              onSelectDate={value => {
                setStartDate(value.from || '');
                setEndDate(value.to || '');
              }}
            />
            <Select
              fixedLabel={
                <CalenderIcon
                  style={{
                    fill: 'var(--blue-accent)',
                  }}
                />
              }
              defaultLabel={strings('Month')}
              optionList={monthOptions}
              allToggle
              divider
              onSelectValue={values => setMonth(values[0])}
            />
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              type="outline"
              label={strings('Export')}
              handleClick={exportJobs}
            />
            <Button label={strings('Create')} handleClick={goToCreateJob} />
          </div>
        </div>
      </div>
      <Modal
        ref={resultModalRef}
        showClose={false}
        body={
          <div>
            <div>{error}</div>
          </div>
        }
        footer={
          <>
            <Button
              label={strings('Ok')}
              type="danger"
              handleClick={() => {
                resultModalRef.current.hideModal();
              }}
            />
          </>
        }
      />
      <Table
        columns={columns}
        data={data}
        rowClick
        pageInput
        hiddenColumns={hiddenColumns}
        manualQuery={searchValue}
        manualFilterSort={manualFilterSort}
        pageCountNumber={Math.ceil(total / _limit)}
        fetchData={fetchData}
      />
    </div>
  );
}
