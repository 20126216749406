import {setScreen} from '../../services/router';
import styles from './PCFStaffProfile.module.scss';
import StaffDetails from './StaffDetails/StaffDetails';
import {ReactComponent as MoreIcon} from '../../assets/img/more.svg';
import {ReactComponent as TrashIcon} from '../../assets/img/trash.svg';
import {ReactComponent as CameraIcon} from '../../assets/img/camera.svg';
import {useStaffDetailsLogic} from './logics';
import {Breadcrumb, Button, Modal, FileUpload} from '../../components';
import {strings} from '../../services/language';
import {useCallback, useEffect} from 'react';
import {formatISOString} from '../../services/utils';
import {useLoading} from '../../services/hooks';
import ReactMarkdown from 'react-markdown';
import {updateState} from '../../services/store';

setScreen('/pcf-staffs/:id', params => {
  const {
    breadcrumbList,
    showMore,
    getStaffApi,
    staffProfile,
    deleteModalRef,
    resultModalRef,
    uploadModalRef,
    deleteStaffApi,
    uploadPhotoApi,
    profile,
    file,
    setFile,
  } = useStaffDetailsLogic(params.id);

  useEffect(() => {
    if (
      (deleteStaffApi.data?.success || deleteStaffApi.error) &&
      !deleteStaffApi.loading
    ) {
      resultModalRef.current.showModal();
    }
  }, [deleteStaffApi.loading]);

  useEffect(() => {
    if (
      (uploadPhotoApi.data?.photo || uploadPhotoApi.error) &&
      !uploadPhotoApi.loading
    ) {
      updateState({
        profile: {
          photo: uploadPhotoApi.data?.photo,
        },
      });
      resultModalRef.current.showModal();
    }
  }, [uploadPhotoApi.loading]);

  useEffect(() => {
    getStaffApi.request();
  }, []);

  useLoading(
    getStaffApi.loading || deleteStaffApi.loading || uploadPhotoApi.loading
  );

  return (
    <>
      {staffProfile ? (
        <>
          <div className="main-header">
            <Breadcrumb linkList={breadcrumbList} />
            <div className={styles.header}>
              <div
                className={styles.avatar}
                style={{
                  backgroundImage: staffProfile.photo
                    ? `url(${uploadPhotoApi.data?.photo || staffProfile.photo})`
                    : 'none',
                }}
              >
                {staffProfile.id === profile.id && (
                  <div
                    className={styles.changeAvatar}
                    onClick={() => {
                      uploadModalRef.current.showModal();
                    }}
                  >
                    <CameraIcon />
                  </div>
                )}
              </div>
              <div className={styles.headerWrapper}>
                <div className={styles.headerRow}>
                  <h2>{staffProfile.name}</h2>
                  {staffProfile.id !== profile.id && (
                    <div
                      className={styles.moreToggle}
                      tabIndex={0}
                      onBlur={() => showMore.setShowMore(false)}
                      onClick={() => showMore.setShowMore(!showMore.showMore)}
                    >
                      <MoreIcon />
                      {showMore.showMore && (
                        <div
                          className={styles.moreButton}
                          onClick={() => {
                            deleteModalRef.current.showModal();
                          }}
                        >
                          <TrashIcon /> {strings('Delete PCF Staff')}
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className={styles.headerRow}>
                  <div className={styles.infoText}>{`${strings(
                    'Employee Code'
                  )}: ${staffProfile.employeeCode || ''}`}</div>
                  <div className={styles.infoText}>{`${strings(
                    'Created'
                  )} ${formatISOString(
                    staffProfile.createdAt,
                    'DD/MM/YY'
                  )}`}</div>
                </div>
              </div>
            </div>
            <Modal
              ref={deleteModalRef}
              header={<h2>{strings('Delete Profile?')}</h2>}
              body={
                <div>
                  <div>
                    {
                      <ReactMarkdown
                        children={strings(
                          'You are deleting staff ‘%0’.',
                          `**${staffProfile.name}**`
                        )}
                      />
                    }
                  </div>
                  <br />
                  <div>{strings('This action cannot be undone.')}</div>
                </div>
              }
              footer={
                <>
                  <Button
                    label={strings('Cancel')}
                    type="transparent"
                    handleClick={() => deleteModalRef.current.hideModal()}
                  />
                  <Button
                    label={strings('Delete')}
                    type="danger"
                    handleClick={() => {
                      deleteStaffApi.request();
                      deleteModalRef.current.hideModal();
                    }}
                  />
                </>
              }
            />
          </div>
          <Modal
            ref={resultModalRef}
            showClose={false}
            body={
              <div>
                <div>
                  {deleteStaffApi.error
                    ? deleteStaffApi.error
                    : deleteStaffApi.data?.success
                    ? strings('Delete Profile Successfully')
                    : uploadPhotoApi.error
                    ? uploadPhotoApi.error
                    : uploadPhotoApi.data?.photo
                    ? strings('Update Profile Picture Successfully')
                    : ''}
                </div>
              </div>
            }
            footer={
              <>
                <Button
                  label={strings('Done')}
                  type="danger"
                  handleClick={() => {
                    resultModalRef.current.hideModal();
                    if (deleteStaffApi.data?.success) {
                      history.back();
                    }
                  }}
                />
              </>
            }
          />
          <Modal
            ref={uploadModalRef}
            width="initial"
            header={<h2>{strings('Upload Photo')}</h2>}
            body={
              <FileUpload
                previewImage
                onSelectFiles={files => {
                  const filesList = Object.values(files);
                  filesList.length > 0
                    ? setFile(filesList[0])
                    : setFile(undefined);
                }}
              />
            }
            footer={
              <>
                <Button
                  label={strings('Cancel')}
                  type="transparent"
                  handleClick={() => {
                    uploadModalRef.current.hideModal();
                  }}
                />
                <Button
                  label={strings('Save')}
                  type="danger"
                  handleClick={() => {
                    if (file) {
                      uploadPhotoApi.request({files: file});
                      uploadModalRef.current.hideModal();
                    }
                  }}
                />
              </>
            }
          />
          <StaffDetails staffProfile={staffProfile} />
        </>
      ) : (
        getStaffApi.error
      )}
    </>
  );
});
