import {strings} from '../../../services/language';
import {Centre, Job, JobStatus, Skill} from '../../../services/models';
import {Badge} from '../../../components';
import styles from './JobPostings.module.scss';
import {useEffect, useMemo, useRef, useState} from 'react';
import {Column} from 'react-table';
import {ReactComponent as ChevronIcon} from '../../../assets/img/chevron.svg';
import {useHistory} from 'react-router-dom';
import {toRoute} from '../../../services/router';
import {TableInstance, Cell} from 'react-table';
import {
  createPayrollMonthOptions,
  createSkillOptions,
} from '../../../services/helpers';
import {downloadCsv, formatISOString} from '../../../services/utils';
import {
  exportJobsAPI,
  getJobsAPI,
  useAPI,
  useResult,
} from '../../../services/api';
import {useLoading, useSyncCentres} from '../../../services/hooks';
import moment from 'moment-timezone';

const _limit = 8;

export const jobStatus = (j: Job) => {
  switch (j.status) {
    case 'open':
    case 'ongoing':
      const startTime = new Date(j.startsAt).getTime();
      const endTime = new Date(j.endsAt).getTime();
      const currentTime = new Date().getTime();
      const startTimeMinus1Minute = startTime - 60 * 1000;
      const startTimeMinus15Minutes = startTime - 15 * 60 * 1000;
      if (j.hired <= 0 && startTimeMinus1Minute < currentTime) {
        return {status: 'expired'};
      } else if (endTime < currentTime) {
        return {status: 'completed'};
      } else if (startTime < currentTime) {
        return {status: 'ongoing', expired: endTime - currentTime};
      } else if (startTimeMinus15Minutes < currentTime) {
        return {status: 'upcoming', expired: startTime - currentTime};
      } else if (currentTime < startTime) {
        return {status: 'open'};
      } else {
        return {expired: startTimeMinus1Minute - currentTime};
      }

    default:
      return {status: j.status};
  }
};

export function useJobPostingsLogic(skills: Skill[]) {
  const history = useHistory();
  const hiddenColumns = ['job', 'jobId', 'role', 'fromDate', 'toDate'];

  const [jobs, setJobs] = useState<Job[]>([]);
  const [total, setTotal] = useState(0);
  const [manualFilterSort, setManualFilterSort] = useState<any>(undefined);
  const [role, setRole] = useState<number[]>([]);
  const [status, setStatus] = useState<string[]>([]);
  const [district, setDistrict] = useState<string[]>([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [searchField, setSearchField] = useState('all');
  const {centres} = useSyncCentres();
  const [error, setError] = useState('');
  const [month, setMonth] = useState<string>('');

  const resultModalRef = useRef<any>(null);

  useEffect(() => {
    if ((startDate && endDate) || (!startDate && !endDate)) {
      setManualFilterSort([]);
    }
  }, [role, status, district, startDate, endDate, searchField, month]);

  const getJobsApi = useAPI(getJobsAPI);
  const exportJobsApi = useAPI(exportJobsAPI);

  function exportJobs() {
    exportJobsApi.request({
      _start: 0,
      _limit: _limit,
      _sort: 'id:DESC',
      searchField,
      ...(searchValue && {query: searchValue}),
      ...(role.length > 0 && {skillIds: role}),
      ...(status.length > 0 && {statuses: status}),
      ...(district.length > 0 && {districtGroups: district}),
      ...(startDate && {
        startsAt_lt: moment(startDate)
          .startOf('day')
          .tz('Asia/Singapore')
          .toISOString(),
      }),
      ...(endDate && {
        endsAt_gt: moment(endDate)
          .endOf('day')
          .tz('Asia/Singapore')
          .toISOString(),
      }),
      ...(month && {filterByMonth: month}),
      export_csv: true,
    });
  }

  useResult(exportJobsApi.data, data => {
    if (data.content) {
      downloadCsv(data.filename, data.content);
    }
  });

  useEffect(() => {
    if (exportJobsApi.error && !exportJobsApi.loading) {
      setError(strings('Please select a month'));
      resultModalRef.current.showModal();
    }
  }, [exportJobsApi.loading]);

  function fetchData(pageIndex: number) {
    getJobsApi.request({
      _start: pageIndex * _limit,
      _limit: _limit,
      _sort: 'id:DESC',
      searchField,
      ...(searchValue && {query: searchValue}),
      ...(role.length > 0 && {skillIds: role}),
      ...(status.length > 0 && {statuses: status}),
      ...(district.length > 0 && {districtGroups: district}),
      ...(startDate && {
        startsAt_lt: moment(startDate)
          .startOf('day')
          .tz('Asia/Singapore')
          .toISOString(),
      }),
      ...(endDate && {
        endsAt_gt: moment(endDate)
          .endOf('day')
          .tz('Asia/Singapore')
          .toISOString(),
      }),
      ...(month && {filterByMonth: month}),
    });
  }

  useResult(getJobsApi.data, data => {
    setJobs(data.list);
    if (data.total !== total) {
      setTotal(data.total);
    }
  });

  useLoading(getJobsApi.loading || exportJobsApi.loading);

  const searchFilterList = useMemo(
    () => [
      {
        content: 'Job',
        value: 'job',
      },
      {
        content: 'Job ID',
        value: 'jobId',
      },
      {
        content: 'Centre',
        value: 'centre',
      },
    ],
    []
  );
  const skillOptions = useMemo(() => createSkillOptions(skills), [skills]);
  const monthOptions = useMemo(() => createPayrollMonthOptions(2), []);

  const columns: Column[] = useMemo(() => createColumns(), []);
  const data: JobData[] = useMemo(
    () => mapJobDataTable(jobs, skills, centres, history),
    [jobs, skills, centres]
  );

  const goToCreateJob = () => {
    history.push(toRoute('/jobs/create-job-posting'));
  };

  return {
    _limit,
    hiddenColumns,
    total,
    manualFilterSort,
    setRole,
    setStatus,
    setDistrict,
    setStartDate,
    setEndDate,
    searchValue,
    setSearchValue,
    setSearchField,
    searchFilterList,
    skillOptions,
    columns,
    data,
    goToCreateJob,
    fetchData,
    exportJobs,
    resultModalRef,
    error,
    monthOptions,
    setMonth,
  };
}

interface JobData {
  jobCell: JSX.Element;
  hired: string;
  status: string;
  centre: string;
  district: string;
  dateTime: string;
  datePublished: string;

  job: string;
  jobId: number;
  role: number;
  fromDate: string;
  toDate: string;

  button: JSX.Element;
}

function createColumns() {
  return [
    {
      Header: strings('Job'),
      accessor: 'jobCell',
    },
    {
      Header: strings('No. of Hired'),
      accessor: 'hired',
    },
    {
      Header: strings('Status'),
      accessor: 'status',
      Cell: (props: TableInstance & Cell) => {
        const status =
          jobStatus({
            ...props.row.values,
            startsAt: props.row.values.fromDate,
            endsAt: props.row.values.toDate,
          } as Job)?.status ?? '';
        return <Badge type={'jobStatus'} status={status as JobStatus} />;
      },
      filter: 'selectOptions',
    },
    {
      Header: strings('Centre'),
      accessor: 'centre',
    },
    {
      Header: strings('District'),
      accessor: 'district',
    },
    {
      Header: strings('Date/Time'),
      accessor: 'dateTime',
    },
    {
      Header: strings('Date Published'),
      accessor: 'datePublished',
    },
    {
      Header: '',
      accessor: 'job',
    },
    {
      Header: '',
      accessor: 'jobId',
    },
    {
      Header: '',
      accessor: 'role',
      filter: 'selectOptions',
    },
    {
      Header: '',
      accessor: 'fromDate',
      filter: 'sameOrAfter',
    },
    {
      Header: '',
      accessor: 'toDate',
      filter: 'sameOrBefore',
    },
    {
      Header: '',
      accessor: 'button',
    },
  ];
}

function mapJobDataTable(
  jobs: Job[],
  skills: Skill[],
  centres: Centre[],
  history: any
): JobData[] {
  return jobs.map(job => {
    const jobSkill = skills.filter(skill => skill.id === job.skillId)[0];
    const centre = centres.filter(centre => centre.id === job.centreId)[0];

    return {
      jobCell: (
        <div className={styles.jobCell}>
          <img
            className={styles.skillIcon}
            src={jobSkill ? jobSkill.icon : ''}
            alt={jobSkill ? jobSkill.name : ''}
            height={40}
          />
          <div>
            <div>{`${job.vacancy} ${jobSkill ? jobSkill.name : ''}`}</div>
            {job.id && <div className={styles.jobCellInfo}>{job.id}</div>}
          </div>
        </div>
      ),
      hired: `${job.hired}/${job.vacancy}`,
      status: job.status || '',
      centre: centre ? centre.name : '',
      district: centre ? centre.districtGroup : '',
      dateTime: `${formatISOString(job.startsAt, 'DD/MM/YY')}-${formatISOString(
        job.endsAt,
        'DD/MM/YY'
      )}`,
      datePublished: formatISOString(job.createdAt, 'DD/MM/YY'),
      job: `${job.vacancy} ${jobSkill.name}`,
      jobId: job.id,
      role: jobSkill.id,
      fromDate: job.startsAt,
      toDate: job.endsAt,
      button: (
        <div
          className={styles.buttonCell}
          onClick={e => {
            history.push('/jobs/' + job.id);
            if (e) {
              e.stopPropagation();
            }
          }}
        >
          <ChevronIcon
            style={{stroke: 'var(--blue-accent)', transform: 'rotate(-90deg)'}}
          />
        </div>
      ),
    };
  });
}
