import {strings} from '../../services/language';
import {useSelector} from '../../services/store';
import {useSyncSkills} from '../../services/hooks';

export function useCentreDetailsLogic(centreId: number) {
  const centres = useSelector(s => s.centres.list);
  const centre = centres.find(c => c.id == centreId);
  const breadcrumbList = [
    {
      title: strings('Centres'),
      route: '/centres',
    },
    {
      title: strings('Centre Detail'),
      active: true,
    },
  ];
  const {skillsApi, skills} = useSyncSkills();

  return {
    breadcrumbList,
    skillsApi,
    skills,
    centre,
  };
}
