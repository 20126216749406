import styles from './NotificationList.module.scss';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {Cell, Column, TableInstance} from 'react-table';
import {ReactComponent as MoreIcon} from '../../assets/img/more.svg';
import {ReactComponent as EditIcon} from '../../assets/img/edit.svg';
import {ReactComponent as ChevronIcon} from '../../assets/img/chevron.svg';
import {ReactComponent as TrashIcon} from '../../assets/img/trash.svg';
import {strings} from '../../services/language';
import {useHistory} from 'react-router-dom';
import {
  deleteNotificationAPI,
  getNotificationsAPI,
  useAPI,
  useResult,
} from '../../services/api';
import {Notification, Skill} from '../../services/models';
import {
  useLoading,
  useMultipleSelect,
  useSyncSkills,
} from '../../services/hooks';
import moment from 'moment';
import {Badge} from '../../components';
import {
  createSkillOptions,
  postalDistrictOptions,
} from '../../services/helpers';

const _limit = 8;

export function useNotificationListLogic() {
  const [notiList, setNotiList] = useState<Notification[]>([]);
  const [showMores, setShowMores] = useState<{[key: string]: boolean}>(() => {
    let obj = {};
    notiList.forEach((_, index) => {
      obj = {...obj, [index]: false};
    });
    return obj;
  });
  const [selectedNoti, setSelectedNoti] = useState<{
    id?: number;
    title?: string;
  }>({});
  const [total, setTotal] = useState(0);
  const [manualFilterSort, setManualFilterSort] = useState<any>(undefined);
  const [searchValue, setSearchValue] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const {states: status, bind: bindStatus} = useMultipleSelect();
  const {states: role, bind: bindRole} = useMultipleSelect();
  const {states: district, bind: bindDistrict} = useMultipleSelect();
  const {skills} = useSyncSkills();

  const skillOptions = useMemo(() => createSkillOptions(skills), [skills]);

  const deleteModalRef = useRef<any>(null);
  const resultModalRef = useRef<any>(null);

  useEffect(() => {
    if ((startDate && endDate) || (!startDate && !endDate)) {
      setManualFilterSort([]);
    }
  }, [
    role.value,
    status.value,
    district.value,
    startDate,
    endDate,
    searchValue,
  ]);

  const getNotisApi = useAPI(pageIndex =>
    getNotificationsAPI({
      _start: pageIndex * _limit,
      _limit: _limit,
      _sort: 'createdAt:DESC',
      ...(searchValue && {search: searchValue}),
      ...(role.value.length > 0 && {skillIds: role.value}),
      ...(status.value.length > 0 && {statuses: status.value}),
      ...(district.value.length > 0 && {districtNumbers: district.value}),
      ...(startDate && {
        from: moment(startDate)
          .startOf('day')
          .tz('Asia/Singapore')
          .toISOString(),
      }),
      ...(endDate && {
        to: moment(endDate).endOf('day').tz('Asia/Singapore').toISOString(),
      }),
    })
  );
  const deleteNotiApi = useAPI(deleteNotificationAPI);

  const fetchData = useCallback((pageIndex: number) => {
    if (!getNotisApi.loading) {
      getNotisApi.request(pageIndex);
    }
  }, []);

  useLoading(getNotisApi.loading || deleteNotiApi.loading);

  useResult(getNotisApi.data, data => {
    setNotiList(data.list);
    if (data.total !== total) {
      setTotal(data.total);
    }
  });

  useEffect(() => {
    if (
      (deleteNotiApi.data?.success || deleteNotiApi.error) &&
      !deleteNotiApi.loading
    ) {
      if (deleteNotiApi.data?.success && selectedNoti.id) {
        const newNotiList = notiList.filter(
          noti => noti.id !== selectedNoti.id
        );
        setNotiList(newNotiList);
      }
      resultModalRef.current.showModal();
    }
  }, [deleteNotiApi.loading]);

  useEffect(() => {
    let obj = {};
    notiList.forEach((_, index) => {
      obj = {...obj, [index]: false};
    });
    setShowMores(obj);
  }, [notiList]);

  const history = useHistory();
  const columns: Column[] = useMemo(() => createColumns(), []);
  const data: NotiData[] = useMemo(
    () =>
      createRowsData(
        history,
        notiList,
        showMores,
        setShowMores,
        setSelectedNoti,
        deleteModalRef,
        skills
      ),
    [showMores, notiList]
  );

  return {
    history,
    columns,
    data,
    total,
    _limit,
    fetchData,
    deleteModalRef,
    resultModalRef,
    selectedNoti,
    deleteNotiApi,
    bindStatus,
    bindRole,
    bindDistrict,
    setStartDate,
    setEndDate,
    skillOptions,
    searchValue,
    setSearchValue,
    manualFilterSort,
  };
}

interface NotiData {
  title: JSX.Element;
  status: string;
  scheduledDate: JSX.Element;
  roles: JSX.Element;
  districts: JSX.Element;
  dateCreated: string;

  button: JSX.Element;
}

function createColumns() {
  return [
    {
      Header: strings('Title'),
      accessor: 'title',
    },
    {
      Header: strings('Status'),
      accessor: 'status',
      Cell: (props: TableInstance & Cell) => (
        <Badge type={'notificationStatus'} status={props.value} />
      ),
    },
    {
      Header: strings('Scheduled Date'),
      accessor: 'scheduledDate',
    },
    {
      Header: strings('Receipient - Roles'),
      accessor: 'roles',
    },
    {
      Header: strings('Receipient - Districts'),
      accessor: 'districts',
    },
    {
      Header: strings('Date Created'),
      accessor: 'dateCreated',
    },
    {
      Header: '',
      accessor: 'button',
    },
  ];
}

function createRowsData(
  history: any,
  notiList: Notification[],
  showMores: {[key: string]: boolean},
  setShowMores: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean;
    }>
  >,
  setSelectedNoti: React.Dispatch<
    React.SetStateAction<{
      id?: number | undefined;
      title?: string | undefined;
    }>
  >,
  deleteModalRef: React.MutableRefObject<any>,
  skills: Skill[]
): NotiData[] {
  return notiList.map((noti, index) => {
    const status =
      noti.scheduledAt && moment(noti.scheduledAt).diff(moment()) > 0
        ? 'queued'
        : 'published';
    const rolesString = skills
      .filter(skill => noti.skillIds?.includes(skill.id))
      .map(role => role.name)
      .join(', ');
    const districtsString = postalDistrictOptions
      .filter(
        d =>
          typeof d.value === 'number' && noti.districtNumbers?.includes(d.value)
      )
      .map(d => d.content)
      .join(', ');

    return {
      title: (
        <div className={styles.cell}>
          <div className={styles.line1} title={noti.title}>
            {noti.title}
          </div>
          <div className={styles.greyText}>{noti.id}</div>
        </div>
      ),
      status: status,
      scheduledDate: noti.scheduledAt ? (
        <div className={styles.cell}>
          <div>{moment(noti.scheduledAt).format('DD/MM/YY')}</div>
          <div>{moment(noti.scheduledAt).format('h:mmA')}</div>
        </div>
      ) : (
        <div></div>
      ),
      roles: (
        <div className={styles.line2} title={rolesString}>
          {rolesString}
        </div>
      ),
      districts: (
        <div className={styles.line2} title={districtsString}>
          {districtsString}
        </div>
      ),
      dateCreated: moment(noti.createdAt).format('DD/MM/YY'),
      button: (
        <div
          className={styles.buttonCell}
          onClick={e => {
            history.push('/notifications/' + noti.id);
            if (e) {
              e.stopPropagation();
            }
          }}
        >
          {status === 'queued' ? (
            <div
              className={styles.moreToggle}
              tabIndex={0}
              onBlur={() => {
                setShowMores({...showMores, [index]: false});
              }}
              onClick={e => {
                setShowMores({...showMores, [index]: !showMores[index]});
                if (e) {
                  e.stopPropagation();
                }
              }}
            >
              <MoreIcon />
              {showMores[index] && (
                <div className={styles.moreDropdown}>
                  <div
                    className={styles.moreButton}
                    onClick={() => history.push('/notifications/' + noti.id)}
                  >
                    <EditIcon /> {strings('Edit Announcement')}
                  </div>
                  <div
                    className={styles.moreButton}
                    onClick={() => {
                      setSelectedNoti({id: noti.id, title: noti.title});
                      deleteModalRef.current.showModal();
                    }}
                  >
                    <TrashIcon /> {strings('Delete Announcement')}
                  </div>
                </div>
              )}
            </div>
          ) : (
            <ChevronIcon
              style={{
                stroke: 'var(--blue-accent)',
                transform: 'rotate(-90deg)',
              }}
            />
          )}
        </div>
      ),
    };
  });
}
