import moment from 'moment';
import {useEffect, useRef} from 'react';
import {useHistory} from 'react-router';
import {
  authRefreshTokenAPI,
  authSendConfirmEmailAPI,
  useAPI,
  useResult,
} from '../../../services/api';
import {useLoading} from '../../../services/hooks';
import {toRoute} from '../../../services/router';
import {getState, setState, updateState} from '../../../services/store';

export function useVerifyEmailLogic() {
  const history = useHistory();
  const email = getState().profile.email || '';
  const refreshToken = getState().secure.refreshToken;

  const resultModalRef = useRef<any>(null);

  const resendApi = useAPI(authSendConfirmEmailAPI);
  const refreshTokenApi = useAPI(authRefreshTokenAPI);

  useResult(refreshTokenApi.data, auth => {
    updateState({
      user: auth.user,
      profile: auth.profile,
      secure: {
        token: auth.token,
        refreshToken: auth.refreshToken,
        expiry: moment().add(55, 'minute').unix(),
      },
    });
    history.push(toRoute('/jobs'));
  });

  const handleRefreshToken = () => {
    refreshTokenApi.request({refreshToken: refreshToken!});
  };

  const onResendEmail = () => {
    resendApi.request({email});
  };

  const logout = () => {
    setState({user: {}, profile: {}, secure: {}});
    history.push(toRoute('/login'));
  };

  useEffect(() => {
    handleRefreshToken();
    window.addEventListener('focus', handleRefreshToken);
    return () => {
      window.removeEventListener('focus', handleRefreshToken);
    };
  }, []);

  useEffect(() => {
    if ((resendApi.data?.success || resendApi.error) && !resendApi.loading) {
      resultModalRef.current.showModal();
    }
  }, [resendApi.loading]);

  useLoading(resendApi.loading || refreshTokenApi.loading);

  return {
    resultModalRef,
    resendApi,
    onResendEmail,
    logout,
  };
}
