import {Notification} from '../../models';
import {API} from '../types';

type Params = {
  id: number;
};

interface Response {
  notification: Notification;
}

export function getNotificationFromIdAPI(params: Params): API<Response> {
  return {
    getData: async client => {
      const {data} = await client({
        url: `/notifications/${params.id}`,
        method: 'GET',
      });
      return {data: {notification: data}};
    },
    getMockData: () => {
      return {
        notification: {
          id: 1,
          title: 'string',
          createdAt: '',
          scheduledAt: '',
          status: 'published',
        },
      };
    },
  };
}
