import styles from './Button.module.scss';

type ButtonProps = {
  label: string;
  type?: 'primary' | 'danger' | 'outline' | 'transparent';
  disabled?: boolean;
  handleClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export function Button({
  label,
  type,
  disabled = false,
  handleClick = () => {},
}: ButtonProps) {
  return (
    <button
      className={`${styles.container} ${styles[type || 'primary']} ${
        disabled ? styles.disabled : ''
      }`}
      onClick={e => handleClick(e)}
    >
      {label}
    </button>
  );
}
