import {Input} from '../../../components';
import {strings} from '../../../services/language';
import {ReactComponent as SearchIcon} from '../../../assets/img/search.svg';
import styles from './General.module.scss';
import {Centre} from '../../../services/models';
import {locationOptions} from '../../../services/helpers';

type Props = {
  centre?: Centre;
};

export default function General({centre}: Props) {
  return (
    <div className={styles.container}>
      <div className={styles.inputItem}>
        <Input
          id="reviewNo"
          value={centre?.totalReviews || '0'}
          disabled
          type="text"
          handleChange={() => {}}
          label={strings('No. of Reviews')}
        />
      </div>
      <div className={styles.inputItem}>
        <Input
          id="district"
          value={centre?.districtGroup || ''}
          disabled
          type="text"
          handleChange={() => {}}
          label={strings('District')}
          rightIcon={
            <SearchIcon
              style={{
                width: 20,
                height: 20,
                fill: 'var(--blue-accent)',
              }}
            />
          }
        />
      </div>
      <div className={styles.inputItem}>
        <Input
          id="location"
          value={centre?.address || ''}
          disabled
          type="text"
          handleChange={() => {}}
          label={strings('Address')}
          rightIcon={
            <SearchIcon
              style={{
                width: 20,
                height: 20,
                fill: 'var(--blue-accent)',
              }}
            />
          }
        />
      </div>
      <div className={styles.inputItem}>
        <Input
          id="location"
          value={
            centre
              ? locationOptions.filter(
                  l => l.value === centre.districtNumber
                )[0].content
              : ''
          }
          disabled
          type="text"
          handleChange={() => {}}
          label={strings('General Location')}
          rightIcon={
            <SearchIcon
              style={{
                width: 20,
                height: 20,
                fill: 'var(--blue-accent)',
              }}
            />
          }
        />
      </div>
    </div>
  );
}
