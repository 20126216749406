import {getFilenameFromHeaders} from '../../utils';
import {API} from '../types';

type GetPCFStaffsParams = {
  _start: number;
  _limit: number;
  _sort: any;
  query?: string;
  roles?: string[];
  export_csv: boolean;
};
interface Response {
  content: string;
  filename: string;
}

export function exportPCFStaffsAPI(params: GetPCFStaffsParams): API<Response> {
  const urlParams = {
    ...params,
    ...(params.roles && {roles: params.roles.join(',')}),
  };
  return {
    getData: async client => {
      const {headers, data} = await client({
        url: '/profiles',
        method: 'GET',
        params: urlParams,
      });

      const filename = getFilenameFromHeaders(headers);

      return {
        data: {
          content: data,
          filename,
        },
      };
    },
    getMockData: () => {
      return {content: 'content', filename: 'filename'};
    },
  };
}
