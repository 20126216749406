import {useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useAPI, getJobFromIdAPI} from '../../services/api';
import {useLoading} from '../../services/hooks';

export function useJobDetailsLogic() {
  const {id} = useParams<{id: string}>();
  const jobApi = useAPI(() => getJobFromIdAPI(id));

  useEffect(() => {
    jobApi.request();
  }, []);

  useLoading(jobApi.loading);

  return {job: jobApi.data?.job, jobApi};
}
