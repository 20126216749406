import {Profile, User} from '../../models';
import {profiles, users} from '../mockDB';
import {API} from '../types';

interface Params {
  refreshToken: string;
}

interface Response {
  token: string;
  refreshToken: string;
  profile: Profile;
  user: User;
}

export function authRefreshTokenAPI(params: Params): API<Response> {
  return {
    getData: async client => {
      const {data} = await client({
        url: '/auth/refresh-token',
        method: 'POST',
        data: params,
      });
      return {data: data};
    },
    getMockData: () => {
      const user = users[0];
      const profile = profiles[0];
      return {
        token: 'qweASD123',
        refreshToken: 'qweASD123',
        profile: {...profile, user},
        user,
      };
    },
  };
}
