import moment from 'moment';
import {useEffect, useState} from 'react';

export function usePickSingleDate(initialValue: Date | undefined = undefined) {
  const initValue = initialValue;
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState('');
  const [changed, setChanged] = useState(false);

  const onSelectDate = (selectedDate: Date | undefined) => {
    setError('');
    setValue(selectedDate);
  };

  useEffect(() => {
    setChanged(moment(initValue).diff(moment(value), 'day') !== 0);
  }, [value]);

  return {
    states: {
      value,
      setValue,
      error,
      setError,
      onSelectDate,
      changed,
      setChanged,
    },
    bind: {
      value,
      error,
      onSelectDate,
    },
  };
}

export function usePickSingleTime(initialValue: string = '') {
  const initValue = initialValue ? convertToTimePickerDate(initialValue) : '';
  const [value, setValue] = useState(initValue);
  const [error, setError] = useState('');
  const [changed, setChanged] = useState(false);

  const onSelectValue = (selectedValues: {from: string; to: string}) => {
    setError('');
    setValue(selectedValues.from);
  };

  useEffect(() => {
    setChanged(initValue !== value);
  }, [value]);

  return {
    states: {
      value,
      setValue,
      error,
      setError,
      onSelectValue,
      changed,
      setChanged,
    },
    bind: {
      fromValueInit: value,
      error,
      onSelectValue,
    },
  };
}

function convertToTimePickerDate(date: string) {
  const momentDate = moment(date);
  return moment({
    hour: momentDate.hour(),
    minute: momentDate.minute(),
    day: 6,
    month: 9,
    year: 1996,
  }).toISOString();
}
