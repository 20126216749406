import {useEffect} from 'react';
import {getCentresAPI, useAPI, useResult} from '../api';
import {updateState, useSelector} from '../store';

export function useSyncCentres() {
  const centres = useSelector(s => s.centres.list);
  const centresApi = useAPI(getCentresAPI);
  useEffect(centresApi.request, []);
  useResult(centresApi.data, data => {
    updateState({centres: data});
  });
  return {centresApi, centres};
}
