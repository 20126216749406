import {setScreen} from '../../services/router';
import {strings} from '../../services/language';
import {NavTabs} from '../../components';
import {useGetJobsLogic} from './logics';
import JobPostings from './JobPostings/JobPostings';
import RolesDetails from './RolesDetails/RolesDetails';

setScreen('/jobs', params => {
  const {skillsApi, skills} = useGetJobsLogic();

  return (
    <>
      <div className="main-header">
        <h1>{strings('Jobs')}</h1>
      </div>
      <NavTabs
        navTabList={[
          {
            id: 'jobPostings',
            label: strings('Job Postings'),
            content: <JobPostings skills={skills} />,
          },
          {
            id: 'rolesDetails',
            label: strings('Roles Details'),
            content: <RolesDetails skills={skills} skillsApi={skillsApi} />,
          },
        ]}
      />
    </>
  );
});
