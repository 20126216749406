import {User} from '../../models';

export const users: User[] = [
  {
    id: 1,
    identifier: '0123456789',
    confirmed: true,
  },
  {
    id: 2,
    identifier: '0123456788',
    confirmed: false,
  },
];
