import {strings} from '../../../services/language';
import styles from './StaffCreate.module.scss';
import {createProfileAPI, useAPI} from '../../../services/api';
import {useMemo, useRef, useState} from 'react';
import {Column, Row} from 'react-table';
import {ReactComponent as TrashIcon} from '../../../assets/img/trash.svg';
import {validateEmail} from '../../../services/utils';
import {useInput, useSelect, useSyncCentres} from '../../../services/hooks';
import {Centre} from '../../../services/models';

interface CentreData {
  index: JSX.Element;
  id: number;
  centre: string;
  district: string;
  button: JSX.Element;
}

interface AllCentresData {
  id: number;
  centre: string;
  district: string;
}

export function useStaffCreateLogic() {
  const [searchValue, setSearchValue] = useState('');
  const [centreList, setCentreList] = useState<Row<object>[]>([]);
  const [centreError, setCentreError] = useState('');
  const [selectingCentres, setSelectingCentres] = useState<Row<object>[]>([]);
  const [recordNumber, setRecordNumber] = useState(0);
  const [selectFilters, setSelectFilters] = useState<{
    [key: string]: (string | number)[];
  }>({district: []});
  const {states: staffName, bind: bindStaffName} = useInput();
  const {states: staffPhone, bind: bindStaffPhone} = useInput();
  const {states: staffEmail, bind: bindStaffEmail} = useInput();
  const {states: staffRole, bind: bindStaffRole} = useSelect();

  const {centresApi, centres} = useSyncCentres();
  const createProfileApi = useAPI(() => {
    const centreIdList = centreList.map(row => row.values.id);

    return createProfileAPI({
      name: staffName.value,
      ...(staffEmail.value && {email: staffEmail.value}),
      role: staffRole.value,
      ...(staffPhone.value && {phone: staffPhone.value}),
      ...(centreIdList.length > 0 && {centreIds: centreIdList}),
    });
  });

  const selectedCentresColumns: Column[] = useMemo(
    () => createCentreColumns(),
    []
  );
  const selectedCentresData: CentreData[] = useMemo(
    () => createCentreData(centreList, setCentreList),
    [centreList]
  );

  const allCentresColumns: Column[] = useMemo(
    () => createAllCentresColumns(),
    []
  );
  const allCentresData: AllCentresData[] = useMemo(
    () => createAllCentresData(centres),
    [centres]
  );

  const editCentreModalRef = useRef<any>(null);
  const resultModalRef = useRef<any>(null);

  const validate = () => {
    staffRole.value === ''
      ? staffRole.setError(strings('Please select a role'))
      : staffRole.setError('');

    staffName.value === ''
      ? staffName.setError(strings('Please enter a name'))
      : staffName.setError('');

    staffEmail.value === ''
      ? staffEmail.setError(strings('Please enter a valid email'))
      : staffEmail.setError('');

    staffEmail.value !== ''
      ? validateEmail(staffEmail.value)
        ? staffEmail.setError('')
        : staffEmail.setError('Please enter a valid email')
      : null;

    ['', 'HR'].includes(staffRole.value)
      ? setCentreError('')
      : centreList.length === 0
      ? setCentreError(strings('Please select a centre'))
      : centreList.length > 1 && ['VP', 'LT', 'ST'].includes(staffRole.value)
      ? setCentreError(strings('Please select only 1 centre'))
      : setCentreError('');

    return (
      !!staffRole.value &&
      !!staffName.value &&
      !!staffEmail.value &&
      validateEmail(staffEmail.value) &&
      (['HR'].includes(staffRole.value) ||
        (centreList.length === 1 &&
          ['VP', 'LT', 'ST'].includes(staffRole.value)) ||
        (centreList.length >= 1 && ['EP', 'EPA'].includes(staffRole.value)))
    );
  };

  const onSubmit = () => {
    const formValid = validate();
    formValid && createProfileApi.request();
  };

  const resetForm = () => {
    staffName.setValue('');
    staffEmail.setValue('');
    staffPhone.setValue('');
    staffRole.setValue('');
    setCentreList([]);
    setSelectingCentres([]);
  };

  return {
    centresApi,
    searchValue,
    setSearchValue,
    staffName,
    bindStaffName,
    staffPhone,
    bindStaffPhone,
    staffEmail,
    bindStaffEmail,
    staffRole,
    bindStaffRole,
    centreList,
    setCentreList,
    selectedCentresColumns,
    selectedCentresData,
    editCentreModalRef,
    resultModalRef,
    centreError,
    recordNumber,
    setRecordNumber,
    selectingCentres,
    setSelectingCentres,
    allCentresColumns,
    allCentresData,
    onSubmit,
    createProfileApi,
    resetForm,
    selectFilters,
    setSelectFilters,
  };
}

function createCentreColumns() {
  return [
    {
      Header: '',
      accessor: 'index',
    },
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: strings('Centre'),
      accessor: 'centre',
    },
    {
      Header: strings('District'),
      accessor: 'district',
    },
    {
      Header: '',
      accessor: 'button',
    },
  ];
}

function createCentreData(
  centreList: Row<object>[],
  setCentreList: React.Dispatch<React.SetStateAction<any[]>>
): CentreData[] {
  return centreList.map((centre, index, centreList) => ({
    index: <span className={styles.greyText}>{index + 1}</span>,
    id: centre.values.id,
    centre: centre.values.centre,
    district: centre.values.district,
    button: (
      <div
        className={styles.buttonCell}
        onClick={() => {
          centre.toggleRowSelected(false);
          setCentreList(centreList.filter((item, i) => i !== index));
        }}
      >
        <TrashIcon />
      </div>
    ),
  }));
}

function createAllCentresColumns() {
  return [
    {
      Header: 'id',
      accessor: 'id',
    },
    {
      Header: strings('Centre'),
      accessor: 'centre',
    },
    {
      Header: strings('District'),
      accessor: 'district',
      filter: 'selectOptions',
    },
  ];
}

function createAllCentresData(allCentres: Centre[]): AllCentresData[] {
  return allCentres.map((centre, index) => ({
    id: centre.id,
    centre: centre.name,
    district: centre.districtGroup,
  }));
}
