import styles from './AutoSubmission.module.scss';
import {
  Button,
  Modal,
  PcfDatePicker,
  PcfTimePicker,
  Table,
} from '../../../components';
import {strings} from '../../../services/language';
import {useScheduleLogic} from './logics';
import ReactMarkdown from 'react-markdown';

type AutoSubmissionProps = {};

export default function AutoSubmission({}: AutoSubmissionProps) {
  const {
    addDateTimeModalRef,
    editDateTimeModalRef,
    deleteModalRef,
    columns,
    data,
    manualFilterSort,
    total,
    _limit,
    fetchData,
    setStartDate,
    setEndDate,
    bindAddDate,
    bindAddTime,
    bindEditDate,
    bindEditTime,
    addSchedule,
    editSchedule,
    deleteSchedule,
    selectedScheduleText,
  } = useScheduleLogic();

  return (
    <div className={styles.container}>
      <div className={styles.controls}>
        <PcfDatePicker
          placeholder={strings('Date')}
          onSelectDate={value => {
            setStartDate(value.from || undefined);
            setEndDate(value.to || undefined);
          }}
        />
        <Button
          label={strings('Add')}
          handleClick={() => addDateTimeModalRef.current.showModal()}
        />
      </div>
      <Modal
        ref={addDateTimeModalRef}
        width="auto"
        header={<h2>{strings('Add Date and Time')}</h2>}
        body={
          <div className={styles.modalBody}>
            <PcfDatePicker
              type="single"
              label={strings('Date')}
              placeholder={strings('Schedule Date')}
              clearButton={false}
              {...bindAddDate}
            />
            <PcfTimePicker
              type="single"
              label={strings('Time')}
              placeholder={strings('Select Time')}
              step={30}
              {...bindAddTime}
            />
          </div>
        }
        footer={
          <>
            <Button
              label={strings('Cancel')}
              type="transparent"
              handleClick={() => addDateTimeModalRef.current.hideModal()}
            />
            <Button
              label={strings('Add')}
              type="danger"
              handleClick={() => {
                addSchedule();
              }}
            />
          </>
        }
      />
      <Modal
        ref={editDateTimeModalRef}
        width="auto"
        header={<h2>{strings('Edit Date and Time')}</h2>}
        body={
          <div className={styles.modalBody}>
            <PcfDatePicker
              type="single"
              label={strings('Date')}
              placeholder={strings('Schedule Date')}
              clearButton={false}
              {...bindEditDate}
            />
            <PcfTimePicker
              type="single"
              label={strings('Time')}
              placeholder={strings('Select Time')}
              step={30}
              {...bindEditTime}
            />
          </div>
        }
        footer={
          <>
            <Button
              label={strings('Discard Changes')}
              type="transparent"
              handleClick={() => editDateTimeModalRef.current.hideModal()}
            />
            <Button
              label={strings('Save Changes')}
              type="danger"
              handleClick={() => editSchedule()}
            />
          </>
        }
      />
      <Modal
        ref={deleteModalRef}
        header={<h2>{strings('Delete Schedule?')}</h2>}
        body={
          <div>
            <div>
              {
                <ReactMarkdown
                  children={strings(
                    'You are deleting schedule ‘%0’.',
                    `**${selectedScheduleText}**`
                  )}
                />
              }
            </div>
            <br />
            <div>{strings('This action cannot be undone.')}</div>
          </div>
        }
        footer={
          <>
            <Button
              label={strings('Cancel')}
              type="transparent"
              handleClick={() => deleteModalRef.current.hideModal()}
            />
            <Button
              label={strings('Delete')}
              type="danger"
              handleClick={() => deleteSchedule()}
            />
          </>
        }
      />
      <Table
        columns={columns}
        data={data}
        minWidth={null}
        manualFilterSort={manualFilterSort}
        pageCountNumber={Math.ceil(total / _limit)}
        fetchData={fetchData}
      />
    </div>
  );
}
