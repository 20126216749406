import {useEffect, useMemo, useRef, useState} from 'react';
import {strings} from '../../../services/language';
import {Job, JobStatus, Skill} from '../../../services/models';
import {
  useClickOutside,
  useInput,
  useLoading,
  useSelect,
  useSyncCentres,
  useSyncSkills,
} from '../../../services/hooks';
import {createSkillOptions} from '../../../services/helpers';
import moment from 'moment';
import {
  getJobFromIdAPI,
  editJobAPI,
  useAPI,
  useResult,
} from '../../../services/api';
import {useHistory, useParams} from 'react-router-dom';
import {toRoute} from '../../../services/router';

export function useJobDetailsContentLogic(initJob: Job) {
  const [job, setJob] = useState(initJob);
  const {states: centre, bind: bindCentre} = useSelect(job.centreId);
  const {states: role, bind: bindRole} = useSelect(job.skillId);
  const {states: paxNo, bind: bindPaxNo} = useInput(job.vacancy.toString());
  const {states: additionalInfo, bind: bindAdditionalInfo} = useInput(
    job.additionalInfo || ''
  );

  const [schedule, setSchedule] = useState<Date[]>(
    getSelectedDays(job.startsAt, job.endsAt, job.daysOff)
  );
  const [scheduleError, setScheduleError] = useState('');
  const [workingHours, setWorkingHours] = useState({
    from: moment(job.startsAt)
      .set({date: 6, month: 9, year: 1996})
      .toISOString(),
    to: moment(job.endsAt).set({date: 6, month: 9, year: 1996}).toISOString(),
  });
  const [workingHoursErrorFrom, setWorkingHoursErrorFrom] = useState('');
  const [workingHoursErrorTo, setWorkingHoursErrorTo] = useState('');
  const [selectedSkill, setSelectedSkill] = useState<Skill | undefined>(
    undefined
  );
  const [detectChange, setDetectChange] = useState({
    centre: false,
    role: false,
    paxNo: false,
    additionalInfo: false,
    schedule: false,
    workingHours: false,
  });
  const [showMore, setShowMore] = useState(false);

  const {id} = useParams<{id: string}>();
  const history = useHistory();

  const jobModalRef = useRef<any>(null);
  const resultModalRef = useRef<any>(null);
  const showMoreRef = useRef<any>(null);
  const removeJobRef = useRef<any>(null);

  const {skills} = useSyncSkills();
  const {centres} = useSyncCentres();

  const editJobApi = useAPI(editJobAPI);

  const skillOptions = useMemo(() => createSkillOptions(skills), [skills]);

  useClickOutside(showMoreRef, () => setShowMore(false));

  useLoading(editJobApi.loading);

  useEffect(() => {
    if (editJobApi.data) {
      setJob(editJobApi.data.job);
    }
    if ((editJobApi.data?.job || editJobApi.error) && !editJobApi.loading) {
      resultModalRef.current.showModal();
    }
  }, [editJobApi.loading]);

  useEffect(() => {
    if (skills.length > 0) {
      setSelectedSkill(skills.filter(skill => skill.id == role.value)[0]);
    }
  }, [role]);

  // Check changed
  useEffect(() => {
    let obj = {};
    const initialDays = getSelectedDays(job.startsAt, job.endsAt, job.daysOff);
    const initialDaysMoment = initialDays.map(d => moment(d));
    const initialHours = {
      from: moment(job.startsAt)
        .set({date: 6, month: 9, year: 1996})
        .toISOString(),
      to: moment(job.endsAt).set({date: 6, month: 9, year: 1996}).toISOString(),
    };

    obj = centre.changed ? {...obj, centre: true} : {...obj, centre: false};
    obj = role.changed ? {...obj, role: true} : {...obj, role: false};
    obj = paxNo.changed ? {...obj, paxNo: true} : {...obj, paxNo: false};
    obj = additionalInfo.changed
      ? {...obj, additionalInfo: true}
      : {...obj, additionalInfo: false};

    if (schedule.length !== initialDays.length) {
      obj = {...obj, schedule: true};
    } else if (
      schedule
        .map(d => moment(d))
        .reduce(
          (bool, d) =>
            includesDay(initialDaysMoment, d) ? bool && true : bool && false,
          true
        )
    ) {
      obj = {...obj, schedule: false};
    } else {
      obj = {...obj, schedule: true};
    }

    if (
      workingHours.from === initialHours.from &&
      workingHours.to === initialHours.to
    ) {
      obj = {...obj, workingHours: false};
    } else {
      obj = {...obj, workingHours: true};
    }

    setDetectChange({...detectChange, ...obj});
    validateForm();
  }, [
    centre.value,
    role.value,
    paxNo.value,
    additionalInfo.value,
    schedule,
    workingHours,
  ]);

  const centreList = useMemo(
    () =>
      centres.map(centre => ({
        content: centre.name,
        value: centre.id,
        text: centre.name,
      })),
    centres
  );

  const linkList = [
    {
      title: strings('Job Postings'),
      route: '/jobs',
    },
    {
      title: strings('Job Details'),
      active: true,
    },
  ];

  const validateForm = () => {
    !centre.value
      ? centre.setError(strings('Please select a centre'))
      : centre.setError('');
    !role.value
      ? role.setError(strings('Please select a role'))
      : role.setError('');
    paxNo.value === ''
      ? paxNo.setError(strings('Please enter a valid number'))
      : paxNo.setError('');
    schedule.length === 0
      ? setScheduleError(strings('Please select a date range'))
      : setScheduleError('');
    workingHours.from === ''
      ? setWorkingHoursErrorFrom(strings('Please enter a start time'))
      : setWorkingHoursErrorFrom('');
    workingHours.to === ''
      ? setWorkingHoursErrorTo(strings('Please enter a end time'))
      : setWorkingHoursErrorTo('');

    return (
      !!centre &&
      !!role &&
      !!paxNo &&
      schedule.length > 0 &&
      !!workingHours.from &&
      !!workingHours.to
    );
  };

  function onSubmitJob() {
    const isValid = validateForm();

    if (isValid) {
      editJobApi.request({
        id,
        vacancy: paxNo.value,
        startsAt: concatDateAndTime(schedule[0], workingHours.from),
        endsAt: concatDateAndTime(
          schedule[schedule.length - 1],
          workingHours.to
        ),
        skillId: parseInt(role.value),
        centreId: parseInt(centre.value),
        ...(getDaysOff(schedule).length > 0 && {daysOff: getDaysOff(schedule)}),
        ...(additionalInfo.value && {additionalInfo: additionalInfo.value}),
      });
    }
  }

  function removeJob() {
    removeJobRef.current.hideModal();
    editJobApi.request({
      id,
      status: 'removed',
    });
  }

  function duplicateJob() {
    history.push(toRoute('/jobs/create-job-posting'), {
      job: initJob,
    });
  }

  return {
    job,
    setJob,
    linkList,
    centreList,
    bindCentre,
    role,
    bindRole,
    bindPaxNo,
    bindAdditionalInfo,
    schedule: {schedule, setSchedule, scheduleError},
    workingHours: {
      workingHours,
      setWorkingHours,
      workingHoursErrorFrom,
      workingHoursErrorTo,
    },
    onSubmitJob,
    skillOptions,
    selectedSkill,
    jobModalRef,
    resultModalRef,
    showMoreRef,
    removeJobRef,
    removeJob,
    duplicateJob,
    editJobApi,
    skills,
    jobSkill: skills.filter(skill => skill.id === job.skillId)[0],
    showMore: {showMore, setShowMore},
    isChanged: Object.values(detectChange).reduce((pre, cur) => pre || cur),
  };
}

function concatDateAndTime(date: Date, time: string) {
  const dateMoment = moment(date);
  const timeMoment = moment(time);

  return moment({
    hour: timeMoment.hour(),
    minute: timeMoment.minute(),
    date: dateMoment.date(),
    month: dateMoment.month(),
    year: dateMoment.year(),
  }).toISOString();
}

function getDaysOff(days: Date[]) {
  const daysMoment = days.map(d => moment(d));
  const startDate = daysMoment[0];
  const endDate = daysMoment[daysMoment.length - 1];
  const dateRange: moment.Moment[] = [];

  const date = startDate.clone();
  while (date <= endDate) {
    dateRange.push(date.clone());
    date.add(1, 'days');
  }

  return dateRange.reduce(
    (arr, d, i) => (includesDay(daysMoment, d) ? arr : [...arr, i]),
    [] as number[]
  );
}

function includesDay(arr: moment.Moment[], d: moment.Moment) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].isSame(d, 'day')) {
      return true;
    }
  }
  return false;
}

function getSelectedDays(startsAt: string, endsAt: string, daysOff?: number[]) {
  const startDate = moment(startsAt);
  const endDate = moment(endsAt);
  const dateRange: moment.Moment[] = [];

  const date = startDate.clone();
  while (date <= endDate) {
    dateRange.push(date.clone());
    date.add(1, 'days');
  }

  const selectedDays =
    daysOff && daysOff.length > 0
      ? dateRange.filter((d, index) => !daysOff.includes(index))
      : dateRange;

  return selectedDays.map(d => d.toDate());
}
