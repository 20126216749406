import {Notification} from '../../models';
import {API} from '../types';

type Params = {
  _start: number;
  _limit: number;
  _sort: string;
  search?: string;
  from?: string;
  to?: string;
  skillIds?: string[];
  districtNumbers?: string[];
  statuses?: string[];
};

interface Response {
  list: Notification[];
  total: number;
}

export function getNotificationsAPI(params: Params): API<Response> {
  const urlParams = {
    ...params,
    ...(params.skillIds && {skillIds: params.skillIds.join(',')}),
    ...(params.statuses && {statuses: params.statuses.join(',')}),
    ...(params.districtNumbers && {
      districtNumbers: params.districtNumbers.join(','),
    }),
  };

  return {
    getData: async client => {
      const {data} = await client({
        url: '/notifications',
        method: 'GET',
        params: urlParams,
      });
      return {data: data};
    },
    getMockData: () => {
      return {
        list: [
          {
            id: 1,
            title: 'string',
            createdAt: '',
            scheduledAt: '',
            status: 'published',
          },
        ],
        total: 1,
      };
    },
  };
}
