import {API} from '../types';

type CreateProfileParams = {
  name: string;
  email?: string;
  role: string;
  phone?: string;
  centreIds?: number[];
};

interface Response {
  success: boolean;
}

export function createProfileAPI(params: CreateProfileParams): API<Response> {
  return {
    getData: async client => {
      const {data} = await client({
        url: '/profiles',
        method: 'POST',
        data: params,
      });
      return {data: data};
    },
    getMockData: () => {
      return {success: true};
    },
  };
}
