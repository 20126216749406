import {useEffect, useMemo, useRef, useState} from 'react';
import {strings} from '../../services/language';
import {
  useInput,
  useLoading,
  useMultipleSelect,
  usePickSingleDate,
  usePickSingleTime,
  useSyncSkills,
} from '../../services/hooks';
import {
  createSkillOptions,
  postalDistrictOptions,
} from '../../services/helpers';
import moment from 'moment';
import {createNotificationAPI, useAPI} from '../../services/api';
import {useHistory} from 'react-router-dom';
import {toRoute} from '../../services/router';

export function useCreateNotiLogic() {
  const history = useHistory();
  const {states: title, bind: bindTitle} = useInput();
  const {states: publishDate, bind: bindPublishDate} = usePickSingleDate();
  const {states: publishTime, bind: bindPublishTime} = usePickSingleTime();
  const [publishNow, setPublishNow] = useState(false);
  const {states: role, bind: bindRole} = useMultipleSelect();
  const {states: district, bind: bindDistrict} = useMultipleSelect();
  const {states: previewText, bind: bindPreviewText} = useInput();
  const [moreDetails, setMoreDetails] = useState(false);
  const {states: additionalInfo, bind: bindAdditionalInfo} = useInput();
  const [file, setFile] = useState<File | undefined>(undefined);
  const [showRecipientsError, setShowRecipientsError] = useState(false);

  const resultModalRef = useRef<any>(null);

  const {skills} = useSyncSkills();
  const createNotiApi = useAPI(createNotificationAPI);

  const skillOptions = useMemo(() => createSkillOptions(skills), [skills]);

  useEffect(() => {
    if (
      (createNotiApi.data?.notification || createNotiApi.error) &&
      !createNotiApi.loading
    ) {
      resultModalRef.current.showModal();
    }
  }, [createNotiApi.loading]);

  useEffect(() => {
    if (publishNow) {
      publishDate.setValue(undefined);
      publishDate.setError('');
      publishTime.setValue('');
      publishTime.setError('');
    }
  }, [publishNow]);

  useEffect(() => {
    if (!moreDetails) {
      additionalInfo.setValue('');
      setFile(undefined);
    }
  }, [moreDetails]);

  useEffect(() => {
    setShowRecipientsError(false);
  }, [role.value, district.value]);

  const linkList = [
    {
      title: strings('Notifications Management'),
      route: '/notifications',
    },
    {
      title: strings('Create New Notification'),
      active: true,
    },
  ];

  const validateForm = () => {
    title.value === ''
      ? title.setError(strings('Please enter a title'))
      : title.setError('');
    role.value.length > 0 || district.value.length > 0
      ? setShowRecipientsError(false)
      : setShowRecipientsError(true);

    if (!publishNow) {
      !publishDate.value
        ? publishDate.setError(strings('Please select a date'))
        : publishDate.setError('');
      !publishTime.value
        ? publishTime.setError(strings('Please select a time'))
        : publishTime.setError('');
    }

    return (
      !!title.value &&
      (role.value.length > 0 || district.value.length > 0) &&
      (publishNow || (!!publishDate.value && !!publishTime.value))
    );
  };

  function onSubmitForm() {
    const isValid = validateForm();

    if (isValid) {
      createNotiApi.request({
        title: title.value,
        scheduledAt: publishNow
          ? moment().toISOString()
          : concatDateAndTime(publishDate.value!, publishTime.value),
        ...(!!previewText.value && {body: previewText.value}),
        ...(!!additionalInfo.value && {additionalInfo: additionalInfo.value}),
        ...(role.value.length > 0 && {
          skillIds: role.value.filter(x => typeof x === 'number'),
        }),
        ...(district.value.length > 0 && {
          districtNumbers: district.value.filter(x => typeof x === 'number'),
        }),
        ...(file && {image: file}),
      });
    }
  }

  function resetFrom() {
    title.setValue('');
    publishDate.setValue(undefined);
    publishTime.setValue('');
    setPublishNow(false);
    role.setValue([]);
    district.setValue([]);
    previewText.setValue('');
    setMoreDetails(false);
    additionalInfo.setValue('');
    setFile(undefined);
  }

  const goToNotifications = () => {
    history.push(toRoute('/notifications'));
  };

  useLoading(createNotiApi.loading);

  return {
    linkList,
    postalDistrictOptions,
    skillOptions,
    resultModalRef,
    createNotiApi,
    moreDetails,
    publishNow,
    bindTitle,
    bindAdditionalInfo,
    bindRole,
    bindDistrict,
    bindPublishDate,
    bindPublishTime,
    bindPreviewText,
    showRecipientsError,
    setMoreDetails,
    setFile,
    onSubmitForm,
    setPublishNow,
    resetFrom,
    goToNotifications,
  };
}

function concatDateAndTime(date: Date, time: string) {
  const dateMoment = moment(date);
  const timeMoment = moment(time);

  return moment({
    hour: timeMoment.hour(),
    minute: timeMoment.minute(),
    date: dateMoment.date(),
    month: dateMoment.month(),
    year: dateMoment.year(),
  }).toISOString();
}
