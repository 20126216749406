import {LoginWrapper, Input, Button, Modal} from '../../../components';
import {strings} from '../../../services/language';
import styles from './VerifyEmail.module.scss';
import {useVerifyEmailLogic} from './logics';

export default function VerifyEmail() {
  const {resultModalRef, resendApi, onResendEmail, logout} =
    useVerifyEmailLogic();

  return (
    <LoginWrapper>
      <div className={styles.formContainer}>
        <div className={styles.textWrapper}>
          <h3>{strings('Verify your email')}</h3>
          <div>{strings('We have sent you a confirmation email.')}</div>
          <div>{strings('Please check your mailbox.')}</div>
        </div>
        <div className={styles.loginControl}>
          <Button
            label={strings('Resend confirmation email')}
            type="danger"
            handleClick={onResendEmail}
          />
          <Button
            label={strings('Log Out')}
            type="outline"
            handleClick={logout}
          />
        </div>
        <Modal
          ref={resultModalRef}
          header={<h2>{strings('Success')}</h2>}
          body={
            <div>
              <div>
                {resendApi.error
                  ? resendApi.error
                  : resendApi.data?.success
                  ? strings('Verification email has been sent!')
                  : ''}
              </div>
            </div>
          }
          footer={
            <>
              <Button
                label={strings('Done')}
                type="danger"
                handleClick={() => {
                  resultModalRef.current.hideModal();
                }}
              />
            </>
          }
        />
      </div>
    </LoginWrapper>
  );
}
