import {Profile} from '../../models';
import {API} from '../types';

type GetPCFStaffsParams = {
  _start: number;
  _limit: number;
  _sort: any;
  query?: string;
  roles?: string[];
  centreIds?: string[];
};

interface Response {
  list: Profile[];
  total: number;
}

export function getPCFStaffsAPI(params: GetPCFStaffsParams): API<Response> {
  const urlParams = {
    ...params,
    ...(params.roles && {roles: params.roles.join(',')}),
    ...(params.centreIds && {centreIds: params.centreIds.join(',')}),
  };
  return {
    getData: async client => {
      const {data} = await client({
        url: '/profiles',
        method: 'GET',
        params: urlParams,
      });
      return {data: data};
    },
    getMockData: () => {
      return {list: [], total: 0};
    },
  };
}
