import {Profile} from '../../models';
import {API} from '../types';

export function getProfileByIdAPI(id: number): API<Profile> {
  return {
    getData: async client => {
      const {data} = await client({
        url: `/profiles/${id}`,
        method: 'GET',
      });
      return {data: data};
    },
    getMockData: () => {
      return {
        id: 1,
        role: 'HR',
        name: 'Name',
        createdAt: '2021-09-13T10:33:13.901Z',
        updatedAt: '2021-09-13T10:33:13.901Z',
      };
    },
  };
}
