import {Skill} from '../../models';
import {getState} from '../../store';
import {skills} from '../mockDB';
import {API} from '../types';

interface Response {
  list: Skill[];
  lastSync: string;
}

export function getSkillsAPI(): API<Response> {
  const now = new Date().toISOString();

  return {
    getData: async client => {
      const {data} = await client({
        url: '/skills',
        method: 'GET',
        params: {
          updatedAt_gt: getState().skills.lastSync,
        },
      });
      const skills: Skill[] = data.list;
      return {
        data: {
          list: getState()
            .skills.list.filter(c1 => !skills.some(c2 => c2.id == c1.id))
            .concat(skills)
            .sort((c1, c2) => c1.id - c2.id),
          lastSync: now,
        },
      };
    },
    getMockData: () => {
      return {list: skills, lastSync: now};
    },
  };
}
