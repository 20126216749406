import {Job} from '../../models';
import {jobs} from '../mockDB';
import {API} from '../types';

type GetJobsParams = {
  _start: number;
  _limit: number;
  _sort?: any;
  query?: string;
  searchField?: string;
  skillIds?: number[];
  statuses?: string[];
  districtGroups?: string[];
  startsAt_lt?: string;
  endsAt_gt?: string;
  filterByMonth?: string;
};
interface Response {
  list: Job[];
  total: number;
}

export function getJobsAPI(params: GetJobsParams): API<Response> {
  const urlParams = {
    ...params,
    ...(params.skillIds && {skillIds: params.skillIds.join(',')}),
    ...(params.statuses && {statuses: params.statuses.join(',')}),
    ...(params.districtGroups && {
      districtGroups: params.districtGroups.join(','),
    }),
  };

  return {
    getData: async client => {
      const {data} = await client({
        url: '/jobs',
        method: 'GET',
        params: urlParams,
      });
      return {data: data};
    },
    getMockData: () => {
      return {list: jobs, total: 1000};
    },
  };
}
