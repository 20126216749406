import {useEffect} from 'react';
import {getSkillsAPI, useAPI, useResult} from '../api';
import {updateState, useSelector} from '../store';

export function useSyncSkills() {
  const skills = useSelector(s => s.skills.list);
  const skillsApi = useAPI(getSkillsAPI);
  useEffect(skillsApi.request, []);
  useResult(skillsApi.data, data => {
    updateState({skills: data});
  });
  return {skillsApi, skills};
}
