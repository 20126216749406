import styles from './NotificationCreate.module.scss';
import {
  Breadcrumb,
  Input,
  Select,
  PcfDatePicker,
  PcfTimePicker,
  Button,
  Modal,
  CheckBox,
  Radio,
  FileUpload,
} from '../../components';
import {ReactComponent as ErrorIcon} from '../../assets/img/error.svg';
import {strings} from '../../services/language';
import {useCreateNotiLogic} from './logics';

export default function NotificationCreate() {
  const {
    linkList,
    postalDistrictOptions,
    skillOptions,
    resultModalRef,
    createNotiApi,
    moreDetails,
    publishNow,
    bindTitle,
    bindAdditionalInfo,
    bindRole,
    bindDistrict,
    bindPublishDate,
    bindPublishTime,
    bindPreviewText,
    showRecipientsError,
    setMoreDetails,
    setFile,
    onSubmitForm,
    setPublishNow,
    resetFrom,
    goToNotifications,
  } = useCreateNotiLogic();

  return (
    <>
      <div className="main-header">
        <Breadcrumb linkList={linkList} />
        <h2 className={styles.header}>{strings('Create New Notification')}</h2>
      </div>
      <div className={styles.container}>
        <h3>{strings('General Details')}</h3>
        <div className={styles.inputItem}>
          <Input
            id="title"
            label={strings('Title')}
            placeholder={strings('Enter Title')}
            maxLength={85}
            {...bindTitle}
          />
        </div>
        <div className={`${styles.inputItem} ${styles.timeWrapper}`}>
          <PcfDatePicker
            label={strings('Publish Date')}
            placeholder={strings('Select Date')}
            type="single"
            clearButton={false}
            disabled={publishNow}
            {...bindPublishDate}
          />

          <PcfTimePicker
            type="single"
            label={strings('Publish Time')}
            placeholder={strings('Select Time')}
            disabled={publishNow}
            {...bindPublishTime}
          />

          <CheckBox
            label={strings('Publish Now')}
            id="publishNow"
            name="publishNow"
            checked={publishNow}
            handleChange={() => setPublishNow(!publishNow)}
          />
        </div>
        <hr />
        <h3>{strings('Recipients')}</h3>
        {showRecipientsError && (
          <div className={styles.errorWrapper}>
            <ErrorIcon />
            <span>{strings('Please select a role or a district')}</span>
          </div>
        )}
        <div className={`${styles.inputItem} ${styles.recipientsWrapper}`}>
          <Select
            topLabel={strings('Role')}
            border
            defaultLabel={strings('Select Role')}
            optionList={skillOptions}
            multipleSelect
            allToggle
            allItem
            {...bindRole}
          />

          <Select
            topLabel={strings('District')}
            border
            defaultLabel={strings('District')}
            optionList={postalDistrictOptions}
            multipleSelect
            allToggle
            allItem
            divider
            {...bindDistrict}
          />
        </div>
        <hr />
        <h3>{strings('Content')}</h3>
        <div className={styles.inputItem}>
          <Input
            id="additionalInfo"
            type="textarea"
            label={strings('Preview Text')}
            placeholder={strings(
              'Preview Text are displayed at the push notification.'
            )}
            maxLength={122}
            {...bindPreviewText}
          />
        </div>
        <div className={styles.inputItem}>
          <div className={styles.radioItem}>
            <Radio
              label={strings('Only Push Notification')}
              id="moreDetails"
              value={false}
              checked={moreDetails === false}
              handleChange={() => {
                setMoreDetails(false);
              }}
            />
          </div>
          <Radio
            label={strings('More Details')}
            id="moreDetails"
            value={true}
            checked={moreDetails === true}
            handleChange={() => {
              setMoreDetails(true);
            }}
          />
        </div>
        {moreDetails && (
          <div>
            <div className={styles.inputItem}>
              <Input
                id="additionalInfo"
                type="textarea"
                label={strings('Additional Information')}
                optionalLabel={strings('Optional')}
                placeholder={strings('Any addtional details?')}
                {...bindAdditionalInfo}
              />
            </div>
            <div className={styles.inputItem}>
              <label className={styles.inputLabel}>
                <span>{strings('Attachment')}</span>
                <span className={styles.optionalLabel}>
                  {strings('Optional')}
                </span>
              </label>
              <FileUpload
                onSelectFiles={files => {
                  const filesList = Object.values(files);
                  filesList.length > 0
                    ? setFile(filesList[0])
                    : setFile(undefined);
                }}
              />
            </div>
          </div>
        )}
        <div className={styles.buttonWrapper}>
          <Button
            label={strings('Cancel')}
            type="transparent"
            handleClick={goToNotifications}
          />
          <Button
            label={strings('Create')}
            type="danger"
            handleClick={onSubmitForm}
          />
        </div>

        <Modal
          ref={resultModalRef}
          header={undefined}
          body={
            <div>
              <div>
                {createNotiApi.error
                  ? createNotiApi.error
                  : createNotiApi.data?.notification
                  ? strings('Create Notification Successfully')
                  : ''}
              </div>
            </div>
          }
          footer={
            <>
              <Button
                label={strings('Done')}
                type="danger"
                handleClick={() => {
                  !createNotiApi.error &&
                    createNotiApi.data?.notification &&
                    resetFrom();
                  resultModalRef.current.hideModal();
                }}
              />
            </>
          }
        />
      </div>
    </>
  );
}
