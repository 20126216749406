import {Job} from '../../models';

export const jobs: Job[] = [
  {
    id: 1,
    vacancy: 1,
    hired: 1,
    startsAt: '2021-03-22T11:31:47Z',
    endsAt: '2022-07-06T22:31:47Z',
    daysOff: undefined,
    createdAt: '2020-09-29T11:56:24Z',
    deletedAt: undefined,
    additionalInfo: 'habitasse',
    skillId: 1,
    centreId: 18,
    profileId: 95,
    applicationIds: undefined,
  },
  {
    id: 2,
    vacancy: 7,
    hired: 7,
    startsAt: '2021-06-09T05:34:12Z',
    endsAt: '2023-02-07T14:34:12Z',
    daysOff: undefined,
    createdAt: '2021-03-06T19:51:23Z',
    deletedAt: '2020-08-15T02:46:00Z',
    additionalInfo: 'pretium quis',
    skillId: 2,
    centreId: 44,
    profileId: 43,
    applicationIds: undefined,
  },
  {
    id: 3,
    vacancy: 9,
    hired: 9,
    startsAt: '2020-09-22T18:47:46Z',
    endsAt: '2021-12-06T04:47:46Z',
    daysOff: undefined,
    createdAt: '2020-08-28T11:23:59Z',
    deletedAt: '2020-07-24T04:16:21Z',
    additionalInfo: 'massa quis',
    skillId: 2,
    centreId: 33,
    profileId: 11,
    applicationIds: undefined,
  },
  {
    id: 4,
    vacancy: 9,
    hired: 2,
    startsAt: '2021-01-23T21:18:09Z',
    endsAt: '2022-07-28T03:18:09Z',
    daysOff: undefined,
    createdAt: '2020-09-16T03:07:42Z',
    deletedAt: '2020-08-15T19:58:06Z',
    additionalInfo: 'posuere cubilia',
    skillId: 3,
    centreId: 13,
    profileId: 59,
    applicationIds: undefined,
  },
  {
    id: 5,
    vacancy: 8,
    hired: 7,
    startsAt: '2020-10-02T12:05:46Z',
    endsAt: '2021-06-30T00:05:46Z',
    daysOff: [8, 3],
    createdAt: '2020-11-17T23:39:23Z',
    deletedAt: '2020-12-17T14:55:45Z',
    additionalInfo: 'commodo vulputate',
    skillId: 3,
    centreId: 20,
    profileId: 15,
    applicationIds: [40, 44, 3, 32, 10, 11],
  },
  {
    id: 6,
    vacancy: 9,
    hired: 6,
    startsAt: '2020-08-09T19:41:07Z',
    endsAt: '2021-05-31T04:41:07Z',
    daysOff: undefined,
    createdAt: '2021-07-06T12:01:29Z',
    deletedAt: '2021-07-19T23:51:13Z',
    additionalInfo: 'consequat',
    skillId: 3,
    centreId: 15,
    profileId: 24,
    applicationIds: undefined,
  },
  {
    id: 7,
    vacancy: 5,
    hired: 0,
    startsAt: '2020-11-04T10:09:32Z',
    endsAt: '2021-08-08T20:09:32Z',
    daysOff: [1, 6],
    createdAt: '2020-09-25T11:24:17Z',
    deletedAt: undefined,
    additionalInfo: 'elit ac',
    skillId: 2,
    centreId: 9,
    profileId: 32,
    applicationIds: [13, 19, 45, 23, 29, 36],
  },
  {
    id: 8,
    vacancy: 8,
    hired: 0,
    startsAt: '2020-09-19T06:34:11Z',
    endsAt: '2022-08-11T17:34:11Z',
    daysOff: undefined,
    createdAt: '2020-09-23T23:32:28Z',
    deletedAt: '2021-01-31T06:03:45Z',
    additionalInfo: 'in',
    skillId: 1,
    centreId: 12,
    profileId: 53,
    applicationIds: undefined,
  },
  {
    id: 9,
    vacancy: 3,
    hired: 0,
    startsAt: '2021-01-06T02:10:49Z',
    endsAt: '2022-06-08T06:10:49Z',
    daysOff: undefined,
    createdAt: '2021-02-08T11:21:20Z',
    deletedAt: '2021-03-03T11:51:11Z',
    additionalInfo: 'undefineda',
    skillId: 1,
    centreId: 45,
    profileId: 7,
    applicationIds: undefined,
  },
  {
    id: 10,
    vacancy: 1,
    hired: 1,
    startsAt: '2021-01-31T05:57:10Z',
    endsAt: '2023-02-20T15:57:10Z',
    daysOff: [6, 5],
    createdAt: '2021-06-16T23:05:51Z',
    deletedAt: '2020-07-31T11:26:38Z',
    additionalInfo: 'cras pellentesque',
    skillId: 2,
    centreId: 1,
    profileId: 51,
    applicationIds: [23, 38, 23, 30, 22, 42],
  },
  {
    id: 11,
    vacancy: 8,
    hired: 4,
    startsAt: '2021-04-01T19:05:40Z',
    endsAt: '2022-09-21T06:05:40Z',
    daysOff: undefined,
    createdAt: '2021-06-15T08:20:48Z',
    deletedAt: '2020-12-20T02:31:21Z',
    additionalInfo: 'dis parturient',
    skillId: 1,
    centreId: 32,
    profileId: 72,
    applicationIds: undefined,
  },
  {
    id: 12,
    vacancy: 3,
    hired: 0,
    startsAt: '2021-06-06T18:12:59Z',
    endsAt: '2023-02-01T06:12:59Z',
    daysOff: undefined,
    createdAt: '2021-06-14T05:40:11Z',
    deletedAt: '2021-05-14T23:17:36Z',
    additionalInfo: 'tempus semper',
    skillId: 2,
    centreId: 50,
    profileId: 17,
    applicationIds: undefined,
  },
  {
    id: 13,
    vacancy: 8,
    hired: 0,
    startsAt: '2020-08-05T00:24:41Z',
    endsAt: '2022-07-10T03:24:41Z',
    daysOff: undefined,
    createdAt: '2021-03-07T00:58:34Z',
    deletedAt: '2020-11-27T20:36:50Z',
    additionalInfo: 'vestibulum',
    skillId: 1,
    centreId: 7,
    profileId: 92,
    applicationIds: undefined,
  },
  {
    id: 14,
    vacancy: 10,
    hired: 9,
    startsAt: '2021-02-20T08:17:15Z',
    endsAt: '2023-01-19T11:17:15Z',
    daysOff: undefined,
    createdAt: '2021-03-19T21:16:28Z',
    deletedAt: '2021-05-03T19:09:06Z',
    additionalInfo: 'erat id',
    skillId: 2,
    centreId: 18,
    profileId: 90,
    applicationIds: undefined,
  },
  {
    id: 15,
    vacancy: 3,
    hired: 3,
    startsAt: '2021-06-22T19:18:42Z',
    endsAt: '2022-07-03T03:18:42Z',
    daysOff: undefined,
    createdAt: '2021-04-01T09:50:08Z',
    deletedAt: '2021-01-04T13:56:39Z',
    additionalInfo: 'viverra',
    skillId: 3,
    centreId: 45,
    profileId: 68,
    applicationIds: undefined,
  },
  {
    id: 16,
    vacancy: 3,
    hired: 2,
    startsAt: '2020-11-24T10:47:39Z',
    endsAt: '2022-06-29T21:47:39Z',
    daysOff: undefined,
    createdAt: '2021-07-21T19:42:43Z',
    deletedAt: undefined,
    additionalInfo: 'sit',
    skillId: 3,
    centreId: 34,
    profileId: 25,
    applicationIds: undefined,
  },
  {
    id: 17,
    vacancy: 8,
    hired: 3,
    startsAt: '2020-07-31T06:56:55Z',
    endsAt: '2020-10-10T10:56:55Z',
    daysOff: undefined,
    createdAt: '2020-08-06T02:45:12Z',
    deletedAt: '2020-09-09T23:38:26Z',
    additionalInfo: 'felis',
    skillId: 1,
    centreId: 7,
    profileId: 78,
    applicationIds: undefined,
  },
  {
    id: 18,
    vacancy: 8,
    hired: 3,
    startsAt: '2020-08-18T05:25:21Z',
    endsAt: '2021-02-10T08:25:21Z',
    daysOff: undefined,
    createdAt: '2020-12-22T22:10:36Z',
    deletedAt: undefined,
    additionalInfo: 'a',
    skillId: 2,
    centreId: 9,
    profileId: 52,
    applicationIds: undefined,
  },
  {
    id: 19,
    vacancy: 10,
    hired: 8,
    startsAt: '2020-11-26T02:45:49Z',
    endsAt: '2021-12-27T11:45:49Z',
    daysOff: [5, 2],
    createdAt: '2021-03-28T23:24:49Z',
    deletedAt: '2020-11-17T15:50:02Z',
    additionalInfo: 'quisque',
    skillId: 2,
    centreId: 5,
    profileId: 90,
    applicationIds: [41, 17, 16, 43, 10, 26],
  },
  {
    id: 20,
    vacancy: 3,
    hired: 2,
    startsAt: '2021-03-12T04:20:42Z',
    endsAt: '2021-08-04T08:20:42Z',
    daysOff: undefined,
    createdAt: '2021-04-25T12:51:41Z',
    deletedAt: '2021-02-26T07:45:52Z',
    additionalInfo: 'at',
    skillId: 3,
    centreId: 32,
    profileId: 30,
    applicationIds: undefined,
  },
  {
    id: 21,
    vacancy: 5,
    hired: 0,
    startsAt: '2021-01-10T01:29:25Z',
    endsAt: '2023-01-28T07:29:25Z',
    daysOff: [2, 8],
    createdAt: '2021-06-16T12:05:43Z',
    deletedAt: '2020-11-25T05:48:14Z',
    additionalInfo: 'diam',
    skillId: 1,
    centreId: 20,
    profileId: 9,
    applicationIds: [46, 28, 8, 10, 3, 36],
  },
  {
    id: 22,
    vacancy: 5,
    hired: 1,
    startsAt: '2020-08-28T08:09:08Z',
    endsAt: '2022-07-26T13:09:08Z',
    daysOff: [10, 8],
    createdAt: '2021-04-02T18:49:32Z',
    deletedAt: '2020-11-16T17:41:42Z',
    additionalInfo: 'tortor',
    skillId: 3,
    centreId: 39,
    profileId: 87,
    applicationIds: [45, 21, 8, 20, 19, 3],
  },
  {
    id: 23,
    vacancy: 8,
    hired: 1,
    startsAt: '2021-06-06T19:51:38Z',
    endsAt: '2023-04-18T02:51:38Z',
    daysOff: undefined,
    createdAt: '2021-05-10T19:38:58Z',
    deletedAt: '2021-02-06T06:57:18Z',
    additionalInfo: 'ultricies',
    skillId: 2,
    centreId: 39,
    profileId: 12,
    applicationIds: undefined,
  },
  {
    id: 24,
    vacancy: 4,
    hired: 4,
    startsAt: '2021-02-01T01:08:00Z',
    endsAt: '2022-04-20T04:08:00Z',
    daysOff: undefined,
    createdAt: '2020-09-17T19:12:55Z',
    deletedAt: '2020-10-09T16:25:50Z',
    additionalInfo: 'a odio',
    skillId: 3,
    centreId: 2,
    profileId: 49,
    applicationIds: undefined,
  },
  {
    id: 25,
    vacancy: 1,
    hired: 1,
    startsAt: '2021-04-03T08:26:34Z',
    endsAt: '2023-04-28T19:26:34Z',
    daysOff: undefined,
    createdAt: '2020-08-24T06:09:36Z',
    deletedAt: '2020-11-08T03:19:30Z',
    additionalInfo: 'sed ante',
    skillId: 1,
    centreId: 49,
    profileId: 61,
    applicationIds: undefined,
  },
  {
    id: 26,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-08-04T00:31:59Z',
    endsAt: '2021-05-10T04:31:59Z',
    daysOff: [3, 2],
    createdAt: '2021-04-26T17:40:32Z',
    deletedAt: undefined,
    additionalInfo: 'in magna',
    skillId: 3,
    centreId: 35,
    profileId: 74,
    applicationIds: [27, 5, 12, 50, 21, 44],
  },
  {
    id: 27,
    vacancy: 6,
    hired: 0,
    startsAt: '2020-11-21T12:00:45Z',
    endsAt: '2022-04-12T17:00:45Z',
    daysOff: undefined,
    createdAt: '2021-02-18T04:07:36Z',
    deletedAt: '2021-01-26T06:21:59Z',
    additionalInfo: 'ipsum praesent',
    skillId: 1,
    centreId: 15,
    profileId: 1,
    applicationIds: undefined,
  },
  {
    id: 28,
    vacancy: 4,
    hired: 2,
    startsAt: '2021-06-30T13:04:38Z',
    endsAt: '2022-03-04T19:04:38Z',
    daysOff: [3, 7],
    createdAt: '2021-05-03T22:30:15Z',
    deletedAt: '2020-10-24T19:20:23Z',
    additionalInfo: 'platea dictumst',
    skillId: 1,
    centreId: 12,
    profileId: 89,
    applicationIds: [5, 18, 18, 46, 1, 33],
  },
  {
    id: 29,
    vacancy: 2,
    hired: 1,
    startsAt: '2020-09-13T14:20:48Z',
    endsAt: '2022-03-24T20:20:48Z',
    daysOff: undefined,
    createdAt: '2021-02-27T17:04:32Z',
    deletedAt: '2020-10-11T15:24:59Z',
    additionalInfo: 'amet turpis',
    skillId: 3,
    centreId: 1,
    profileId: 5,
    applicationIds: undefined,
  },
  {
    id: 30,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-09-30T00:02:47Z',
    endsAt: '2022-07-03T03:02:47Z',
    daysOff: undefined,
    createdAt: '2020-12-07T09:13:55Z',
    deletedAt: '2020-08-27T18:02:50Z',
    additionalInfo: 'elit ac',
    skillId: 3,
    centreId: 44,
    profileId: 41,
    applicationIds: undefined,
  },
  {
    id: 31,
    vacancy: 4,
    hired: 2,
    startsAt: '2021-06-27T10:16:45Z',
    endsAt: '2021-11-27T20:16:45Z',
    daysOff: undefined,
    createdAt: '2021-02-09T21:50:28Z',
    deletedAt: '2021-04-20T09:41:08Z',
    additionalInfo: 'et ultrices',
    skillId: 3,
    centreId: 43,
    profileId: 2,
    applicationIds: undefined,
  },
  {
    id: 32,
    vacancy: 2,
    hired: 1,
    startsAt: '2020-07-27T07:25:43Z',
    endsAt: '2021-05-26T19:25:43Z',
    daysOff: undefined,
    createdAt: '2021-03-25T23:52:06Z',
    deletedAt: undefined,
    additionalInfo: 'vivamus tortor',
    skillId: 2,
    centreId: 39,
    profileId: 40,
    applicationIds: undefined,
  },
  {
    id: 33,
    vacancy: 8,
    hired: 1,
    startsAt: '2020-08-03T23:30:14Z',
    endsAt: '2021-08-29T05:30:14Z',
    daysOff: undefined,
    createdAt: '2020-10-17T01:52:50Z',
    deletedAt: undefined,
    additionalInfo: 'donec',
    skillId: 2,
    centreId: 5,
    profileId: 33,
    applicationIds: undefined,
  },
  {
    id: 34,
    vacancy: 2,
    hired: 0,
    startsAt: '2020-11-13T14:52:01Z',
    endsAt: '2021-08-30T19:52:01Z',
    daysOff: undefined,
    createdAt: '2021-04-25T16:46:28Z',
    deletedAt: '2020-10-02T09:15:42Z',
    additionalInfo: 'quam sapien',
    skillId: 3,
    centreId: 5,
    profileId: 39,
    applicationIds: undefined,
  },
  {
    id: 35,
    vacancy: 4,
    hired: 2,
    startsAt: '2021-02-02T08:16:51Z',
    endsAt: '2022-11-10T20:16:51Z',
    daysOff: [4, 10],
    createdAt: '2020-10-18T01:08:13Z',
    deletedAt: '2021-07-18T11:16:21Z',
    additionalInfo: 'at',
    skillId: 2,
    centreId: 49,
    profileId: 16,
    applicationIds: [50, 28, 2, 40, 39, 10],
  },
  {
    id: 36,
    vacancy: 3,
    hired: 1,
    startsAt: '2021-02-16T16:10:07Z',
    endsAt: '2021-07-12T02:10:07Z',
    daysOff: undefined,
    createdAt: '2020-09-26T03:16:38Z',
    deletedAt: undefined,
    additionalInfo: 'duis bibendum',
    skillId: 1,
    centreId: 15,
    profileId: 100,
    applicationIds: undefined,
  },
  {
    id: 37,
    vacancy: 10,
    hired: 6,
    startsAt: '2020-11-21T05:48:15Z',
    endsAt: '2021-08-11T09:48:15Z',
    daysOff: [3, 1],
    createdAt: '2021-04-13T15:34:36Z',
    deletedAt: '2021-02-09T18:56:37Z',
    additionalInfo: 'morbi',
    skillId: 2,
    centreId: 9,
    profileId: 2,
    applicationIds: [24, 3, 17, 1, 4, 13],
  },
  {
    id: 38,
    vacancy: 10,
    hired: 3,
    startsAt: '2020-12-05T12:01:05Z',
    endsAt: '2022-03-27T00:01:05Z',
    daysOff: undefined,
    createdAt: '2021-02-28T10:23:31Z',
    deletedAt: '2021-07-13T06:14:41Z',
    additionalInfo: 'aliquam erat',
    skillId: 1,
    centreId: 10,
    profileId: 20,
    applicationIds: undefined,
  },
  {
    id: 39,
    vacancy: 7,
    hired: 5,
    startsAt: '2020-11-19T22:16:20Z',
    endsAt: '2022-08-26T06:16:20Z',
    daysOff: undefined,
    createdAt: '2020-12-08T19:38:43Z',
    deletedAt: '2020-11-07T13:09:13Z',
    additionalInfo: 'orci',
    skillId: 2,
    centreId: 6,
    profileId: 36,
    applicationIds: undefined,
  },
  {
    id: 40,
    vacancy: 10,
    hired: 9,
    startsAt: '2021-04-27T06:09:05Z',
    endsAt: '2022-06-29T12:09:05Z',
    daysOff: [2, 5],
    createdAt: '2021-03-30T09:21:04Z',
    deletedAt: undefined,
    additionalInfo: 'neque',
    skillId: 2,
    centreId: 41,
    profileId: 37,
    applicationIds: [6, 50, 37, 3, 13, 3],
  },
  {
    id: 41,
    vacancy: 7,
    hired: 3,
    startsAt: '2021-05-12T12:02:54Z',
    endsAt: '2022-08-21T16:02:54Z',
    daysOff: [5, 7],
    createdAt: '2020-11-20T02:44:54Z',
    deletedAt: '2021-01-29T22:12:10Z',
    additionalInfo: 'quam pharetra',
    skillId: 3,
    centreId: 19,
    profileId: 10,
    applicationIds: [38, 19, 23, 40, 39, 11],
  },
  {
    id: 42,
    vacancy: 3,
    hired: 2,
    startsAt: '2020-08-13T00:15:35Z',
    endsAt: '2022-02-03T10:15:35Z',
    daysOff: [8, 7],
    createdAt: '2020-09-29T13:44:20Z',
    deletedAt: '2021-03-16T21:17:08Z',
    additionalInfo: 'potenti',
    skillId: 3,
    centreId: 1,
    profileId: 8,
    applicationIds: [1, 15, 30, 47, 21, 19],
  },
  {
    id: 43,
    vacancy: 2,
    hired: 1,
    startsAt: '2020-09-10T05:35:12Z',
    endsAt: '2021-07-21T10:35:12Z',
    daysOff: [1, 5],
    createdAt: '2020-11-07T10:13:11Z',
    deletedAt: '2020-10-01T18:44:50Z',
    additionalInfo: 'pede ullamcorper',
    skillId: 2,
    centreId: 21,
    profileId: 28,
    applicationIds: [7, 22, 22, 44, 31, 16],
  },
  {
    id: 44,
    vacancy: 10,
    hired: 9,
    startsAt: '2021-05-24T01:39:37Z',
    endsAt: '2021-09-22T12:39:37Z',
    daysOff: undefined,
    createdAt: '2020-07-30T08:15:00Z',
    deletedAt: '2021-06-13T22:56:11Z',
    additionalInfo: 'vivamus metus',
    skillId: 2,
    centreId: 2,
    profileId: 45,
    applicationIds: undefined,
  },
  {
    id: 45,
    vacancy: 6,
    hired: 5,
    startsAt: '2021-04-19T19:38:06Z',
    endsAt: '2022-11-15T00:38:06Z',
    daysOff: [9, 6],
    createdAt: '2020-08-06T06:11:52Z',
    deletedAt: '2021-07-19T05:38:39Z',
    additionalInfo: 'habitasse',
    skillId: 3,
    centreId: 34,
    profileId: 66,
    applicationIds: [22, 33, 17, 21, 34, 44],
  },
  {
    id: 46,
    vacancy: 2,
    hired: 0,
    startsAt: '2020-09-09T13:36:01Z',
    endsAt: '2020-12-09T22:36:01Z',
    daysOff: undefined,
    createdAt: '2020-11-07T09:47:49Z',
    deletedAt: undefined,
    additionalInfo: 'sapien varius',
    skillId: 3,
    centreId: 22,
    profileId: 66,
    applicationIds: undefined,
  },
  {
    id: 47,
    vacancy: 9,
    hired: 8,
    startsAt: '2020-10-14T23:01:29Z',
    endsAt: '2022-09-05T06:01:29Z',
    daysOff: [4, 7],
    createdAt: '2020-11-01T03:13:04Z',
    deletedAt: '2020-11-19T08:28:23Z',
    additionalInfo: 'suscipit a',
    skillId: 3,
    centreId: 7,
    profileId: 37,
    applicationIds: [30, 4, 7, 12, 12, 12],
  },
  {
    id: 48,
    vacancy: 7,
    hired: 7,
    startsAt: '2021-02-20T14:21:05Z',
    endsAt: '2022-10-27T20:21:05Z',
    daysOff: undefined,
    createdAt: '2020-09-16T21:52:40Z',
    deletedAt: '2021-07-16T15:17:20Z',
    additionalInfo: 'ut ultrices',
    skillId: 1,
    centreId: 22,
    profileId: 75,
    applicationIds: undefined,
  },
  {
    id: 49,
    vacancy: 2,
    hired: 1,
    startsAt: '2021-03-29T18:05:03Z',
    endsAt: '2022-08-03T22:05:03Z',
    daysOff: undefined,
    createdAt: '2021-05-22T15:56:45Z',
    deletedAt: '2020-07-31T16:57:25Z',
    additionalInfo: 'lorem',
    skillId: 2,
    centreId: 14,
    profileId: 22,
    applicationIds: undefined,
  },
  {
    id: 50,
    vacancy: 5,
    hired: 5,
    startsAt: '2021-04-17T04:23:06Z',
    endsAt: '2023-02-13T14:23:06Z',
    daysOff: [7, 1],
    createdAt: '2021-03-22T00:43:42Z',
    deletedAt: '2021-06-22T07:13:07Z',
    additionalInfo: 'fusce',
    skillId: 1,
    centreId: 50,
    profileId: 88,
    applicationIds: [6, 19, 1, 40, 6, 23],
  },
  {
    id: 51,
    vacancy: 6,
    hired: 0,
    startsAt: '2021-05-27T09:18:47Z',
    endsAt: '2022-07-24T14:18:47Z',
    daysOff: undefined,
    createdAt: '2021-03-08T05:23:02Z',
    deletedAt: '2020-10-29T11:36:30Z',
    additionalInfo: 'feugiat non',
    skillId: 2,
    centreId: 17,
    profileId: 73,
    applicationIds: undefined,
  },
  {
    id: 52,
    vacancy: 8,
    hired: 8,
    startsAt: '2021-07-04T08:01:12Z',
    endsAt: '2022-08-19T19:01:12Z',
    daysOff: undefined,
    createdAt: '2021-02-26T02:37:20Z',
    deletedAt: undefined,
    additionalInfo: 'aliquet at',
    skillId: 3,
    centreId: 17,
    profileId: 74,
    applicationIds: undefined,
  },
  {
    id: 53,
    vacancy: 8,
    hired: 2,
    startsAt: '2021-04-17T12:32:01Z',
    endsAt: '2022-02-16T00:32:01Z',
    daysOff: undefined,
    createdAt: '2020-12-04T22:51:08Z',
    deletedAt: '2020-09-11T22:33:35Z',
    additionalInfo: 'vivamus vel',
    skillId: 2,
    centreId: 11,
    profileId: 51,
    applicationIds: undefined,
  },
  {
    id: 54,
    vacancy: 7,
    hired: 5,
    startsAt: '2021-03-05T02:59:28Z',
    endsAt: '2022-06-30T10:59:28Z',
    daysOff: undefined,
    createdAt: '2020-12-15T08:55:58Z',
    deletedAt: undefined,
    additionalInfo: 'eros elementum',
    skillId: 1,
    centreId: 10,
    profileId: 77,
    applicationIds: undefined,
  },
  {
    id: 55,
    vacancy: 3,
    hired: 1,
    startsAt: '2021-03-05T08:18:09Z',
    endsAt: '2022-07-26T15:18:09Z',
    daysOff: [6, 6],
    createdAt: '2021-03-21T13:26:17Z',
    deletedAt: '2021-02-06T17:43:03Z',
    additionalInfo: 'vel',
    skillId: 3,
    centreId: 31,
    profileId: 20,
    applicationIds: [20, 14, 7, 30, 45, 23],
  },
  {
    id: 56,
    vacancy: 2,
    hired: 2,
    startsAt: '2021-03-06T21:21:56Z',
    endsAt: '2021-06-11T06:21:56Z',
    daysOff: undefined,
    createdAt: '2021-03-11T14:39:33Z',
    deletedAt: undefined,
    additionalInfo: 'tortor risus',
    skillId: 1,
    centreId: 34,
    profileId: 34,
    applicationIds: undefined,
  },
  {
    id: 57,
    vacancy: 6,
    hired: 4,
    startsAt: '2021-01-21T12:47:34Z',
    endsAt: '2023-01-06T16:47:34Z',
    daysOff: [5, 8],
    createdAt: '2021-03-31T04:59:29Z',
    deletedAt: '2021-04-06T03:21:11Z',
    additionalInfo: 'dictumst aliquam',
    skillId: 3,
    centreId: 25,
    profileId: 67,
    applicationIds: [38, 49, 41, 26, 2, 23],
  },
  {
    id: 58,
    vacancy: 8,
    hired: 0,
    startsAt: '2020-08-07T07:43:37Z',
    endsAt: '2020-11-14T10:43:37Z',
    daysOff: [7, 10],
    createdAt: '2021-03-30T03:20:33Z',
    deletedAt: undefined,
    additionalInfo: 'in blandit',
    skillId: 3,
    centreId: 3,
    profileId: 70,
    applicationIds: [3, 2, 7, 9, 28, 23],
  },
  {
    id: 59,
    vacancy: 3,
    hired: 3,
    startsAt: '2021-02-21T03:39:31Z',
    endsAt: '2022-07-20T07:39:31Z',
    daysOff: undefined,
    createdAt: '2021-05-22T18:37:35Z',
    deletedAt: '2021-01-31T14:31:49Z',
    additionalInfo: 'sapien',
    skillId: 2,
    centreId: 1,
    profileId: 41,
    applicationIds: undefined,
  },
  {
    id: 60,
    vacancy: 3,
    hired: 0,
    startsAt: '2021-01-16T06:01:05Z',
    endsAt: '2022-10-02T12:01:05Z',
    daysOff: [5, 3],
    createdAt: '2020-09-06T23:47:17Z',
    deletedAt: undefined,
    additionalInfo: 'quam',
    skillId: 1,
    centreId: 20,
    profileId: 66,
    applicationIds: [29, 41, 10, 48, 29, 14],
  },
  {
    id: 61,
    vacancy: 3,
    hired: 3,
    startsAt: '2021-03-11T02:03:43Z',
    endsAt: '2021-10-12T06:03:43Z',
    daysOff: undefined,
    createdAt: '2021-06-11T05:58:18Z',
    deletedAt: undefined,
    additionalInfo: 'ut massa',
    skillId: 3,
    centreId: 13,
    profileId: 80,
    applicationIds: undefined,
  },
  {
    id: 62,
    vacancy: 8,
    hired: 8,
    startsAt: '2021-04-29T07:41:46Z',
    endsAt: '2022-05-13T18:41:46Z',
    daysOff: undefined,
    createdAt: '2021-03-18T06:07:56Z',
    deletedAt: '2020-12-15T00:04:31Z',
    additionalInfo: 'semper',
    skillId: 2,
    centreId: 31,
    profileId: 43,
    applicationIds: undefined,
  },
  {
    id: 63,
    vacancy: 7,
    hired: 5,
    startsAt: '2020-10-22T00:19:46Z',
    endsAt: '2022-05-07T06:19:46Z',
    daysOff: [7, 3],
    createdAt: '2020-12-09T18:31:34Z',
    deletedAt: '2021-05-23T16:00:53Z',
    additionalInfo: 'primis',
    skillId: 1,
    centreId: 39,
    profileId: 64,
    applicationIds: [5, 37, 4, 33, 28, 18],
  },
  {
    id: 64,
    vacancy: 2,
    hired: 2,
    startsAt: '2021-04-17T20:17:00Z',
    endsAt: '2023-04-12T07:17:00Z',
    daysOff: undefined,
    createdAt: '2021-07-04T17:38:35Z',
    deletedAt: '2020-10-22T18:45:17Z',
    additionalInfo: 'feugiat',
    skillId: 1,
    centreId: 17,
    profileId: 92,
    applicationIds: undefined,
  },
  {
    id: 65,
    vacancy: 4,
    hired: 3,
    startsAt: '2021-06-25T19:05:52Z',
    endsAt: '2022-05-11T05:05:52Z',
    daysOff: [10, 5],
    createdAt: '2020-10-12T13:33:50Z',
    deletedAt: '2020-12-30T22:04:03Z',
    additionalInfo: 'diam',
    skillId: 1,
    centreId: 43,
    profileId: 20,
    applicationIds: [49, 28, 10, 22, 10, 9],
  },
  {
    id: 66,
    vacancy: 10,
    hired: 4,
    startsAt: '2021-01-06T04:48:52Z',
    endsAt: '2021-11-05T16:48:52Z',
    daysOff: undefined,
    createdAt: '2021-07-08T07:14:07Z',
    deletedAt: '2020-08-16T14:00:54Z',
    additionalInfo: 'ultrices aliquet',
    skillId: 1,
    centreId: 27,
    profileId: 18,
    applicationIds: undefined,
  },
  {
    id: 67,
    vacancy: 6,
    hired: 5,
    startsAt: '2021-05-03T19:36:57Z',
    endsAt: '2023-01-29T04:36:57Z',
    daysOff: undefined,
    createdAt: '2021-05-19T11:42:49Z',
    deletedAt: '2020-08-18T04:50:23Z',
    additionalInfo: 'tellus',
    skillId: 2,
    centreId: 1,
    profileId: 74,
    applicationIds: undefined,
  },
  {
    id: 68,
    vacancy: 8,
    hired: 6,
    startsAt: '2020-08-26T21:38:51Z',
    endsAt: '2020-10-02T01:38:51Z',
    daysOff: undefined,
    createdAt: '2020-10-16T23:56:51Z',
    deletedAt: '2021-03-14T10:58:53Z',
    additionalInfo: 'vestibulum',
    skillId: 1,
    centreId: 3,
    profileId: 19,
    applicationIds: undefined,
  },
  {
    id: 69,
    vacancy: 1,
    hired: 1,
    startsAt: '2021-07-08T23:22:29Z',
    endsAt: '2022-07-15T10:22:29Z',
    daysOff: [5, 2],
    createdAt: '2020-12-26T15:38:14Z',
    deletedAt: '2020-09-02T19:01:20Z',
    additionalInfo: 'id',
    skillId: 2,
    centreId: 43,
    profileId: 94,
    applicationIds: [3, 28, 21, 30, 42, 12],
  },
  {
    id: 70,
    vacancy: 7,
    hired: 5,
    startsAt: '2021-04-20T07:03:43Z',
    endsAt: '2022-03-05T18:03:43Z',
    daysOff: [6, 4],
    createdAt: '2020-08-14T02:45:20Z',
    deletedAt: '2021-03-06T17:58:33Z',
    additionalInfo: 'maecenas',
    skillId: 3,
    centreId: 14,
    profileId: 65,
    applicationIds: [38, 7, 20, 45, 22, 37],
  },
  {
    id: 71,
    vacancy: 5,
    hired: 0,
    startsAt: '2021-06-17T08:25:16Z',
    endsAt: '2022-11-09T17:25:16Z',
    daysOff: undefined,
    createdAt: '2021-06-28T18:01:31Z',
    deletedAt: undefined,
    additionalInfo: 'quam pharetra',
    skillId: 3,
    centreId: 34,
    profileId: 36,
    applicationIds: undefined,
  },
  {
    id: 72,
    vacancy: 5,
    hired: 5,
    startsAt: '2020-09-09T21:13:25Z',
    endsAt: '2022-07-05T08:13:25Z',
    daysOff: [1, 9],
    createdAt: '2020-09-17T09:11:37Z',
    deletedAt: '2021-05-12T21:28:52Z',
    additionalInfo: 'consequat',
    skillId: 2,
    centreId: 33,
    profileId: 4,
    applicationIds: [20, 23, 3, 2, 35, 39],
  },
  {
    id: 73,
    vacancy: 7,
    hired: 3,
    startsAt: '2020-11-09T18:14:41Z',
    endsAt: '2021-04-22T23:14:41Z',
    daysOff: undefined,
    createdAt: '2021-02-19T00:29:56Z',
    deletedAt: '2021-03-07T13:34:51Z',
    additionalInfo: 'et magnis',
    skillId: 1,
    centreId: 10,
    profileId: 24,
    applicationIds: undefined,
  },
  {
    id: 74,
    vacancy: 9,
    hired: 0,
    startsAt: '2020-10-17T13:16:38Z',
    endsAt: '2021-01-26T00:16:38Z',
    daysOff: undefined,
    createdAt: '2021-05-26T02:41:02Z',
    deletedAt: '2021-07-21T19:49:49Z',
    additionalInfo: 'metus',
    skillId: 2,
    centreId: 38,
    profileId: 72,
    applicationIds: undefined,
  },
  {
    id: 75,
    vacancy: 9,
    hired: 6,
    startsAt: '2020-12-26T11:31:06Z',
    endsAt: '2022-04-03T21:31:06Z',
    daysOff: undefined,
    createdAt: '2020-08-10T12:44:57Z',
    deletedAt: '2020-11-30T00:22:00Z',
    additionalInfo: 'morbi',
    skillId: 1,
    centreId: 27,
    profileId: 76,
    applicationIds: undefined,
  },
  {
    id: 76,
    vacancy: 4,
    hired: 4,
    startsAt: '2021-02-22T19:51:47Z',
    endsAt: '2021-04-15T22:51:47Z',
    daysOff: [1, 10],
    createdAt: '2021-03-24T05:21:59Z',
    deletedAt: '2020-10-15T14:14:56Z',
    additionalInfo: 'mauris morbi',
    skillId: 3,
    centreId: 37,
    profileId: 99,
    applicationIds: [21, 50, 16, 43, 40, 18],
  },
  {
    id: 77,
    vacancy: 10,
    hired: 2,
    startsAt: '2021-04-09T05:42:30Z',
    endsAt: '2022-04-27T16:42:30Z',
    daysOff: undefined,
    createdAt: '2020-10-04T19:36:12Z',
    deletedAt: '2021-04-08T06:31:58Z',
    additionalInfo: 'interdum in',
    skillId: 2,
    centreId: 30,
    profileId: 94,
    applicationIds: undefined,
  },
  {
    id: 78,
    vacancy: 7,
    hired: 6,
    startsAt: '2020-10-06T20:33:50Z',
    endsAt: '2021-06-01T07:33:50Z',
    daysOff: undefined,
    createdAt: '2021-05-03T01:08:17Z',
    deletedAt: '2021-01-10T20:04:59Z',
    additionalInfo: 'cras',
    skillId: 3,
    centreId: 21,
    profileId: 83,
    applicationIds: undefined,
  },
  {
    id: 79,
    vacancy: 9,
    hired: 5,
    startsAt: '2021-06-09T15:06:56Z',
    endsAt: '2023-06-21T20:06:56Z',
    daysOff: undefined,
    createdAt: '2020-08-27T00:34:59Z',
    deletedAt: '2021-04-06T08:04:11Z',
    additionalInfo: 'etiam faucibus',
    skillId: 1,
    centreId: 40,
    profileId: 38,
    applicationIds: undefined,
  },
  {
    id: 80,
    vacancy: 3,
    hired: 3,
    startsAt: '2020-08-24T18:10:38Z',
    endsAt: '2022-06-02T03:10:38Z',
    daysOff: undefined,
    createdAt: '2021-01-29T15:32:36Z',
    deletedAt: '2021-04-15T22:27:44Z',
    additionalInfo: 'sed vestibulum',
    skillId: 1,
    centreId: 21,
    profileId: 52,
    applicationIds: undefined,
  },
  {
    id: 81,
    vacancy: 7,
    hired: 4,
    startsAt: '2021-06-20T07:55:18Z',
    endsAt: '2022-03-29T13:55:18Z',
    daysOff: undefined,
    createdAt: '2021-04-12T09:37:46Z',
    deletedAt: '2021-01-08T20:34:53Z',
    additionalInfo: 'accumsan tellus',
    skillId: 1,
    centreId: 43,
    profileId: 77,
    applicationIds: undefined,
  },
  {
    id: 82,
    vacancy: 2,
    hired: 2,
    startsAt: '2020-11-25T22:53:39Z',
    endsAt: '2021-12-18T09:53:39Z',
    daysOff: undefined,
    createdAt: '2020-09-25T08:01:22Z',
    deletedAt: '2020-09-18T22:53:29Z',
    additionalInfo: 'turpis donec',
    skillId: 2,
    centreId: 15,
    profileId: 100,
    applicationIds: undefined,
  },
  {
    id: 83,
    vacancy: 3,
    hired: 0,
    startsAt: '2021-02-07T07:31:35Z',
    endsAt: '2022-09-11T19:31:35Z',
    daysOff: undefined,
    createdAt: '2020-08-27T04:07:45Z',
    deletedAt: undefined,
    additionalInfo: 'blandit',
    skillId: 3,
    centreId: 30,
    profileId: 70,
    applicationIds: undefined,
  },
  {
    id: 84,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-12-14T10:40:11Z',
    endsAt: '2021-04-03T19:40:11Z',
    daysOff: undefined,
    createdAt: '2020-12-25T12:19:45Z',
    deletedAt: '2021-05-04T14:51:34Z',
    additionalInfo: 'velit',
    skillId: 1,
    centreId: 34,
    profileId: 97,
    applicationIds: undefined,
  },
  {
    id: 85,
    vacancy: 3,
    hired: 3,
    startsAt: '2020-10-04T17:23:31Z',
    endsAt: '2021-02-15T05:23:31Z',
    daysOff: [8, 5],
    createdAt: '2021-05-06T06:27:00Z',
    deletedAt: '2020-09-09T13:52:41Z',
    additionalInfo: 'enim',
    skillId: 2,
    centreId: 18,
    profileId: 56,
    applicationIds: [18, 47, 49, 25, 23, 16],
  },
  {
    id: 86,
    vacancy: 7,
    hired: 3,
    startsAt: '2021-01-03T03:32:18Z',
    endsAt: '2022-08-04T10:32:18Z',
    daysOff: undefined,
    createdAt: '2020-08-05T01:46:57Z',
    deletedAt: undefined,
    additionalInfo: 'vivamus',
    skillId: 2,
    centreId: 10,
    profileId: 56,
    applicationIds: undefined,
  },
  {
    id: 87,
    vacancy: 4,
    hired: 2,
    startsAt: '2020-12-26T16:44:03Z',
    endsAt: '2021-11-30T21:44:03Z',
    daysOff: undefined,
    createdAt: '2021-03-01T06:11:03Z',
    deletedAt: undefined,
    additionalInfo: 'eros suspendisse',
    skillId: 3,
    centreId: 17,
    profileId: 25,
    applicationIds: undefined,
  },
  {
    id: 88,
    vacancy: 3,
    hired: 1,
    startsAt: '2020-12-27T09:56:20Z',
    endsAt: '2021-02-21T18:56:20Z',
    daysOff: undefined,
    createdAt: '2021-01-18T10:48:04Z',
    deletedAt: '2021-01-10T18:02:10Z',
    additionalInfo: 'vel ipsum',
    skillId: 1,
    centreId: 37,
    profileId: 79,
    applicationIds: undefined,
  },
  {
    id: 89,
    vacancy: 3,
    hired: 0,
    startsAt: '2021-01-21T09:35:15Z',
    endsAt: '2021-05-31T19:35:15Z',
    daysOff: undefined,
    createdAt: '2021-01-19T07:42:55Z',
    deletedAt: '2020-08-20T00:23:58Z',
    additionalInfo: 'sapien',
    skillId: 2,
    centreId: 20,
    profileId: 35,
    applicationIds: undefined,
  },
  {
    id: 90,
    vacancy: 4,
    hired: 1,
    startsAt: '2021-01-14T20:54:30Z',
    endsAt: '2022-05-25T03:54:30Z',
    daysOff: undefined,
    createdAt: '2020-08-26T20:29:52Z',
    deletedAt: '2020-11-20T02:51:01Z',
    additionalInfo: 'dolor',
    skillId: 2,
    centreId: 39,
    profileId: 22,
    applicationIds: undefined,
  },
  {
    id: 91,
    vacancy: 2,
    hired: 1,
    startsAt: '2021-06-25T18:54:30Z',
    endsAt: '2022-08-23T05:54:30Z',
    daysOff: undefined,
    createdAt: '2021-05-06T22:42:29Z',
    deletedAt: '2020-08-29T18:09:15Z',
    additionalInfo: 'molestie hendrerit',
    skillId: 3,
    centreId: 30,
    profileId: 59,
    applicationIds: undefined,
  },
  {
    id: 92,
    vacancy: 4,
    hired: 0,
    startsAt: '2020-10-19T11:24:41Z',
    endsAt: '2022-01-06T15:24:41Z',
    daysOff: [3, 3],
    createdAt: '2021-05-21T02:42:16Z',
    deletedAt: '2020-12-11T14:21:15Z',
    additionalInfo: 'amet diam',
    skillId: 3,
    centreId: 31,
    profileId: 47,
    applicationIds: [37, 18, 45, 8, 44, 42],
  },
  {
    id: 93,
    vacancy: 5,
    hired: 3,
    startsAt: '2021-01-27T02:16:04Z',
    endsAt: '2023-02-15T14:16:04Z',
    daysOff: undefined,
    createdAt: '2021-04-11T13:57:38Z',
    deletedAt: undefined,
    additionalInfo: 'suspendisse potenti',
    skillId: 3,
    centreId: 27,
    profileId: 6,
    applicationIds: undefined,
  },
  {
    id: 94,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-09-09T03:23:58Z',
    endsAt: '2022-08-26T06:23:58Z',
    daysOff: undefined,
    createdAt: '2021-01-05T12:14:43Z',
    deletedAt: '2020-12-08T17:33:47Z',
    additionalInfo: 'duis mattis',
    skillId: 2,
    centreId: 13,
    profileId: 75,
    applicationIds: undefined,
  },
  {
    id: 95,
    vacancy: 4,
    hired: 1,
    startsAt: '2020-10-04T23:10:16Z',
    endsAt: '2020-11-15T11:10:16Z',
    daysOff: undefined,
    createdAt: '2020-09-17T20:49:15Z',
    deletedAt: '2020-10-01T09:53:32Z',
    additionalInfo: 'lacus',
    skillId: 2,
    centreId: 19,
    profileId: 34,
    applicationIds: undefined,
  },
  {
    id: 96,
    vacancy: 8,
    hired: 7,
    startsAt: '2020-11-19T21:47:34Z',
    endsAt: '2021-08-15T02:47:34Z',
    daysOff: undefined,
    createdAt: '2020-10-21T21:28:50Z',
    deletedAt: undefined,
    additionalInfo: 'eget elit',
    skillId: 3,
    centreId: 41,
    profileId: 9,
    applicationIds: undefined,
  },
  {
    id: 97,
    vacancy: 1,
    hired: 1,
    startsAt: '2021-03-20T02:18:04Z',
    endsAt: '2021-04-30T05:18:04Z',
    daysOff: undefined,
    createdAt: '2021-05-23T07:54:32Z',
    deletedAt: undefined,
    additionalInfo: 'quam pharetra',
    skillId: 2,
    centreId: 42,
    profileId: 94,
    applicationIds: undefined,
  },
  {
    id: 98,
    vacancy: 4,
    hired: 4,
    startsAt: '2021-05-19T01:46:28Z',
    endsAt: '2022-10-30T06:46:28Z',
    daysOff: undefined,
    createdAt: '2021-06-13T07:22:19Z',
    deletedAt: '2020-11-18T06:54:09Z',
    additionalInfo: 'sit',
    skillId: 2,
    centreId: 37,
    profileId: 4,
    applicationIds: undefined,
  },
  {
    id: 99,
    vacancy: 6,
    hired: 5,
    startsAt: '2020-09-28T23:33:57Z',
    endsAt: '2021-03-15T08:33:57Z',
    daysOff: undefined,
    createdAt: '2021-04-24T07:35:06Z',
    deletedAt: '2021-02-09T01:54:28Z',
    additionalInfo: 'natoque',
    skillId: 2,
    centreId: 17,
    profileId: 92,
    applicationIds: undefined,
  },
  {
    id: 100,
    vacancy: 4,
    hired: 2,
    startsAt: '2020-09-15T09:21:53Z',
    endsAt: '2022-04-23T17:21:53Z',
    daysOff: undefined,
    createdAt: '2021-07-04T20:39:53Z',
    deletedAt: '2021-03-26T19:54:19Z',
    additionalInfo: 'integer non',
    skillId: 1,
    centreId: 17,
    profileId: 42,
    applicationIds: undefined,
  },
  {
    id: 101,
    vacancy: 2,
    hired: 0,
    startsAt: '2021-06-10T07:07:02Z',
    endsAt: '2023-02-13T16:07:02Z',
    daysOff: undefined,
    createdAt: '2021-06-04T09:27:01Z',
    deletedAt: undefined,
    additionalInfo: 'suspendisse',
    skillId: 3,
    centreId: 35,
    profileId: 87,
    applicationIds: undefined,
  },
  {
    id: 102,
    vacancy: 3,
    hired: 0,
    startsAt: '2020-12-15T03:53:51Z',
    endsAt: '2021-10-11T07:53:51Z',
    daysOff: undefined,
    createdAt: '2021-02-02T20:22:15Z',
    deletedAt: '2021-04-14T08:26:04Z',
    additionalInfo: 'dui',
    skillId: 3,
    centreId: 4,
    profileId: 8,
    applicationIds: undefined,
  },
  {
    id: 103,
    vacancy: 4,
    hired: 4,
    startsAt: '2020-08-29T21:22:34Z',
    endsAt: '2022-06-19T08:22:34Z',
    daysOff: undefined,
    createdAt: '2020-09-05T16:42:15Z',
    deletedAt: '2020-12-03T17:40:36Z',
    additionalInfo: 'eget rutrum',
    skillId: 1,
    centreId: 6,
    profileId: 47,
    applicationIds: undefined,
  },
  {
    id: 104,
    vacancy: 10,
    hired: 4,
    startsAt: '2020-08-14T21:08:36Z',
    endsAt: '2020-09-19T05:08:36Z',
    daysOff: undefined,
    createdAt: '2020-08-02T00:35:19Z',
    deletedAt: '2021-07-01T03:23:10Z',
    additionalInfo: 'sapien in',
    skillId: 1,
    centreId: 27,
    profileId: 56,
    applicationIds: undefined,
  },
  {
    id: 105,
    vacancy: 1,
    hired: 1,
    startsAt: '2021-06-09T09:51:23Z',
    endsAt: '2021-12-20T12:51:23Z',
    daysOff: [3, 3],
    createdAt: '2021-01-29T04:12:56Z',
    deletedAt: '2021-02-20T03:43:04Z',
    additionalInfo: 'ligula vehicula',
    skillId: 1,
    centreId: 24,
    profileId: 92,
    applicationIds: [16, 49, 8, 36, 45, 35],
  },
  {
    id: 106,
    vacancy: 3,
    hired: 1,
    startsAt: '2021-06-17T09:40:52Z',
    endsAt: '2022-07-26T13:40:52Z',
    daysOff: [8, 7],
    createdAt: '2021-02-05T20:56:19Z',
    deletedAt: '2021-06-27T04:26:53Z',
    additionalInfo: 'in faucibus',
    skillId: 2,
    centreId: 39,
    profileId: 4,
    applicationIds: [21, 21, 17, 42, 39, 18],
  },
  {
    id: 107,
    vacancy: 6,
    hired: 5,
    startsAt: '2020-08-11T08:06:31Z',
    endsAt: '2020-10-01T15:06:31Z',
    daysOff: undefined,
    createdAt: '2021-06-30T20:59:25Z',
    deletedAt: '2020-09-08T03:05:00Z',
    additionalInfo: 'vehicula',
    skillId: 1,
    centreId: 17,
    profileId: 4,
    applicationIds: undefined,
  },
  {
    id: 108,
    vacancy: 1,
    hired: 1,
    startsAt: '2021-05-04T09:17:23Z',
    endsAt: '2021-07-11T12:17:23Z',
    daysOff: undefined,
    createdAt: '2020-11-03T00:12:05Z',
    deletedAt: '2020-08-22T11:56:08Z',
    additionalInfo: 'ipsum ac',
    skillId: 3,
    centreId: 37,
    profileId: 29,
    applicationIds: undefined,
  },
  {
    id: 109,
    vacancy: 4,
    hired: 1,
    startsAt: '2020-12-12T16:11:04Z',
    endsAt: '2022-02-28T22:11:04Z',
    daysOff: [2, 9],
    createdAt: '2021-03-19T02:36:40Z',
    deletedAt: '2021-06-19T03:09:56Z',
    additionalInfo: 'nascetur',
    skillId: 2,
    centreId: 2,
    profileId: 4,
    applicationIds: [33, 37, 48, 47, 10, 13],
  },
  {
    id: 110,
    vacancy: 6,
    hired: 5,
    startsAt: '2020-09-04T16:16:15Z',
    endsAt: '2020-12-12T22:16:15Z',
    daysOff: undefined,
    createdAt: '2020-07-27T19:42:01Z',
    deletedAt: '2021-03-26T13:48:23Z',
    additionalInfo: 'nec',
    skillId: 1,
    centreId: 3,
    profileId: 77,
    applicationIds: undefined,
  },
  {
    id: 111,
    vacancy: 5,
    hired: 4,
    startsAt: '2021-01-08T17:45:19Z',
    endsAt: '2021-05-06T05:45:19Z',
    daysOff: undefined,
    createdAt: '2020-11-06T15:36:12Z',
    deletedAt: '2021-02-27T23:05:16Z',
    additionalInfo: 'aliquet',
    skillId: 1,
    centreId: 27,
    profileId: 66,
    applicationIds: undefined,
  },
  {
    id: 112,
    vacancy: 8,
    hired: 7,
    startsAt: '2020-09-08T19:40:49Z',
    endsAt: '2021-01-24T03:40:49Z',
    daysOff: undefined,
    createdAt: '2020-11-24T07:30:17Z',
    deletedAt: '2021-02-12T14:39:03Z',
    additionalInfo: 'quis orci',
    skillId: 3,
    centreId: 43,
    profileId: 48,
    applicationIds: undefined,
  },
  {
    id: 113,
    vacancy: 10,
    hired: 3,
    startsAt: '2021-06-11T21:14:38Z',
    endsAt: '2022-05-05T09:14:38Z',
    daysOff: undefined,
    createdAt: '2021-02-11T17:37:32Z',
    deletedAt: '2021-03-21T16:55:40Z',
    additionalInfo: 'in',
    skillId: 1,
    centreId: 1,
    profileId: 9,
    applicationIds: undefined,
  },
  {
    id: 114,
    vacancy: 8,
    hired: 1,
    startsAt: '2020-11-28T21:38:33Z',
    endsAt: '2022-09-01T01:38:33Z',
    daysOff: [4, 3],
    createdAt: '2021-02-03T08:53:42Z',
    deletedAt: '2021-04-29T02:55:36Z',
    additionalInfo: 'ipsum primis',
    skillId: 1,
    centreId: 1,
    profileId: 19,
    applicationIds: [25, 10, 39, 17, 5, 25],
  },
  {
    id: 115,
    vacancy: 1,
    hired: 0,
    startsAt: '2021-07-17T07:38:26Z',
    endsAt: '2023-07-29T11:38:26Z',
    daysOff: undefined,
    createdAt: '2021-02-08T02:30:49Z',
    deletedAt: undefined,
    additionalInfo: 'tristique',
    skillId: 1,
    centreId: 8,
    profileId: 74,
    applicationIds: undefined,
  },
  {
    id: 116,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-11-27T20:15:52Z',
    endsAt: '2021-08-26T05:15:52Z',
    daysOff: [7, 8],
    createdAt: '2021-02-02T11:52:55Z',
    deletedAt: '2021-07-11T11:26:01Z',
    additionalInfo: 'suspendisse accumsan',
    skillId: 1,
    centreId: 20,
    profileId: 12,
    applicationIds: [32, 6, 17, 28, 26, 36],
  },
  {
    id: 117,
    vacancy: 6,
    hired: 6,
    startsAt: '2021-02-10T15:54:49Z',
    endsAt: '2022-07-10T22:54:49Z',
    daysOff: undefined,
    createdAt: '2020-10-24T03:49:05Z',
    deletedAt: '2020-10-22T21:08:32Z',
    additionalInfo: 'consectetuer',
    skillId: 3,
    centreId: 40,
    profileId: 34,
    applicationIds: undefined,
  },
  {
    id: 118,
    vacancy: 7,
    hired: 7,
    startsAt: '2021-04-03T15:53:44Z',
    endsAt: '2021-06-07T20:53:44Z',
    daysOff: undefined,
    createdAt: '2020-09-29T20:35:43Z',
    deletedAt: undefined,
    additionalInfo: 'blandit',
    skillId: 3,
    centreId: 27,
    profileId: 5,
    applicationIds: undefined,
  },
  {
    id: 119,
    vacancy: 10,
    hired: 0,
    startsAt: '2021-02-13T23:18:00Z',
    endsAt: '2022-06-22T11:18:00Z',
    daysOff: undefined,
    createdAt: '2021-04-12T04:26:23Z',
    deletedAt: '2020-10-07T12:20:58Z',
    additionalInfo: 'posuere metus',
    skillId: 1,
    centreId: 3,
    profileId: 32,
    applicationIds: undefined,
  },
  {
    id: 120,
    vacancy: 5,
    hired: 5,
    startsAt: '2021-02-14T17:24:06Z',
    endsAt: '2022-11-22T03:24:06Z',
    daysOff: undefined,
    createdAt: '2020-09-03T12:28:06Z',
    deletedAt: '2021-05-16T07:52:29Z',
    additionalInfo: 'at lorem',
    skillId: 3,
    centreId: 5,
    profileId: 71,
    applicationIds: undefined,
  },
  {
    id: 121,
    vacancy: 5,
    hired: 0,
    startsAt: '2021-03-18T07:27:58Z',
    endsAt: '2022-10-24T13:27:58Z',
    daysOff: [8, 5],
    createdAt: '2021-04-28T21:27:22Z',
    deletedAt: '2020-08-23T07:07:29Z',
    additionalInfo: 'magnis dis',
    skillId: 3,
    centreId: 42,
    profileId: 68,
    applicationIds: [7, 22, 12, 21, 27, 32],
  },
  {
    id: 122,
    vacancy: 2,
    hired: 0,
    startsAt: '2021-03-30T21:44:27Z',
    endsAt: '2021-12-09T04:44:27Z',
    daysOff: undefined,
    createdAt: '2021-05-29T22:38:14Z',
    deletedAt: '2021-05-22T08:56:57Z',
    additionalInfo: 'faucibus',
    skillId: 1,
    centreId: 10,
    profileId: 10,
    applicationIds: undefined,
  },
  {
    id: 123,
    vacancy: 3,
    hired: 1,
    startsAt: '2021-02-11T01:12:05Z',
    endsAt: '2022-10-07T13:12:05Z',
    daysOff: undefined,
    createdAt: '2021-05-25T11:03:14Z',
    deletedAt: '2021-06-29T13:36:57Z',
    additionalInfo: 'vestibulum',
    skillId: 3,
    centreId: 43,
    profileId: 1,
    applicationIds: undefined,
  },
  {
    id: 124,
    vacancy: 9,
    hired: 6,
    startsAt: '2021-05-07T02:28:01Z',
    endsAt: '2021-07-27T07:28:01Z',
    daysOff: [9, 9],
    createdAt: '2021-07-07T21:48:03Z',
    deletedAt: undefined,
    additionalInfo: 'erat',
    skillId: 1,
    centreId: 3,
    profileId: 40,
    applicationIds: [42, 7, 7, 27, 49, 33],
  },
  {
    id: 125,
    vacancy: 2,
    hired: 0,
    startsAt: '2020-09-17T05:46:06Z',
    endsAt: '2022-07-10T16:46:06Z',
    daysOff: undefined,
    createdAt: '2020-08-17T00:18:04Z',
    deletedAt: '2021-05-05T07:21:26Z',
    additionalInfo: 'magnis',
    skillId: 2,
    centreId: 13,
    profileId: 75,
    applicationIds: undefined,
  },
  {
    id: 126,
    vacancy: 6,
    hired: 1,
    startsAt: '2021-04-05T18:44:16Z',
    endsAt: '2022-02-10T04:44:16Z',
    daysOff: [1, 9],
    createdAt: '2020-10-16T15:29:06Z',
    deletedAt: '2021-02-18T15:35:40Z',
    additionalInfo: 'tristique',
    skillId: 1,
    centreId: 7,
    profileId: 69,
    applicationIds: [35, 36, 33, 9, 32, 18],
  },
  {
    id: 127,
    vacancy: 5,
    hired: 3,
    startsAt: '2021-04-09T06:00:17Z',
    endsAt: '2021-09-14T16:00:17Z',
    daysOff: undefined,
    createdAt: '2021-04-04T16:06:47Z',
    deletedAt: '2021-02-18T04:31:58Z',
    additionalInfo: 'massa',
    skillId: 1,
    centreId: 32,
    profileId: 53,
    applicationIds: undefined,
  },
  {
    id: 128,
    vacancy: 10,
    hired: 7,
    startsAt: '2021-06-27T00:07:08Z',
    endsAt: '2022-07-13T08:07:08Z',
    daysOff: undefined,
    createdAt: '2021-05-06T14:58:00Z',
    deletedAt: '2021-05-06T05:32:24Z',
    additionalInfo: 'tellus semper',
    skillId: 2,
    centreId: 25,
    profileId: 29,
    applicationIds: undefined,
  },
  {
    id: 129,
    vacancy: 8,
    hired: 8,
    startsAt: '2021-06-30T15:18:39Z',
    endsAt: '2023-07-29T22:18:39Z',
    daysOff: undefined,
    createdAt: '2021-01-10T20:52:37Z',
    deletedAt: '2021-03-19T17:38:40Z',
    additionalInfo: 'a',
    skillId: 3,
    centreId: 24,
    profileId: 17,
    applicationIds: undefined,
  },
  {
    id: 130,
    vacancy: 4,
    hired: 4,
    startsAt: '2020-11-03T20:29:36Z',
    endsAt: '2022-04-19T08:29:36Z',
    daysOff: undefined,
    createdAt: '2021-05-25T04:30:51Z',
    deletedAt: '2021-03-02T12:34:48Z',
    additionalInfo: 'maecenas tincidunt',
    skillId: 3,
    centreId: 31,
    profileId: 72,
    applicationIds: undefined,
  },
  {
    id: 131,
    vacancy: 9,
    hired: 5,
    startsAt: '2020-08-31T22:50:50Z',
    endsAt: '2022-05-27T02:50:50Z',
    daysOff: [2, 8],
    createdAt: '2020-11-14T16:52:39Z',
    deletedAt: '2021-01-20T14:26:21Z',
    additionalInfo: 'pellentesque',
    skillId: 1,
    centreId: 45,
    profileId: 78,
    applicationIds: [48, 49, 32, 11, 43, 45],
  },
  {
    id: 132,
    vacancy: 7,
    hired: 7,
    startsAt: '2020-08-07T04:02:07Z',
    endsAt: '2021-02-13T10:02:07Z',
    daysOff: [9, 1],
    createdAt: '2020-11-19T09:14:52Z',
    deletedAt: '2020-11-25T23:06:56Z',
    additionalInfo: 'cras pellentesque',
    skillId: 3,
    centreId: 14,
    profileId: 79,
    applicationIds: [19, 21, 36, 9, 50, 16],
  },
  {
    id: 133,
    vacancy: 5,
    hired: 1,
    startsAt: '2020-10-10T06:21:34Z',
    endsAt: '2022-09-13T16:21:34Z',
    daysOff: undefined,
    createdAt: '2020-08-11T23:13:39Z',
    deletedAt: '2021-07-21T18:04:47Z',
    additionalInfo: 'aenean auctor',
    skillId: 3,
    centreId: 2,
    profileId: 65,
    applicationIds: undefined,
  },
  {
    id: 134,
    vacancy: 8,
    hired: 5,
    startsAt: '2020-09-11T03:46:26Z',
    endsAt: '2022-05-15T11:46:26Z',
    daysOff: undefined,
    createdAt: '2021-01-25T05:24:31Z',
    deletedAt: '2021-03-24T04:24:11Z',
    additionalInfo: 'justo lacinia',
    skillId: 1,
    centreId: 35,
    profileId: 56,
    applicationIds: undefined,
  },
  {
    id: 135,
    vacancy: 3,
    hired: 2,
    startsAt: '2021-06-23T20:06:11Z',
    endsAt: '2022-05-23T00:06:11Z',
    daysOff: [1, 6],
    createdAt: '2021-04-30T13:09:02Z',
    deletedAt: '2021-03-22T23:49:21Z',
    additionalInfo: 'erat',
    skillId: 2,
    centreId: 46,
    profileId: 33,
    applicationIds: [4, 32, 25, 20, 50, 33],
  },
  {
    id: 136,
    vacancy: 1,
    hired: 0,
    startsAt: '2021-06-03T19:02:57Z',
    endsAt: '2022-08-28T06:02:57Z',
    daysOff: [2, 10],
    createdAt: '2021-03-14T21:31:01Z',
    deletedAt: '2021-03-12T06:50:50Z',
    additionalInfo: 'adipiscing',
    skillId: 2,
    centreId: 14,
    profileId: 69,
    applicationIds: [50, 47, 38, 3, 25, 9],
  },
  {
    id: 137,
    vacancy: 10,
    hired: 7,
    startsAt: '2020-10-13T21:02:11Z',
    endsAt: '2021-12-26T07:02:11Z',
    daysOff: undefined,
    createdAt: '2021-05-13T20:59:42Z',
    deletedAt: '2021-01-16T08:33:35Z',
    additionalInfo: 'nibh fusce',
    skillId: 2,
    centreId: 36,
    profileId: 51,
    applicationIds: undefined,
  },
  {
    id: 138,
    vacancy: 1,
    hired: 1,
    startsAt: '2021-02-26T12:34:42Z',
    endsAt: '2022-12-16T17:34:42Z',
    daysOff: undefined,
    createdAt: '2021-04-17T14:05:16Z',
    deletedAt: '2020-08-30T06:14:32Z',
    additionalInfo: 'vel',
    skillId: 3,
    centreId: 33,
    profileId: 45,
    applicationIds: undefined,
  },
  {
    id: 139,
    vacancy: 6,
    hired: 6,
    startsAt: '2020-07-31T12:12:57Z',
    endsAt: '2021-07-15T20:12:57Z',
    daysOff: [2, 2],
    createdAt: '2021-02-11T00:51:28Z',
    deletedAt: '2020-10-24T17:25:07Z',
    additionalInfo: 'felis sed',
    skillId: 2,
    centreId: 9,
    profileId: 19,
    applicationIds: [24, 29, 36, 2, 31, 37],
  },
  {
    id: 140,
    vacancy: 5,
    hired: 3,
    startsAt: '2021-06-12T19:24:48Z',
    endsAt: '2022-04-10T01:24:48Z',
    daysOff: [10, 4],
    createdAt: '2020-09-10T19:12:41Z',
    deletedAt: '2021-05-28T05:54:11Z',
    additionalInfo: 'morbi ut',
    skillId: 3,
    centreId: 48,
    profileId: 60,
    applicationIds: [19, 1, 5, 19, 31, 44],
  },
  {
    id: 141,
    vacancy: 10,
    hired: 6,
    startsAt: '2021-02-14T03:26:20Z',
    endsAt: '2021-09-14T07:26:20Z',
    daysOff: [7, 8],
    createdAt: '2021-02-16T08:57:18Z',
    deletedAt: undefined,
    additionalInfo: 'tortor',
    skillId: 2,
    centreId: 32,
    profileId: 23,
    applicationIds: [47, 13, 22, 28, 40, 34],
  },
  {
    id: 142,
    vacancy: 1,
    hired: 0,
    startsAt: '2021-05-01T12:46:56Z',
    endsAt: '2022-04-29T15:46:56Z',
    daysOff: undefined,
    createdAt: '2021-01-17T09:23:12Z',
    deletedAt: '2020-12-07T00:17:11Z',
    additionalInfo: 'enim lorem',
    skillId: 2,
    centreId: 7,
    profileId: 12,
    applicationIds: undefined,
  },
  {
    id: 143,
    vacancy: 6,
    hired: 0,
    startsAt: '2020-11-30T04:44:48Z',
    endsAt: '2021-03-09T10:44:48Z',
    daysOff: undefined,
    createdAt: '2021-07-22T19:38:14Z',
    deletedAt: undefined,
    additionalInfo: 'sem duis',
    skillId: 1,
    centreId: 30,
    profileId: 70,
    applicationIds: undefined,
  },
  {
    id: 144,
    vacancy: 8,
    hired: 7,
    startsAt: '2021-02-03T13:23:29Z',
    endsAt: '2022-01-04T16:23:29Z',
    daysOff: undefined,
    createdAt: '2021-01-13T11:19:22Z',
    deletedAt: '2020-08-12T00:54:13Z',
    additionalInfo: 'vel',
    skillId: 1,
    centreId: 21,
    profileId: 91,
    applicationIds: undefined,
  },
  {
    id: 145,
    vacancy: 2,
    hired: 2,
    startsAt: '2021-01-10T02:37:15Z',
    endsAt: '2021-03-14T07:37:15Z',
    daysOff: undefined,
    createdAt: '2021-04-06T20:55:19Z',
    deletedAt: '2020-12-24T05:28:40Z',
    additionalInfo: 'undefineda tempus',
    skillId: 3,
    centreId: 13,
    profileId: 15,
    applicationIds: undefined,
  },
  {
    id: 146,
    vacancy: 2,
    hired: 1,
    startsAt: '2021-01-16T09:42:58Z',
    endsAt: '2021-12-01T14:42:58Z',
    daysOff: undefined,
    createdAt: '2020-09-12T05:22:19Z',
    deletedAt: '2021-06-20T23:01:32Z',
    additionalInfo: 'et',
    skillId: 2,
    centreId: 48,
    profileId: 16,
    applicationIds: undefined,
  },
  {
    id: 147,
    vacancy: 2,
    hired: 2,
    startsAt: '2021-03-01T16:43:24Z',
    endsAt: '2022-08-12T22:43:24Z',
    daysOff: undefined,
    createdAt: '2020-10-19T08:39:18Z',
    deletedAt: '2021-05-06T23:19:06Z',
    additionalInfo: 'lectus aliquam',
    skillId: 1,
    centreId: 21,
    profileId: 72,
    applicationIds: undefined,
  },
  {
    id: 148,
    vacancy: 3,
    hired: 3,
    startsAt: '2021-05-14T22:10:23Z',
    endsAt: '2021-06-30T09:10:23Z',
    daysOff: undefined,
    createdAt: '2021-03-15T15:54:34Z',
    deletedAt: '2021-03-21T03:34:59Z',
    additionalInfo: 'sodales',
    skillId: 2,
    centreId: 13,
    profileId: 90,
    applicationIds: undefined,
  },
  {
    id: 149,
    vacancy: 10,
    hired: 9,
    startsAt: '2021-04-14T23:00:56Z',
    endsAt: '2021-10-26T03:00:56Z',
    daysOff: undefined,
    createdAt: '2020-08-21T08:51:29Z',
    deletedAt: '2021-03-09T08:06:07Z',
    additionalInfo: 'lectus',
    skillId: 3,
    centreId: 19,
    profileId: 78,
    applicationIds: undefined,
  },
  {
    id: 150,
    vacancy: 2,
    hired: 1,
    startsAt: '2021-05-25T10:34:38Z',
    endsAt: '2021-07-09T19:34:38Z',
    daysOff: undefined,
    createdAt: '2021-01-14T15:37:18Z',
    deletedAt: '2020-09-28T01:24:01Z',
    additionalInfo: 'risus',
    skillId: 2,
    centreId: 25,
    profileId: 75,
    applicationIds: undefined,
  },
  {
    id: 151,
    vacancy: 7,
    hired: 6,
    startsAt: '2021-02-24T08:55:31Z',
    endsAt: '2021-08-28T17:55:31Z',
    daysOff: undefined,
    createdAt: '2021-01-04T12:15:13Z',
    deletedAt: undefined,
    additionalInfo: 'arcu adipiscing',
    skillId: 1,
    centreId: 50,
    profileId: 61,
    applicationIds: undefined,
  },
  {
    id: 152,
    vacancy: 7,
    hired: 6,
    startsAt: '2020-09-09T03:32:46Z',
    endsAt: '2021-07-16T06:32:46Z',
    daysOff: undefined,
    createdAt: '2021-02-20T23:40:37Z',
    deletedAt: '2021-07-20T12:12:38Z',
    additionalInfo: 'vel',
    skillId: 2,
    centreId: 48,
    profileId: 15,
    applicationIds: undefined,
  },
  {
    id: 153,
    vacancy: 3,
    hired: 1,
    startsAt: '2021-02-19T04:32:06Z',
    endsAt: '2022-02-23T14:32:06Z',
    daysOff: undefined,
    createdAt: '2020-09-26T13:46:38Z',
    deletedAt: '2020-08-10T15:09:08Z',
    additionalInfo: 'sagittis sapien',
    skillId: 2,
    centreId: 13,
    profileId: 93,
    applicationIds: undefined,
  },
  {
    id: 154,
    vacancy: 6,
    hired: 3,
    startsAt: '2021-03-23T13:19:41Z',
    endsAt: '2021-07-26T00:19:41Z',
    daysOff: undefined,
    createdAt: '2020-10-07T22:04:58Z',
    deletedAt: '2020-09-24T23:57:40Z',
    additionalInfo: 'vulputate elementum',
    skillId: 3,
    centreId: 16,
    profileId: 100,
    applicationIds: undefined,
  },
  {
    id: 155,
    vacancy: 9,
    hired: 4,
    startsAt: '2021-03-22T04:50:42Z',
    endsAt: '2023-01-31T14:50:42Z',
    daysOff: undefined,
    createdAt: '2020-11-06T10:56:10Z',
    deletedAt: '2021-03-28T23:24:10Z',
    additionalInfo: 'augue quam',
    skillId: 3,
    centreId: 17,
    profileId: 6,
    applicationIds: undefined,
  },
  {
    id: 156,
    vacancy: 10,
    hired: 0,
    startsAt: '2021-05-25T22:44:04Z',
    endsAt: '2021-12-04T03:44:04Z',
    daysOff: undefined,
    createdAt: '2020-12-09T21:12:17Z',
    deletedAt: undefined,
    additionalInfo: 'eros',
    skillId: 1,
    centreId: 29,
    profileId: 75,
    applicationIds: undefined,
  },
  {
    id: 157,
    vacancy: 5,
    hired: 0,
    startsAt: '2021-04-08T06:08:10Z',
    endsAt: '2021-10-23T09:08:10Z',
    daysOff: [8, 1],
    createdAt: '2021-03-19T01:06:08Z',
    deletedAt: '2020-11-01T05:47:52Z',
    additionalInfo: 'et eros',
    skillId: 1,
    centreId: 19,
    profileId: 37,
    applicationIds: [44, 42, 43, 24, 38, 4],
  },
  {
    id: 158,
    vacancy: 3,
    hired: 2,
    startsAt: '2021-05-21T06:00:37Z',
    endsAt: '2021-10-20T18:00:37Z',
    daysOff: [7, 4],
    createdAt: '2020-10-21T19:52:15Z',
    deletedAt: '2021-03-23T04:38:11Z',
    additionalInfo: 'massa volutpat',
    skillId: 1,
    centreId: 46,
    profileId: 66,
    applicationIds: [17, 35, 43, 38, 16, 18],
  },
  {
    id: 159,
    vacancy: 10,
    hired: 7,
    startsAt: '2021-07-09T18:32:27Z',
    endsAt: '2022-10-08T02:32:27Z',
    daysOff: undefined,
    createdAt: '2021-05-26T15:55:16Z',
    deletedAt: undefined,
    additionalInfo: 'ut ultrices',
    skillId: 2,
    centreId: 32,
    profileId: 73,
    applicationIds: undefined,
  },
  {
    id: 160,
    vacancy: 10,
    hired: 10,
    startsAt: '2021-03-28T12:58:46Z',
    endsAt: '2022-10-26T22:58:46Z',
    daysOff: undefined,
    createdAt: '2021-06-06T12:23:13Z',
    deletedAt: '2021-01-06T03:42:35Z',
    additionalInfo: 'posuere felis',
    skillId: 2,
    centreId: 33,
    profileId: 65,
    applicationIds: undefined,
  },
  {
    id: 161,
    vacancy: 4,
    hired: 4,
    startsAt: '2021-06-21T10:01:23Z',
    endsAt: '2022-02-14T17:01:23Z',
    daysOff: [8, 1],
    createdAt: '2020-08-01T01:55:51Z',
    deletedAt: '2021-02-13T07:23:26Z',
    additionalInfo: 'elementum eu',
    skillId: 2,
    centreId: 21,
    profileId: 35,
    applicationIds: [7, 34, 33, 50, 23, 34],
  },
  {
    id: 162,
    vacancy: 9,
    hired: 6,
    startsAt: '2021-01-11T06:10:51Z',
    endsAt: '2022-09-03T16:10:51Z',
    daysOff: undefined,
    createdAt: '2020-12-18T18:48:29Z',
    deletedAt: '2021-03-24T01:23:13Z',
    additionalInfo: 'mattis',
    skillId: 2,
    centreId: 16,
    profileId: 88,
    applicationIds: undefined,
  },
  {
    id: 163,
    vacancy: 2,
    hired: 1,
    startsAt: '2020-09-16T19:11:42Z',
    endsAt: '2021-06-03T04:11:42Z',
    daysOff: undefined,
    createdAt: '2021-02-24T19:35:02Z',
    deletedAt: undefined,
    additionalInfo: 'magnis dis',
    skillId: 1,
    centreId: 44,
    profileId: 100,
    applicationIds: undefined,
  },
  {
    id: 164,
    vacancy: 5,
    hired: 0,
    startsAt: '2020-11-03T03:02:47Z',
    endsAt: '2021-06-04T08:02:47Z',
    daysOff: undefined,
    createdAt: '2021-02-06T01:05:47Z',
    deletedAt: '2021-06-16T06:23:42Z',
    additionalInfo: 'fusce',
    skillId: 2,
    centreId: 4,
    profileId: 34,
    applicationIds: undefined,
  },
  {
    id: 165,
    vacancy: 3,
    hired: 0,
    startsAt: '2021-06-08T09:20:57Z',
    endsAt: '2022-02-19T13:20:57Z',
    daysOff: undefined,
    createdAt: '2020-09-03T18:06:57Z',
    deletedAt: '2021-05-01T05:21:16Z',
    additionalInfo: 'adipiscing lorem',
    skillId: 3,
    centreId: 36,
    profileId: 83,
    applicationIds: undefined,
  },
  {
    id: 166,
    vacancy: 8,
    hired: 3,
    startsAt: '2020-11-10T12:12:10Z',
    endsAt: '2022-05-20T17:12:10Z',
    daysOff: undefined,
    createdAt: '2021-07-14T22:50:43Z',
    deletedAt: '2021-04-29T15:49:30Z',
    additionalInfo: 'ligula',
    skillId: 3,
    centreId: 5,
    profileId: 12,
    applicationIds: undefined,
  },
  {
    id: 167,
    vacancy: 5,
    hired: 5,
    startsAt: '2021-05-12T19:26:22Z',
    endsAt: '2022-05-30T03:26:22Z',
    daysOff: undefined,
    createdAt: '2020-11-03T21:32:42Z',
    deletedAt: '2021-07-15T18:54:17Z',
    additionalInfo: 'purus',
    skillId: 2,
    centreId: 23,
    profileId: 58,
    applicationIds: undefined,
  },
  {
    id: 168,
    vacancy: 8,
    hired: 1,
    startsAt: '2020-09-01T08:26:11Z',
    endsAt: '2020-10-10T19:26:11Z',
    daysOff: undefined,
    createdAt: '2020-11-28T09:57:56Z',
    deletedAt: '2020-09-29T19:48:59Z',
    additionalInfo: 'id luctus',
    skillId: 3,
    centreId: 40,
    profileId: 31,
    applicationIds: undefined,
  },
  {
    id: 169,
    vacancy: 8,
    hired: 3,
    startsAt: '2020-08-18T17:22:48Z',
    endsAt: '2021-04-20T01:22:48Z',
    daysOff: undefined,
    createdAt: '2021-06-23T09:22:39Z',
    deletedAt: undefined,
    additionalInfo: 'pede',
    skillId: 3,
    centreId: 3,
    profileId: 84,
    applicationIds: undefined,
  },
  {
    id: 170,
    vacancy: 3,
    hired: 0,
    startsAt: '2021-06-16T08:45:14Z',
    endsAt: '2021-10-02T12:45:14Z',
    daysOff: undefined,
    createdAt: '2020-08-30T17:31:26Z',
    deletedAt: '2021-03-14T23:07:10Z',
    additionalInfo: 'in purus',
    skillId: 3,
    centreId: 37,
    profileId: 45,
    applicationIds: undefined,
  },
  {
    id: 171,
    vacancy: 9,
    hired: 5,
    startsAt: '2021-06-18T08:14:17Z',
    endsAt: '2021-11-25T20:14:17Z',
    daysOff: undefined,
    createdAt: '2021-05-07T15:07:36Z',
    deletedAt: '2021-06-12T19:38:35Z',
    additionalInfo: 'in faucibus',
    skillId: 1,
    centreId: 39,
    profileId: 9,
    applicationIds: undefined,
  },
  {
    id: 172,
    vacancy: 4,
    hired: 0,
    startsAt: '2020-07-27T05:02:10Z',
    endsAt: '2021-05-21T17:02:10Z',
    daysOff: [6, 7],
    createdAt: '2020-11-21T13:07:23Z',
    deletedAt: '2021-05-25T19:45:10Z',
    additionalInfo: 'suspendisse potenti',
    skillId: 3,
    centreId: 27,
    profileId: 6,
    applicationIds: [45, 27, 14, 14, 35, 5],
  },
  {
    id: 173,
    vacancy: 8,
    hired: 1,
    startsAt: '2021-06-09T19:13:05Z',
    endsAt: '2022-10-24T06:13:05Z',
    daysOff: undefined,
    createdAt: '2021-06-14T21:06:42Z',
    deletedAt: '2021-03-24T17:15:29Z',
    additionalInfo: 'tellus',
    skillId: 3,
    centreId: 45,
    profileId: 30,
    applicationIds: undefined,
  },
  {
    id: 174,
    vacancy: 8,
    hired: 8,
    startsAt: '2020-11-21T10:58:43Z',
    endsAt: '2022-03-18T14:58:43Z',
    daysOff: undefined,
    createdAt: '2021-06-23T22:42:54Z',
    deletedAt: '2021-03-19T07:31:22Z',
    additionalInfo: 'neque',
    skillId: 2,
    centreId: 41,
    profileId: 62,
    applicationIds: undefined,
  },
  {
    id: 175,
    vacancy: 1,
    hired: 0,
    startsAt: '2020-11-12T05:21:16Z',
    endsAt: '2021-01-22T16:21:16Z',
    daysOff: undefined,
    createdAt: '2021-07-13T03:37:52Z',
    deletedAt: '2021-03-12T14:42:56Z',
    additionalInfo: 'mauris',
    skillId: 2,
    centreId: 26,
    profileId: 35,
    applicationIds: undefined,
  },
  {
    id: 176,
    vacancy: 2,
    hired: 0,
    startsAt: '2021-04-06T21:50:48Z',
    endsAt: '2022-02-25T04:50:48Z',
    daysOff: undefined,
    createdAt: '2021-02-16T14:15:52Z',
    deletedAt: '2020-09-22T08:01:33Z',
    additionalInfo: 'libero undefinedam',
    skillId: 1,
    centreId: 37,
    profileId: 47,
    applicationIds: undefined,
  },
  {
    id: 177,
    vacancy: 6,
    hired: 3,
    startsAt: '2021-04-09T14:37:27Z',
    endsAt: '2022-02-28T23:37:27Z',
    daysOff: [4, 3],
    createdAt: '2021-01-06T19:08:08Z',
    deletedAt: '2021-02-09T06:31:55Z',
    additionalInfo: 'convallis',
    skillId: 3,
    centreId: 42,
    profileId: 97,
    applicationIds: [1, 8, 43, 40, 46, 15],
  },
  {
    id: 178,
    vacancy: 2,
    hired: 1,
    startsAt: '2020-10-05T11:53:56Z',
    endsAt: '2022-02-03T18:53:56Z',
    daysOff: undefined,
    createdAt: '2021-04-18T11:08:30Z',
    deletedAt: undefined,
    additionalInfo: 'sapien',
    skillId: 1,
    centreId: 32,
    profileId: 26,
    applicationIds: undefined,
  },
  {
    id: 179,
    vacancy: 8,
    hired: 2,
    startsAt: '2021-04-10T00:58:51Z',
    endsAt: '2021-07-22T06:58:51Z',
    daysOff: undefined,
    createdAt: '2020-09-19T23:16:59Z',
    deletedAt: undefined,
    additionalInfo: 'ut mauris',
    skillId: 3,
    centreId: 15,
    profileId: 54,
    applicationIds: undefined,
  },
  {
    id: 180,
    vacancy: 7,
    hired: 5,
    startsAt: '2020-08-08T19:10:59Z',
    endsAt: '2022-04-03T06:10:59Z',
    daysOff: undefined,
    createdAt: '2021-05-12T08:49:20Z',
    deletedAt: '2020-11-12T21:16:56Z',
    additionalInfo: 'accumsan tellus',
    skillId: 3,
    centreId: 41,
    profileId: 38,
    applicationIds: undefined,
  },
  {
    id: 181,
    vacancy: 7,
    hired: 7,
    startsAt: '2021-02-26T13:35:34Z',
    endsAt: '2022-01-13T18:35:34Z',
    daysOff: undefined,
    createdAt: '2020-10-07T18:52:06Z',
    deletedAt: undefined,
    additionalInfo: 'quam',
    skillId: 2,
    centreId: 8,
    profileId: 79,
    applicationIds: undefined,
  },
  {
    id: 182,
    vacancy: 9,
    hired: 3,
    startsAt: '2020-07-24T13:20:48Z',
    endsAt: '2022-05-23T20:20:48Z',
    daysOff: undefined,
    createdAt: '2021-05-16T08:14:56Z',
    deletedAt: undefined,
    additionalInfo: 'quis',
    skillId: 1,
    centreId: 23,
    profileId: 23,
    applicationIds: undefined,
  },
  {
    id: 183,
    vacancy: 3,
    hired: 3,
    startsAt: '2020-10-31T03:24:42Z',
    endsAt: '2021-11-18T09:24:42Z',
    daysOff: undefined,
    createdAt: '2021-02-16T15:23:17Z',
    deletedAt: undefined,
    additionalInfo: 'donec ut',
    skillId: 2,
    centreId: 30,
    profileId: 83,
    applicationIds: undefined,
  },
  {
    id: 184,
    vacancy: 8,
    hired: 2,
    startsAt: '2020-12-19T11:37:46Z',
    endsAt: '2021-11-29T20:37:46Z',
    daysOff: undefined,
    createdAt: '2021-05-01T02:43:21Z',
    deletedAt: undefined,
    additionalInfo: 'nisl',
    skillId: 3,
    centreId: 37,
    profileId: 63,
    applicationIds: undefined,
  },
  {
    id: 185,
    vacancy: 9,
    hired: 5,
    startsAt: '2021-04-07T08:56:59Z',
    endsAt: '2022-07-24T19:56:59Z',
    daysOff: undefined,
    createdAt: '2020-11-10T12:44:10Z',
    deletedAt: '2020-12-26T07:02:28Z',
    additionalInfo: 'a',
    skillId: 1,
    centreId: 50,
    profileId: 57,
    applicationIds: undefined,
  },
  {
    id: 186,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-10-20T21:31:51Z',
    endsAt: '2021-02-09T02:31:51Z',
    daysOff: [8, 5],
    createdAt: '2021-07-08T05:15:59Z',
    deletedAt: '2021-01-01T01:43:03Z',
    additionalInfo: 'in faucibus',
    skillId: 1,
    centreId: 2,
    profileId: 40,
    applicationIds: [8, 5, 42, 20, 1, 21],
  },
  {
    id: 187,
    vacancy: 6,
    hired: 4,
    startsAt: '2020-07-28T22:30:21Z',
    endsAt: '2020-11-18T05:30:21Z',
    daysOff: [9, 1],
    createdAt: '2020-09-13T07:40:20Z',
    deletedAt: undefined,
    additionalInfo: 'donec vitae',
    skillId: 2,
    centreId: 6,
    profileId: 46,
    applicationIds: [21, 49, 7, 2, 17, 30],
  },
  {
    id: 188,
    vacancy: 9,
    hired: 5,
    startsAt: '2021-03-26T19:27:46Z',
    endsAt: '2022-07-09T07:27:46Z',
    daysOff: undefined,
    createdAt: '2021-05-16T07:58:05Z',
    deletedAt: '2020-09-23T06:43:54Z',
    additionalInfo: 'facilisi cras',
    skillId: 1,
    centreId: 1,
    profileId: 32,
    applicationIds: undefined,
  },
  {
    id: 189,
    vacancy: 3,
    hired: 3,
    startsAt: '2021-04-11T22:56:12Z',
    endsAt: '2021-06-07T03:56:12Z',
    daysOff: [9, 5],
    createdAt: '2021-05-01T14:47:47Z',
    deletedAt: undefined,
    additionalInfo: 'volutpat',
    skillId: 3,
    centreId: 33,
    profileId: 15,
    applicationIds: [1, 9, 30, 50, 36, 33],
  },
  {
    id: 190,
    vacancy: 1,
    hired: 0,
    startsAt: '2021-01-15T03:04:20Z',
    endsAt: '2022-04-07T10:04:20Z',
    daysOff: [10, 10],
    createdAt: '2021-04-18T14:14:01Z',
    deletedAt: '2021-03-31T03:24:51Z',
    additionalInfo: 'nunc',
    skillId: 1,
    centreId: 21,
    profileId: 22,
    applicationIds: [20, 30, 14, 5, 22, 29],
  },
  {
    id: 191,
    vacancy: 10,
    hired: 0,
    startsAt: '2021-07-02T02:21:50Z',
    endsAt: '2023-04-13T13:21:50Z',
    daysOff: undefined,
    createdAt: '2021-06-04T02:10:45Z',
    deletedAt: '2020-09-02T22:49:41Z',
    additionalInfo: 'ornare',
    skillId: 2,
    centreId: 19,
    profileId: 25,
    applicationIds: undefined,
  },
  {
    id: 192,
    vacancy: 1,
    hired: 0,
    startsAt: '2020-12-06T06:45:32Z',
    endsAt: '2021-10-12T13:45:32Z',
    daysOff: undefined,
    createdAt: '2021-03-20T01:04:22Z',
    deletedAt: '2020-12-31T23:23:48Z',
    additionalInfo: 'nisi nam',
    skillId: 2,
    centreId: 49,
    profileId: 91,
    applicationIds: undefined,
  },
  {
    id: 193,
    vacancy: 5,
    hired: 1,
    startsAt: '2020-09-02T23:03:27Z',
    endsAt: '2021-10-19T09:03:27Z',
    daysOff: undefined,
    createdAt: '2021-05-28T11:25:52Z',
    deletedAt: '2021-01-17T06:39:29Z',
    additionalInfo: 'etiam',
    skillId: 1,
    centreId: 42,
    profileId: 52,
    applicationIds: undefined,
  },
  {
    id: 194,
    vacancy: 6,
    hired: 2,
    startsAt: '2021-04-02T21:00:26Z',
    endsAt: '2021-05-20T07:00:26Z',
    daysOff: undefined,
    createdAt: '2020-10-03T03:03:29Z',
    deletedAt: '2020-09-12T01:08:41Z',
    additionalInfo: 'phasellus sit',
    skillId: 3,
    centreId: 42,
    profileId: 22,
    applicationIds: undefined,
  },
  {
    id: 195,
    vacancy: 9,
    hired: 6,
    startsAt: '2020-10-23T00:27:25Z',
    endsAt: '2021-10-09T06:27:25Z',
    daysOff: [7, 6],
    createdAt: '2020-08-28T16:24:00Z',
    deletedAt: '2020-09-12T14:04:31Z',
    additionalInfo: 'in',
    skillId: 3,
    centreId: 47,
    profileId: 29,
    applicationIds: [37, 14, 6, 16, 47, 2],
  },
  {
    id: 196,
    vacancy: 5,
    hired: 3,
    startsAt: '2021-04-01T23:42:25Z',
    endsAt: '2022-04-02T10:42:25Z',
    daysOff: undefined,
    createdAt: '2021-02-17T10:47:41Z',
    deletedAt: undefined,
    additionalInfo: 'mauris vulputate',
    skillId: 1,
    centreId: 32,
    profileId: 64,
    applicationIds: undefined,
  },
  {
    id: 197,
    vacancy: 3,
    hired: 2,
    startsAt: '2021-05-25T11:00:36Z',
    endsAt: '2021-11-12T20:00:36Z',
    daysOff: undefined,
    createdAt: '2021-04-11T09:58:28Z',
    deletedAt: '2020-12-09T00:15:47Z',
    additionalInfo: 'ultricies eu',
    skillId: 1,
    centreId: 46,
    profileId: 73,
    applicationIds: undefined,
  },
  {
    id: 198,
    vacancy: 2,
    hired: 2,
    startsAt: '2020-08-25T01:46:04Z',
    endsAt: '2021-12-24T12:46:04Z',
    daysOff: [2, 6],
    createdAt: '2021-05-14T20:41:51Z',
    deletedAt: '2021-04-09T15:31:52Z',
    additionalInfo: 'justo morbi',
    skillId: 2,
    centreId: 26,
    profileId: 19,
    applicationIds: [9, 43, 23, 42, 9, 12],
  },
  {
    id: 199,
    vacancy: 7,
    hired: 7,
    startsAt: '2021-04-13T22:29:40Z',
    endsAt: '2022-01-08T05:29:40Z',
    daysOff: [1, 4],
    createdAt: '2020-12-12T12:13:31Z',
    deletedAt: '2021-06-13T09:09:31Z',
    additionalInfo: 'libero',
    skillId: 3,
    centreId: 41,
    profileId: 4,
    applicationIds: [19, 3, 37, 12, 23, 26],
  },
  {
    id: 200,
    vacancy: 9,
    hired: 9,
    startsAt: '2020-08-25T00:50:10Z',
    endsAt: '2021-07-06T05:50:10Z',
    daysOff: undefined,
    createdAt: '2020-10-26T00:27:07Z',
    deletedAt: '2021-03-27T23:08:52Z',
    additionalInfo: 'massa',
    skillId: 2,
    centreId: 25,
    profileId: 62,
    applicationIds: undefined,
  },
  {
    id: 201,
    vacancy: 4,
    hired: 3,
    startsAt: '2020-11-27T04:53:55Z',
    endsAt: '2021-06-18T08:53:55Z',
    daysOff: undefined,
    createdAt: '2020-09-17T15:54:13Z',
    deletedAt: '2021-05-19T23:15:52Z',
    additionalInfo: 'vestibulum vestibulum',
    skillId: 2,
    centreId: 19,
    profileId: 73,
    applicationIds: undefined,
  },
  {
    id: 202,
    vacancy: 1,
    hired: 0,
    startsAt: '2021-07-21T19:36:55Z',
    endsAt: '2022-06-27T01:36:55Z',
    daysOff: undefined,
    createdAt: '2020-10-12T03:35:41Z',
    deletedAt: undefined,
    additionalInfo: 'quis',
    skillId: 2,
    centreId: 26,
    profileId: 95,
    applicationIds: undefined,
  },
  {
    id: 203,
    vacancy: 10,
    hired: 4,
    startsAt: '2020-11-23T14:45:56Z',
    endsAt: '2022-09-19T22:45:56Z',
    daysOff: [2, 4],
    createdAt: '2020-09-06T23:08:41Z',
    deletedAt: undefined,
    additionalInfo: 'augue vestibulum',
    skillId: 2,
    centreId: 27,
    profileId: 9,
    applicationIds: [3, 37, 17, 48, 9, 43],
  },
  {
    id: 204,
    vacancy: 5,
    hired: 3,
    startsAt: '2021-03-20T08:50:19Z',
    endsAt: '2021-05-21T14:50:19Z',
    daysOff: [3, 9],
    createdAt: '2021-06-03T16:26:02Z',
    deletedAt: '2021-02-01T01:31:39Z',
    additionalInfo: 'undefineda',
    skillId: 1,
    centreId: 29,
    profileId: 34,
    applicationIds: [15, 1, 42, 12, 38, 48],
  },
  {
    id: 205,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-12-08T06:43:42Z',
    endsAt: '2022-02-01T11:43:42Z',
    daysOff: [3, 6],
    createdAt: '2020-09-30T23:34:53Z',
    deletedAt: '2020-11-29T02:14:33Z',
    additionalInfo: 'dui luctus',
    skillId: 2,
    centreId: 46,
    profileId: 43,
    applicationIds: [1, 42, 22, 32, 41, 40],
  },
  {
    id: 206,
    vacancy: 1,
    hired: 1,
    startsAt: '2021-03-08T19:31:49Z',
    endsAt: '2022-03-10T06:31:49Z',
    daysOff: undefined,
    createdAt: '2020-10-31T13:59:02Z',
    deletedAt: '2020-11-18T01:35:00Z',
    additionalInfo: 'eu est',
    skillId: 3,
    centreId: 32,
    profileId: 100,
    applicationIds: undefined,
  },
  {
    id: 207,
    vacancy: 4,
    hired: 1,
    startsAt: '2021-07-20T11:55:49Z',
    endsAt: '2023-01-03T23:55:49Z',
    daysOff: undefined,
    createdAt: '2021-07-18T00:34:15Z',
    deletedAt: '2020-07-27T15:01:03Z',
    additionalInfo: 'ante ipsum',
    skillId: 1,
    centreId: 42,
    profileId: 35,
    applicationIds: undefined,
  },
  {
    id: 208,
    vacancy: 4,
    hired: 4,
    startsAt: '2021-02-18T02:18:38Z',
    endsAt: '2021-08-07T08:18:38Z',
    daysOff: undefined,
    createdAt: '2020-08-09T12:07:30Z',
    deletedAt: '2020-11-09T12:35:54Z',
    additionalInfo: 'eu',
    skillId: 3,
    centreId: 9,
    profileId: 36,
    applicationIds: undefined,
  },
  {
    id: 209,
    vacancy: 2,
    hired: 0,
    startsAt: '2020-09-24T03:24:52Z',
    endsAt: '2021-03-30T14:24:52Z',
    daysOff: [9, 6],
    createdAt: '2020-08-06T16:13:23Z',
    deletedAt: '2021-05-25T00:51:44Z',
    additionalInfo: 'sapien',
    skillId: 2,
    centreId: 50,
    profileId: 38,
    applicationIds: [4, 7, 40, 43, 40, 36],
  },
  {
    id: 210,
    vacancy: 10,
    hired: 3,
    startsAt: '2020-10-25T22:55:05Z',
    endsAt: '2022-04-15T07:55:05Z',
    daysOff: undefined,
    createdAt: '2021-03-31T18:12:56Z',
    deletedAt: '2020-12-18T22:24:26Z',
    additionalInfo: 'dis parturient',
    skillId: 2,
    centreId: 14,
    profileId: 70,
    applicationIds: undefined,
  },
  {
    id: 211,
    vacancy: 3,
    hired: 3,
    startsAt: '2021-06-01T15:16:35Z',
    endsAt: '2023-05-01T23:16:35Z',
    daysOff: undefined,
    createdAt: '2021-03-14T09:06:49Z',
    deletedAt: '2020-08-21T04:07:42Z',
    additionalInfo: 'ut',
    skillId: 3,
    centreId: 47,
    profileId: 4,
    applicationIds: undefined,
  },
  {
    id: 212,
    vacancy: 2,
    hired: 0,
    startsAt: '2021-07-18T18:00:58Z',
    endsAt: '2021-11-27T00:00:58Z',
    daysOff: [9, 6],
    createdAt: '2021-07-14T14:44:35Z',
    deletedAt: undefined,
    additionalInfo: 'in quis',
    skillId: 2,
    centreId: 47,
    profileId: 7,
    applicationIds: [34, 27, 24, 30, 30, 10],
  },
  {
    id: 213,
    vacancy: 2,
    hired: 0,
    startsAt: '2021-03-28T02:03:41Z',
    endsAt: '2022-05-26T13:03:41Z',
    daysOff: [8, 2],
    createdAt: '2021-05-01T07:00:40Z',
    deletedAt: undefined,
    additionalInfo: 'rutrum undefineda',
    skillId: 2,
    centreId: 29,
    profileId: 9,
    applicationIds: [27, 21, 20, 21, 26, 29],
  },
  {
    id: 214,
    vacancy: 3,
    hired: 0,
    startsAt: '2020-10-06T09:41:58Z',
    endsAt: '2021-01-27T12:41:58Z',
    daysOff: [3, 8],
    createdAt: '2021-01-03T22:58:57Z',
    deletedAt: undefined,
    additionalInfo: 'est lacinia',
    skillId: 3,
    centreId: 3,
    profileId: 9,
    applicationIds: [15, 50, 32, 2, 3, 47],
  },
  {
    id: 215,
    vacancy: 6,
    hired: 4,
    startsAt: '2021-06-07T04:15:34Z',
    endsAt: '2022-01-22T13:15:34Z',
    daysOff: undefined,
    createdAt: '2021-04-05T09:53:13Z',
    deletedAt: undefined,
    additionalInfo: 'hac',
    skillId: 1,
    centreId: 43,
    profileId: 29,
    applicationIds: undefined,
  },
  {
    id: 216,
    vacancy: 10,
    hired: 4,
    startsAt: '2021-07-08T23:37:07Z',
    endsAt: '2021-10-02T06:37:07Z',
    daysOff: undefined,
    createdAt: '2021-04-28T08:17:41Z',
    deletedAt: '2021-06-24T02:51:30Z',
    additionalInfo: 'lorem ipsum',
    skillId: 2,
    centreId: 40,
    profileId: 31,
    applicationIds: undefined,
  },
  {
    id: 217,
    vacancy: 4,
    hired: 1,
    startsAt: '2021-02-06T08:18:55Z',
    endsAt: '2023-01-26T17:18:55Z',
    daysOff: undefined,
    createdAt: '2021-05-17T15:47:45Z',
    deletedAt: '2020-12-21T02:49:38Z',
    additionalInfo: 'felis sed',
    skillId: 3,
    centreId: 16,
    profileId: 16,
    applicationIds: undefined,
  },
  {
    id: 218,
    vacancy: 3,
    hired: 3,
    startsAt: '2021-05-15T15:52:29Z',
    endsAt: '2022-01-22T02:52:29Z',
    daysOff: undefined,
    createdAt: '2020-10-22T16:42:26Z',
    deletedAt: '2021-03-02T08:24:16Z',
    additionalInfo: 'nunc',
    skillId: 2,
    centreId: 46,
    profileId: 82,
    applicationIds: undefined,
  },
  {
    id: 219,
    vacancy: 10,
    hired: 5,
    startsAt: '2020-12-07T18:13:12Z',
    endsAt: '2021-05-09T21:13:12Z',
    daysOff: undefined,
    createdAt: '2020-12-30T17:12:13Z',
    deletedAt: '2021-06-22T19:24:22Z',
    additionalInfo: 'lobortis',
    skillId: 3,
    centreId: 26,
    profileId: 49,
    applicationIds: undefined,
  },
  {
    id: 220,
    vacancy: 7,
    hired: 0,
    startsAt: '2021-03-19T09:24:28Z',
    endsAt: '2022-07-10T20:24:28Z',
    daysOff: [1, 7],
    createdAt: '2020-12-09T03:17:37Z',
    deletedAt: '2021-03-29T07:06:47Z',
    additionalInfo: 'nibh',
    skillId: 3,
    centreId: 20,
    profileId: 18,
    applicationIds: [40, 7, 16, 40, 45, 30],
  },
  {
    id: 221,
    vacancy: 6,
    hired: 4,
    startsAt: '2020-08-17T09:41:54Z',
    endsAt: '2021-08-03T18:41:54Z',
    daysOff: undefined,
    createdAt: '2021-06-19T11:00:13Z',
    deletedAt: '2021-03-15T08:59:01Z',
    additionalInfo: 'erat vestibulum',
    skillId: 2,
    centreId: 34,
    profileId: 64,
    applicationIds: undefined,
  },
  {
    id: 222,
    vacancy: 3,
    hired: 1,
    startsAt: '2020-10-05T10:43:56Z',
    endsAt: '2022-10-12T15:43:56Z',
    daysOff: undefined,
    createdAt: '2021-02-26T08:15:52Z',
    deletedAt: '2020-11-29T01:56:13Z',
    additionalInfo: 'semper est',
    skillId: 3,
    centreId: 41,
    profileId: 54,
    applicationIds: undefined,
  },
  {
    id: 223,
    vacancy: 7,
    hired: 4,
    startsAt: '2021-04-28T02:58:36Z',
    endsAt: '2022-03-23T06:58:36Z',
    daysOff: undefined,
    createdAt: '2021-01-20T13:05:37Z',
    deletedAt: '2020-08-05T05:01:24Z',
    additionalInfo: 'quam nec',
    skillId: 1,
    centreId: 26,
    profileId: 3,
    applicationIds: undefined,
  },
  {
    id: 224,
    vacancy: 1,
    hired: 0,
    startsAt: '2020-08-07T00:56:35Z',
    endsAt: '2021-08-04T05:56:35Z',
    daysOff: undefined,
    createdAt: '2021-02-21T03:24:02Z',
    deletedAt: '2020-11-30T13:12:50Z',
    additionalInfo: 'lobortis vel',
    skillId: 2,
    centreId: 18,
    profileId: 23,
    applicationIds: undefined,
  },
  {
    id: 225,
    vacancy: 7,
    hired: 4,
    startsAt: '2020-11-16T13:26:41Z',
    endsAt: '2021-10-15T22:26:41Z',
    daysOff: [10, 7],
    createdAt: '2020-12-21T20:36:03Z',
    deletedAt: undefined,
    additionalInfo: 'sagittis',
    skillId: 2,
    centreId: 32,
    profileId: 14,
    applicationIds: [6, 15, 29, 20, 23, 3],
  },
  {
    id: 226,
    vacancy: 8,
    hired: 6,
    startsAt: '2021-05-25T10:21:22Z',
    endsAt: '2022-10-17T16:21:22Z',
    daysOff: undefined,
    createdAt: '2020-11-11T04:19:25Z',
    deletedAt: '2021-06-07T14:07:59Z',
    additionalInfo: 'non mi',
    skillId: 1,
    centreId: 13,
    profileId: 42,
    applicationIds: undefined,
  },
  {
    id: 227,
    vacancy: 5,
    hired: 5,
    startsAt: '2021-02-02T15:17:07Z',
    endsAt: '2022-02-24T02:17:07Z',
    daysOff: [4, 5],
    createdAt: '2021-07-21T23:26:25Z',
    deletedAt: '2021-06-02T09:22:06Z',
    additionalInfo: 'montes nascetur',
    skillId: 1,
    centreId: 8,
    profileId: 80,
    applicationIds: [43, 40, 41, 50, 27, 32],
  },
  {
    id: 228,
    vacancy: 3,
    hired: 3,
    startsAt: '2020-11-22T03:51:47Z',
    endsAt: '2021-10-07T10:51:47Z',
    daysOff: [7, 5],
    createdAt: '2021-05-26T05:06:27Z',
    deletedAt: '2021-07-02T17:33:44Z',
    additionalInfo: 'nunc commodo',
    skillId: 3,
    centreId: 34,
    profileId: 24,
    applicationIds: [34, 8, 45, 37, 41, 46],
  },
  {
    id: 229,
    vacancy: 10,
    hired: 9,
    startsAt: '2020-09-23T04:32:47Z',
    endsAt: '2021-03-03T12:32:47Z',
    daysOff: undefined,
    createdAt: '2021-04-25T15:07:02Z',
    deletedAt: undefined,
    additionalInfo: 'erat eros',
    skillId: 1,
    centreId: 31,
    profileId: 4,
    applicationIds: undefined,
  },
  {
    id: 230,
    vacancy: 9,
    hired: 2,
    startsAt: '2021-06-12T12:45:41Z',
    endsAt: '2021-09-29T18:45:41Z',
    daysOff: [1, 9],
    createdAt: '2021-01-10T13:00:53Z',
    deletedAt: '2020-07-29T22:01:31Z',
    additionalInfo: 'duis at',
    skillId: 3,
    centreId: 34,
    profileId: 82,
    applicationIds: [22, 40, 1, 43, 36, 4],
  },
  {
    id: 231,
    vacancy: 5,
    hired: 1,
    startsAt: '2020-12-17T01:27:51Z',
    endsAt: '2021-03-18T06:27:51Z',
    daysOff: undefined,
    createdAt: '2021-02-16T12:02:33Z',
    deletedAt: '2020-09-24T02:34:11Z',
    additionalInfo: 'at vulputate',
    skillId: 1,
    centreId: 35,
    profileId: 36,
    applicationIds: undefined,
  },
  {
    id: 232,
    vacancy: 2,
    hired: 1,
    startsAt: '2021-02-04T07:59:09Z',
    endsAt: '2022-12-13T13:59:09Z',
    daysOff: undefined,
    createdAt: '2020-08-27T05:11:21Z',
    deletedAt: undefined,
    additionalInfo: 'eget elit',
    skillId: 1,
    centreId: 28,
    profileId: 78,
    applicationIds: undefined,
  },
  {
    id: 233,
    vacancy: 8,
    hired: 1,
    startsAt: '2021-03-31T12:43:23Z',
    endsAt: '2022-10-28T15:43:23Z',
    daysOff: [9, 3],
    createdAt: '2020-11-20T14:35:56Z',
    deletedAt: undefined,
    additionalInfo: 'in lectus',
    skillId: 2,
    centreId: 30,
    profileId: 60,
    applicationIds: [26, 19, 24, 5, 8, 42],
  },
  {
    id: 234,
    vacancy: 6,
    hired: 2,
    startsAt: '2021-06-27T16:55:47Z',
    endsAt: '2021-11-12T20:55:47Z',
    daysOff: undefined,
    createdAt: '2021-05-14T17:07:55Z',
    deletedAt: '2021-03-31T03:56:07Z',
    additionalInfo: 'consectetuer',
    skillId: 1,
    centreId: 2,
    profileId: 8,
    applicationIds: undefined,
  },
  {
    id: 235,
    vacancy: 1,
    hired: 0,
    startsAt: '2020-08-31T18:56:05Z',
    endsAt: '2022-06-29T21:56:05Z',
    daysOff: undefined,
    createdAt: '2021-01-15T13:57:53Z',
    deletedAt: '2020-09-15T15:56:05Z',
    additionalInfo: 'id pretium',
    skillId: 1,
    centreId: 47,
    profileId: 95,
    applicationIds: undefined,
  },
  {
    id: 236,
    vacancy: 10,
    hired: 4,
    startsAt: '2020-12-04T03:19:38Z',
    endsAt: '2022-04-30T11:19:38Z',
    daysOff: undefined,
    createdAt: '2020-10-08T10:49:37Z',
    deletedAt: '2021-03-22T09:55:24Z',
    additionalInfo: 'mauris',
    skillId: 1,
    centreId: 30,
    profileId: 44,
    applicationIds: undefined,
  },
  {
    id: 237,
    vacancy: 8,
    hired: 6,
    startsAt: '2020-12-25T18:19:37Z',
    endsAt: '2021-11-30T23:19:37Z',
    daysOff: [9, 1],
    createdAt: '2020-08-25T16:35:46Z',
    deletedAt: '2020-08-05T14:27:45Z',
    additionalInfo: 'leo odio',
    skillId: 3,
    centreId: 13,
    profileId: 34,
    applicationIds: [32, 20, 25, 40, 19, 12],
  },
  {
    id: 238,
    vacancy: 8,
    hired: 5,
    startsAt: '2021-04-25T00:35:07Z',
    endsAt: '2021-12-01T05:35:07Z',
    daysOff: undefined,
    createdAt: '2021-01-03T09:44:54Z',
    deletedAt: '2021-02-11T00:25:37Z',
    additionalInfo: 'potenti cras',
    skillId: 3,
    centreId: 11,
    profileId: 84,
    applicationIds: undefined,
  },
  {
    id: 239,
    vacancy: 9,
    hired: 7,
    startsAt: '2020-10-03T15:22:44Z',
    endsAt: '2021-07-31T01:22:44Z',
    daysOff: undefined,
    createdAt: '2021-06-25T07:46:30Z',
    deletedAt: '2021-06-01T17:47:48Z',
    additionalInfo: 'potenti cras',
    skillId: 1,
    centreId: 38,
    profileId: 8,
    applicationIds: undefined,
  },
  {
    id: 240,
    vacancy: 3,
    hired: 2,
    startsAt: '2021-04-06T18:55:34Z',
    endsAt: '2023-01-20T01:55:34Z',
    daysOff: undefined,
    createdAt: '2020-10-21T12:42:31Z',
    deletedAt: '2021-03-16T07:06:01Z',
    additionalInfo: 'sed magna',
    skillId: 1,
    centreId: 8,
    profileId: 73,
    applicationIds: undefined,
  },
  {
    id: 241,
    vacancy: 9,
    hired: 7,
    startsAt: '2020-12-10T21:42:47Z',
    endsAt: '2021-06-28T05:42:47Z',
    daysOff: undefined,
    createdAt: '2020-11-21T00:27:56Z',
    deletedAt: '2021-06-05T08:38:52Z',
    additionalInfo: 'sapien',
    skillId: 1,
    centreId: 44,
    profileId: 67,
    applicationIds: undefined,
  },
  {
    id: 242,
    vacancy: 4,
    hired: 4,
    startsAt: '2020-11-18T04:25:45Z',
    endsAt: '2022-03-22T10:25:45Z',
    daysOff: [6, 10],
    createdAt: '2021-04-15T11:09:13Z',
    deletedAt: '2020-08-01T18:20:33Z',
    additionalInfo: 'aenean auctor',
    skillId: 3,
    centreId: 19,
    profileId: 62,
    applicationIds: [21, 29, 45, 16, 32, 42],
  },
  {
    id: 243,
    vacancy: 6,
    hired: 0,
    startsAt: '2021-04-08T20:30:08Z',
    endsAt: '2022-12-28T06:30:08Z',
    daysOff: undefined,
    createdAt: '2021-01-28T09:45:37Z',
    deletedAt: '2020-12-14T02:40:35Z',
    additionalInfo: 'in eleifend',
    skillId: 3,
    centreId: 34,
    profileId: 85,
    applicationIds: undefined,
  },
  {
    id: 244,
    vacancy: 10,
    hired: 0,
    startsAt: '2021-03-08T22:42:21Z',
    endsAt: '2022-10-21T09:42:21Z',
    daysOff: undefined,
    createdAt: '2021-06-02T17:30:21Z',
    deletedAt: '2021-04-17T12:15:08Z',
    additionalInfo: 'arcu',
    skillId: 2,
    centreId: 31,
    profileId: 43,
    applicationIds: undefined,
  },
  {
    id: 245,
    vacancy: 5,
    hired: 2,
    startsAt: '2020-11-24T15:07:27Z',
    endsAt: '2021-08-22T19:07:27Z',
    daysOff: undefined,
    createdAt: '2020-12-23T05:14:09Z',
    deletedAt: undefined,
    additionalInfo: 'venenatis turpis',
    skillId: 2,
    centreId: 4,
    profileId: 28,
    applicationIds: undefined,
  },
  {
    id: 246,
    vacancy: 6,
    hired: 4,
    startsAt: '2021-05-29T15:16:39Z',
    endsAt: '2023-03-30T22:16:39Z',
    daysOff: undefined,
    createdAt: '2021-04-11T21:14:14Z',
    deletedAt: '2021-07-20T03:50:26Z',
    additionalInfo: 'cubilia',
    skillId: 1,
    centreId: 43,
    profileId: 58,
    applicationIds: undefined,
  },
  {
    id: 247,
    vacancy: 9,
    hired: 6,
    startsAt: '2021-05-04T19:59:19Z',
    endsAt: '2021-08-28T01:59:19Z',
    daysOff: undefined,
    createdAt: '2021-01-03T21:29:29Z',
    deletedAt: undefined,
    additionalInfo: 'sit amet',
    skillId: 1,
    centreId: 44,
    profileId: 92,
    applicationIds: undefined,
  },
  {
    id: 248,
    vacancy: 2,
    hired: 0,
    startsAt: '2021-06-14T06:42:20Z',
    endsAt: '2022-11-15T17:42:20Z',
    daysOff: [1, 10],
    createdAt: '2020-11-09T23:41:19Z',
    deletedAt: '2021-07-03T18:55:03Z',
    additionalInfo: 'mattis pulvinar',
    skillId: 2,
    centreId: 26,
    profileId: 42,
    applicationIds: [19, 39, 50, 17, 42, 23],
  },
  {
    id: 249,
    vacancy: 8,
    hired: 7,
    startsAt: '2020-11-17T07:02:17Z',
    endsAt: '2022-02-19T19:02:17Z',
    daysOff: [6, 7],
    createdAt: '2020-09-01T21:03:02Z',
    deletedAt: '2020-09-13T19:38:15Z',
    additionalInfo: 'praesent lectus',
    skillId: 2,
    centreId: 11,
    profileId: 54,
    applicationIds: [20, 9, 13, 9, 4, 11],
  },
  {
    id: 250,
    vacancy: 9,
    hired: 7,
    startsAt: '2021-03-24T14:42:57Z',
    endsAt: '2022-11-01T22:42:57Z',
    daysOff: [5, 8],
    createdAt: '2021-03-19T00:04:02Z',
    deletedAt: '2020-08-13T06:14:33Z',
    additionalInfo: 'platea',
    skillId: 1,
    centreId: 31,
    profileId: 6,
    applicationIds: [14, 13, 23, 24, 17, 41],
  },
  {
    id: 251,
    vacancy: 3,
    hired: 3,
    startsAt: '2021-05-16T07:55:44Z',
    endsAt: '2022-04-26T10:55:44Z',
    daysOff: [9, 10],
    createdAt: '2020-09-03T08:18:11Z',
    deletedAt: '2021-05-29T22:05:22Z',
    additionalInfo: 'congue risus',
    skillId: 1,
    centreId: 9,
    profileId: 40,
    applicationIds: [26, 41, 25, 23, 50, 12],
  },
  {
    id: 252,
    vacancy: 10,
    hired: 0,
    startsAt: '2021-05-28T02:50:49Z',
    endsAt: '2021-10-27T14:50:49Z',
    daysOff: [1, 8],
    createdAt: '2021-06-01T18:49:13Z',
    deletedAt: '2021-01-16T15:00:37Z',
    additionalInfo: 'natoque',
    skillId: 1,
    centreId: 22,
    profileId: 48,
    applicationIds: [15, 9, 29, 23, 17, 27],
  },
  {
    id: 253,
    vacancy: 3,
    hired: 1,
    startsAt: '2021-04-19T16:42:16Z',
    endsAt: '2022-11-03T19:42:16Z',
    daysOff: undefined,
    createdAt: '2021-02-22T00:05:00Z',
    deletedAt: '2021-04-28T18:47:29Z',
    additionalInfo: 'interdum venenatis',
    skillId: 3,
    centreId: 1,
    profileId: 15,
    applicationIds: undefined,
  },
  {
    id: 254,
    vacancy: 9,
    hired: 3,
    startsAt: '2021-03-29T16:45:22Z',
    endsAt: '2021-09-26T00:45:22Z',
    daysOff: undefined,
    createdAt: '2020-07-26T05:06:09Z',
    deletedAt: '2021-03-05T04:58:45Z',
    additionalInfo: 'dui',
    skillId: 2,
    centreId: 35,
    profileId: 97,
    applicationIds: undefined,
  },
  {
    id: 255,
    vacancy: 4,
    hired: 2,
    startsAt: '2020-10-07T08:17:04Z',
    endsAt: '2022-02-24T12:17:04Z',
    daysOff: undefined,
    createdAt: '2021-02-18T06:48:59Z',
    deletedAt: '2020-09-14T08:41:07Z',
    additionalInfo: 'lacinia',
    skillId: 2,
    centreId: 39,
    profileId: 99,
    applicationIds: undefined,
  },
  {
    id: 256,
    vacancy: 6,
    hired: 4,
    startsAt: '2021-01-09T21:39:12Z',
    endsAt: '2022-03-29T07:39:12Z',
    daysOff: undefined,
    createdAt: '2020-10-31T04:13:17Z',
    deletedAt: undefined,
    additionalInfo: 'id nisl',
    skillId: 2,
    centreId: 10,
    profileId: 7,
    applicationIds: undefined,
  },
  {
    id: 257,
    vacancy: 8,
    hired: 2,
    startsAt: '2021-01-14T21:11:17Z',
    endsAt: '2021-09-26T05:11:17Z',
    daysOff: undefined,
    createdAt: '2020-12-10T15:58:22Z',
    deletedAt: '2020-09-27T02:51:23Z',
    additionalInfo: 'facilisi',
    skillId: 3,
    centreId: 28,
    profileId: 2,
    applicationIds: undefined,
  },
  {
    id: 258,
    vacancy: 9,
    hired: 3,
    startsAt: '2020-11-10T01:18:35Z',
    endsAt: '2022-12-08T12:18:35Z',
    daysOff: undefined,
    createdAt: '2020-10-27T20:11:51Z',
    deletedAt: '2020-11-28T19:55:03Z',
    additionalInfo: 'id consequat',
    skillId: 3,
    centreId: 45,
    profileId: 100,
    applicationIds: undefined,
  },
  {
    id: 259,
    vacancy: 9,
    hired: 5,
    startsAt: '2020-11-02T09:46:26Z',
    endsAt: '2022-06-17T15:46:26Z',
    daysOff: [6, 10],
    createdAt: '2021-06-06T18:41:19Z',
    deletedAt: '2020-11-14T04:57:08Z',
    additionalInfo: 'potenti',
    skillId: 2,
    centreId: 38,
    profileId: 22,
    applicationIds: [37, 13, 19, 42, 45, 14],
  },
  {
    id: 260,
    vacancy: 2,
    hired: 1,
    startsAt: '2020-08-13T08:04:36Z',
    endsAt: '2022-08-28T16:04:36Z',
    daysOff: undefined,
    createdAt: '2021-01-05T10:30:11Z',
    deletedAt: '2020-09-12T07:42:44Z',
    additionalInfo: 'curabitur in',
    skillId: 3,
    centreId: 48,
    profileId: 24,
    applicationIds: undefined,
  },
  {
    id: 261,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-09-02T23:07:04Z',
    endsAt: '2022-04-05T08:07:04Z',
    daysOff: undefined,
    createdAt: '2021-05-05T01:54:31Z',
    deletedAt: '2020-10-11T02:01:21Z',
    additionalInfo: 'rhoncus sed',
    skillId: 2,
    centreId: 15,
    profileId: 68,
    applicationIds: undefined,
  },
  {
    id: 262,
    vacancy: 5,
    hired: 5,
    startsAt: '2020-11-12T14:50:10Z',
    endsAt: '2021-11-06T17:50:10Z',
    daysOff: [6, 2],
    createdAt: '2021-03-21T13:36:59Z',
    deletedAt: '2020-09-29T13:47:35Z',
    additionalInfo: 'venenatis',
    skillId: 3,
    centreId: 3,
    profileId: 75,
    applicationIds: [3, 25, 35, 40, 9, 10],
  },
  {
    id: 263,
    vacancy: 5,
    hired: 2,
    startsAt: '2021-03-02T18:19:25Z',
    endsAt: '2023-01-29T03:19:25Z',
    daysOff: [1, 4],
    createdAt: '2021-04-19T18:17:08Z',
    deletedAt: '2021-03-22T11:54:20Z',
    additionalInfo: 'porta',
    skillId: 2,
    centreId: 16,
    profileId: 67,
    applicationIds: [44, 12, 48, 7, 2, 18],
  },
  {
    id: 264,
    vacancy: 7,
    hired: 6,
    startsAt: '2021-05-06T01:08:05Z',
    endsAt: '2023-01-26T10:08:05Z',
    daysOff: undefined,
    createdAt: '2020-08-27T19:16:47Z',
    deletedAt: '2021-01-27T12:18:56Z',
    additionalInfo: 'diam',
    skillId: 2,
    centreId: 36,
    profileId: 78,
    applicationIds: undefined,
  },
  {
    id: 265,
    vacancy: 10,
    hired: 2,
    startsAt: '2021-05-28T16:25:44Z',
    endsAt: '2021-10-20T04:25:44Z',
    daysOff: undefined,
    createdAt: '2020-08-10T09:33:32Z',
    deletedAt: '2021-06-07T02:13:37Z',
    additionalInfo: 'quam',
    skillId: 2,
    centreId: 43,
    profileId: 16,
    applicationIds: undefined,
  },
  {
    id: 266,
    vacancy: 8,
    hired: 0,
    startsAt: '2021-06-20T07:25:15Z',
    endsAt: '2023-07-09T16:25:15Z',
    daysOff: undefined,
    createdAt: '2021-05-25T01:41:17Z',
    deletedAt: '2021-04-02T12:12:28Z',
    additionalInfo: 'massa id',
    skillId: 2,
    centreId: 9,
    profileId: 59,
    applicationIds: undefined,
  },
  {
    id: 267,
    vacancy: 9,
    hired: 9,
    startsAt: '2020-09-07T15:24:19Z',
    endsAt: '2020-12-06T22:24:19Z',
    daysOff: undefined,
    createdAt: '2020-10-26T23:40:32Z',
    deletedAt: '2020-10-29T18:18:04Z',
    additionalInfo: 'mi',
    skillId: 2,
    centreId: 21,
    profileId: 64,
    applicationIds: undefined,
  },
  {
    id: 268,
    vacancy: 10,
    hired: 5,
    startsAt: '2021-02-02T18:16:03Z',
    endsAt: '2021-05-02T05:16:03Z',
    daysOff: undefined,
    createdAt: '2021-04-27T08:38:29Z',
    deletedAt: '2020-10-31T14:03:32Z',
    additionalInfo: 'justo sollicitudin',
    skillId: 2,
    centreId: 39,
    profileId: 65,
    applicationIds: undefined,
  },
  {
    id: 269,
    vacancy: 6,
    hired: 1,
    startsAt: '2020-10-19T18:54:50Z',
    endsAt: '2022-02-16T03:54:50Z',
    daysOff: undefined,
    createdAt: '2021-06-21T08:50:06Z',
    deletedAt: '2021-07-11T10:49:00Z',
    additionalInfo: 'amet sapien',
    skillId: 2,
    centreId: 37,
    profileId: 95,
    applicationIds: undefined,
  },
  {
    id: 270,
    vacancy: 2,
    hired: 0,
    startsAt: '2021-05-22T06:04:23Z',
    endsAt: '2022-05-09T17:04:23Z',
    daysOff: undefined,
    createdAt: '2021-01-06T17:34:50Z',
    deletedAt: '2020-10-06T23:47:13Z',
    additionalInfo: 'quisque',
    skillId: 1,
    centreId: 9,
    profileId: 40,
    applicationIds: undefined,
  },
  {
    id: 271,
    vacancy: 7,
    hired: 6,
    startsAt: '2020-09-07T23:33:04Z',
    endsAt: '2020-11-19T09:33:04Z',
    daysOff: [2, 10],
    createdAt: '2021-03-04T13:02:30Z',
    deletedAt: '2021-07-07T17:48:39Z',
    additionalInfo: 'maecenas pulvinar',
    skillId: 2,
    centreId: 36,
    profileId: 23,
    applicationIds: [5, 4, 21, 19, 2, 27],
  },
  {
    id: 272,
    vacancy: 7,
    hired: 5,
    startsAt: '2020-08-14T09:24:32Z',
    endsAt: '2021-08-09T15:24:32Z',
    daysOff: undefined,
    createdAt: '2021-05-11T21:46:18Z',
    deletedAt: '2020-11-19T04:39:58Z',
    additionalInfo: 'pretium nisl',
    skillId: 3,
    centreId: 11,
    profileId: 12,
    applicationIds: undefined,
  },
  {
    id: 273,
    vacancy: 6,
    hired: 4,
    startsAt: '2020-11-23T07:16:41Z',
    endsAt: '2022-04-12T15:16:41Z',
    daysOff: undefined,
    createdAt: '2021-01-06T15:39:51Z',
    deletedAt: '2020-10-20T08:13:41Z',
    additionalInfo: 'dapibus',
    skillId: 1,
    centreId: 15,
    profileId: 86,
    applicationIds: undefined,
  },
  {
    id: 274,
    vacancy: 10,
    hired: 7,
    startsAt: '2020-07-30T02:16:45Z',
    endsAt: '2022-04-12T07:16:45Z',
    daysOff: [6, 9],
    createdAt: '2020-10-17T05:23:16Z',
    deletedAt: '2020-09-22T05:17:13Z',
    additionalInfo: 'curabitur',
    skillId: 1,
    centreId: 30,
    profileId: 52,
    applicationIds: [43, 30, 50, 27, 27, 3],
  },
  {
    id: 275,
    vacancy: 7,
    hired: 0,
    startsAt: '2020-07-27T00:39:03Z',
    endsAt: '2021-02-28T10:39:03Z',
    daysOff: undefined,
    createdAt: '2021-07-01T02:38:33Z',
    deletedAt: undefined,
    additionalInfo: 'mauris ullamcorper',
    skillId: 1,
    centreId: 8,
    profileId: 85,
    applicationIds: undefined,
  },
  {
    id: 276,
    vacancy: 10,
    hired: 6,
    startsAt: '2021-05-21T00:16:52Z',
    endsAt: '2023-05-11T09:16:52Z',
    daysOff: [1, 9],
    createdAt: '2020-12-12T18:05:38Z',
    deletedAt: undefined,
    additionalInfo: 'vel',
    skillId: 2,
    centreId: 48,
    profileId: 56,
    applicationIds: [2, 9, 48, 7, 24, 33],
  },
  {
    id: 277,
    vacancy: 4,
    hired: 3,
    startsAt: '2021-03-14T17:48:11Z',
    endsAt: '2022-12-29T03:48:11Z',
    daysOff: undefined,
    createdAt: '2020-11-13T22:46:44Z',
    deletedAt: '2020-08-17T21:05:38Z',
    additionalInfo: 'vivamus vestibulum',
    skillId: 1,
    centreId: 17,
    profileId: 80,
    applicationIds: undefined,
  },
  {
    id: 278,
    vacancy: 7,
    hired: 0,
    startsAt: '2021-03-19T11:45:55Z',
    endsAt: '2021-08-09T19:45:55Z',
    daysOff: undefined,
    createdAt: '2020-12-24T15:29:20Z',
    deletedAt: undefined,
    additionalInfo: 'nec',
    skillId: 1,
    centreId: 33,
    profileId: 96,
    applicationIds: undefined,
  },
  {
    id: 279,
    vacancy: 6,
    hired: 2,
    startsAt: '2020-11-25T16:38:31Z',
    endsAt: '2021-10-02T01:38:31Z',
    daysOff: [6, 7],
    createdAt: '2021-05-07T22:50:43Z',
    deletedAt: '2021-04-14T21:01:11Z',
    additionalInfo: 'ut ultrices',
    skillId: 2,
    centreId: 43,
    profileId: 92,
    applicationIds: [47, 41, 7, 43, 27, 41],
  },
  {
    id: 280,
    vacancy: 5,
    hired: 2,
    startsAt: '2021-01-01T21:53:57Z',
    endsAt: '2022-02-12T01:53:57Z',
    daysOff: undefined,
    createdAt: '2021-04-20T08:12:19Z',
    deletedAt: '2020-10-30T05:30:39Z',
    additionalInfo: 'diam',
    skillId: 2,
    centreId: 44,
    profileId: 92,
    applicationIds: undefined,
  },
  {
    id: 281,
    vacancy: 6,
    hired: 5,
    startsAt: '2020-11-18T03:52:39Z',
    endsAt: '2022-01-12T14:52:39Z',
    daysOff: undefined,
    createdAt: '2020-07-23T23:18:26Z',
    deletedAt: undefined,
    additionalInfo: 'egestas metus',
    skillId: 1,
    centreId: 43,
    profileId: 72,
    applicationIds: undefined,
  },
  {
    id: 282,
    vacancy: 3,
    hired: 2,
    startsAt: '2021-06-18T11:17:01Z',
    endsAt: '2022-11-11T22:17:01Z',
    daysOff: [1, 3],
    createdAt: '2021-06-13T18:45:32Z',
    deletedAt: '2020-08-24T02:00:18Z',
    additionalInfo: 'luctus et',
    skillId: 3,
    centreId: 32,
    profileId: 15,
    applicationIds: [17, 33, 39, 22, 45, 27],
  },
  {
    id: 283,
    vacancy: 9,
    hired: 0,
    startsAt: '2020-10-12T01:33:28Z',
    endsAt: '2021-02-02T09:33:28Z',
    daysOff: undefined,
    createdAt: '2021-01-22T07:32:31Z',
    deletedAt: '2021-01-15T04:53:41Z',
    additionalInfo: 'sed',
    skillId: 1,
    centreId: 12,
    profileId: 77,
    applicationIds: undefined,
  },
  {
    id: 284,
    vacancy: 4,
    hired: 1,
    startsAt: '2020-08-03T15:25:44Z',
    endsAt: '2021-10-31T03:25:44Z',
    daysOff: undefined,
    createdAt: '2021-03-14T01:32:24Z',
    deletedAt: '2021-03-21T04:02:22Z',
    additionalInfo: 'suscipit',
    skillId: 2,
    centreId: 30,
    profileId: 34,
    applicationIds: undefined,
  },
  {
    id: 285,
    vacancy: 6,
    hired: 6,
    startsAt: '2020-12-27T19:09:19Z',
    endsAt: '2022-01-09T01:09:19Z',
    daysOff: [10, 3],
    createdAt: '2021-01-12T01:34:23Z',
    deletedAt: undefined,
    additionalInfo: 'ullamcorper augue',
    skillId: 1,
    centreId: 29,
    profileId: 34,
    applicationIds: [9, 7, 8, 21, 20, 33],
  },
  {
    id: 286,
    vacancy: 5,
    hired: 1,
    startsAt: '2020-07-27T06:06:24Z',
    endsAt: '2021-02-26T12:06:24Z',
    daysOff: undefined,
    createdAt: '2020-08-09T11:21:46Z',
    deletedAt: '2020-12-17T22:41:31Z',
    additionalInfo: 'ac enim',
    skillId: 3,
    centreId: 6,
    profileId: 40,
    applicationIds: undefined,
  },
  {
    id: 287,
    vacancy: 9,
    hired: 2,
    startsAt: '2021-04-07T06:34:24Z',
    endsAt: '2023-04-01T15:34:24Z',
    daysOff: undefined,
    createdAt: '2020-11-21T21:14:28Z',
    deletedAt: '2020-10-26T07:16:59Z',
    additionalInfo: 'elit',
    skillId: 2,
    centreId: 24,
    profileId: 68,
    applicationIds: undefined,
  },
  {
    id: 288,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-11-30T05:37:44Z',
    endsAt: '2021-06-26T14:37:44Z',
    daysOff: undefined,
    createdAt: '2021-05-21T08:49:12Z',
    deletedAt: '2021-03-15T07:07:49Z',
    additionalInfo: 'ultrices erat',
    skillId: 2,
    centreId: 12,
    profileId: 60,
    applicationIds: undefined,
  },
  {
    id: 289,
    vacancy: 9,
    hired: 9,
    startsAt: '2020-12-15T13:33:20Z',
    endsAt: '2022-10-24T01:33:20Z',
    daysOff: [7, 6],
    createdAt: '2021-06-03T19:30:16Z',
    deletedAt: '2021-07-18T12:03:58Z',
    additionalInfo: 'massa donec',
    skillId: 3,
    centreId: 36,
    profileId: 58,
    applicationIds: [30, 46, 44, 47, 1, 4],
  },
  {
    id: 290,
    vacancy: 2,
    hired: 2,
    startsAt: '2021-02-16T06:53:22Z',
    endsAt: '2022-01-22T10:53:22Z',
    daysOff: [6, 3],
    createdAt: '2021-07-02T13:13:44Z',
    deletedAt: '2021-05-12T23:24:19Z',
    additionalInfo: 'lorem ipsum',
    skillId: 2,
    centreId: 35,
    profileId: 75,
    applicationIds: [4, 16, 37, 47, 22, 42],
  },
  {
    id: 291,
    vacancy: 9,
    hired: 7,
    startsAt: '2020-09-20T11:39:59Z',
    endsAt: '2021-07-25T22:39:59Z',
    daysOff: undefined,
    createdAt: '2020-09-16T02:50:23Z',
    deletedAt: '2021-01-20T04:05:38Z',
    additionalInfo: 'eu',
    skillId: 1,
    centreId: 3,
    profileId: 48,
    applicationIds: undefined,
  },
  {
    id: 292,
    vacancy: 5,
    hired: 2,
    startsAt: '2021-03-01T10:05:21Z',
    endsAt: '2021-06-10T18:05:21Z',
    daysOff: [5, 1],
    createdAt: '2020-09-03T09:19:22Z',
    deletedAt: '2020-10-23T12:53:20Z',
    additionalInfo: 'odio in',
    skillId: 3,
    centreId: 29,
    profileId: 97,
    applicationIds: [13, 27, 43, 11, 31, 6],
  },
  {
    id: 293,
    vacancy: 10,
    hired: 6,
    startsAt: '2020-08-10T10:23:47Z',
    endsAt: '2021-10-29T14:23:47Z',
    daysOff: undefined,
    createdAt: '2021-02-22T17:38:33Z',
    deletedAt: '2021-02-19T12:47:04Z',
    additionalInfo: 'turpis',
    skillId: 3,
    centreId: 31,
    profileId: 93,
    applicationIds: undefined,
  },
  {
    id: 294,
    vacancy: 10,
    hired: 0,
    startsAt: '2020-09-30T14:18:18Z',
    endsAt: '2022-08-16T19:18:18Z',
    daysOff: undefined,
    createdAt: '2021-04-20T19:14:24Z',
    deletedAt: '2020-08-15T01:16:05Z',
    additionalInfo: 'et ultrices',
    skillId: 2,
    centreId: 50,
    profileId: 41,
    applicationIds: undefined,
  },
  {
    id: 295,
    vacancy: 2,
    hired: 1,
    startsAt: '2020-08-17T14:33:26Z',
    endsAt: '2021-10-15T19:33:26Z',
    daysOff: undefined,
    createdAt: '2020-08-16T11:35:27Z',
    deletedAt: '2021-03-16T20:00:30Z',
    additionalInfo: 'arcu sed',
    skillId: 3,
    centreId: 1,
    profileId: 2,
    applicationIds: undefined,
  },
  {
    id: 296,
    vacancy: 6,
    hired: 1,
    startsAt: '2020-09-27T14:33:40Z',
    endsAt: '2022-04-27T23:33:40Z',
    daysOff: undefined,
    createdAt: '2021-06-24T23:04:57Z',
    deletedAt: '2020-08-19T13:44:01Z',
    additionalInfo: 'rutrum rutrum',
    skillId: 1,
    centreId: 35,
    profileId: 24,
    applicationIds: undefined,
  },
  {
    id: 297,
    vacancy: 5,
    hired: 1,
    startsAt: '2021-05-11T05:52:22Z',
    endsAt: '2022-09-13T17:52:22Z',
    daysOff: undefined,
    createdAt: '2021-06-02T18:09:25Z',
    deletedAt: '2020-11-14T00:05:37Z',
    additionalInfo: 'urna pretium',
    skillId: 3,
    centreId: 41,
    profileId: 28,
    applicationIds: undefined,
  },
  {
    id: 298,
    vacancy: 3,
    hired: 2,
    startsAt: '2020-11-30T09:26:30Z',
    endsAt: '2021-06-26T18:26:30Z',
    daysOff: undefined,
    createdAt: '2021-06-14T16:19:24Z',
    deletedAt: '2021-05-02T05:19:34Z',
    additionalInfo: 'undefineda nisl',
    skillId: 1,
    centreId: 4,
    profileId: 75,
    applicationIds: undefined,
  },
  {
    id: 299,
    vacancy: 9,
    hired: 1,
    startsAt: '2020-11-13T10:32:05Z',
    endsAt: '2021-10-23T21:32:05Z',
    daysOff: undefined,
    createdAt: '2021-03-10T08:21:54Z',
    deletedAt: undefined,
    additionalInfo: 'cubilia',
    skillId: 3,
    centreId: 23,
    profileId: 92,
    applicationIds: undefined,
  },
  {
    id: 300,
    vacancy: 4,
    hired: 3,
    startsAt: '2021-01-17T21:57:19Z',
    endsAt: '2022-06-02T08:57:19Z',
    daysOff: [5, 6],
    createdAt: '2021-06-23T06:31:00Z',
    deletedAt: '2020-11-04T14:24:44Z',
    additionalInfo: 'primis',
    skillId: 2,
    centreId: 24,
    profileId: 2,
    applicationIds: [26, 48, 16, 47, 50, 10],
  },
  {
    id: 301,
    vacancy: 10,
    hired: 0,
    startsAt: '2020-11-08T08:10:03Z',
    endsAt: '2021-10-01T19:10:03Z',
    daysOff: [8, 10],
    createdAt: '2021-03-21T20:19:00Z',
    deletedAt: undefined,
    additionalInfo: 'enim sit',
    skillId: 2,
    centreId: 49,
    profileId: 79,
    applicationIds: [28, 29, 15, 27, 30, 19],
  },
  {
    id: 302,
    vacancy: 9,
    hired: 8,
    startsAt: '2021-01-28T13:55:36Z',
    endsAt: '2021-11-11T16:55:36Z',
    daysOff: undefined,
    createdAt: '2020-07-24T08:05:01Z',
    deletedAt: '2020-08-04T04:06:28Z',
    additionalInfo: 'eros',
    skillId: 2,
    centreId: 11,
    profileId: 5,
    applicationIds: undefined,
  },
  {
    id: 303,
    vacancy: 3,
    hired: 2,
    startsAt: '2020-11-19T06:51:48Z',
    endsAt: '2022-04-10T14:51:48Z',
    daysOff: undefined,
    createdAt: '2021-01-24T06:07:01Z',
    deletedAt: '2021-05-28T04:46:38Z',
    additionalInfo: 'et ultrices',
    skillId: 1,
    centreId: 23,
    profileId: 5,
    applicationIds: undefined,
  },
  {
    id: 304,
    vacancy: 1,
    hired: 1,
    startsAt: '2021-05-04T11:34:49Z',
    endsAt: '2022-07-16T16:34:49Z',
    daysOff: undefined,
    createdAt: '2020-09-29T07:40:52Z',
    deletedAt: '2021-06-13T09:20:37Z',
    additionalInfo: 'fusce lacus',
    skillId: 2,
    centreId: 17,
    profileId: 8,
    applicationIds: undefined,
  },
  {
    id: 305,
    vacancy: 8,
    hired: 2,
    startsAt: '2020-08-26T14:59:18Z',
    endsAt: '2020-12-01T22:59:18Z',
    daysOff: [3, 1],
    createdAt: '2020-08-06T18:29:23Z',
    deletedAt: '2021-03-28T20:52:27Z',
    additionalInfo: 'mauris lacinia',
    skillId: 1,
    centreId: 45,
    profileId: 41,
    applicationIds: [5, 10, 50, 29, 22, 47],
  },
  {
    id: 306,
    vacancy: 2,
    hired: 0,
    startsAt: '2021-02-18T02:43:46Z',
    endsAt: '2022-04-17T14:43:46Z',
    daysOff: undefined,
    createdAt: '2021-02-20T11:13:21Z',
    deletedAt: '2020-12-16T04:57:18Z',
    additionalInfo: 'venenatis turpis',
    skillId: 2,
    centreId: 41,
    profileId: 13,
    applicationIds: undefined,
  },
  {
    id: 307,
    vacancy: 3,
    hired: 0,
    startsAt: '2021-06-29T04:00:29Z',
    endsAt: '2021-09-28T12:00:29Z',
    daysOff: undefined,
    createdAt: '2021-04-22T23:00:18Z',
    deletedAt: '2021-07-08T14:32:57Z',
    additionalInfo: 'lectus',
    skillId: 2,
    centreId: 44,
    profileId: 20,
    applicationIds: undefined,
  },
  {
    id: 308,
    vacancy: 4,
    hired: 2,
    startsAt: '2021-04-09T04:08:14Z',
    endsAt: '2021-08-20T13:08:14Z',
    daysOff: [6, 4],
    createdAt: '2020-10-07T09:30:29Z',
    deletedAt: '2020-09-29T06:50:26Z',
    additionalInfo: 'pellentesque',
    skillId: 1,
    centreId: 36,
    profileId: 11,
    applicationIds: [40, 23, 43, 27, 22, 17],
  },
  {
    id: 309,
    vacancy: 4,
    hired: 4,
    startsAt: '2021-03-27T01:30:00Z',
    endsAt: '2021-11-19T13:30:00Z',
    daysOff: undefined,
    createdAt: '2021-04-17T22:20:20Z',
    deletedAt: '2021-03-08T17:35:27Z',
    additionalInfo: 'consectetuer',
    skillId: 3,
    centreId: 46,
    profileId: 25,
    applicationIds: undefined,
  },
  {
    id: 310,
    vacancy: 2,
    hired: 1,
    startsAt: '2020-10-22T16:55:02Z',
    endsAt: '2022-05-07T04:55:02Z',
    daysOff: undefined,
    createdAt: '2020-07-31T05:26:26Z',
    deletedAt: '2021-01-08T22:40:30Z',
    additionalInfo: 'tellus in',
    skillId: 1,
    centreId: 46,
    profileId: 6,
    applicationIds: undefined,
  },
  {
    id: 311,
    vacancy: 7,
    hired: 5,
    startsAt: '2020-08-09T21:37:42Z',
    endsAt: '2020-12-13T02:37:42Z',
    daysOff: [5, 1],
    createdAt: '2020-11-10T17:42:55Z',
    deletedAt: '2021-02-25T17:45:31Z',
    additionalInfo: 'rutrum',
    skillId: 2,
    centreId: 22,
    profileId: 82,
    applicationIds: [7, 44, 2, 34, 3, 1],
  },
  {
    id: 312,
    vacancy: 5,
    hired: 2,
    startsAt: '2020-08-16T15:33:40Z',
    endsAt: '2020-12-11T22:33:40Z',
    daysOff: undefined,
    createdAt: '2021-03-06T01:52:27Z',
    deletedAt: '2021-02-14T12:40:12Z',
    additionalInfo: 'id',
    skillId: 3,
    centreId: 40,
    profileId: 65,
    applicationIds: undefined,
  },
  {
    id: 313,
    vacancy: 7,
    hired: 4,
    startsAt: '2021-06-06T12:33:22Z',
    endsAt: '2023-01-15T15:33:22Z',
    daysOff: [5, 3],
    createdAt: '2020-08-13T17:23:01Z',
    deletedAt: '2021-01-07T00:19:45Z',
    additionalInfo: 'nisi',
    skillId: 2,
    centreId: 35,
    profileId: 29,
    applicationIds: [45, 25, 13, 4, 1, 13],
  },
  {
    id: 314,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-08-19T21:34:40Z',
    endsAt: '2022-02-28T07:34:40Z',
    daysOff: [2, 10],
    createdAt: '2020-07-25T01:29:02Z',
    deletedAt: '2021-06-29T16:45:12Z',
    additionalInfo: 'id',
    skillId: 1,
    centreId: 7,
    profileId: 2,
    applicationIds: [40, 49, 16, 43, 46, 43],
  },
  {
    id: 315,
    vacancy: 5,
    hired: 3,
    startsAt: '2021-05-03T02:19:36Z',
    endsAt: '2023-02-05T14:19:36Z',
    daysOff: undefined,
    createdAt: '2020-09-02T02:56:55Z',
    deletedAt: '2020-07-31T09:56:15Z',
    additionalInfo: 'aliquet',
    skillId: 2,
    centreId: 22,
    profileId: 75,
    applicationIds: undefined,
  },
  {
    id: 316,
    vacancy: 10,
    hired: 10,
    startsAt: '2021-03-07T20:49:12Z',
    endsAt: '2023-03-13T06:49:12Z',
    daysOff: undefined,
    createdAt: '2021-05-10T22:33:59Z',
    deletedAt: '2021-05-14T03:24:56Z',
    additionalInfo: 'risus dapibus',
    skillId: 3,
    centreId: 39,
    profileId: 84,
    applicationIds: undefined,
  },
  {
    id: 317,
    vacancy: 2,
    hired: 2,
    startsAt: '2021-01-01T10:20:17Z',
    endsAt: '2022-05-22T20:20:17Z',
    daysOff: undefined,
    createdAt: '2020-12-09T22:35:53Z',
    deletedAt: '2020-12-22T14:19:58Z',
    additionalInfo: 'est',
    skillId: 1,
    centreId: 4,
    profileId: 6,
    applicationIds: undefined,
  },
  {
    id: 318,
    vacancy: 6,
    hired: 3,
    startsAt: '2020-07-29T06:40:40Z',
    endsAt: '2021-07-31T18:40:40Z',
    daysOff: undefined,
    createdAt: '2020-09-12T09:07:55Z',
    deletedAt: undefined,
    additionalInfo: 'vel est',
    skillId: 2,
    centreId: 50,
    profileId: 34,
    applicationIds: undefined,
  },
  {
    id: 319,
    vacancy: 5,
    hired: 3,
    startsAt: '2021-05-08T09:20:19Z',
    endsAt: '2023-03-27T21:20:19Z',
    daysOff: undefined,
    createdAt: '2020-10-10T09:05:40Z',
    deletedAt: '2020-09-26T13:29:33Z',
    additionalInfo: 'in',
    skillId: 1,
    centreId: 1,
    profileId: 47,
    applicationIds: undefined,
  },
  {
    id: 320,
    vacancy: 6,
    hired: 2,
    startsAt: '2021-01-10T02:21:21Z',
    endsAt: '2022-10-21T07:21:21Z',
    daysOff: undefined,
    createdAt: '2021-04-16T10:51:37Z',
    deletedAt: '2020-12-23T22:54:08Z',
    additionalInfo: 'duis',
    skillId: 3,
    centreId: 47,
    profileId: 91,
    applicationIds: undefined,
  },
  {
    id: 321,
    vacancy: 2,
    hired: 0,
    startsAt: '2021-04-22T22:08:29Z',
    endsAt: '2022-05-23T10:08:29Z',
    daysOff: undefined,
    createdAt: '2021-03-24T23:33:05Z',
    deletedAt: undefined,
    additionalInfo: 'amet eleifend',
    skillId: 1,
    centreId: 48,
    profileId: 25,
    applicationIds: undefined,
  },
  {
    id: 322,
    vacancy: 6,
    hired: 6,
    startsAt: '2021-03-22T22:21:38Z',
    endsAt: '2023-04-06T03:21:38Z',
    daysOff: [5, 1],
    createdAt: '2020-08-02T02:55:18Z',
    deletedAt: undefined,
    additionalInfo: 'sed',
    skillId: 1,
    centreId: 35,
    profileId: 31,
    applicationIds: [5, 36, 42, 43, 49, 28],
  },
  {
    id: 323,
    vacancy: 8,
    hired: 2,
    startsAt: '2021-04-29T09:12:32Z',
    endsAt: '2021-07-13T12:12:32Z',
    daysOff: [1, 4],
    createdAt: '2021-07-08T19:22:34Z',
    deletedAt: '2020-12-13T22:42:02Z',
    additionalInfo: 'a pede',
    skillId: 3,
    centreId: 27,
    profileId: 89,
    applicationIds: [10, 45, 13, 6, 22, 17],
  },
  {
    id: 324,
    vacancy: 3,
    hired: 2,
    startsAt: '2021-07-18T21:54:25Z',
    endsAt: '2022-07-30T05:54:25Z',
    daysOff: [10, 10],
    createdAt: '2021-01-03T16:16:43Z',
    deletedAt: '2020-12-09T14:53:49Z',
    additionalInfo: 'congue',
    skillId: 1,
    centreId: 19,
    profileId: 91,
    applicationIds: [14, 40, 32, 9, 34, 27],
  },
  {
    id: 325,
    vacancy: 8,
    hired: 4,
    startsAt: '2021-07-06T11:28:37Z',
    endsAt: '2022-09-29T17:28:37Z',
    daysOff: [2, 3],
    createdAt: '2021-06-19T04:41:39Z',
    deletedAt: '2020-09-11T07:20:02Z',
    additionalInfo: 'felis eu',
    skillId: 3,
    centreId: 23,
    profileId: 63,
    applicationIds: [32, 32, 20, 43, 9, 22],
  },
  {
    id: 326,
    vacancy: 7,
    hired: 5,
    startsAt: '2020-10-31T11:30:24Z',
    endsAt: '2022-10-22T15:30:24Z',
    daysOff: undefined,
    createdAt: '2021-06-25T02:27:33Z',
    deletedAt: '2021-07-14T08:49:54Z',
    additionalInfo: 'turpis integer',
    skillId: 3,
    centreId: 32,
    profileId: 43,
    applicationIds: undefined,
  },
  {
    id: 327,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-12-18T02:33:18Z',
    endsAt: '2022-07-29T11:33:18Z',
    daysOff: undefined,
    createdAt: '2021-04-17T03:59:23Z',
    deletedAt: '2021-01-06T20:47:08Z',
    additionalInfo: 'quisque ut',
    skillId: 1,
    centreId: 48,
    profileId: 26,
    applicationIds: undefined,
  },
  {
    id: 328,
    vacancy: 6,
    hired: 3,
    startsAt: '2020-11-22T09:47:36Z',
    endsAt: '2022-06-25T18:47:36Z',
    daysOff: undefined,
    createdAt: '2021-04-01T10:30:16Z',
    deletedAt: '2020-09-24T03:26:56Z',
    additionalInfo: 'eleifend pede',
    skillId: 2,
    centreId: 25,
    profileId: 48,
    applicationIds: undefined,
  },
  {
    id: 329,
    vacancy: 10,
    hired: 4,
    startsAt: '2020-08-04T10:29:49Z',
    endsAt: '2021-05-09T17:29:49Z',
    daysOff: undefined,
    createdAt: '2021-06-16T16:43:20Z',
    deletedAt: '2020-10-01T23:11:26Z',
    additionalInfo: 'morbi',
    skillId: 1,
    centreId: 32,
    profileId: 6,
    applicationIds: undefined,
  },
  {
    id: 330,
    vacancy: 2,
    hired: 1,
    startsAt: '2021-06-18T21:48:53Z',
    endsAt: '2022-12-28T07:48:53Z',
    daysOff: undefined,
    createdAt: '2021-05-22T17:32:34Z',
    deletedAt: '2020-11-10T00:58:06Z',
    additionalInfo: 'elit ac',
    skillId: 3,
    centreId: 20,
    profileId: 64,
    applicationIds: undefined,
  },
  {
    id: 331,
    vacancy: 10,
    hired: 6,
    startsAt: '2020-12-07T05:25:16Z',
    endsAt: '2021-07-28T14:25:16Z',
    daysOff: [9, 6],
    createdAt: '2021-01-22T15:37:23Z',
    deletedAt: '2021-03-07T14:06:52Z',
    additionalInfo: 'in faucibus',
    skillId: 3,
    centreId: 16,
    profileId: 85,
    applicationIds: [45, 8, 43, 12, 14, 16],
  },
  {
    id: 332,
    vacancy: 6,
    hired: 4,
    startsAt: '2021-06-07T00:10:03Z',
    endsAt: '2021-10-30T07:10:03Z',
    daysOff: [5, 5],
    createdAt: '2021-04-01T05:59:55Z',
    deletedAt: '2020-12-20T14:03:06Z',
    additionalInfo: 'justo in',
    skillId: 1,
    centreId: 5,
    profileId: 43,
    applicationIds: [41, 16, 11, 22, 39, 45],
  },
  {
    id: 333,
    vacancy: 2,
    hired: 0,
    startsAt: '2021-01-09T08:02:12Z',
    endsAt: '2021-11-23T14:02:12Z',
    daysOff: undefined,
    createdAt: '2021-01-11T01:26:11Z',
    deletedAt: '2020-08-24T19:12:52Z',
    additionalInfo: 'morbi odio',
    skillId: 3,
    centreId: 6,
    profileId: 94,
    applicationIds: undefined,
  },
  {
    id: 334,
    vacancy: 2,
    hired: 1,
    startsAt: '2020-09-29T07:31:00Z',
    endsAt: '2021-08-22T17:31:00Z',
    daysOff: undefined,
    createdAt: '2021-05-12T17:26:27Z',
    deletedAt: '2021-02-09T06:07:07Z',
    additionalInfo: 'amet eros',
    skillId: 1,
    centreId: 39,
    profileId: 9,
    applicationIds: undefined,
  },
  {
    id: 335,
    vacancy: 3,
    hired: 3,
    startsAt: '2020-12-17T14:16:32Z',
    endsAt: '2022-06-12T18:16:32Z',
    daysOff: undefined,
    createdAt: '2021-03-11T15:25:06Z',
    deletedAt: '2021-04-29T11:09:28Z',
    additionalInfo: 'morbi',
    skillId: 2,
    centreId: 38,
    profileId: 5,
    applicationIds: undefined,
  },
  {
    id: 336,
    vacancy: 10,
    hired: 9,
    startsAt: '2020-10-02T13:40:20Z',
    endsAt: '2021-12-10T23:40:20Z',
    daysOff: undefined,
    createdAt: '2021-04-13T02:14:22Z',
    deletedAt: '2021-06-02T13:36:04Z',
    additionalInfo: 'pede morbi',
    skillId: 3,
    centreId: 4,
    profileId: 57,
    applicationIds: undefined,
  },
  {
    id: 337,
    vacancy: 1,
    hired: 1,
    startsAt: '2021-02-25T16:53:26Z',
    endsAt: '2022-10-07T23:53:26Z',
    daysOff: undefined,
    createdAt: '2021-01-22T16:15:26Z',
    deletedAt: '2021-01-20T21:00:17Z',
    additionalInfo: 'scelerisque',
    skillId: 3,
    centreId: 13,
    profileId: 11,
    applicationIds: undefined,
  },
  {
    id: 338,
    vacancy: 9,
    hired: 3,
    startsAt: '2021-06-23T11:11:46Z',
    endsAt: '2023-04-05T16:11:46Z',
    daysOff: undefined,
    createdAt: '2021-03-24T12:57:37Z',
    deletedAt: '2020-11-28T03:15:02Z',
    additionalInfo: 'id turpis',
    skillId: 2,
    centreId: 16,
    profileId: 27,
    applicationIds: undefined,
  },
  {
    id: 339,
    vacancy: 6,
    hired: 6,
    startsAt: '2021-07-14T23:50:31Z',
    endsAt: '2022-12-31T09:50:31Z',
    daysOff: undefined,
    createdAt: '2021-06-20T05:07:30Z',
    deletedAt: undefined,
    additionalInfo: 'aliquam',
    skillId: 3,
    centreId: 11,
    profileId: 78,
    applicationIds: undefined,
  },
  {
    id: 340,
    vacancy: 5,
    hired: 2,
    startsAt: '2021-02-16T21:00:50Z',
    endsAt: '2021-07-12T09:00:50Z',
    daysOff: [8, 8],
    createdAt: '2021-03-12T18:26:04Z',
    deletedAt: undefined,
    additionalInfo: 'ut',
    skillId: 1,
    centreId: 34,
    profileId: 82,
    applicationIds: [45, 26, 24, 25, 33, 6],
  },
  {
    id: 341,
    vacancy: 7,
    hired: 5,
    startsAt: '2021-04-27T15:02:17Z',
    endsAt: '2022-04-12T03:02:17Z',
    daysOff: [7, 3],
    createdAt: '2020-11-17T02:02:02Z',
    deletedAt: '2020-10-21T10:41:13Z',
    additionalInfo: 'dolor morbi',
    skillId: 2,
    centreId: 37,
    profileId: 34,
    applicationIds: [23, 28, 24, 2, 36, 2],
  },
  {
    id: 342,
    vacancy: 6,
    hired: 4,
    startsAt: '2021-06-03T10:32:21Z',
    endsAt: '2021-09-03T17:32:21Z',
    daysOff: undefined,
    createdAt: '2021-07-04T06:04:04Z',
    deletedAt: '2021-02-14T14:28:13Z',
    additionalInfo: 'lectus pellentesque',
    skillId: 2,
    centreId: 41,
    profileId: 60,
    applicationIds: undefined,
  },
  {
    id: 343,
    vacancy: 7,
    hired: 0,
    startsAt: '2020-10-22T05:35:31Z',
    endsAt: '2022-02-19T11:35:31Z',
    daysOff: undefined,
    createdAt: '2021-06-19T07:46:23Z',
    deletedAt: '2021-05-26T20:50:52Z',
    additionalInfo: 'non interdum',
    skillId: 1,
    centreId: 15,
    profileId: 26,
    applicationIds: undefined,
  },
  {
    id: 344,
    vacancy: 8,
    hired: 8,
    startsAt: '2020-09-21T20:59:43Z',
    endsAt: '2021-03-15T03:59:43Z',
    daysOff: undefined,
    createdAt: '2021-04-19T22:45:33Z',
    deletedAt: '2020-10-09T17:57:32Z',
    additionalInfo: 'sem',
    skillId: 2,
    centreId: 36,
    profileId: 72,
    applicationIds: undefined,
  },
  {
    id: 345,
    vacancy: 1,
    hired: 0,
    startsAt: '2021-01-05T14:09:53Z',
    endsAt: '2023-01-21T00:09:53Z',
    daysOff: undefined,
    createdAt: '2020-08-09T14:52:38Z',
    deletedAt: '2021-02-09T15:54:28Z',
    additionalInfo: 'amet',
    skillId: 3,
    centreId: 31,
    profileId: 7,
    applicationIds: undefined,
  },
  {
    id: 346,
    vacancy: 1,
    hired: 0,
    startsAt: '2021-07-17T16:13:48Z',
    endsAt: '2023-08-08T22:13:48Z',
    daysOff: undefined,
    createdAt: '2020-11-18T22:24:18Z',
    deletedAt: undefined,
    additionalInfo: 'undefineda',
    skillId: 2,
    centreId: 38,
    profileId: 79,
    applicationIds: undefined,
  },
  {
    id: 347,
    vacancy: 7,
    hired: 6,
    startsAt: '2020-11-04T07:44:21Z',
    endsAt: '2022-06-08T15:44:21Z',
    daysOff: undefined,
    createdAt: '2021-01-06T08:58:17Z',
    deletedAt: '2020-11-14T04:15:01Z',
    additionalInfo: 'tortor',
    skillId: 2,
    centreId: 5,
    profileId: 70,
    applicationIds: undefined,
  },
  {
    id: 348,
    vacancy: 6,
    hired: 6,
    startsAt: '2021-07-02T18:52:54Z',
    endsAt: '2023-01-07T22:52:54Z',
    daysOff: [3, 7],
    createdAt: '2020-08-03T06:02:10Z',
    deletedAt: '2021-01-29T15:40:41Z',
    additionalInfo: 'ut',
    skillId: 2,
    centreId: 43,
    profileId: 100,
    applicationIds: [34, 14, 14, 36, 30, 19],
  },
  {
    id: 349,
    vacancy: 8,
    hired: 6,
    startsAt: '2021-07-18T21:26:40Z',
    endsAt: '2021-12-29T09:26:40Z',
    daysOff: undefined,
    createdAt: '2021-01-23T21:30:51Z',
    deletedAt: '2021-01-07T13:04:40Z',
    additionalInfo: 'quisque',
    skillId: 2,
    centreId: 39,
    profileId: 74,
    applicationIds: undefined,
  },
  {
    id: 350,
    vacancy: 2,
    hired: 2,
    startsAt: '2020-09-08T00:35:01Z',
    endsAt: '2022-05-30T04:35:01Z',
    daysOff: undefined,
    createdAt: '2021-01-27T23:45:50Z',
    deletedAt: '2021-05-10T04:50:03Z',
    additionalInfo: 'vulputate justo',
    skillId: 2,
    centreId: 46,
    profileId: 44,
    applicationIds: undefined,
  },
  {
    id: 351,
    vacancy: 3,
    hired: 2,
    startsAt: '2021-01-22T08:02:43Z',
    endsAt: '2022-06-08T17:02:43Z',
    daysOff: undefined,
    createdAt: '2020-10-06T23:56:10Z',
    deletedAt: '2021-04-27T14:01:49Z',
    additionalInfo: 'sit',
    skillId: 3,
    centreId: 26,
    profileId: 99,
    applicationIds: undefined,
  },
  {
    id: 352,
    vacancy: 4,
    hired: 3,
    startsAt: '2021-06-10T01:02:07Z',
    endsAt: '2022-09-05T13:02:07Z',
    daysOff: undefined,
    createdAt: '2021-01-09T18:12:30Z',
    deletedAt: '2021-07-15T17:21:31Z',
    additionalInfo: 'sit amet',
    skillId: 2,
    centreId: 50,
    profileId: 68,
    applicationIds: undefined,
  },
  {
    id: 353,
    vacancy: 1,
    hired: 0,
    startsAt: '2020-10-28T20:08:59Z',
    endsAt: '2021-10-09T03:08:59Z',
    daysOff: [1, 8],
    createdAt: '2021-01-09T09:20:21Z',
    deletedAt: '2021-02-19T17:29:24Z',
    additionalInfo: 'vel',
    skillId: 3,
    centreId: 21,
    profileId: 90,
    applicationIds: [13, 38, 48, 44, 28, 4],
  },
  {
    id: 354,
    vacancy: 8,
    hired: 6,
    startsAt: '2021-01-10T20:16:10Z',
    endsAt: '2021-06-22T05:16:10Z',
    daysOff: [8, 4],
    createdAt: '2020-07-29T05:27:56Z',
    deletedAt: '2021-07-18T05:47:20Z',
    additionalInfo: 'tincidunt in',
    skillId: 3,
    centreId: 15,
    profileId: 61,
    applicationIds: [50, 13, 12, 44, 34, 43],
  },
  {
    id: 355,
    vacancy: 3,
    hired: 0,
    startsAt: '2021-05-04T07:33:12Z',
    endsAt: '2023-02-28T15:33:12Z',
    daysOff: undefined,
    createdAt: '2021-03-19T12:42:18Z',
    deletedAt: '2021-07-09T11:53:40Z',
    additionalInfo: 'amet undefineda',
    skillId: 2,
    centreId: 45,
    profileId: 69,
    applicationIds: undefined,
  },
  {
    id: 356,
    vacancy: 6,
    hired: 5,
    startsAt: '2020-10-23T16:17:11Z',
    endsAt: '2022-01-13T01:17:11Z',
    daysOff: [2, 6],
    createdAt: '2020-11-30T23:04:29Z',
    deletedAt: '2020-09-11T18:45:04Z',
    additionalInfo: 'commodo',
    skillId: 1,
    centreId: 7,
    profileId: 81,
    applicationIds: [24, 30, 5, 38, 21, 15],
  },
  {
    id: 357,
    vacancy: 3,
    hired: 0,
    startsAt: '2021-02-20T15:52:25Z',
    endsAt: '2023-03-22T02:52:25Z',
    daysOff: undefined,
    createdAt: '2020-12-29T21:42:06Z',
    deletedAt: '2021-04-28T20:39:43Z',
    additionalInfo: 'imperdiet',
    skillId: 3,
    centreId: 50,
    profileId: 6,
    applicationIds: undefined,
  },
  {
    id: 358,
    vacancy: 2,
    hired: 2,
    startsAt: '2021-06-03T18:53:40Z',
    endsAt: '2021-09-10T05:53:40Z',
    daysOff: undefined,
    createdAt: '2020-10-04T08:27:36Z',
    deletedAt: undefined,
    additionalInfo: 'condimentum',
    skillId: 1,
    centreId: 32,
    profileId: 14,
    applicationIds: undefined,
  },
  {
    id: 359,
    vacancy: 5,
    hired: 3,
    startsAt: '2021-01-07T02:54:49Z',
    endsAt: '2021-10-03T12:54:49Z',
    daysOff: [9, 5],
    createdAt: '2020-12-11T05:40:17Z',
    deletedAt: '2021-06-30T15:58:01Z',
    additionalInfo: 'condimentum neque',
    skillId: 1,
    centreId: 29,
    profileId: 24,
    applicationIds: [38, 45, 22, 39, 15, 18],
  },
  {
    id: 360,
    vacancy: 9,
    hired: 4,
    startsAt: '2020-11-29T18:33:28Z',
    endsAt: '2021-11-02T21:33:28Z',
    daysOff: [6, 9],
    createdAt: '2021-05-18T21:02:42Z',
    deletedAt: '2021-06-22T07:32:43Z',
    additionalInfo: 'viverra',
    skillId: 2,
    centreId: 33,
    profileId: 32,
    applicationIds: [10, 31, 15, 21, 15, 17],
  },
  {
    id: 361,
    vacancy: 10,
    hired: 4,
    startsAt: '2020-12-26T04:06:42Z',
    endsAt: '2022-03-04T09:06:42Z',
    daysOff: undefined,
    createdAt: '2020-09-28T08:52:52Z',
    deletedAt: '2020-11-19T10:06:38Z',
    additionalInfo: 'cursus',
    skillId: 2,
    centreId: 28,
    profileId: 16,
    applicationIds: undefined,
  },
  {
    id: 362,
    vacancy: 7,
    hired: 6,
    startsAt: '2021-03-13T02:35:06Z',
    endsAt: '2023-04-09T06:35:06Z',
    daysOff: undefined,
    createdAt: '2020-11-11T01:37:04Z',
    deletedAt: '2020-12-14T15:32:47Z',
    additionalInfo: 'ut blandit',
    skillId: 2,
    centreId: 44,
    profileId: 25,
    applicationIds: undefined,
  },
  {
    id: 363,
    vacancy: 7,
    hired: 5,
    startsAt: '2021-05-24T20:50:16Z',
    endsAt: '2021-11-05T02:50:16Z',
    daysOff: [8, 7],
    createdAt: '2020-11-19T06:22:06Z',
    deletedAt: '2021-02-02T00:41:56Z',
    additionalInfo: 'aliquam non',
    skillId: 3,
    centreId: 40,
    profileId: 46,
    applicationIds: [1, 2, 18, 45, 29, 35],
  },
  {
    id: 364,
    vacancy: 10,
    hired: 4,
    startsAt: '2021-05-16T19:23:21Z',
    endsAt: '2023-04-02T00:23:21Z',
    daysOff: [3, 9],
    createdAt: '2021-01-01T01:55:19Z',
    deletedAt: '2020-08-25T08:43:10Z',
    additionalInfo: 'donec',
    skillId: 2,
    centreId: 25,
    profileId: 63,
    applicationIds: [14, 20, 10, 22, 37, 6],
  },
  {
    id: 365,
    vacancy: 7,
    hired: 0,
    startsAt: '2020-12-08T23:16:59Z',
    endsAt: '2022-11-28T10:16:59Z',
    daysOff: undefined,
    createdAt: '2021-05-26T06:33:08Z',
    deletedAt: '2020-10-17T14:19:16Z',
    additionalInfo: 'amet consectetuer',
    skillId: 2,
    centreId: 33,
    profileId: 94,
    applicationIds: undefined,
  },
  {
    id: 366,
    vacancy: 9,
    hired: 2,
    startsAt: '2021-03-02T03:50:59Z',
    endsAt: '2022-05-11T09:50:59Z',
    daysOff: [2, 10],
    createdAt: '2021-06-12T16:39:16Z',
    deletedAt: undefined,
    additionalInfo: 'orci',
    skillId: 1,
    centreId: 3,
    profileId: 87,
    applicationIds: [40, 43, 16, 23, 18, 23],
  },
  {
    id: 367,
    vacancy: 5,
    hired: 5,
    startsAt: '2021-03-16T18:58:09Z',
    endsAt: '2022-04-22T00:58:09Z',
    daysOff: undefined,
    createdAt: '2020-08-04T15:50:44Z',
    deletedAt: '2021-04-11T13:37:52Z',
    additionalInfo: 'semper',
    skillId: 2,
    centreId: 29,
    profileId: 43,
    applicationIds: undefined,
  },
  {
    id: 368,
    vacancy: 3,
    hired: 2,
    startsAt: '2021-01-01T20:15:21Z',
    endsAt: '2021-11-18T00:15:21Z',
    daysOff: undefined,
    createdAt: '2020-09-22T04:33:46Z',
    deletedAt: '2020-09-29T23:07:59Z',
    additionalInfo: 'dapibus',
    skillId: 1,
    centreId: 50,
    profileId: 65,
    applicationIds: undefined,
  },
  {
    id: 369,
    vacancy: 8,
    hired: 0,
    startsAt: '2020-09-23T12:56:51Z',
    endsAt: '2022-10-13T00:56:51Z',
    daysOff: undefined,
    createdAt: '2021-01-18T00:26:37Z',
    deletedAt: '2021-06-30T21:39:14Z',
    additionalInfo: 'ac',
    skillId: 2,
    centreId: 31,
    profileId: 84,
    applicationIds: undefined,
  },
  {
    id: 370,
    vacancy: 8,
    hired: 2,
    startsAt: '2020-08-04T11:45:20Z',
    endsAt: '2022-04-27T21:45:20Z',
    daysOff: [8, 8],
    createdAt: '2021-06-20T12:18:50Z',
    deletedAt: undefined,
    additionalInfo: 'at',
    skillId: 3,
    centreId: 22,
    profileId: 70,
    applicationIds: [19, 33, 17, 17, 43, 9],
  },
  {
    id: 371,
    vacancy: 8,
    hired: 6,
    startsAt: '2021-06-25T08:46:04Z',
    endsAt: '2022-10-23T12:46:04Z',
    daysOff: undefined,
    createdAt: '2021-04-26T13:39:34Z',
    deletedAt: '2020-09-22T03:41:41Z',
    additionalInfo: 'dui vel',
    skillId: 1,
    centreId: 26,
    profileId: 24,
    applicationIds: undefined,
  },
  {
    id: 372,
    vacancy: 10,
    hired: 7,
    startsAt: '2021-02-02T16:28:21Z',
    endsAt: '2021-03-14T21:28:21Z',
    daysOff: undefined,
    createdAt: '2021-07-04T12:03:11Z',
    deletedAt: '2020-08-06T03:52:43Z',
    additionalInfo: 'ornare imperdiet',
    skillId: 3,
    centreId: 31,
    profileId: 75,
    applicationIds: undefined,
  },
  {
    id: 373,
    vacancy: 8,
    hired: 2,
    startsAt: '2021-03-18T06:12:31Z',
    endsAt: '2021-10-03T14:12:31Z',
    daysOff: [3, 7],
    createdAt: '2020-08-12T11:25:37Z',
    deletedAt: '2021-03-29T18:40:34Z',
    additionalInfo: 'eget',
    skillId: 2,
    centreId: 24,
    profileId: 88,
    applicationIds: [21, 42, 11, 20, 45, 19],
  },
  {
    id: 374,
    vacancy: 7,
    hired: 5,
    startsAt: '2020-12-10T07:38:52Z',
    endsAt: '2021-01-23T18:38:52Z',
    daysOff: undefined,
    createdAt: '2021-01-10T12:10:16Z',
    deletedAt: undefined,
    additionalInfo: 'suspendisse',
    skillId: 3,
    centreId: 4,
    profileId: 95,
    applicationIds: undefined,
  },
  {
    id: 375,
    vacancy: 5,
    hired: 2,
    startsAt: '2020-08-12T09:10:47Z',
    endsAt: '2021-05-01T19:10:47Z',
    daysOff: undefined,
    createdAt: '2021-04-13T10:59:24Z',
    deletedAt: '2021-05-27T14:27:45Z',
    additionalInfo: 'sapien',
    skillId: 1,
    centreId: 3,
    profileId: 81,
    applicationIds: undefined,
  },
  {
    id: 376,
    vacancy: 8,
    hired: 7,
    startsAt: '2021-04-01T10:43:47Z',
    endsAt: '2022-10-03T18:43:47Z',
    daysOff: undefined,
    createdAt: '2021-01-18T11:24:01Z',
    deletedAt: '2021-02-03T15:56:32Z',
    additionalInfo: 'elementum',
    skillId: 1,
    centreId: 4,
    profileId: 67,
    applicationIds: undefined,
  },
  {
    id: 377,
    vacancy: 10,
    hired: 10,
    startsAt: '2021-01-29T05:27:55Z',
    endsAt: '2023-01-14T16:27:55Z',
    daysOff: undefined,
    createdAt: '2021-04-26T03:29:05Z',
    deletedAt: '2021-01-09T15:04:24Z',
    additionalInfo: 'vulputate vitae',
    skillId: 3,
    centreId: 14,
    profileId: 63,
    applicationIds: undefined,
  },
  {
    id: 378,
    vacancy: 9,
    hired: 1,
    startsAt: '2021-02-23T00:39:24Z',
    endsAt: '2022-09-17T03:39:24Z',
    daysOff: undefined,
    createdAt: '2021-06-27T19:45:42Z',
    deletedAt: '2020-12-06T06:00:32Z',
    additionalInfo: 'in congue',
    skillId: 3,
    centreId: 30,
    profileId: 58,
    applicationIds: undefined,
  },
  {
    id: 379,
    vacancy: 1,
    hired: 0,
    startsAt: '2020-11-13T00:04:03Z',
    endsAt: '2022-06-10T03:04:03Z',
    daysOff: undefined,
    createdAt: '2021-03-14T11:51:53Z',
    deletedAt: '2020-10-02T14:20:14Z',
    additionalInfo: 'est donec',
    skillId: 2,
    centreId: 27,
    profileId: 20,
    applicationIds: undefined,
  },
  {
    id: 380,
    vacancy: 3,
    hired: 1,
    startsAt: '2021-02-05T09:47:27Z',
    endsAt: '2022-02-09T13:47:27Z',
    daysOff: undefined,
    createdAt: '2021-07-14T13:47:27Z',
    deletedAt: undefined,
    additionalInfo: 'at dolor',
    skillId: 1,
    centreId: 9,
    profileId: 39,
    applicationIds: undefined,
  },
  {
    id: 381,
    vacancy: 10,
    hired: 3,
    startsAt: '2020-07-28T18:29:17Z',
    endsAt: '2021-06-01T02:29:17Z',
    daysOff: undefined,
    createdAt: '2021-04-01T16:09:39Z',
    deletedAt: '2021-07-11T02:53:56Z',
    additionalInfo: 'ut at',
    skillId: 2,
    centreId: 37,
    profileId: 68,
    applicationIds: undefined,
  },
  {
    id: 382,
    vacancy: 6,
    hired: 3,
    startsAt: '2020-08-01T20:41:51Z',
    endsAt: '2022-05-27T06:41:51Z',
    daysOff: undefined,
    createdAt: '2021-04-16T08:59:32Z',
    deletedAt: '2020-07-28T10:55:04Z',
    additionalInfo: 'elementum pellentesque',
    skillId: 2,
    centreId: 22,
    profileId: 10,
    applicationIds: undefined,
  },
  {
    id: 383,
    vacancy: 4,
    hired: 4,
    startsAt: '2021-04-15T16:46:52Z',
    endsAt: '2021-07-15T23:46:52Z',
    daysOff: [6, 5],
    createdAt: '2021-03-30T13:29:15Z',
    deletedAt: '2021-02-11T00:27:11Z',
    additionalInfo: 'quisque arcu',
    skillId: 1,
    centreId: 25,
    profileId: 49,
    applicationIds: [37, 36, 27, 13, 15, 32],
  },
  {
    id: 384,
    vacancy: 7,
    hired: 1,
    startsAt: '2020-09-13T23:36:26Z',
    endsAt: '2021-03-02T11:36:26Z',
    daysOff: undefined,
    createdAt: '2020-12-28T04:02:29Z',
    deletedAt: '2021-03-02T07:46:29Z',
    additionalInfo: 'velit nec',
    skillId: 1,
    centreId: 1,
    profileId: 83,
    applicationIds: undefined,
  },
  {
    id: 385,
    vacancy: 5,
    hired: 5,
    startsAt: '2021-01-29T22:00:33Z',
    endsAt: '2021-08-14T05:00:33Z',
    daysOff: [2, 7],
    createdAt: '2021-07-17T14:46:57Z',
    deletedAt: '2021-02-12T03:07:34Z',
    additionalInfo: 'euismod',
    skillId: 2,
    centreId: 44,
    profileId: 41,
    applicationIds: [41, 29, 22, 47, 33, 34],
  },
  {
    id: 386,
    vacancy: 9,
    hired: 5,
    startsAt: '2021-03-14T18:54:37Z',
    endsAt: '2023-01-01T00:54:37Z',
    daysOff: [2, 10],
    createdAt: '2021-05-14T16:29:52Z',
    deletedAt: '2021-06-02T07:27:56Z',
    additionalInfo: 'eget',
    skillId: 2,
    centreId: 19,
    profileId: 47,
    applicationIds: [3, 6, 42, 32, 24, 37],
  },
  {
    id: 387,
    vacancy: 2,
    hired: 1,
    startsAt: '2021-02-19T03:18:17Z',
    endsAt: '2022-03-02T06:18:17Z',
    daysOff: undefined,
    createdAt: '2021-04-08T21:39:05Z',
    deletedAt: '2021-02-15T02:21:20Z',
    additionalInfo: 'molestie hendrerit',
    skillId: 1,
    centreId: 5,
    profileId: 12,
    applicationIds: undefined,
  },
  {
    id: 388,
    vacancy: 2,
    hired: 0,
    startsAt: '2021-06-09T17:25:12Z',
    endsAt: '2022-06-14T00:25:12Z',
    daysOff: undefined,
    createdAt: '2020-08-21T19:10:17Z',
    deletedAt: '2020-12-27T03:31:06Z',
    additionalInfo: 'morbi non',
    skillId: 1,
    centreId: 49,
    profileId: 99,
    applicationIds: undefined,
  },
  {
    id: 389,
    vacancy: 2,
    hired: 0,
    startsAt: '2021-04-26T11:28:09Z',
    endsAt: '2022-12-10T15:28:09Z',
    daysOff: [7, 5],
    createdAt: '2020-09-05T22:00:41Z',
    deletedAt: '2020-11-26T21:08:16Z',
    additionalInfo: 'tortor',
    skillId: 2,
    centreId: 36,
    profileId: 66,
    applicationIds: [42, 12, 45, 4, 26, 37],
  },
  {
    id: 390,
    vacancy: 9,
    hired: 5,
    startsAt: '2020-08-09T09:33:41Z',
    endsAt: '2021-08-02T12:33:41Z',
    daysOff: undefined,
    createdAt: '2021-05-27T05:44:45Z',
    deletedAt: '2021-04-11T01:42:32Z',
    additionalInfo: 'vel',
    skillId: 3,
    centreId: 29,
    profileId: 53,
    applicationIds: undefined,
  },
  {
    id: 391,
    vacancy: 9,
    hired: 6,
    startsAt: '2021-01-03T03:59:15Z',
    endsAt: '2022-09-26T08:59:15Z',
    daysOff: undefined,
    createdAt: '2020-08-19T21:19:48Z',
    deletedAt: '2020-12-29T00:12:19Z',
    additionalInfo: 'maecenas rhoncus',
    skillId: 2,
    centreId: 9,
    profileId: 30,
    applicationIds: undefined,
  },
  {
    id: 392,
    vacancy: 3,
    hired: 0,
    startsAt: '2020-09-28T11:45:27Z',
    endsAt: '2021-04-12T23:45:27Z',
    daysOff: undefined,
    createdAt: '2021-07-04T20:36:37Z',
    deletedAt: '2021-06-26T05:03:11Z',
    additionalInfo: 'a nibh',
    skillId: 3,
    centreId: 30,
    profileId: 39,
    applicationIds: undefined,
  },
  {
    id: 393,
    vacancy: 2,
    hired: 1,
    startsAt: '2021-06-18T19:43:31Z',
    endsAt: '2021-10-27T05:43:31Z',
    daysOff: [6, 4],
    createdAt: '2021-02-08T02:19:51Z',
    deletedAt: '2020-08-02T09:20:43Z',
    additionalInfo: 'odio',
    skillId: 1,
    centreId: 8,
    profileId: 82,
    applicationIds: [16, 45, 43, 23, 19, 40],
  },
  {
    id: 394,
    vacancy: 5,
    hired: 0,
    startsAt: '2020-11-29T05:27:11Z',
    endsAt: '2022-12-02T11:27:11Z',
    daysOff: [1, 2],
    createdAt: '2021-07-19T20:51:30Z',
    deletedAt: '2020-12-23T17:58:32Z',
    additionalInfo: 'pulvinar sed',
    skillId: 2,
    centreId: 19,
    profileId: 74,
    applicationIds: [31, 4, 10, 7, 23, 21],
  },
  {
    id: 395,
    vacancy: 2,
    hired: 2,
    startsAt: '2021-05-12T14:24:41Z',
    endsAt: '2022-07-22T23:24:41Z',
    daysOff: [7, 7],
    createdAt: '2021-01-02T06:46:56Z',
    deletedAt: '2020-07-23T10:42:50Z',
    additionalInfo: 'ac tellus',
    skillId: 2,
    centreId: 19,
    profileId: 47,
    applicationIds: [41, 22, 7, 42, 27, 40],
  },
  {
    id: 396,
    vacancy: 1,
    hired: 1,
    startsAt: '2021-06-28T20:46:48Z',
    endsAt: '2021-12-09T01:46:48Z',
    daysOff: [10, 1],
    createdAt: '2021-01-02T06:20:26Z',
    deletedAt: undefined,
    additionalInfo: 'mattis nibh',
    skillId: 2,
    centreId: 6,
    profileId: 31,
    applicationIds: [16, 38, 39, 46, 37, 9],
  },
  {
    id: 397,
    vacancy: 5,
    hired: 5,
    startsAt: '2021-03-04T19:17:28Z',
    endsAt: '2021-06-24T01:17:28Z',
    daysOff: undefined,
    createdAt: '2021-07-05T10:59:18Z',
    deletedAt: undefined,
    additionalInfo: 'ultrices posuere',
    skillId: 3,
    centreId: 3,
    profileId: 27,
    applicationIds: undefined,
  },
  {
    id: 398,
    vacancy: 5,
    hired: 1,
    startsAt: '2020-07-26T18:41:05Z',
    endsAt: '2021-10-03T05:41:05Z',
    daysOff: undefined,
    createdAt: '2020-09-01T10:49:04Z',
    deletedAt: '2021-07-10T08:45:37Z',
    additionalInfo: 'quisque id',
    skillId: 1,
    centreId: 15,
    profileId: 16,
    applicationIds: undefined,
  },
  {
    id: 399,
    vacancy: 4,
    hired: 4,
    startsAt: '2020-08-11T03:49:28Z',
    endsAt: '2022-06-25T11:49:28Z',
    daysOff: undefined,
    createdAt: '2021-03-18T05:48:37Z',
    deletedAt: '2020-12-02T04:03:34Z',
    additionalInfo: 'sodales scelerisque',
    skillId: 1,
    centreId: 25,
    profileId: 52,
    applicationIds: undefined,
  },
  {
    id: 400,
    vacancy: 10,
    hired: 8,
    startsAt: '2021-04-15T07:17:47Z',
    endsAt: '2022-05-11T11:17:47Z',
    daysOff: undefined,
    createdAt: '2021-02-20T15:31:32Z',
    deletedAt: '2020-11-11T17:12:45Z',
    additionalInfo: 'elit',
    skillId: 3,
    centreId: 15,
    profileId: 78,
    applicationIds: undefined,
  },
  {
    id: 401,
    vacancy: 5,
    hired: 4,
    startsAt: '2021-03-11T18:55:12Z',
    endsAt: '2022-09-28T05:55:12Z',
    daysOff: undefined,
    createdAt: '2020-12-03T11:21:17Z',
    deletedAt: '2020-07-26T11:23:55Z',
    additionalInfo: 'vestibulum ac',
    skillId: 1,
    centreId: 4,
    profileId: 57,
    applicationIds: undefined,
  },
  {
    id: 402,
    vacancy: 4,
    hired: 0,
    startsAt: '2020-09-30T15:09:04Z',
    endsAt: '2022-01-30T03:09:04Z',
    daysOff: [5, 6],
    createdAt: '2021-07-07T10:56:22Z',
    deletedAt: '2021-06-25T08:44:33Z',
    additionalInfo: 'etiam justo',
    skillId: 1,
    centreId: 1,
    profileId: 70,
    applicationIds: [33, 12, 15, 8, 32, 9],
  },
  {
    id: 403,
    vacancy: 8,
    hired: 8,
    startsAt: '2020-12-26T16:35:04Z',
    endsAt: '2021-11-16T21:35:04Z',
    daysOff: undefined,
    createdAt: '2020-07-25T07:29:58Z',
    deletedAt: undefined,
    additionalInfo: 'vel nisl',
    skillId: 2,
    centreId: 8,
    profileId: 28,
    applicationIds: undefined,
  },
  {
    id: 404,
    vacancy: 1,
    hired: 0,
    startsAt: '2020-11-09T04:06:18Z',
    endsAt: '2022-03-05T11:06:18Z',
    daysOff: undefined,
    createdAt: '2020-07-29T04:27:09Z',
    deletedAt: undefined,
    additionalInfo: 'gravida sem',
    skillId: 2,
    centreId: 6,
    profileId: 83,
    applicationIds: undefined,
  },
  {
    id: 405,
    vacancy: 10,
    hired: 10,
    startsAt: '2020-10-20T00:15:31Z',
    endsAt: '2021-05-17T03:15:31Z',
    daysOff: undefined,
    createdAt: '2020-11-04T23:01:07Z',
    deletedAt: '2021-06-19T05:17:10Z',
    additionalInfo: 'ligula',
    skillId: 1,
    centreId: 46,
    profileId: 10,
    applicationIds: undefined,
  },
  {
    id: 406,
    vacancy: 3,
    hired: 2,
    startsAt: '2021-02-23T19:09:28Z',
    endsAt: '2022-06-26T06:09:28Z',
    daysOff: undefined,
    createdAt: '2021-06-24T14:39:11Z',
    deletedAt: '2021-03-12T08:21:13Z',
    additionalInfo: 'quisque porta',
    skillId: 1,
    centreId: 29,
    profileId: 5,
    applicationIds: undefined,
  },
  {
    id: 407,
    vacancy: 6,
    hired: 5,
    startsAt: '2021-01-20T07:04:44Z',
    endsAt: '2022-06-23T15:04:44Z',
    daysOff: undefined,
    createdAt: '2020-12-16T21:12:18Z',
    deletedAt: '2020-07-24T20:23:25Z',
    additionalInfo: 'maecenas rhoncus',
    skillId: 2,
    centreId: 3,
    profileId: 76,
    applicationIds: undefined,
  },
  {
    id: 408,
    vacancy: 10,
    hired: 9,
    startsAt: '2021-01-23T15:34:21Z',
    endsAt: '2021-07-16T23:34:21Z',
    daysOff: undefined,
    createdAt: '2021-05-13T03:23:37Z',
    deletedAt: '2021-01-24T20:23:53Z',
    additionalInfo: 'mattis nibh',
    skillId: 1,
    centreId: 48,
    profileId: 18,
    applicationIds: undefined,
  },
  {
    id: 409,
    vacancy: 6,
    hired: 4,
    startsAt: '2021-02-03T07:43:56Z',
    endsAt: '2022-02-28T14:43:56Z',
    daysOff: undefined,
    createdAt: '2021-05-01T15:47:25Z',
    deletedAt: '2020-09-09T14:02:00Z',
    additionalInfo: 'lectus',
    skillId: 3,
    centreId: 14,
    profileId: 3,
    applicationIds: undefined,
  },
  {
    id: 410,
    vacancy: 2,
    hired: 2,
    startsAt: '2020-12-03T11:57:04Z',
    endsAt: '2021-05-02T23:57:04Z',
    daysOff: [3, 7],
    createdAt: '2021-02-13T04:58:28Z',
    deletedAt: '2021-07-13T03:22:42Z',
    additionalInfo: 'convallis tortor',
    skillId: 2,
    centreId: 40,
    profileId: 43,
    applicationIds: [37, 3, 44, 11, 10, 50],
  },
  {
    id: 411,
    vacancy: 6,
    hired: 1,
    startsAt: '2020-09-05T03:23:09Z',
    endsAt: '2022-10-03T13:23:09Z',
    daysOff: undefined,
    createdAt: '2021-07-13T01:49:06Z',
    deletedAt: '2020-12-01T02:00:21Z',
    additionalInfo: 'undefineda quisque',
    skillId: 1,
    centreId: 26,
    profileId: 90,
    applicationIds: undefined,
  },
  {
    id: 412,
    vacancy: 3,
    hired: 2,
    startsAt: '2021-03-29T16:27:48Z',
    endsAt: '2021-11-08T23:27:48Z',
    daysOff: [5, 1],
    createdAt: '2021-04-29T15:15:15Z',
    deletedAt: '2021-05-12T13:06:43Z',
    additionalInfo: 'vestibulum',
    skillId: 3,
    centreId: 19,
    profileId: 23,
    applicationIds: [21, 1, 12, 42, 38, 24],
  },
  {
    id: 413,
    vacancy: 10,
    hired: 1,
    startsAt: '2020-09-24T01:36:54Z',
    endsAt: '2022-06-06T05:36:54Z',
    daysOff: undefined,
    createdAt: '2021-07-02T15:07:52Z',
    deletedAt: '2020-09-27T07:59:08Z',
    additionalInfo: 'ipsum',
    skillId: 2,
    centreId: 39,
    profileId: 19,
    applicationIds: undefined,
  },
  {
    id: 414,
    vacancy: 9,
    hired: 6,
    startsAt: '2021-02-08T10:32:40Z',
    endsAt: '2022-04-09T21:32:40Z',
    daysOff: [10, 3],
    createdAt: '2020-09-14T12:36:21Z',
    deletedAt: '2021-06-18T11:22:41Z',
    additionalInfo: 'suscipit ligula',
    skillId: 2,
    centreId: 27,
    profileId: 47,
    applicationIds: [41, 28, 17, 42, 3, 45],
  },
  {
    id: 415,
    vacancy: 6,
    hired: 0,
    startsAt: '2021-07-21T10:34:58Z',
    endsAt: '2022-07-08T22:34:58Z',
    daysOff: undefined,
    createdAt: '2021-05-06T18:32:20Z',
    deletedAt: '2021-04-06T00:47:23Z',
    additionalInfo: 'lacus',
    skillId: 1,
    centreId: 26,
    profileId: 9,
    applicationIds: undefined,
  },
  {
    id: 416,
    vacancy: 1,
    hired: 1,
    startsAt: '2021-07-15T13:17:55Z',
    endsAt: '2023-05-05T00:17:55Z',
    daysOff: undefined,
    createdAt: '2021-01-05T19:12:17Z',
    deletedAt: '2020-10-26T11:35:00Z',
    additionalInfo: 'parturient',
    skillId: 1,
    centreId: 31,
    profileId: 13,
    applicationIds: undefined,
  },
  {
    id: 417,
    vacancy: 2,
    hired: 0,
    startsAt: '2021-06-07T19:42:13Z',
    endsAt: '2023-05-07T04:42:13Z',
    daysOff: undefined,
    createdAt: '2020-08-17T02:23:42Z',
    deletedAt: '2021-03-17T13:10:30Z',
    additionalInfo: 'metus sapien',
    skillId: 1,
    centreId: 6,
    profileId: 84,
    applicationIds: undefined,
  },
  {
    id: 418,
    vacancy: 10,
    hired: 7,
    startsAt: '2020-08-02T15:58:29Z',
    endsAt: '2022-01-03T19:58:29Z',
    daysOff: [6, 1],
    createdAt: '2021-07-12T21:41:19Z',
    deletedAt: '2021-04-14T00:32:05Z',
    additionalInfo: 'vel',
    skillId: 1,
    centreId: 23,
    profileId: 90,
    applicationIds: [40, 37, 40, 32, 47, 50],
  },
  {
    id: 419,
    vacancy: 9,
    hired: 1,
    startsAt: '2020-10-02T03:36:43Z',
    endsAt: '2022-03-03T12:36:43Z',
    daysOff: undefined,
    createdAt: '2021-07-12T03:20:41Z',
    deletedAt: '2021-03-18T09:32:37Z',
    additionalInfo: 'cubilia curae',
    skillId: 2,
    centreId: 18,
    profileId: 76,
    applicationIds: undefined,
  },
  {
    id: 420,
    vacancy: 1,
    hired: 1,
    startsAt: '2021-06-22T06:29:42Z',
    endsAt: '2022-05-06T18:29:42Z',
    daysOff: undefined,
    createdAt: '2021-04-22T03:10:28Z',
    deletedAt: undefined,
    additionalInfo: 'platea',
    skillId: 3,
    centreId: 32,
    profileId: 94,
    applicationIds: undefined,
  },
  {
    id: 421,
    vacancy: 6,
    hired: 3,
    startsAt: '2021-04-24T01:55:01Z',
    endsAt: '2022-12-25T04:55:01Z',
    daysOff: [6, 4],
    createdAt: '2020-10-15T08:41:19Z',
    deletedAt: undefined,
    additionalInfo: 'vestibulum sagittis',
    skillId: 2,
    centreId: 5,
    profileId: 39,
    applicationIds: [22, 26, 1, 3, 40, 12],
  },
  {
    id: 422,
    vacancy: 8,
    hired: 4,
    startsAt: '2021-02-03T05:19:26Z',
    endsAt: '2021-03-13T09:19:26Z',
    daysOff: undefined,
    createdAt: '2021-05-20T00:46:14Z',
    deletedAt: '2020-09-15T12:52:16Z',
    additionalInfo: 'eget',
    skillId: 1,
    centreId: 3,
    profileId: 75,
    applicationIds: undefined,
  },
  {
    id: 423,
    vacancy: 5,
    hired: 0,
    startsAt: '2021-07-05T05:21:33Z',
    endsAt: '2022-08-26T16:21:33Z',
    daysOff: undefined,
    createdAt: '2020-08-29T00:55:54Z',
    deletedAt: '2021-01-21T16:20:17Z',
    additionalInfo: 'justo eu',
    skillId: 2,
    centreId: 28,
    profileId: 53,
    applicationIds: undefined,
  },
  {
    id: 424,
    vacancy: 2,
    hired: 1,
    startsAt: '2020-08-03T08:43:30Z',
    endsAt: '2022-06-07T18:43:30Z',
    daysOff: undefined,
    createdAt: '2020-10-16T15:38:14Z',
    deletedAt: '2020-09-17T07:14:56Z',
    additionalInfo: 'in consequat',
    skillId: 3,
    centreId: 17,
    profileId: 53,
    applicationIds: undefined,
  },
  {
    id: 425,
    vacancy: 3,
    hired: 1,
    startsAt: '2020-08-18T23:34:57Z',
    endsAt: '2021-11-12T04:34:57Z',
    daysOff: undefined,
    createdAt: '2021-02-13T20:47:54Z',
    deletedAt: '2021-02-18T20:51:36Z',
    additionalInfo: 'in quis',
    skillId: 1,
    centreId: 44,
    profileId: 14,
    applicationIds: undefined,
  },
  {
    id: 426,
    vacancy: 5,
    hired: 2,
    startsAt: '2021-06-11T15:05:44Z',
    endsAt: '2023-06-23T02:05:44Z',
    daysOff: undefined,
    createdAt: '2021-03-10T06:30:48Z',
    deletedAt: '2021-04-10T02:03:49Z',
    additionalInfo: 'quis',
    skillId: 1,
    centreId: 1,
    profileId: 72,
    applicationIds: undefined,
  },
  {
    id: 427,
    vacancy: 2,
    hired: 2,
    startsAt: '2020-11-27T06:44:08Z',
    endsAt: '2022-07-15T17:44:08Z',
    daysOff: [6, 9],
    createdAt: '2021-04-09T19:16:55Z',
    deletedAt: '2020-11-11T03:46:44Z',
    additionalInfo: 'auctor',
    skillId: 2,
    centreId: 23,
    profileId: 59,
    applicationIds: [46, 13, 43, 5, 7, 43],
  },
  {
    id: 428,
    vacancy: 4,
    hired: 3,
    startsAt: '2021-03-11T17:07:32Z',
    endsAt: '2022-10-10T20:07:32Z',
    daysOff: undefined,
    createdAt: '2021-07-18T09:21:22Z',
    deletedAt: '2020-12-17T14:49:08Z',
    additionalInfo: 'arcu libero',
    skillId: 1,
    centreId: 9,
    profileId: 1,
    applicationIds: undefined,
  },
  {
    id: 429,
    vacancy: 3,
    hired: 1,
    startsAt: '2020-09-29T10:15:32Z',
    endsAt: '2021-02-04T13:15:32Z',
    daysOff: [6, 10],
    createdAt: '2020-09-24T16:13:56Z',
    deletedAt: '2021-05-24T12:36:39Z',
    additionalInfo: 'primis in',
    skillId: 3,
    centreId: 9,
    profileId: 46,
    applicationIds: [28, 48, 40, 31, 43, 31],
  },
  {
    id: 430,
    vacancy: 5,
    hired: 2,
    startsAt: '2020-09-27T17:28:29Z',
    endsAt: '2021-01-14T22:28:29Z',
    daysOff: undefined,
    createdAt: '2021-02-15T06:32:58Z',
    deletedAt: '2020-10-25T04:28:38Z',
    additionalInfo: 'nec',
    skillId: 2,
    centreId: 26,
    profileId: 81,
    applicationIds: undefined,
  },
  {
    id: 431,
    vacancy: 10,
    hired: 6,
    startsAt: '2021-04-25T04:59:36Z',
    endsAt: '2023-01-18T08:59:36Z',
    daysOff: [5, 9],
    createdAt: '2021-05-16T22:41:50Z',
    deletedAt: '2020-08-28T05:14:46Z',
    additionalInfo: 'luctus ultricies',
    skillId: 1,
    centreId: 10,
    profileId: 49,
    applicationIds: [40, 8, 35, 21, 5, 30],
  },
  {
    id: 432,
    vacancy: 4,
    hired: 2,
    startsAt: '2020-09-01T23:44:49Z',
    endsAt: '2021-08-02T02:44:49Z',
    daysOff: undefined,
    createdAt: '2020-08-15T11:37:21Z',
    deletedAt: '2021-03-26T18:38:11Z',
    additionalInfo: 'non velit',
    skillId: 1,
    centreId: 19,
    profileId: 26,
    applicationIds: undefined,
  },
  {
    id: 433,
    vacancy: 1,
    hired: 0,
    startsAt: '2021-04-23T20:24:21Z',
    endsAt: '2022-06-26T07:24:21Z',
    daysOff: undefined,
    createdAt: '2021-07-02T12:17:43Z',
    deletedAt: '2021-06-08T21:01:00Z',
    additionalInfo: 'phasellus',
    skillId: 3,
    centreId: 31,
    profileId: 20,
    applicationIds: undefined,
  },
  {
    id: 434,
    vacancy: 6,
    hired: 2,
    startsAt: '2021-04-03T15:53:31Z',
    endsAt: '2022-03-16T03:53:31Z',
    daysOff: undefined,
    createdAt: '2021-03-10T02:41:54Z',
    deletedAt: '2021-05-10T10:31:21Z',
    additionalInfo: 'at turpis',
    skillId: 3,
    centreId: 25,
    profileId: 84,
    applicationIds: undefined,
  },
  {
    id: 435,
    vacancy: 8,
    hired: 0,
    startsAt: '2021-05-03T21:08:43Z',
    endsAt: '2021-10-08T09:08:43Z',
    daysOff: undefined,
    createdAt: '2020-08-20T05:25:36Z',
    deletedAt: '2021-05-26T00:35:05Z',
    additionalInfo: 'interdum mauris',
    skillId: 3,
    centreId: 50,
    profileId: 54,
    applicationIds: undefined,
  },
  {
    id: 436,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-12-29T10:08:06Z',
    endsAt: '2021-11-16T21:08:06Z',
    daysOff: [10, 8],
    createdAt: '2020-12-01T11:18:33Z',
    deletedAt: '2021-05-30T02:36:19Z',
    additionalInfo: 'phasellus id',
    skillId: 3,
    centreId: 4,
    profileId: 98,
    applicationIds: [25, 38, 29, 10, 30, 22],
  },
  {
    id: 437,
    vacancy: 9,
    hired: 9,
    startsAt: '2021-03-16T13:14:13Z',
    endsAt: '2022-03-10T23:14:13Z',
    daysOff: [5, 10],
    createdAt: '2021-01-11T11:26:13Z',
    deletedAt: undefined,
    additionalInfo: 'ipsum',
    skillId: 1,
    centreId: 3,
    profileId: 61,
    applicationIds: [23, 25, 7, 36, 16, 1],
  },
  {
    id: 438,
    vacancy: 9,
    hired: 5,
    startsAt: '2020-12-24T05:39:22Z',
    endsAt: '2022-07-22T17:39:22Z',
    daysOff: undefined,
    createdAt: '2021-06-29T03:13:42Z',
    deletedAt: undefined,
    additionalInfo: 'maecenas',
    skillId: 2,
    centreId: 25,
    profileId: 43,
    applicationIds: undefined,
  },
  {
    id: 439,
    vacancy: 3,
    hired: 2,
    startsAt: '2021-07-05T09:26:52Z',
    endsAt: '2022-09-29T13:26:52Z',
    daysOff: undefined,
    createdAt: '2020-11-23T06:21:14Z',
    deletedAt: '2021-02-28T16:38:37Z',
    additionalInfo: 'dolor sit',
    skillId: 1,
    centreId: 17,
    profileId: 89,
    applicationIds: undefined,
  },
  {
    id: 440,
    vacancy: 2,
    hired: 2,
    startsAt: '2020-11-03T17:58:37Z',
    endsAt: '2021-08-05T21:58:37Z',
    daysOff: undefined,
    createdAt: '2021-05-03T09:56:49Z',
    deletedAt: '2021-06-08T03:15:23Z',
    additionalInfo: 'porttitor',
    skillId: 2,
    centreId: 49,
    profileId: 44,
    applicationIds: undefined,
  },
  {
    id: 441,
    vacancy: 8,
    hired: 1,
    startsAt: '2020-08-12T10:16:48Z',
    endsAt: '2022-05-05T16:16:48Z',
    daysOff: undefined,
    createdAt: '2021-03-14T13:47:26Z',
    deletedAt: '2020-08-09T21:35:19Z',
    additionalInfo: 'undefineda',
    skillId: 3,
    centreId: 19,
    profileId: 36,
    applicationIds: undefined,
  },
  {
    id: 442,
    vacancy: 2,
    hired: 2,
    startsAt: '2021-04-30T08:08:18Z',
    endsAt: '2023-03-23T15:08:18Z',
    daysOff: [3, 5],
    createdAt: '2020-07-25T09:41:02Z',
    deletedAt: '2020-12-28T19:35:13Z',
    additionalInfo: 'tincidunt',
    skillId: 3,
    centreId: 27,
    profileId: 72,
    applicationIds: [42, 35, 32, 50, 46, 42],
  },
  {
    id: 443,
    vacancy: 3,
    hired: 3,
    startsAt: '2020-10-22T16:15:26Z',
    endsAt: '2021-11-16T01:15:26Z',
    daysOff: undefined,
    createdAt: '2020-09-02T18:05:32Z',
    deletedAt: '2021-03-13T17:19:51Z',
    additionalInfo: 'undefineda eget',
    skillId: 3,
    centreId: 33,
    profileId: 5,
    applicationIds: undefined,
  },
  {
    id: 444,
    vacancy: 5,
    hired: 4,
    startsAt: '2020-11-08T05:39:35Z',
    endsAt: '2022-12-04T12:39:35Z',
    daysOff: undefined,
    createdAt: '2020-10-21T02:59:33Z',
    deletedAt: '2021-07-09T23:07:47Z',
    additionalInfo: 'eu',
    skillId: 3,
    centreId: 2,
    profileId: 74,
    applicationIds: undefined,
  },
  {
    id: 445,
    vacancy: 7,
    hired: 0,
    startsAt: '2020-11-16T10:56:31Z',
    endsAt: '2022-09-10T20:56:31Z',
    daysOff: undefined,
    createdAt: '2020-09-28T15:18:26Z',
    deletedAt: '2020-12-23T06:39:15Z',
    additionalInfo: 'augue',
    skillId: 2,
    centreId: 50,
    profileId: 18,
    applicationIds: undefined,
  },
  {
    id: 446,
    vacancy: 1,
    hired: 0,
    startsAt: '2020-11-19T04:11:44Z',
    endsAt: '2021-11-18T12:11:44Z',
    daysOff: undefined,
    createdAt: '2021-01-17T02:39:40Z',
    deletedAt: '2020-09-28T19:31:00Z',
    additionalInfo: 'mus vivamus',
    skillId: 2,
    centreId: 46,
    profileId: 72,
    applicationIds: undefined,
  },
  {
    id: 447,
    vacancy: 10,
    hired: 3,
    startsAt: '2020-07-30T11:15:40Z',
    endsAt: '2020-09-24T14:15:40Z',
    daysOff: undefined,
    createdAt: '2021-01-22T19:25:01Z',
    deletedAt: undefined,
    additionalInfo: 'auctor gravida',
    skillId: 1,
    centreId: 35,
    profileId: 95,
    applicationIds: undefined,
  },
  {
    id: 448,
    vacancy: 2,
    hired: 0,
    startsAt: '2020-10-04T13:50:27Z',
    endsAt: '2022-02-08T18:50:27Z',
    daysOff: undefined,
    createdAt: '2021-05-18T00:30:19Z',
    deletedAt: '2020-12-01T10:15:02Z',
    additionalInfo: 'ac',
    skillId: 3,
    centreId: 17,
    profileId: 75,
    applicationIds: undefined,
  },
  {
    id: 449,
    vacancy: 5,
    hired: 3,
    startsAt: '2021-06-30T22:34:56Z',
    endsAt: '2022-03-31T06:34:56Z',
    daysOff: undefined,
    createdAt: '2021-03-09T00:59:01Z',
    deletedAt: '2021-01-25T16:30:43Z',
    additionalInfo: 'dictumst',
    skillId: 1,
    centreId: 8,
    profileId: 67,
    applicationIds: undefined,
  },
  {
    id: 450,
    vacancy: 7,
    hired: 0,
    startsAt: '2020-11-14T03:23:24Z',
    endsAt: '2022-11-05T06:23:24Z',
    daysOff: undefined,
    createdAt: '2021-05-02T04:43:07Z',
    deletedAt: '2020-09-24T00:26:39Z',
    additionalInfo: 'congue',
    skillId: 2,
    centreId: 36,
    profileId: 12,
    applicationIds: undefined,
  },
  {
    id: 451,
    vacancy: 7,
    hired: 1,
    startsAt: '2021-02-17T15:31:41Z',
    endsAt: '2021-05-20T02:31:41Z',
    daysOff: undefined,
    createdAt: '2020-08-17T13:46:34Z',
    deletedAt: '2021-03-27T10:03:28Z',
    additionalInfo: 'et',
    skillId: 2,
    centreId: 14,
    profileId: 55,
    applicationIds: undefined,
  },
  {
    id: 452,
    vacancy: 4,
    hired: 4,
    startsAt: '2021-03-07T12:15:23Z',
    endsAt: '2021-12-04T18:15:23Z',
    daysOff: [10, 1],
    createdAt: '2020-09-19T20:00:49Z',
    deletedAt: undefined,
    additionalInfo: 'a pede',
    skillId: 2,
    centreId: 31,
    profileId: 54,
    applicationIds: [38, 42, 19, 22, 20, 12],
  },
  {
    id: 453,
    vacancy: 8,
    hired: 8,
    startsAt: '2020-08-27T23:58:37Z',
    endsAt: '2022-01-27T04:58:37Z',
    daysOff: [9, 5],
    createdAt: '2020-10-23T12:30:36Z',
    deletedAt: '2021-05-08T20:14:22Z',
    additionalInfo: 'neque libero',
    skillId: 1,
    centreId: 5,
    profileId: 59,
    applicationIds: [10, 35, 49, 49, 1, 5],
  },
  {
    id: 454,
    vacancy: 7,
    hired: 0,
    startsAt: '2020-12-19T01:01:44Z',
    endsAt: '2021-03-13T08:01:44Z',
    daysOff: undefined,
    createdAt: '2021-05-02T19:32:27Z',
    deletedAt: undefined,
    additionalInfo: 'integer tincidunt',
    skillId: 1,
    centreId: 41,
    profileId: 80,
    applicationIds: undefined,
  },
  {
    id: 455,
    vacancy: 7,
    hired: 3,
    startsAt: '2020-08-21T07:34:52Z',
    endsAt: '2020-10-10T14:34:52Z',
    daysOff: undefined,
    createdAt: '2021-05-23T23:00:23Z',
    deletedAt: undefined,
    additionalInfo: 'turpis a',
    skillId: 1,
    centreId: 6,
    profileId: 97,
    applicationIds: undefined,
  },
  {
    id: 456,
    vacancy: 7,
    hired: 6,
    startsAt: '2020-07-23T19:28:17Z',
    endsAt: '2021-01-16T23:28:17Z',
    daysOff: [8, 6],
    createdAt: '2020-12-30T15:10:33Z',
    deletedAt: '2021-03-04T11:48:58Z',
    additionalInfo: 'sapien',
    skillId: 2,
    centreId: 7,
    profileId: 92,
    applicationIds: [13, 22, 30, 42, 16, 30],
  },
  {
    id: 457,
    vacancy: 5,
    hired: 2,
    startsAt: '2021-05-04T09:56:19Z',
    endsAt: '2021-10-24T12:56:19Z',
    daysOff: [3, 10],
    createdAt: '2021-05-08T06:18:36Z',
    deletedAt: '2021-02-10T01:33:00Z',
    additionalInfo: 'massa',
    skillId: 3,
    centreId: 24,
    profileId: 52,
    applicationIds: [13, 44, 10, 45, 32, 34],
  },
  {
    id: 458,
    vacancy: 5,
    hired: 4,
    startsAt: '2021-01-07T14:41:28Z',
    endsAt: '2022-09-27T22:41:28Z',
    daysOff: [4, 6],
    createdAt: '2020-12-03T12:56:57Z',
    deletedAt: undefined,
    additionalInfo: 'ac',
    skillId: 2,
    centreId: 20,
    profileId: 78,
    applicationIds: [44, 43, 12, 44, 7, 28],
  },
  {
    id: 459,
    vacancy: 10,
    hired: 3,
    startsAt: '2020-09-23T20:03:46Z',
    endsAt: '2021-07-08T08:03:46Z',
    daysOff: undefined,
    createdAt: '2020-11-03T15:15:44Z',
    deletedAt: undefined,
    additionalInfo: 'augue aliquam',
    skillId: 1,
    centreId: 48,
    profileId: 15,
    applicationIds: undefined,
  },
  {
    id: 460,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-12-28T06:47:21Z',
    endsAt: '2022-01-07T17:47:21Z',
    daysOff: undefined,
    createdAt: '2021-06-17T15:34:17Z',
    deletedAt: undefined,
    additionalInfo: 'congue',
    skillId: 2,
    centreId: 15,
    profileId: 56,
    applicationIds: undefined,
  },
  {
    id: 461,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-11-22T01:24:16Z',
    endsAt: '2021-09-10T04:24:16Z',
    daysOff: [6, 1],
    createdAt: '2021-02-17T21:22:10Z',
    deletedAt: '2020-12-19T12:06:51Z',
    additionalInfo: 'a',
    skillId: 3,
    centreId: 30,
    profileId: 21,
    applicationIds: [43, 38, 25, 16, 33, 30],
  },
  {
    id: 462,
    vacancy: 10,
    hired: 5,
    startsAt: '2021-04-12T09:03:48Z',
    endsAt: '2022-11-06T17:03:48Z',
    daysOff: [1, 4],
    createdAt: '2021-01-19T07:45:19Z',
    deletedAt: '2021-07-17T11:00:43Z',
    additionalInfo: 'platea dictumst',
    skillId: 2,
    centreId: 11,
    profileId: 55,
    applicationIds: [26, 48, 28, 27, 6, 38],
  },
  {
    id: 463,
    vacancy: 3,
    hired: 0,
    startsAt: '2021-07-15T11:38:45Z',
    endsAt: '2022-08-14T22:38:45Z',
    daysOff: undefined,
    createdAt: '2021-03-16T12:27:28Z',
    deletedAt: '2020-11-06T11:51:06Z',
    additionalInfo: 'rutrum',
    skillId: 2,
    centreId: 6,
    profileId: 59,
    applicationIds: undefined,
  },
  {
    id: 464,
    vacancy: 6,
    hired: 5,
    startsAt: '2020-10-29T10:26:38Z',
    endsAt: '2022-06-30T16:26:38Z',
    daysOff: [3, 4],
    createdAt: '2021-05-11T19:03:34Z',
    deletedAt: '2021-07-12T01:44:37Z',
    additionalInfo: 'cras in',
    skillId: 3,
    centreId: 6,
    profileId: 8,
    applicationIds: [23, 3, 41, 23, 36, 5],
  },
  {
    id: 465,
    vacancy: 3,
    hired: 3,
    startsAt: '2020-08-29T17:08:15Z',
    endsAt: '2022-04-15T02:08:15Z',
    daysOff: undefined,
    createdAt: '2021-06-01T04:54:41Z',
    deletedAt: '2020-11-01T11:02:39Z',
    additionalInfo: 'vestibulum',
    skillId: 3,
    centreId: 45,
    profileId: 66,
    applicationIds: undefined,
  },
  {
    id: 466,
    vacancy: 9,
    hired: 3,
    startsAt: '2020-12-06T15:04:04Z',
    endsAt: '2021-12-07T20:04:04Z',
    daysOff: undefined,
    createdAt: '2021-02-17T16:44:49Z',
    deletedAt: '2020-10-04T09:33:43Z',
    additionalInfo: 'nam',
    skillId: 1,
    centreId: 4,
    profileId: 2,
    applicationIds: undefined,
  },
  {
    id: 467,
    vacancy: 3,
    hired: 3,
    startsAt: '2021-06-29T09:21:39Z',
    endsAt: '2022-08-20T17:21:39Z',
    daysOff: undefined,
    createdAt: '2021-04-08T14:51:23Z',
    deletedAt: '2020-12-26T00:47:21Z',
    additionalInfo: 'lorem',
    skillId: 2,
    centreId: 13,
    profileId: 57,
    applicationIds: undefined,
  },
  {
    id: 468,
    vacancy: 9,
    hired: 6,
    startsAt: '2020-12-13T10:30:30Z',
    endsAt: '2021-03-26T16:30:30Z',
    daysOff: [7, 1],
    createdAt: '2021-03-07T16:33:59Z',
    deletedAt: '2020-09-28T17:20:21Z',
    additionalInfo: 'vitae quam',
    skillId: 1,
    centreId: 35,
    profileId: 71,
    applicationIds: [47, 37, 6, 44, 3, 24],
  },
  {
    id: 469,
    vacancy: 10,
    hired: 9,
    startsAt: '2021-07-01T00:47:18Z',
    endsAt: '2022-11-18T07:47:18Z',
    daysOff: [3, 5],
    createdAt: '2020-08-12T09:57:57Z',
    deletedAt: '2021-04-16T10:21:25Z',
    additionalInfo: 'proin',
    skillId: 3,
    centreId: 24,
    profileId: 46,
    applicationIds: [43, 13, 29, 12, 10, 40],
  },
  {
    id: 470,
    vacancy: 4,
    hired: 2,
    startsAt: '2021-06-08T23:26:12Z',
    endsAt: '2022-08-23T08:26:12Z',
    daysOff: undefined,
    createdAt: '2021-04-03T20:07:02Z',
    deletedAt: '2020-11-19T00:47:55Z',
    additionalInfo: 'platea',
    skillId: 1,
    centreId: 22,
    profileId: 98,
    applicationIds: undefined,
  },
  {
    id: 471,
    vacancy: 2,
    hired: 1,
    startsAt: '2021-06-18T13:50:29Z',
    endsAt: '2021-09-30T18:50:29Z',
    daysOff: undefined,
    createdAt: '2020-11-27T03:53:21Z',
    deletedAt: '2021-02-28T02:14:59Z',
    additionalInfo: 'orci',
    skillId: 2,
    centreId: 28,
    profileId: 75,
    applicationIds: undefined,
  },
  {
    id: 472,
    vacancy: 6,
    hired: 0,
    startsAt: '2021-04-28T11:08:58Z',
    endsAt: '2022-12-22T22:08:58Z',
    daysOff: undefined,
    createdAt: '2021-04-19T00:39:49Z',
    deletedAt: undefined,
    additionalInfo: 'adipiscing elit',
    skillId: 2,
    centreId: 23,
    profileId: 3,
    applicationIds: undefined,
  },
  {
    id: 473,
    vacancy: 7,
    hired: 7,
    startsAt: '2021-01-04T03:08:51Z',
    endsAt: '2021-06-08T11:08:51Z',
    daysOff: undefined,
    createdAt: '2020-11-26T14:55:17Z',
    deletedAt: '2021-01-07T13:23:33Z',
    additionalInfo: 'sit',
    skillId: 1,
    centreId: 32,
    profileId: 34,
    applicationIds: undefined,
  },
  {
    id: 474,
    vacancy: 8,
    hired: 4,
    startsAt: '2021-03-14T02:15:31Z',
    endsAt: '2022-09-28T08:15:31Z',
    daysOff: undefined,
    createdAt: '2021-06-03T15:47:51Z',
    deletedAt: '2021-06-13T11:53:09Z',
    additionalInfo: 'faucibus orci',
    skillId: 2,
    centreId: 49,
    profileId: 18,
    applicationIds: undefined,
  },
  {
    id: 475,
    vacancy: 5,
    hired: 3,
    startsAt: '2020-10-07T12:28:27Z',
    endsAt: '2022-08-01T16:28:27Z',
    daysOff: undefined,
    createdAt: '2021-06-09T00:23:54Z',
    deletedAt: '2020-11-25T12:48:51Z',
    additionalInfo: 'quisque erat',
    skillId: 2,
    centreId: 34,
    profileId: 66,
    applicationIds: undefined,
  },
  {
    id: 476,
    vacancy: 9,
    hired: 0,
    startsAt: '2021-02-26T21:44:58Z',
    endsAt: '2022-04-24T03:44:58Z',
    daysOff: [7, 4],
    createdAt: '2020-11-11T09:35:17Z',
    deletedAt: '2021-05-31T19:58:01Z',
    additionalInfo: 'convallis',
    skillId: 2,
    centreId: 4,
    profileId: 9,
    applicationIds: [39, 49, 36, 36, 32, 6],
  },
  {
    id: 477,
    vacancy: 8,
    hired: 6,
    startsAt: '2021-05-12T20:16:29Z',
    endsAt: '2022-09-27T08:16:29Z',
    daysOff: [8, 10],
    createdAt: '2021-02-26T03:29:17Z',
    deletedAt: undefined,
    additionalInfo: 'ante',
    skillId: 1,
    centreId: 7,
    profileId: 59,
    applicationIds: [29, 18, 21, 7, 34, 2],
  },
  {
    id: 478,
    vacancy: 6,
    hired: 4,
    startsAt: '2020-09-28T06:28:36Z',
    endsAt: '2022-05-27T12:28:36Z',
    daysOff: [1, 3],
    createdAt: '2021-06-08T15:20:22Z',
    deletedAt: '2020-12-03T13:00:09Z',
    additionalInfo: 'lectus vestibulum',
    skillId: 3,
    centreId: 6,
    profileId: 96,
    applicationIds: [47, 26, 42, 25, 50, 50],
  },
  {
    id: 479,
    vacancy: 9,
    hired: 4,
    startsAt: '2021-01-16T21:31:26Z',
    endsAt: '2022-12-21T05:31:26Z',
    daysOff: undefined,
    createdAt: '2021-02-23T05:48:13Z',
    deletedAt: '2021-05-24T13:36:04Z',
    additionalInfo: 'sociis natoque',
    skillId: 2,
    centreId: 10,
    profileId: 42,
    applicationIds: undefined,
  },
  {
    id: 480,
    vacancy: 9,
    hired: 5,
    startsAt: '2020-08-11T04:30:16Z',
    endsAt: '2021-08-21T07:30:16Z',
    daysOff: [10, 8],
    createdAt: '2021-06-21T07:30:03Z',
    deletedAt: '2020-11-06T17:47:23Z',
    additionalInfo: 'odio',
    skillId: 2,
    centreId: 4,
    profileId: 81,
    applicationIds: [11, 31, 25, 20, 21, 7],
  },
  {
    id: 481,
    vacancy: 10,
    hired: 2,
    startsAt: '2020-11-30T09:00:52Z',
    endsAt: '2022-12-17T21:00:52Z',
    daysOff: undefined,
    createdAt: '2021-06-27T10:28:45Z',
    deletedAt: undefined,
    additionalInfo: 'eget',
    skillId: 3,
    centreId: 24,
    profileId: 73,
    applicationIds: undefined,
  },
  {
    id: 482,
    vacancy: 6,
    hired: 4,
    startsAt: '2020-10-25T23:00:36Z',
    endsAt: '2022-07-25T03:00:36Z',
    daysOff: undefined,
    createdAt: '2021-01-28T20:15:38Z',
    deletedAt: '2021-05-27T11:30:19Z',
    additionalInfo: 'undefinedam sit',
    skillId: 1,
    centreId: 26,
    profileId: 41,
    applicationIds: undefined,
  },
  {
    id: 483,
    vacancy: 3,
    hired: 1,
    startsAt: '2021-03-07T06:27:18Z',
    endsAt: '2022-05-11T14:27:18Z',
    daysOff: undefined,
    createdAt: '2021-04-21T20:45:58Z',
    deletedAt: '2020-11-29T11:06:42Z',
    additionalInfo: 'hac',
    skillId: 1,
    centreId: 9,
    profileId: 35,
    applicationIds: undefined,
  },
  {
    id: 484,
    vacancy: 3,
    hired: 2,
    startsAt: '2021-01-22T06:05:08Z',
    endsAt: '2021-03-19T18:05:08Z',
    daysOff: undefined,
    createdAt: '2020-11-22T16:30:09Z',
    deletedAt: '2020-12-16T19:15:48Z',
    additionalInfo: 'odio',
    skillId: 2,
    centreId: 4,
    profileId: 2,
    applicationIds: undefined,
  },
  {
    id: 485,
    vacancy: 5,
    hired: 1,
    startsAt: '2020-10-08T13:03:49Z',
    endsAt: '2022-10-27T01:03:49Z',
    daysOff: undefined,
    createdAt: '2020-08-04T04:10:14Z',
    deletedAt: '2021-04-03T09:32:11Z',
    additionalInfo: 'ipsum',
    skillId: 3,
    centreId: 48,
    profileId: 81,
    applicationIds: undefined,
  },
  {
    id: 486,
    vacancy: 5,
    hired: 2,
    startsAt: '2020-09-04T01:16:31Z',
    endsAt: '2021-08-03T13:16:31Z',
    daysOff: [8, 2],
    createdAt: '2020-07-30T19:26:00Z',
    deletedAt: undefined,
    additionalInfo: 'undefineda',
    skillId: 2,
    centreId: 22,
    profileId: 17,
    applicationIds: [8, 27, 25, 37, 14, 31],
  },
  {
    id: 487,
    vacancy: 1,
    hired: 0,
    startsAt: '2020-09-19T09:43:06Z',
    endsAt: '2021-05-09T14:43:06Z',
    daysOff: undefined,
    createdAt: '2021-05-23T19:38:38Z',
    deletedAt: '2021-05-19T15:20:15Z',
    additionalInfo: 'tempor',
    skillId: 2,
    centreId: 30,
    profileId: 100,
    applicationIds: undefined,
  },
  {
    id: 488,
    vacancy: 3,
    hired: 2,
    startsAt: '2020-07-27T22:30:02Z',
    endsAt: '2022-04-30T07:30:02Z',
    daysOff: [1, 1],
    createdAt: '2020-11-28T10:38:20Z',
    deletedAt: '2021-01-06T14:55:47Z',
    additionalInfo: 'ornare',
    skillId: 3,
    centreId: 40,
    profileId: 61,
    applicationIds: [47, 45, 48, 38, 4, 34],
  },
  {
    id: 489,
    vacancy: 8,
    hired: 3,
    startsAt: '2020-11-26T18:04:07Z',
    endsAt: '2022-11-29T23:04:07Z',
    daysOff: undefined,
    createdAt: '2021-03-11T15:30:56Z',
    deletedAt: undefined,
    additionalInfo: 'elit proin',
    skillId: 3,
    centreId: 41,
    profileId: 97,
    applicationIds: undefined,
  },
  {
    id: 490,
    vacancy: 2,
    hired: 0,
    startsAt: '2020-09-12T02:31:34Z',
    endsAt: '2021-06-24T13:31:34Z',
    daysOff: undefined,
    createdAt: '2020-09-19T12:47:17Z',
    deletedAt: '2020-10-10T18:48:54Z',
    additionalInfo: 'libero non',
    skillId: 2,
    centreId: 34,
    profileId: 97,
    applicationIds: undefined,
  },
  {
    id: 491,
    vacancy: 10,
    hired: 3,
    startsAt: '2021-02-25T19:21:22Z',
    endsAt: '2021-08-13T02:21:22Z',
    daysOff: undefined,
    createdAt: '2020-07-24T22:31:46Z',
    deletedAt: '2021-06-22T23:15:49Z',
    additionalInfo: 'libero',
    skillId: 1,
    centreId: 14,
    profileId: 58,
    applicationIds: undefined,
  },
  {
    id: 492,
    vacancy: 8,
    hired: 5,
    startsAt: '2021-01-15T05:28:27Z',
    endsAt: '2022-06-14T09:28:27Z',
    daysOff: [7, 5],
    createdAt: '2021-03-01T11:16:52Z',
    deletedAt: undefined,
    additionalInfo: 'donec',
    skillId: 2,
    centreId: 47,
    profileId: 7,
    applicationIds: [26, 49, 1, 10, 34, 40],
  },
  {
    id: 493,
    vacancy: 7,
    hired: 2,
    startsAt: '2021-05-19T06:57:00Z',
    endsAt: '2021-08-13T16:57:00Z',
    daysOff: undefined,
    createdAt: '2020-11-13T17:41:48Z',
    deletedAt: '2020-07-27T22:11:29Z',
    additionalInfo: 'tortor sollicitudin',
    skillId: 1,
    centreId: 39,
    profileId: 74,
    applicationIds: undefined,
  },
  {
    id: 494,
    vacancy: 3,
    hired: 0,
    startsAt: '2020-11-13T12:28:01Z',
    endsAt: '2021-01-20T17:28:01Z',
    daysOff: undefined,
    createdAt: '2020-07-31T21:59:48Z',
    deletedAt: '2021-02-06T12:16:24Z',
    additionalInfo: 'semper porta',
    skillId: 1,
    centreId: 39,
    profileId: 13,
    applicationIds: undefined,
  },
  {
    id: 495,
    vacancy: 4,
    hired: 2,
    startsAt: '2020-12-31T11:17:26Z',
    endsAt: '2022-04-03T20:17:26Z',
    daysOff: undefined,
    createdAt: '2020-11-19T22:37:56Z',
    deletedAt: '2021-03-11T14:25:21Z',
    additionalInfo: 'undefineda facilisi',
    skillId: 3,
    centreId: 44,
    profileId: 21,
    applicationIds: undefined,
  },
  {
    id: 496,
    vacancy: 8,
    hired: 3,
    startsAt: '2021-01-16T05:51:58Z',
    endsAt: '2022-04-01T17:51:58Z',
    daysOff: undefined,
    createdAt: '2020-09-23T10:47:03Z',
    deletedAt: '2020-12-04T09:58:20Z',
    additionalInfo: 'justo',
    skillId: 3,
    centreId: 4,
    profileId: 58,
    applicationIds: undefined,
  },
  {
    id: 497,
    vacancy: 5,
    hired: 4,
    startsAt: '2020-08-14T07:48:21Z',
    endsAt: '2022-04-24T13:48:21Z',
    daysOff: undefined,
    createdAt: '2020-08-17T20:55:11Z',
    deletedAt: undefined,
    additionalInfo: 'donec pharetra',
    skillId: 3,
    centreId: 21,
    profileId: 56,
    applicationIds: undefined,
  },
  {
    id: 498,
    vacancy: 2,
    hired: 0,
    startsAt: '2021-01-11T00:25:34Z',
    endsAt: '2022-08-01T10:25:34Z',
    daysOff: undefined,
    createdAt: '2020-10-06T08:21:26Z',
    deletedAt: undefined,
    additionalInfo: 'ultrices',
    skillId: 3,
    centreId: 3,
    profileId: 7,
    applicationIds: undefined,
  },
  {
    id: 499,
    vacancy: 6,
    hired: 2,
    startsAt: '2020-11-16T19:08:20Z',
    endsAt: '2021-05-22T03:08:20Z',
    daysOff: [1, 3],
    createdAt: '2021-02-04T21:53:01Z',
    deletedAt: '2020-08-03T02:17:42Z',
    additionalInfo: 'lectus',
    skillId: 1,
    centreId: 11,
    profileId: 20,
    applicationIds: [42, 29, 32, 14, 4, 20],
  },
  {
    id: 500,
    vacancy: 2,
    hired: 1,
    startsAt: '2020-09-24T23:21:31Z',
    endsAt: '2022-01-07T07:21:31Z',
    daysOff: undefined,
    createdAt: '2020-10-04T14:56:29Z',
    deletedAt: undefined,
    additionalInfo: 'ut volutpat',
    skillId: 3,
    centreId: 19,
    profileId: 91,
    applicationIds: undefined,
  },
  {
    id: 501,
    vacancy: 10,
    hired: 4,
    startsAt: '2021-03-16T01:21:10Z',
    endsAt: '2021-11-24T12:21:10Z',
    daysOff: undefined,
    createdAt: '2021-02-25T11:07:46Z',
    deletedAt: '2020-12-23T14:24:35Z',
    additionalInfo: 'eu sapien',
    skillId: 3,
    centreId: 11,
    profileId: 65,
    applicationIds: undefined,
  },
  {
    id: 502,
    vacancy: 6,
    hired: 1,
    startsAt: '2021-02-20T21:20:23Z',
    endsAt: '2022-02-10T04:20:23Z',
    daysOff: [9, 5],
    createdAt: '2020-11-19T14:58:09Z',
    deletedAt: '2021-04-25T14:33:18Z',
    additionalInfo: 'quam pede',
    skillId: 2,
    centreId: 49,
    profileId: 6,
    applicationIds: [18, 35, 39, 31, 5, 34],
  },
  {
    id: 503,
    vacancy: 10,
    hired: 8,
    startsAt: '2021-03-12T14:13:52Z',
    endsAt: '2022-06-19T21:13:52Z',
    daysOff: undefined,
    createdAt: '2020-10-30T06:41:25Z',
    deletedAt: '2021-02-28T07:06:35Z',
    additionalInfo: 'bibendum morbi',
    skillId: 2,
    centreId: 31,
    profileId: 76,
    applicationIds: undefined,
  },
  {
    id: 504,
    vacancy: 8,
    hired: 3,
    startsAt: '2021-05-08T04:57:32Z',
    endsAt: '2021-08-29T12:57:32Z',
    daysOff: undefined,
    createdAt: '2020-10-20T03:35:22Z',
    deletedAt: '2021-04-30T10:24:47Z',
    additionalInfo: 'eget',
    skillId: 3,
    centreId: 22,
    profileId: 10,
    applicationIds: undefined,
  },
  {
    id: 505,
    vacancy: 8,
    hired: 4,
    startsAt: '2021-04-30T14:08:34Z',
    endsAt: '2023-02-10T23:08:34Z',
    daysOff: undefined,
    createdAt: '2021-03-22T01:41:15Z',
    deletedAt: '2020-09-11T09:34:47Z',
    additionalInfo: 'commodo placerat',
    skillId: 1,
    centreId: 35,
    profileId: 25,
    applicationIds: undefined,
  },
  {
    id: 506,
    vacancy: 6,
    hired: 1,
    startsAt: '2020-09-02T18:55:53Z',
    endsAt: '2022-09-25T05:55:53Z',
    daysOff: undefined,
    createdAt: '2020-11-04T01:24:47Z',
    deletedAt: '2020-12-31T02:58:42Z',
    additionalInfo: 'vitae consectetuer',
    skillId: 2,
    centreId: 35,
    profileId: 34,
    applicationIds: undefined,
  },
  {
    id: 507,
    vacancy: 8,
    hired: 8,
    startsAt: '2021-03-21T06:59:00Z',
    endsAt: '2023-02-28T18:59:00Z',
    daysOff: [8, 1],
    createdAt: '2020-08-05T00:05:39Z',
    deletedAt: '2020-12-16T17:48:17Z',
    additionalInfo: 'mollis molestie',
    skillId: 2,
    centreId: 7,
    profileId: 43,
    applicationIds: [16, 17, 8, 27, 18, 3],
  },
  {
    id: 508,
    vacancy: 7,
    hired: 4,
    startsAt: '2021-03-12T15:41:02Z',
    endsAt: '2021-07-28T22:41:02Z',
    daysOff: undefined,
    createdAt: '2021-06-02T19:36:00Z',
    deletedAt: '2020-11-02T23:20:28Z',
    additionalInfo: 'placerat',
    skillId: 2,
    centreId: 9,
    profileId: 38,
    applicationIds: undefined,
  },
  {
    id: 509,
    vacancy: 4,
    hired: 1,
    startsAt: '2020-09-04T03:35:20Z',
    endsAt: '2021-05-24T10:35:20Z',
    daysOff: undefined,
    createdAt: '2021-06-09T16:34:19Z',
    deletedAt: undefined,
    additionalInfo: 'undefineda',
    skillId: 3,
    centreId: 26,
    profileId: 67,
    applicationIds: undefined,
  },
  {
    id: 510,
    vacancy: 2,
    hired: 1,
    startsAt: '2021-01-07T05:34:42Z',
    endsAt: '2022-12-29T09:34:42Z',
    daysOff: [8, 5],
    createdAt: '2021-04-18T00:42:31Z',
    deletedAt: undefined,
    additionalInfo: 'justo eu',
    skillId: 1,
    centreId: 47,
    profileId: 18,
    applicationIds: [15, 24, 22, 50, 10, 40],
  },
  {
    id: 511,
    vacancy: 5,
    hired: 4,
    startsAt: '2021-04-12T08:42:05Z',
    endsAt: '2022-11-30T12:42:05Z',
    daysOff: undefined,
    createdAt: '2020-12-13T07:48:10Z',
    deletedAt: '2020-11-02T05:21:11Z',
    additionalInfo: 'vivamus',
    skillId: 2,
    centreId: 24,
    profileId: 93,
    applicationIds: undefined,
  },
  {
    id: 512,
    vacancy: 7,
    hired: 7,
    startsAt: '2021-07-08T05:50:02Z',
    endsAt: '2023-05-13T14:50:02Z',
    daysOff: undefined,
    createdAt: '2021-03-22T15:18:58Z',
    deletedAt: '2021-04-28T12:54:29Z',
    additionalInfo: 'fusce',
    skillId: 2,
    centreId: 25,
    profileId: 87,
    applicationIds: undefined,
  },
  {
    id: 513,
    vacancy: 6,
    hired: 5,
    startsAt: '2021-07-15T01:22:47Z',
    endsAt: '2023-01-22T06:22:47Z',
    daysOff: [2, 9],
    createdAt: '2021-05-15T06:25:46Z',
    deletedAt: '2020-11-07T05:33:32Z',
    additionalInfo: 'hac',
    skillId: 3,
    centreId: 30,
    profileId: 74,
    applicationIds: [13, 35, 16, 42, 41, 2],
  },
  {
    id: 514,
    vacancy: 5,
    hired: 4,
    startsAt: '2021-03-23T23:33:44Z',
    endsAt: '2022-11-06T08:33:44Z',
    daysOff: undefined,
    createdAt: '2020-07-25T11:22:40Z',
    deletedAt: undefined,
    additionalInfo: 'proin',
    skillId: 3,
    centreId: 14,
    profileId: 95,
    applicationIds: undefined,
  },
  {
    id: 515,
    vacancy: 5,
    hired: 1,
    startsAt: '2021-04-06T06:37:13Z',
    endsAt: '2022-11-08T10:37:13Z',
    daysOff: [9, 9],
    createdAt: '2021-05-06T09:39:05Z',
    deletedAt: '2021-03-21T05:39:05Z',
    additionalInfo: 'lacinia eget',
    skillId: 2,
    centreId: 47,
    profileId: 14,
    applicationIds: [30, 38, 8, 19, 23, 43],
  },
  {
    id: 516,
    vacancy: 3,
    hired: 0,
    startsAt: '2020-12-21T23:16:33Z',
    endsAt: '2022-09-10T08:16:33Z',
    daysOff: [4, 1],
    createdAt: '2020-10-31T19:43:47Z',
    deletedAt: '2021-02-18T06:46:42Z',
    additionalInfo: 'tincidunt undefineda',
    skillId: 1,
    centreId: 31,
    profileId: 88,
    applicationIds: [21, 18, 4, 22, 12, 3],
  },
  {
    id: 517,
    vacancy: 4,
    hired: 2,
    startsAt: '2021-04-10T13:04:10Z',
    endsAt: '2022-10-15T22:04:10Z',
    daysOff: undefined,
    createdAt: '2021-01-14T03:25:02Z',
    deletedAt: '2021-03-31T02:34:13Z',
    additionalInfo: 'dictumst',
    skillId: 1,
    centreId: 49,
    profileId: 61,
    applicationIds: undefined,
  },
  {
    id: 518,
    vacancy: 3,
    hired: 3,
    startsAt: '2020-10-14T12:34:18Z',
    endsAt: '2021-01-06T16:34:18Z',
    daysOff: [5, 10],
    createdAt: '2020-09-24T05:56:48Z',
    deletedAt: undefined,
    additionalInfo: 'luctus et',
    skillId: 3,
    centreId: 2,
    profileId: 5,
    applicationIds: [40, 23, 5, 11, 3, 31],
  },
  {
    id: 519,
    vacancy: 10,
    hired: 6,
    startsAt: '2020-07-25T19:57:09Z',
    endsAt: '2022-05-15T22:57:09Z',
    daysOff: [7, 2],
    createdAt: '2021-04-17T02:19:26Z',
    deletedAt: undefined,
    additionalInfo: 'consequat',
    skillId: 2,
    centreId: 44,
    profileId: 69,
    applicationIds: [27, 49, 48, 50, 44, 35],
  },
  {
    id: 520,
    vacancy: 5,
    hired: 4,
    startsAt: '2021-06-23T01:59:07Z',
    endsAt: '2022-01-16T07:59:07Z',
    daysOff: undefined,
    createdAt: '2021-06-16T15:44:23Z',
    deletedAt: '2021-05-13T05:20:12Z',
    additionalInfo: 'vestibulum sed',
    skillId: 3,
    centreId: 37,
    profileId: 86,
    applicationIds: undefined,
  },
  {
    id: 521,
    vacancy: 10,
    hired: 9,
    startsAt: '2020-07-28T17:37:26Z',
    endsAt: '2021-06-20T05:37:26Z',
    daysOff: undefined,
    createdAt: '2021-07-15T06:47:11Z',
    deletedAt: '2020-09-03T08:00:32Z',
    additionalInfo: 'tincidunt',
    skillId: 3,
    centreId: 13,
    profileId: 19,
    applicationIds: undefined,
  },
  {
    id: 522,
    vacancy: 4,
    hired: 0,
    startsAt: '2021-06-03T21:11:08Z',
    endsAt: '2021-09-01T01:11:08Z',
    daysOff: undefined,
    createdAt: '2021-02-13T12:48:41Z',
    deletedAt: '2021-05-27T01:16:28Z',
    additionalInfo: 'morbi odio',
    skillId: 3,
    centreId: 18,
    profileId: 14,
    applicationIds: undefined,
  },
  {
    id: 523,
    vacancy: 7,
    hired: 6,
    startsAt: '2020-09-28T00:34:01Z',
    endsAt: '2022-03-01T12:34:01Z',
    daysOff: undefined,
    createdAt: '2020-07-24T13:48:37Z',
    deletedAt: undefined,
    additionalInfo: 'in',
    skillId: 3,
    centreId: 35,
    profileId: 34,
    applicationIds: undefined,
  },
  {
    id: 524,
    vacancy: 6,
    hired: 4,
    startsAt: '2020-09-17T22:56:32Z',
    endsAt: '2021-10-23T08:56:32Z',
    daysOff: undefined,
    createdAt: '2020-09-19T08:46:45Z',
    deletedAt: '2020-09-12T05:00:47Z',
    additionalInfo: 'vel nisl',
    skillId: 3,
    centreId: 41,
    profileId: 76,
    applicationIds: undefined,
  },
  {
    id: 525,
    vacancy: 5,
    hired: 3,
    startsAt: '2021-02-19T03:27:04Z',
    endsAt: '2022-05-20T12:27:04Z',
    daysOff: undefined,
    createdAt: '2021-04-08T20:00:03Z',
    deletedAt: '2021-01-20T16:30:35Z',
    additionalInfo: 'odio',
    skillId: 1,
    centreId: 37,
    profileId: 44,
    applicationIds: undefined,
  },
  {
    id: 526,
    vacancy: 3,
    hired: 1,
    startsAt: '2021-03-23T21:48:39Z',
    endsAt: '2022-09-13T08:48:39Z',
    daysOff: [1, 6],
    createdAt: '2021-07-11T12:10:18Z',
    deletedAt: '2021-07-09T05:42:04Z',
    additionalInfo: 'a libero',
    skillId: 1,
    centreId: 31,
    profileId: 63,
    applicationIds: [12, 48, 46, 8, 26, 6],
  },
  {
    id: 527,
    vacancy: 8,
    hired: 3,
    startsAt: '2020-12-14T07:41:03Z',
    endsAt: '2022-11-23T14:41:03Z',
    daysOff: undefined,
    createdAt: '2020-10-24T14:03:36Z',
    deletedAt: '2020-11-24T03:14:38Z',
    additionalInfo: 'sit',
    skillId: 2,
    centreId: 38,
    profileId: 33,
    applicationIds: undefined,
  },
  {
    id: 528,
    vacancy: 4,
    hired: 0,
    startsAt: '2020-11-26T10:20:19Z',
    endsAt: '2022-07-10T20:20:19Z',
    daysOff: undefined,
    createdAt: '2020-12-25T01:43:58Z',
    deletedAt: undefined,
    additionalInfo: 'ornare',
    skillId: 3,
    centreId: 48,
    profileId: 52,
    applicationIds: undefined,
  },
  {
    id: 529,
    vacancy: 2,
    hired: 1,
    startsAt: '2021-02-27T11:16:51Z',
    endsAt: '2023-01-16T21:16:51Z',
    daysOff: undefined,
    createdAt: '2021-07-01T13:34:42Z',
    deletedAt: '2021-05-28T08:25:01Z',
    additionalInfo: 'pellentesque',
    skillId: 3,
    centreId: 47,
    profileId: 56,
    applicationIds: undefined,
  },
  {
    id: 530,
    vacancy: 4,
    hired: 1,
    startsAt: '2020-12-09T08:14:54Z',
    endsAt: '2021-01-20T17:14:54Z',
    daysOff: [3, 1],
    createdAt: '2020-12-18T07:47:18Z',
    deletedAt: '2021-03-22T12:12:27Z',
    additionalInfo: 'curabitur gravida',
    skillId: 2,
    centreId: 16,
    profileId: 96,
    applicationIds: [17, 25, 47, 43, 48, 39],
  },
  {
    id: 531,
    vacancy: 6,
    hired: 3,
    startsAt: '2021-05-14T03:30:00Z',
    endsAt: '2021-11-27T09:30:00Z',
    daysOff: undefined,
    createdAt: '2020-08-30T07:57:00Z',
    deletedAt: '2021-03-03T21:53:47Z',
    additionalInfo: 'nec',
    skillId: 1,
    centreId: 28,
    profileId: 54,
    applicationIds: undefined,
  },
  {
    id: 532,
    vacancy: 2,
    hired: 2,
    startsAt: '2021-02-17T07:30:19Z',
    endsAt: '2022-11-06T16:30:19Z',
    daysOff: undefined,
    createdAt: '2020-09-12T09:18:13Z',
    deletedAt: '2020-12-07T05:42:18Z',
    additionalInfo: 'sed tristique',
    skillId: 2,
    centreId: 38,
    profileId: 86,
    applicationIds: undefined,
  },
  {
    id: 533,
    vacancy: 2,
    hired: 1,
    startsAt: '2020-07-29T03:53:51Z',
    endsAt: '2022-06-28T08:53:51Z',
    daysOff: undefined,
    createdAt: '2021-02-10T15:53:44Z',
    deletedAt: '2021-02-16T01:04:51Z',
    additionalInfo: 'sit amet',
    skillId: 2,
    centreId: 19,
    profileId: 50,
    applicationIds: undefined,
  },
  {
    id: 534,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-12-10T14:35:47Z',
    endsAt: '2022-08-04T02:35:47Z',
    daysOff: undefined,
    createdAt: '2021-03-12T21:34:02Z',
    deletedAt: undefined,
    additionalInfo: 'purus sit',
    skillId: 3,
    centreId: 35,
    profileId: 5,
    applicationIds: undefined,
  },
  {
    id: 535,
    vacancy: 2,
    hired: 2,
    startsAt: '2020-08-10T13:29:17Z',
    endsAt: '2021-08-17T23:29:17Z',
    daysOff: undefined,
    createdAt: '2020-09-12T22:20:00Z',
    deletedAt: undefined,
    additionalInfo: 'ut at',
    skillId: 2,
    centreId: 50,
    profileId: 69,
    applicationIds: undefined,
  },
  {
    id: 536,
    vacancy: 2,
    hired: 1,
    startsAt: '2021-01-31T21:36:39Z',
    endsAt: '2022-07-04T04:36:39Z',
    daysOff: [6, 7],
    createdAt: '2020-11-27T00:47:40Z',
    deletedAt: '2021-02-26T21:15:51Z',
    additionalInfo: 'id mauris',
    skillId: 3,
    centreId: 13,
    profileId: 32,
    applicationIds: [31, 1, 4, 5, 27, 9],
  },
  {
    id: 537,
    vacancy: 10,
    hired: 10,
    startsAt: '2021-02-06T23:04:04Z',
    endsAt: '2021-04-10T11:04:04Z',
    daysOff: undefined,
    createdAt: '2021-02-18T20:50:26Z',
    deletedAt: undefined,
    additionalInfo: 'habitasse platea',
    skillId: 1,
    centreId: 32,
    profileId: 83,
    applicationIds: undefined,
  },
  {
    id: 538,
    vacancy: 4,
    hired: 3,
    startsAt: '2020-10-27T09:15:14Z',
    endsAt: '2021-09-04T12:15:14Z',
    daysOff: undefined,
    createdAt: '2021-06-29T02:15:03Z',
    deletedAt: '2021-04-01T20:34:24Z',
    additionalInfo: 'cras in',
    skillId: 2,
    centreId: 36,
    profileId: 97,
    applicationIds: undefined,
  },
  {
    id: 539,
    vacancy: 6,
    hired: 1,
    startsAt: '2021-02-26T17:48:21Z',
    endsAt: '2022-04-08T23:48:21Z',
    daysOff: undefined,
    createdAt: '2020-11-16T16:15:01Z',
    deletedAt: '2020-10-20T16:53:13Z',
    additionalInfo: 'hac',
    skillId: 3,
    centreId: 36,
    profileId: 98,
    applicationIds: undefined,
  },
  {
    id: 540,
    vacancy: 9,
    hired: 7,
    startsAt: '2021-05-21T21:20:07Z',
    endsAt: '2023-03-02T08:20:07Z',
    daysOff: undefined,
    createdAt: '2021-04-11T16:26:57Z',
    deletedAt: '2021-06-20T05:53:57Z',
    additionalInfo: 'dui maecenas',
    skillId: 2,
    centreId: 34,
    profileId: 28,
    applicationIds: undefined,
  },
  {
    id: 541,
    vacancy: 9,
    hired: 9,
    startsAt: '2021-06-29T19:32:10Z',
    endsAt: '2023-04-04T01:32:10Z',
    daysOff: undefined,
    createdAt: '2021-06-30T23:26:41Z',
    deletedAt: '2020-11-30T00:40:46Z',
    additionalInfo: 'in faucibus',
    skillId: 1,
    centreId: 26,
    profileId: 32,
    applicationIds: undefined,
  },
  {
    id: 542,
    vacancy: 2,
    hired: 1,
    startsAt: '2021-03-15T16:25:03Z',
    endsAt: '2022-07-22T02:25:03Z',
    daysOff: undefined,
    createdAt: '2021-06-24T06:47:37Z',
    deletedAt: '2021-04-21T13:55:52Z',
    additionalInfo: 'orci pede',
    skillId: 1,
    centreId: 33,
    profileId: 14,
    applicationIds: undefined,
  },
  {
    id: 543,
    vacancy: 2,
    hired: 2,
    startsAt: '2020-07-28T04:54:14Z',
    endsAt: '2021-06-30T09:54:14Z',
    daysOff: undefined,
    createdAt: '2020-11-14T21:17:34Z',
    deletedAt: '2021-02-05T08:31:47Z',
    additionalInfo: 'sodales',
    skillId: 3,
    centreId: 12,
    profileId: 11,
    applicationIds: undefined,
  },
  {
    id: 544,
    vacancy: 7,
    hired: 1,
    startsAt: '2021-03-06T21:25:41Z',
    endsAt: '2021-06-04T07:25:41Z',
    daysOff: undefined,
    createdAt: '2021-05-30T02:25:25Z',
    deletedAt: '2021-01-05T19:37:09Z',
    additionalInfo: 'sed',
    skillId: 2,
    centreId: 18,
    profileId: 52,
    applicationIds: undefined,
  },
  {
    id: 545,
    vacancy: 4,
    hired: 4,
    startsAt: '2020-12-27T19:33:53Z',
    endsAt: '2022-07-08T23:33:53Z',
    daysOff: undefined,
    createdAt: '2020-12-13T08:29:34Z',
    deletedAt: '2020-12-14T17:29:54Z',
    additionalInfo: 'mi',
    skillId: 2,
    centreId: 11,
    profileId: 98,
    applicationIds: undefined,
  },
  {
    id: 546,
    vacancy: 2,
    hired: 0,
    startsAt: '2021-05-20T09:08:42Z',
    endsAt: '2023-03-28T16:08:42Z',
    daysOff: undefined,
    createdAt: '2020-11-29T08:23:59Z',
    deletedAt: '2020-10-08T11:03:18Z',
    additionalInfo: 'vitae quam',
    skillId: 2,
    centreId: 30,
    profileId: 23,
    applicationIds: undefined,
  },
  {
    id: 547,
    vacancy: 5,
    hired: 0,
    startsAt: '2020-12-10T21:44:47Z',
    endsAt: '2021-12-04T01:44:47Z',
    daysOff: undefined,
    createdAt: '2021-06-03T14:35:33Z',
    deletedAt: '2020-12-08T14:02:14Z',
    additionalInfo: 'sit',
    skillId: 1,
    centreId: 37,
    profileId: 39,
    applicationIds: undefined,
  },
  {
    id: 548,
    vacancy: 8,
    hired: 1,
    startsAt: '2021-02-23T00:43:09Z',
    endsAt: '2022-11-28T10:43:09Z',
    daysOff: [2, 3],
    createdAt: '2021-04-29T23:15:39Z',
    deletedAt: undefined,
    additionalInfo: 'primis in',
    skillId: 3,
    centreId: 37,
    profileId: 53,
    applicationIds: [13, 14, 46, 25, 15, 14],
  },
  {
    id: 549,
    vacancy: 5,
    hired: 4,
    startsAt: '2020-07-26T09:10:06Z',
    endsAt: '2022-04-25T21:10:06Z',
    daysOff: undefined,
    createdAt: '2021-03-10T07:06:15Z',
    deletedAt: '2021-03-01T18:27:15Z',
    additionalInfo: 'tempus',
    skillId: 1,
    centreId: 19,
    profileId: 20,
    applicationIds: undefined,
  },
  {
    id: 550,
    vacancy: 5,
    hired: 4,
    startsAt: '2020-11-24T19:14:20Z',
    endsAt: '2022-09-24T07:14:20Z',
    daysOff: undefined,
    createdAt: '2021-06-12T06:54:51Z',
    deletedAt: '2021-05-13T18:51:34Z',
    additionalInfo: 'lacus at',
    skillId: 3,
    centreId: 1,
    profileId: 100,
    applicationIds: undefined,
  },
  {
    id: 551,
    vacancy: 3,
    hired: 1,
    startsAt: '2021-01-22T14:58:20Z',
    endsAt: '2021-11-20T19:58:20Z',
    daysOff: undefined,
    createdAt: '2021-06-09T16:06:12Z',
    deletedAt: '2021-03-31T03:08:57Z',
    additionalInfo: 'luctus tincidunt',
    skillId: 1,
    centreId: 15,
    profileId: 15,
    applicationIds: undefined,
  },
  {
    id: 552,
    vacancy: 1,
    hired: 0,
    startsAt: '2020-08-18T23:40:21Z',
    endsAt: '2022-01-20T02:40:21Z',
    daysOff: undefined,
    createdAt: '2021-03-29T08:56:16Z',
    deletedAt: '2020-11-04T02:07:52Z',
    additionalInfo: 'odio',
    skillId: 2,
    centreId: 38,
    profileId: 44,
    applicationIds: undefined,
  },
  {
    id: 553,
    vacancy: 2,
    hired: 1,
    startsAt: '2020-09-11T02:36:54Z',
    endsAt: '2021-01-10T10:36:54Z',
    daysOff: [7, 10],
    createdAt: '2021-06-09T04:10:14Z',
    deletedAt: '2020-08-05T00:18:44Z',
    additionalInfo: 'mollis',
    skillId: 1,
    centreId: 35,
    profileId: 9,
    applicationIds: [32, 24, 34, 33, 1, 31],
  },
  {
    id: 554,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-11-04T14:54:38Z',
    endsAt: '2021-09-17T18:54:38Z',
    daysOff: undefined,
    createdAt: '2021-04-03T02:13:36Z',
    deletedAt: '2021-04-02T11:50:20Z',
    additionalInfo: 'magna',
    skillId: 1,
    centreId: 10,
    profileId: 48,
    applicationIds: undefined,
  },
  {
    id: 555,
    vacancy: 2,
    hired: 2,
    startsAt: '2020-12-25T05:56:38Z',
    endsAt: '2023-01-09T09:56:38Z',
    daysOff: undefined,
    createdAt: '2021-01-12T21:02:36Z',
    deletedAt: '2020-09-16T21:59:11Z',
    additionalInfo: 'dolor',
    skillId: 3,
    centreId: 23,
    profileId: 52,
    applicationIds: undefined,
  },
  {
    id: 556,
    vacancy: 5,
    hired: 0,
    startsAt: '2021-03-21T15:45:35Z',
    endsAt: '2021-06-13T22:45:35Z',
    daysOff: undefined,
    createdAt: '2021-07-10T20:07:24Z',
    deletedAt: '2021-06-27T09:45:56Z',
    additionalInfo: 'rhoncus aliquet',
    skillId: 2,
    centreId: 18,
    profileId: 34,
    applicationIds: undefined,
  },
  {
    id: 557,
    vacancy: 10,
    hired: 1,
    startsAt: '2020-09-08T21:27:14Z',
    endsAt: '2022-09-04T06:27:14Z',
    daysOff: undefined,
    createdAt: '2020-12-26T09:53:08Z',
    deletedAt: undefined,
    additionalInfo: 'lobortis ligula',
    skillId: 2,
    centreId: 5,
    profileId: 3,
    applicationIds: undefined,
  },
  {
    id: 558,
    vacancy: 4,
    hired: 3,
    startsAt: '2020-07-31T06:24:22Z',
    endsAt: '2021-12-12T18:24:22Z',
    daysOff: undefined,
    createdAt: '2021-05-14T03:08:40Z',
    deletedAt: '2020-08-10T00:25:32Z',
    additionalInfo: 'odio',
    skillId: 3,
    centreId: 35,
    profileId: 50,
    applicationIds: undefined,
  },
  {
    id: 559,
    vacancy: 4,
    hired: 2,
    startsAt: '2021-05-04T17:18:14Z',
    endsAt: '2022-10-29T04:18:14Z',
    daysOff: undefined,
    createdAt: '2020-11-25T17:57:37Z',
    deletedAt: '2020-12-26T12:41:04Z',
    additionalInfo: 'phasellus sit',
    skillId: 3,
    centreId: 16,
    profileId: 32,
    applicationIds: undefined,
  },
  {
    id: 560,
    vacancy: 3,
    hired: 3,
    startsAt: '2021-05-09T11:20:06Z',
    endsAt: '2023-01-21T21:20:06Z',
    daysOff: undefined,
    createdAt: '2020-12-04T11:14:49Z',
    deletedAt: '2020-08-03T11:38:08Z',
    additionalInfo: 'morbi non',
    skillId: 2,
    centreId: 10,
    profileId: 70,
    applicationIds: undefined,
  },
  {
    id: 561,
    vacancy: 2,
    hired: 0,
    startsAt: '2020-10-24T16:06:44Z',
    endsAt: '2022-10-08T21:06:44Z',
    daysOff: undefined,
    createdAt: '2020-07-29T13:47:39Z',
    deletedAt: '2021-02-21T22:38:38Z',
    additionalInfo: 'morbi quis',
    skillId: 1,
    centreId: 32,
    profileId: 16,
    applicationIds: undefined,
  },
  {
    id: 562,
    vacancy: 3,
    hired: 0,
    startsAt: '2021-05-24T18:12:41Z',
    endsAt: '2022-02-04T06:12:41Z',
    daysOff: undefined,
    createdAt: '2021-06-23T14:03:59Z',
    deletedAt: '2020-09-25T05:43:30Z',
    additionalInfo: 'risus',
    skillId: 1,
    centreId: 25,
    profileId: 31,
    applicationIds: undefined,
  },
  {
    id: 563,
    vacancy: 6,
    hired: 2,
    startsAt: '2020-08-30T01:22:32Z',
    endsAt: '2021-04-21T07:22:32Z',
    daysOff: undefined,
    createdAt: '2020-09-27T00:40:50Z',
    deletedAt: '2020-12-09T11:40:22Z',
    additionalInfo: 'montes',
    skillId: 2,
    centreId: 1,
    profileId: 70,
    applicationIds: undefined,
  },
  {
    id: 564,
    vacancy: 5,
    hired: 1,
    startsAt: '2020-11-05T10:53:12Z',
    endsAt: '2020-12-17T22:53:12Z',
    daysOff: undefined,
    createdAt: '2020-08-27T23:17:54Z',
    deletedAt: undefined,
    additionalInfo: 'quam',
    skillId: 1,
    centreId: 44,
    profileId: 56,
    applicationIds: undefined,
  },
  {
    id: 565,
    vacancy: 10,
    hired: 6,
    startsAt: '2020-09-14T00:46:57Z',
    endsAt: '2022-08-12T04:46:57Z',
    daysOff: undefined,
    createdAt: '2021-03-15T16:51:34Z',
    deletedAt: '2021-04-27T21:12:23Z',
    additionalInfo: 'in',
    skillId: 2,
    centreId: 9,
    profileId: 48,
    applicationIds: undefined,
  },
  {
    id: 566,
    vacancy: 10,
    hired: 4,
    startsAt: '2020-12-18T14:53:24Z',
    endsAt: '2022-08-01T22:53:24Z',
    daysOff: undefined,
    createdAt: '2020-08-12T17:46:58Z',
    deletedAt: '2021-02-15T16:04:00Z',
    additionalInfo: 'turpis adipiscing',
    skillId: 2,
    centreId: 23,
    profileId: 76,
    applicationIds: undefined,
  },
  {
    id: 567,
    vacancy: 6,
    hired: 5,
    startsAt: '2021-02-20T20:59:27Z',
    endsAt: '2021-11-10T23:59:27Z',
    daysOff: undefined,
    createdAt: '2021-03-23T04:02:43Z',
    deletedAt: '2021-02-27T11:48:02Z',
    additionalInfo: 'eros',
    skillId: 1,
    centreId: 46,
    profileId: 74,
    applicationIds: undefined,
  },
  {
    id: 568,
    vacancy: 7,
    hired: 1,
    startsAt: '2020-10-16T10:48:44Z',
    endsAt: '2020-12-24T14:48:44Z',
    daysOff: undefined,
    createdAt: '2020-08-25T09:18:13Z',
    deletedAt: '2021-04-24T18:04:42Z',
    additionalInfo: 'cum',
    skillId: 3,
    centreId: 4,
    profileId: 82,
    applicationIds: undefined,
  },
  {
    id: 569,
    vacancy: 7,
    hired: 2,
    startsAt: '2020-09-30T06:21:59Z',
    endsAt: '2021-04-20T13:21:59Z',
    daysOff: undefined,
    createdAt: '2021-06-18T09:45:27Z',
    deletedAt: '2021-02-05T07:08:47Z',
    additionalInfo: 'in tempus',
    skillId: 2,
    centreId: 3,
    profileId: 81,
    applicationIds: undefined,
  },
  {
    id: 570,
    vacancy: 2,
    hired: 0,
    startsAt: '2020-08-13T06:11:23Z',
    endsAt: '2022-09-04T13:11:23Z',
    daysOff: undefined,
    createdAt: '2021-01-06T03:11:14Z',
    deletedAt: undefined,
    additionalInfo: 'lorem id',
    skillId: 3,
    centreId: 44,
    profileId: 64,
    applicationIds: undefined,
  },
  {
    id: 571,
    vacancy: 6,
    hired: 3,
    startsAt: '2020-11-20T21:09:13Z',
    endsAt: '2021-03-06T09:09:13Z',
    daysOff: [10, 2],
    createdAt: '2021-03-28T10:40:46Z',
    deletedAt: '2020-12-07T09:50:36Z',
    additionalInfo: 'rhoncus',
    skillId: 3,
    centreId: 44,
    profileId: 76,
    applicationIds: [5, 4, 45, 46, 30, 18],
  },
  {
    id: 572,
    vacancy: 8,
    hired: 7,
    startsAt: '2021-03-04T19:47:04Z',
    endsAt: '2021-08-25T00:47:04Z',
    daysOff: undefined,
    createdAt: '2021-05-20T11:35:01Z',
    deletedAt: undefined,
    additionalInfo: 'amet',
    skillId: 3,
    centreId: 22,
    profileId: 100,
    applicationIds: undefined,
  },
  {
    id: 573,
    vacancy: 10,
    hired: 8,
    startsAt: '2020-08-26T11:38:56Z',
    endsAt: '2022-06-18T17:38:56Z',
    daysOff: undefined,
    createdAt: '2020-11-22T05:49:03Z',
    deletedAt: '2020-10-07T06:46:05Z',
    additionalInfo: 'felis',
    skillId: 2,
    centreId: 14,
    profileId: 84,
    applicationIds: undefined,
  },
  {
    id: 574,
    vacancy: 5,
    hired: 2,
    startsAt: '2021-05-13T15:19:10Z',
    endsAt: '2021-09-23T03:19:10Z',
    daysOff: undefined,
    createdAt: '2021-01-11T05:49:30Z',
    deletedAt: '2020-12-30T11:40:40Z',
    additionalInfo: 'ultrices posuere',
    skillId: 3,
    centreId: 38,
    profileId: 12,
    applicationIds: undefined,
  },
  {
    id: 575,
    vacancy: 5,
    hired: 5,
    startsAt: '2020-11-07T23:09:54Z',
    endsAt: '2022-10-02T04:09:54Z',
    daysOff: undefined,
    createdAt: '2020-09-21T08:09:09Z',
    deletedAt: '2020-12-29T08:57:36Z',
    additionalInfo: 'sapien',
    skillId: 3,
    centreId: 15,
    profileId: 55,
    applicationIds: undefined,
  },
  {
    id: 576,
    vacancy: 4,
    hired: 3,
    startsAt: '2021-02-23T06:07:34Z',
    endsAt: '2021-11-17T14:07:34Z',
    daysOff: undefined,
    createdAt: '2021-06-16T05:39:01Z',
    deletedAt: '2021-04-25T23:20:46Z',
    additionalInfo: 'turpis a',
    skillId: 1,
    centreId: 25,
    profileId: 81,
    applicationIds: undefined,
  },
  {
    id: 577,
    vacancy: 2,
    hired: 0,
    startsAt: '2020-08-20T03:31:53Z',
    endsAt: '2021-07-21T15:31:53Z',
    daysOff: undefined,
    createdAt: '2020-12-01T08:20:26Z',
    deletedAt: '2020-12-03T09:38:51Z',
    additionalInfo: 'condimentum id',
    skillId: 2,
    centreId: 34,
    profileId: 46,
    applicationIds: undefined,
  },
  {
    id: 578,
    vacancy: 2,
    hired: 1,
    startsAt: '2021-07-09T13:06:42Z',
    endsAt: '2022-02-07T18:06:42Z',
    daysOff: [1, 5],
    createdAt: '2021-04-09T21:52:21Z',
    deletedAt: '2020-07-30T03:29:22Z',
    additionalInfo: 'at ipsum',
    skillId: 3,
    centreId: 34,
    profileId: 95,
    applicationIds: [3, 3, 38, 49, 24, 29],
  },
  {
    id: 579,
    vacancy: 3,
    hired: 2,
    startsAt: '2020-10-03T19:01:47Z',
    endsAt: '2022-10-28T04:01:47Z',
    daysOff: undefined,
    createdAt: '2021-04-21T03:55:26Z',
    deletedAt: '2020-12-13T07:52:48Z',
    additionalInfo: 'ut',
    skillId: 3,
    centreId: 34,
    profileId: 84,
    applicationIds: undefined,
  },
  {
    id: 580,
    vacancy: 6,
    hired: 2,
    startsAt: '2020-11-19T18:02:49Z',
    endsAt: '2021-11-30T04:02:49Z',
    daysOff: undefined,
    createdAt: '2020-10-05T07:52:50Z',
    deletedAt: '2021-04-21T03:18:09Z',
    additionalInfo: 'vitae consectetuer',
    skillId: 3,
    centreId: 2,
    profileId: 77,
    applicationIds: undefined,
  },
  {
    id: 581,
    vacancy: 3,
    hired: 2,
    startsAt: '2020-08-20T19:26:58Z',
    endsAt: '2021-01-09T07:26:58Z',
    daysOff: undefined,
    createdAt: '2021-01-27T11:24:52Z',
    deletedAt: undefined,
    additionalInfo: 'justo',
    skillId: 1,
    centreId: 30,
    profileId: 16,
    applicationIds: undefined,
  },
  {
    id: 582,
    vacancy: 6,
    hired: 0,
    startsAt: '2021-07-04T14:14:29Z',
    endsAt: '2023-05-17T17:14:29Z',
    daysOff: undefined,
    createdAt: '2020-10-15T21:37:48Z',
    deletedAt: '2021-01-07T09:25:31Z',
    additionalInfo: 'vestibulum',
    skillId: 1,
    centreId: 18,
    profileId: 9,
    applicationIds: undefined,
  },
  {
    id: 583,
    vacancy: 1,
    hired: 1,
    startsAt: '2021-04-28T03:22:08Z',
    endsAt: '2022-12-02T10:22:08Z',
    daysOff: undefined,
    createdAt: '2020-09-25T10:05:37Z',
    deletedAt: '2021-02-23T16:04:54Z',
    additionalInfo: 'nibh in',
    skillId: 3,
    centreId: 21,
    profileId: 4,
    applicationIds: undefined,
  },
  {
    id: 584,
    vacancy: 4,
    hired: 2,
    startsAt: '2021-03-26T11:50:48Z',
    endsAt: '2022-07-03T15:50:48Z',
    daysOff: undefined,
    createdAt: '2021-01-26T13:52:00Z',
    deletedAt: '2021-02-14T15:21:05Z',
    additionalInfo: 'aliquet',
    skillId: 2,
    centreId: 5,
    profileId: 27,
    applicationIds: undefined,
  },
  {
    id: 585,
    vacancy: 2,
    hired: 2,
    startsAt: '2021-06-23T17:52:41Z',
    endsAt: '2023-04-08T23:52:41Z',
    daysOff: undefined,
    createdAt: '2020-09-02T16:48:43Z',
    deletedAt: '2020-09-11T00:35:25Z',
    additionalInfo: 'primis in',
    skillId: 1,
    centreId: 36,
    profileId: 4,
    applicationIds: undefined,
  },
  {
    id: 586,
    vacancy: 4,
    hired: 1,
    startsAt: '2021-02-24T19:09:10Z',
    endsAt: '2022-11-12T00:09:10Z',
    daysOff: undefined,
    createdAt: '2021-04-26T20:06:04Z',
    deletedAt: '2020-10-23T21:32:57Z',
    additionalInfo: 'pretium',
    skillId: 1,
    centreId: 14,
    profileId: 27,
    applicationIds: undefined,
  },
  {
    id: 587,
    vacancy: 2,
    hired: 1,
    startsAt: '2021-04-01T03:48:11Z',
    endsAt: '2022-04-15T11:48:11Z',
    daysOff: undefined,
    createdAt: '2021-03-27T01:44:44Z',
    deletedAt: '2020-08-23T23:56:35Z',
    additionalInfo: 'tristique',
    skillId: 2,
    centreId: 26,
    profileId: 90,
    applicationIds: undefined,
  },
  {
    id: 588,
    vacancy: 1,
    hired: 1,
    startsAt: '2021-05-10T17:54:13Z',
    endsAt: '2022-06-24T04:54:13Z',
    daysOff: [9, 1],
    createdAt: '2021-04-27T23:06:56Z',
    deletedAt: '2021-07-09T15:50:56Z',
    additionalInfo: 'dui',
    skillId: 2,
    centreId: 36,
    profileId: 60,
    applicationIds: [49, 37, 13, 18, 6, 38],
  },
  {
    id: 589,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-12-24T02:02:07Z',
    endsAt: '2022-05-30T11:02:07Z',
    daysOff: undefined,
    createdAt: '2021-05-18T21:17:58Z',
    deletedAt: '2020-08-12T08:20:29Z',
    additionalInfo: 'mi sit',
    skillId: 2,
    centreId: 1,
    profileId: 27,
    applicationIds: undefined,
  },
  {
    id: 590,
    vacancy: 9,
    hired: 1,
    startsAt: '2021-05-16T10:51:08Z',
    endsAt: '2022-06-04T21:51:08Z',
    daysOff: undefined,
    createdAt: '2020-11-27T17:16:32Z',
    deletedAt: undefined,
    additionalInfo: 'sapien',
    skillId: 1,
    centreId: 20,
    profileId: 92,
    applicationIds: undefined,
  },
  {
    id: 591,
    vacancy: 7,
    hired: 4,
    startsAt: '2021-01-19T15:23:15Z',
    endsAt: '2022-11-30T01:23:15Z',
    daysOff: undefined,
    createdAt: '2021-07-07T11:58:26Z',
    deletedAt: '2021-07-04T14:28:15Z',
    additionalInfo: 'curae donec',
    skillId: 3,
    centreId: 19,
    profileId: 50,
    applicationIds: undefined,
  },
  {
    id: 592,
    vacancy: 5,
    hired: 1,
    startsAt: '2020-09-26T11:41:03Z',
    endsAt: '2022-03-23T18:41:03Z',
    daysOff: undefined,
    createdAt: '2021-03-16T17:40:20Z',
    deletedAt: undefined,
    additionalInfo: 'adipiscing molestie',
    skillId: 2,
    centreId: 25,
    profileId: 53,
    applicationIds: undefined,
  },
  {
    id: 593,
    vacancy: 3,
    hired: 0,
    startsAt: '2021-04-27T01:38:39Z',
    endsAt: '2022-03-27T12:38:39Z',
    daysOff: undefined,
    createdAt: '2021-01-31T01:09:00Z',
    deletedAt: '2020-11-14T11:14:46Z',
    additionalInfo: 'undefineda nisl',
    skillId: 2,
    centreId: 16,
    profileId: 21,
    applicationIds: undefined,
  },
  {
    id: 594,
    vacancy: 7,
    hired: 4,
    startsAt: '2021-01-06T00:46:36Z',
    endsAt: '2021-02-13T05:46:36Z',
    daysOff: undefined,
    createdAt: '2021-07-10T10:00:36Z',
    deletedAt: '2021-03-05T23:01:29Z',
    additionalInfo: 'sed magna',
    skillId: 2,
    centreId: 50,
    profileId: 29,
    applicationIds: undefined,
  },
  {
    id: 595,
    vacancy: 8,
    hired: 4,
    startsAt: '2020-09-07T19:46:24Z',
    endsAt: '2020-12-10T05:46:24Z',
    daysOff: undefined,
    createdAt: '2021-07-20T18:38:59Z',
    deletedAt: '2021-06-16T01:47:51Z',
    additionalInfo: 'sapien',
    skillId: 3,
    centreId: 47,
    profileId: 70,
    applicationIds: undefined,
  },
  {
    id: 596,
    vacancy: 6,
    hired: 5,
    startsAt: '2020-08-13T10:00:54Z',
    endsAt: '2021-04-29T20:00:54Z',
    daysOff: undefined,
    createdAt: '2021-02-02T08:11:18Z',
    deletedAt: undefined,
    additionalInfo: 'donec',
    skillId: 1,
    centreId: 40,
    profileId: 39,
    applicationIds: undefined,
  },
  {
    id: 597,
    vacancy: 3,
    hired: 3,
    startsAt: '2021-04-24T20:58:47Z',
    endsAt: '2022-02-28T02:58:47Z',
    daysOff: undefined,
    createdAt: '2020-09-22T19:07:02Z',
    deletedAt: '2020-10-12T18:00:42Z',
    additionalInfo: 'nisi vulputate',
    skillId: 2,
    centreId: 28,
    profileId: 8,
    applicationIds: undefined,
  },
  {
    id: 598,
    vacancy: 7,
    hired: 3,
    startsAt: '2021-01-07T13:30:39Z',
    endsAt: '2021-08-11T01:30:39Z',
    daysOff: undefined,
    createdAt: '2021-06-08T21:40:05Z',
    deletedAt: '2021-01-05T16:37:12Z',
    additionalInfo: 'congue',
    skillId: 2,
    centreId: 26,
    profileId: 32,
    applicationIds: undefined,
  },
  {
    id: 599,
    vacancy: 6,
    hired: 2,
    startsAt: '2020-11-11T07:09:08Z',
    endsAt: '2022-05-10T17:09:08Z',
    daysOff: undefined,
    createdAt: '2020-09-13T06:34:36Z',
    deletedAt: '2020-10-17T19:19:00Z',
    additionalInfo: 'amet',
    skillId: 2,
    centreId: 4,
    profileId: 31,
    applicationIds: undefined,
  },
  {
    id: 600,
    vacancy: 7,
    hired: 6,
    startsAt: '2021-01-18T17:33:50Z',
    endsAt: '2021-04-02T04:33:50Z',
    daysOff: [10, 6],
    createdAt: '2021-06-11T11:32:39Z',
    deletedAt: undefined,
    additionalInfo: 'eu',
    skillId: 1,
    centreId: 14,
    profileId: 92,
    applicationIds: [41, 23, 21, 48, 28, 40],
  },
  {
    id: 601,
    vacancy: 3,
    hired: 2,
    startsAt: '2021-04-09T17:40:09Z',
    endsAt: '2023-03-21T01:40:09Z',
    daysOff: undefined,
    createdAt: '2020-07-24T09:06:23Z',
    deletedAt: '2021-04-18T16:15:51Z',
    additionalInfo: 'luctus',
    skillId: 3,
    centreId: 5,
    profileId: 36,
    applicationIds: undefined,
  },
  {
    id: 602,
    vacancy: 7,
    hired: 0,
    startsAt: '2021-01-14T18:56:03Z',
    endsAt: '2023-02-03T22:56:03Z',
    daysOff: [1, 5],
    createdAt: '2021-02-13T22:08:28Z',
    deletedAt: undefined,
    additionalInfo: 'lacus',
    skillId: 1,
    centreId: 15,
    profileId: 21,
    applicationIds: [39, 1, 46, 5, 15, 40],
  },
  {
    id: 603,
    vacancy: 5,
    hired: 3,
    startsAt: '2021-01-20T06:19:07Z',
    endsAt: '2022-07-11T14:19:07Z',
    daysOff: undefined,
    createdAt: '2021-03-24T17:14:55Z',
    deletedAt: '2020-11-03T16:18:11Z',
    additionalInfo: 'justo',
    skillId: 2,
    centreId: 13,
    profileId: 41,
    applicationIds: undefined,
  },
  {
    id: 604,
    vacancy: 5,
    hired: 1,
    startsAt: '2021-02-09T11:57:11Z',
    endsAt: '2021-05-20T15:57:11Z',
    daysOff: undefined,
    createdAt: '2020-09-26T02:25:13Z',
    deletedAt: undefined,
    additionalInfo: 'orci',
    skillId: 1,
    centreId: 22,
    profileId: 65,
    applicationIds: undefined,
  },
  {
    id: 605,
    vacancy: 6,
    hired: 0,
    startsAt: '2020-12-28T00:47:06Z',
    endsAt: '2021-02-07T07:47:06Z',
    daysOff: undefined,
    createdAt: '2021-04-17T12:38:33Z',
    deletedAt: '2021-06-07T13:12:23Z',
    additionalInfo: 'quis',
    skillId: 2,
    centreId: 20,
    profileId: 3,
    applicationIds: undefined,
  },
  {
    id: 606,
    vacancy: 5,
    hired: 4,
    startsAt: '2021-01-18T06:10:37Z',
    endsAt: '2022-08-11T18:10:37Z',
    daysOff: undefined,
    createdAt: '2021-03-22T07:23:03Z',
    deletedAt: undefined,
    additionalInfo: 'neque aenean',
    skillId: 2,
    centreId: 34,
    profileId: 42,
    applicationIds: undefined,
  },
  {
    id: 607,
    vacancy: 1,
    hired: 0,
    startsAt: '2021-03-10T11:02:08Z',
    endsAt: '2023-02-26T17:02:08Z',
    daysOff: undefined,
    createdAt: '2021-06-24T07:12:11Z',
    deletedAt: '2021-01-26T10:59:42Z',
    additionalInfo: 'curabitur',
    skillId: 2,
    centreId: 21,
    profileId: 80,
    applicationIds: undefined,
  },
  {
    id: 608,
    vacancy: 5,
    hired: 2,
    startsAt: '2020-11-16T20:56:02Z',
    endsAt: '2022-12-14T01:56:02Z',
    daysOff: [6, 2],
    createdAt: '2020-12-20T20:07:45Z',
    deletedAt: '2021-03-16T06:25:03Z',
    additionalInfo: 'donec',
    skillId: 1,
    centreId: 2,
    profileId: 6,
    applicationIds: [26, 30, 50, 12, 41, 50],
  },
  {
    id: 609,
    vacancy: 1,
    hired: 0,
    startsAt: '2021-03-19T09:04:11Z',
    endsAt: '2022-03-30T13:04:11Z',
    daysOff: undefined,
    createdAt: '2020-10-21T05:41:46Z',
    deletedAt: '2020-11-08T14:17:10Z',
    additionalInfo: 'nisl',
    skillId: 2,
    centreId: 29,
    profileId: 52,
    applicationIds: undefined,
  },
  {
    id: 610,
    vacancy: 10,
    hired: 1,
    startsAt: '2021-03-16T10:19:40Z',
    endsAt: '2022-09-25T20:19:40Z',
    daysOff: undefined,
    createdAt: '2020-11-21T11:37:44Z',
    deletedAt: '2021-06-06T07:24:30Z',
    additionalInfo: 'ante',
    skillId: 2,
    centreId: 19,
    profileId: 30,
    applicationIds: undefined,
  },
  {
    id: 611,
    vacancy: 2,
    hired: 1,
    startsAt: '2021-05-28T20:17:44Z',
    endsAt: '2022-03-05T04:17:44Z',
    daysOff: undefined,
    createdAt: '2020-08-12T04:46:54Z',
    deletedAt: '2021-01-31T02:14:57Z',
    additionalInfo: 'venenatis turpis',
    skillId: 1,
    centreId: 31,
    profileId: 85,
    applicationIds: undefined,
  },
  {
    id: 612,
    vacancy: 3,
    hired: 1,
    startsAt: '2021-06-18T11:52:12Z',
    endsAt: '2022-02-02T15:52:12Z',
    daysOff: undefined,
    createdAt: '2021-05-27T02:39:11Z',
    deletedAt: '2020-09-11T02:05:23Z',
    additionalInfo: 'duis at',
    skillId: 1,
    centreId: 19,
    profileId: 27,
    applicationIds: undefined,
  },
  {
    id: 613,
    vacancy: 4,
    hired: 1,
    startsAt: '2021-05-28T07:40:33Z',
    endsAt: '2021-11-17T11:40:33Z',
    daysOff: undefined,
    createdAt: '2021-03-04T07:10:12Z',
    deletedAt: '2021-04-23T12:02:45Z',
    additionalInfo: 'justo morbi',
    skillId: 1,
    centreId: 31,
    profileId: 18,
    applicationIds: undefined,
  },
  {
    id: 614,
    vacancy: 7,
    hired: 1,
    startsAt: '2021-02-22T14:25:28Z',
    endsAt: '2021-06-23T22:25:28Z',
    daysOff: undefined,
    createdAt: '2021-03-04T02:37:42Z',
    deletedAt: '2021-02-04T17:18:17Z',
    additionalInfo: 'lobortis ligula',
    skillId: 1,
    centreId: 21,
    profileId: 54,
    applicationIds: undefined,
  },
  {
    id: 615,
    vacancy: 9,
    hired: 2,
    startsAt: '2021-02-12T06:24:15Z',
    endsAt: '2022-07-09T10:24:15Z',
    daysOff: [10, 7],
    createdAt: '2020-11-12T22:20:21Z',
    deletedAt: undefined,
    additionalInfo: 'at',
    skillId: 3,
    centreId: 2,
    profileId: 13,
    applicationIds: [3, 30, 36, 29, 4, 23],
  },
  {
    id: 616,
    vacancy: 7,
    hired: 1,
    startsAt: '2021-04-17T13:00:47Z',
    endsAt: '2023-02-26T18:00:47Z',
    daysOff: undefined,
    createdAt: '2020-10-03T19:25:21Z',
    deletedAt: '2021-04-04T18:45:16Z',
    additionalInfo: 'elementum eu',
    skillId: 1,
    centreId: 5,
    profileId: 36,
    applicationIds: undefined,
  },
  {
    id: 617,
    vacancy: 4,
    hired: 2,
    startsAt: '2021-01-18T15:26:17Z',
    endsAt: '2022-12-29T18:26:17Z',
    daysOff: undefined,
    createdAt: '2020-07-31T01:35:05Z',
    deletedAt: '2021-06-12T05:39:02Z',
    additionalInfo: 'aliquam',
    skillId: 1,
    centreId: 20,
    profileId: 43,
    applicationIds: undefined,
  },
  {
    id: 618,
    vacancy: 6,
    hired: 2,
    startsAt: '2021-04-09T21:10:56Z',
    endsAt: '2022-07-29T01:10:56Z',
    daysOff: [7, 10],
    createdAt: '2020-09-17T18:20:42Z',
    deletedAt: '2020-11-26T02:48:10Z',
    additionalInfo: 'platea dictumst',
    skillId: 2,
    centreId: 28,
    profileId: 97,
    applicationIds: [48, 19, 16, 6, 6, 22],
  },
  {
    id: 619,
    vacancy: 6,
    hired: 5,
    startsAt: '2021-06-06T20:28:38Z',
    endsAt: '2023-04-01T02:28:38Z',
    daysOff: undefined,
    createdAt: '2021-05-21T13:45:43Z',
    deletedAt: '2020-08-15T19:19:42Z',
    additionalInfo: 'consequat',
    skillId: 3,
    centreId: 43,
    profileId: 58,
    applicationIds: undefined,
  },
  {
    id: 620,
    vacancy: 1,
    hired: 0,
    startsAt: '2020-09-08T06:18:04Z',
    endsAt: '2021-11-21T12:18:04Z',
    daysOff: undefined,
    createdAt: '2020-12-07T18:44:09Z',
    deletedAt: undefined,
    additionalInfo: 'erat tortor',
    skillId: 1,
    centreId: 29,
    profileId: 65,
    applicationIds: undefined,
  },
  {
    id: 621,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-12-24T22:39:03Z',
    endsAt: '2021-06-30T10:39:03Z',
    daysOff: undefined,
    createdAt: '2021-04-26T00:02:53Z',
    deletedAt: '2021-07-08T19:30:36Z',
    additionalInfo: 'magnis dis',
    skillId: 1,
    centreId: 49,
    profileId: 91,
    applicationIds: undefined,
  },
  {
    id: 622,
    vacancy: 3,
    hired: 2,
    startsAt: '2020-09-24T05:43:13Z',
    endsAt: '2021-12-10T14:43:13Z',
    daysOff: undefined,
    createdAt: '2021-02-01T05:18:43Z',
    deletedAt: '2021-02-27T06:12:01Z',
    additionalInfo: 'convallis tortor',
    skillId: 2,
    centreId: 35,
    profileId: 48,
    applicationIds: undefined,
  },
  {
    id: 623,
    vacancy: 4,
    hired: 0,
    startsAt: '2021-07-20T01:18:45Z',
    endsAt: '2021-10-01T10:18:45Z',
    daysOff: [1, 7],
    createdAt: '2020-09-22T01:21:20Z',
    deletedAt: '2021-03-15T05:39:51Z',
    additionalInfo: 'vitae consectetuer',
    skillId: 2,
    centreId: 19,
    profileId: 58,
    applicationIds: [40, 13, 15, 49, 26, 16],
  },
  {
    id: 624,
    vacancy: 5,
    hired: 0,
    startsAt: '2020-09-23T11:58:37Z',
    endsAt: '2021-12-22T15:58:37Z',
    daysOff: undefined,
    createdAt: '2021-04-14T17:57:12Z',
    deletedAt: '2021-04-05T09:24:01Z',
    additionalInfo: 'interdum eu',
    skillId: 1,
    centreId: 12,
    profileId: 73,
    applicationIds: undefined,
  },
  {
    id: 625,
    vacancy: 2,
    hired: 0,
    startsAt: '2021-04-07T17:16:27Z',
    endsAt: '2022-10-13T04:16:27Z',
    daysOff: undefined,
    createdAt: '2020-07-28T00:03:31Z',
    deletedAt: '2021-05-01T07:02:59Z',
    additionalInfo: 'id pretium',
    skillId: 2,
    centreId: 31,
    profileId: 4,
    applicationIds: undefined,
  },
  {
    id: 626,
    vacancy: 2,
    hired: 1,
    startsAt: '2020-09-12T08:09:08Z',
    endsAt: '2021-06-05T17:09:08Z',
    daysOff: undefined,
    createdAt: '2021-01-13T17:50:12Z',
    deletedAt: '2021-06-19T03:02:32Z',
    additionalInfo: 'undefineda',
    skillId: 1,
    centreId: 21,
    profileId: 78,
    applicationIds: undefined,
  },
  {
    id: 627,
    vacancy: 4,
    hired: 0,
    startsAt: '2020-11-02T09:58:16Z',
    endsAt: '2021-08-30T20:58:16Z',
    daysOff: undefined,
    createdAt: '2021-02-03T07:28:12Z',
    deletedAt: '2020-12-04T20:56:49Z',
    additionalInfo: 'id pretium',
    skillId: 3,
    centreId: 36,
    profileId: 42,
    applicationIds: undefined,
  },
  {
    id: 628,
    vacancy: 4,
    hired: 4,
    startsAt: '2021-06-01T22:06:13Z',
    endsAt: '2022-10-22T04:06:13Z',
    daysOff: [5, 2],
    createdAt: '2020-09-30T10:57:37Z',
    deletedAt: '2020-11-09T22:59:21Z',
    additionalInfo: 'turpis',
    skillId: 2,
    centreId: 2,
    profileId: 56,
    applicationIds: [8, 40, 23, 1, 7, 2],
  },
  {
    id: 629,
    vacancy: 4,
    hired: 4,
    startsAt: '2020-09-22T13:59:55Z',
    endsAt: '2020-11-01T23:59:55Z',
    daysOff: undefined,
    createdAt: '2021-05-22T20:53:43Z',
    deletedAt: '2020-08-21T11:33:59Z',
    additionalInfo: 'odio cras',
    skillId: 3,
    centreId: 32,
    profileId: 2,
    applicationIds: undefined,
  },
  {
    id: 630,
    vacancy: 5,
    hired: 4,
    startsAt: '2020-09-05T03:27:52Z',
    endsAt: '2022-04-28T09:27:52Z',
    daysOff: undefined,
    createdAt: '2020-11-19T03:24:17Z',
    deletedAt: '2021-06-14T11:33:03Z',
    additionalInfo: 'undefineda ac',
    skillId: 3,
    centreId: 21,
    profileId: 14,
    applicationIds: undefined,
  },
  {
    id: 631,
    vacancy: 8,
    hired: 0,
    startsAt: '2021-03-31T16:27:36Z',
    endsAt: '2022-12-06T20:27:36Z',
    daysOff: undefined,
    createdAt: '2020-11-26T11:43:23Z',
    deletedAt: '2020-12-22T06:08:02Z',
    additionalInfo: 'turpis',
    skillId: 1,
    centreId: 18,
    profileId: 86,
    applicationIds: undefined,
  },
  {
    id: 632,
    vacancy: 8,
    hired: 2,
    startsAt: '2021-02-16T21:28:41Z',
    endsAt: '2023-02-04T04:28:41Z',
    daysOff: undefined,
    createdAt: '2021-04-06T05:28:19Z',
    deletedAt: undefined,
    additionalInfo: 'semper',
    skillId: 2,
    centreId: 10,
    profileId: 47,
    applicationIds: undefined,
  },
  {
    id: 633,
    vacancy: 8,
    hired: 0,
    startsAt: '2021-04-30T10:17:57Z',
    endsAt: '2022-01-08T15:17:57Z',
    daysOff: [3, 1],
    createdAt: '2021-03-31T23:16:25Z',
    deletedAt: '2021-04-03T11:53:12Z',
    additionalInfo: 'semper est',
    skillId: 2,
    centreId: 1,
    profileId: 63,
    applicationIds: [30, 16, 18, 43, 44, 40],
  },
  {
    id: 634,
    vacancy: 7,
    hired: 1,
    startsAt: '2021-07-21T16:55:27Z',
    endsAt: '2023-02-07T21:55:27Z',
    daysOff: undefined,
    createdAt: '2020-11-29T07:53:35Z',
    deletedAt: '2021-03-22T17:37:28Z',
    additionalInfo: 'ac neque',
    skillId: 3,
    centreId: 3,
    profileId: 65,
    applicationIds: undefined,
  },
  {
    id: 635,
    vacancy: 9,
    hired: 0,
    startsAt: '2020-09-25T15:44:05Z',
    endsAt: '2022-10-06T22:44:05Z',
    daysOff: [3, 6],
    createdAt: '2021-07-02T17:59:33Z',
    deletedAt: '2021-06-03T23:52:21Z',
    additionalInfo: 'lacinia',
    skillId: 1,
    centreId: 37,
    profileId: 52,
    applicationIds: [34, 47, 30, 30, 31, 16],
  },
  {
    id: 636,
    vacancy: 2,
    hired: 0,
    startsAt: '2021-03-10T13:43:31Z',
    endsAt: '2022-05-05T16:43:31Z',
    daysOff: undefined,
    createdAt: '2021-01-29T11:16:22Z',
    deletedAt: undefined,
    additionalInfo: 'in',
    skillId: 3,
    centreId: 32,
    profileId: 88,
    applicationIds: undefined,
  },
  {
    id: 637,
    vacancy: 9,
    hired: 6,
    startsAt: '2021-01-12T08:34:12Z',
    endsAt: '2023-01-27T17:34:12Z',
    daysOff: [10, 2],
    createdAt: '2021-05-21T14:54:01Z',
    deletedAt: '2020-09-28T06:23:40Z',
    additionalInfo: 'tristique',
    skillId: 1,
    centreId: 40,
    profileId: 89,
    applicationIds: [18, 31, 3, 45, 43, 15],
  },
  {
    id: 638,
    vacancy: 1,
    hired: 0,
    startsAt: '2021-01-18T13:08:18Z',
    endsAt: '2022-09-09T21:08:18Z',
    daysOff: undefined,
    createdAt: '2021-01-25T09:45:44Z',
    deletedAt: undefined,
    additionalInfo: 'ipsum',
    skillId: 2,
    centreId: 5,
    profileId: 67,
    applicationIds: undefined,
  },
  {
    id: 639,
    vacancy: 4,
    hired: 1,
    startsAt: '2020-10-24T06:17:13Z',
    endsAt: '2022-07-10T11:17:13Z',
    daysOff: undefined,
    createdAt: '2020-11-22T03:07:18Z',
    deletedAt: '2020-12-14T08:31:24Z',
    additionalInfo: 'etiam',
    skillId: 3,
    centreId: 30,
    profileId: 100,
    applicationIds: undefined,
  },
  {
    id: 640,
    vacancy: 2,
    hired: 0,
    startsAt: '2020-10-27T17:09:34Z',
    endsAt: '2022-06-30T23:09:34Z',
    daysOff: undefined,
    createdAt: '2020-10-27T00:17:29Z',
    deletedAt: '2020-10-29T21:45:49Z',
    additionalInfo: 'vulputate elementum',
    skillId: 2,
    centreId: 42,
    profileId: 73,
    applicationIds: undefined,
  },
  {
    id: 641,
    vacancy: 8,
    hired: 1,
    startsAt: '2021-05-28T16:22:05Z',
    endsAt: '2022-02-05T04:22:05Z',
    daysOff: undefined,
    createdAt: '2021-04-02T00:40:04Z',
    deletedAt: '2020-08-08T00:47:26Z',
    additionalInfo: 'augue',
    skillId: 1,
    centreId: 29,
    profileId: 90,
    applicationIds: undefined,
  },
  {
    id: 642,
    vacancy: 5,
    hired: 4,
    startsAt: '2020-09-19T23:11:46Z',
    endsAt: '2022-10-13T07:11:46Z',
    daysOff: undefined,
    createdAt: '2021-04-08T22:18:35Z',
    deletedAt: '2020-11-27T06:52:41Z',
    additionalInfo: 'lectus',
    skillId: 1,
    centreId: 1,
    profileId: 49,
    applicationIds: undefined,
  },
  {
    id: 643,
    vacancy: 5,
    hired: 0,
    startsAt: '2021-05-29T12:42:25Z',
    endsAt: '2021-10-28T15:42:25Z',
    daysOff: undefined,
    createdAt: '2020-08-04T18:34:54Z',
    deletedAt: '2020-11-26T12:52:47Z',
    additionalInfo: 'lacus',
    skillId: 3,
    centreId: 12,
    profileId: 99,
    applicationIds: undefined,
  },
  {
    id: 644,
    vacancy: 6,
    hired: 4,
    startsAt: '2021-04-19T13:52:40Z',
    endsAt: '2022-08-05T23:52:40Z',
    daysOff: undefined,
    createdAt: '2021-04-07T09:35:22Z',
    deletedAt: '2020-09-25T14:51:01Z',
    additionalInfo: 'tellus',
    skillId: 2,
    centreId: 38,
    profileId: 64,
    applicationIds: undefined,
  },
  {
    id: 645,
    vacancy: 3,
    hired: 2,
    startsAt: '2021-05-13T03:55:39Z',
    endsAt: '2023-04-22T07:55:39Z',
    daysOff: undefined,
    createdAt: '2021-07-12T15:55:28Z',
    deletedAt: '2021-02-04T14:07:25Z',
    additionalInfo: 'felis',
    skillId: 3,
    centreId: 49,
    profileId: 51,
    applicationIds: undefined,
  },
  {
    id: 646,
    vacancy: 7,
    hired: 6,
    startsAt: '2021-05-04T15:22:48Z',
    endsAt: '2021-07-26T01:22:48Z',
    daysOff: undefined,
    createdAt: '2021-01-08T18:43:42Z',
    deletedAt: '2021-01-19T15:33:55Z',
    additionalInfo: 'non mauris',
    skillId: 2,
    centreId: 8,
    profileId: 33,
    applicationIds: undefined,
  },
  {
    id: 647,
    vacancy: 7,
    hired: 6,
    startsAt: '2021-01-27T03:56:04Z',
    endsAt: '2022-03-01T08:56:04Z',
    daysOff: [5, 6],
    createdAt: '2020-12-01T11:02:08Z',
    deletedAt: '2021-03-22T10:44:52Z',
    additionalInfo: 'eros vestibulum',
    skillId: 2,
    centreId: 45,
    profileId: 87,
    applicationIds: [39, 21, 11, 16, 22, 37],
  },
  {
    id: 648,
    vacancy: 6,
    hired: 2,
    startsAt: '2020-11-24T05:55:59Z',
    endsAt: '2021-10-18T16:55:59Z',
    daysOff: [2, 7],
    createdAt: '2021-04-14T08:04:54Z',
    deletedAt: '2021-01-13T11:47:28Z',
    additionalInfo: 'diam nam',
    skillId: 1,
    centreId: 7,
    profileId: 1,
    applicationIds: [28, 11, 5, 18, 28, 17],
  },
  {
    id: 649,
    vacancy: 7,
    hired: 4,
    startsAt: '2021-06-17T07:57:11Z',
    endsAt: '2023-03-28T15:57:11Z',
    daysOff: undefined,
    createdAt: '2020-09-22T04:00:45Z',
    deletedAt: '2020-09-30T23:49:14Z',
    additionalInfo: 'magna vulputate',
    skillId: 1,
    centreId: 46,
    profileId: 10,
    applicationIds: undefined,
  },
  {
    id: 650,
    vacancy: 5,
    hired: 5,
    startsAt: '2021-01-07T17:47:43Z',
    endsAt: '2022-02-13T02:47:43Z',
    daysOff: undefined,
    createdAt: '2020-11-04T16:59:47Z',
    deletedAt: '2021-01-29T08:42:13Z',
    additionalInfo: 'mauris laoreet',
    skillId: 2,
    centreId: 50,
    profileId: 37,
    applicationIds: undefined,
  },
  {
    id: 651,
    vacancy: 7,
    hired: 1,
    startsAt: '2020-10-10T02:21:53Z',
    endsAt: '2021-06-25T11:21:53Z',
    daysOff: [2, 10],
    createdAt: '2021-02-05T11:44:22Z',
    deletedAt: '2020-07-30T03:14:28Z',
    additionalInfo: 'vitae',
    skillId: 1,
    centreId: 25,
    profileId: 81,
    applicationIds: [10, 34, 29, 28, 4, 50],
  },
  {
    id: 652,
    vacancy: 5,
    hired: 3,
    startsAt: '2020-11-18T09:38:01Z',
    endsAt: '2021-10-27T14:38:01Z',
    daysOff: undefined,
    createdAt: '2021-03-09T17:28:53Z',
    deletedAt: '2020-09-07T00:51:25Z',
    additionalInfo: 'quis',
    skillId: 2,
    centreId: 35,
    profileId: 93,
    applicationIds: undefined,
  },
  {
    id: 653,
    vacancy: 10,
    hired: 8,
    startsAt: '2021-05-04T10:55:28Z',
    endsAt: '2021-09-16T20:55:28Z',
    daysOff: undefined,
    createdAt: '2021-03-25T13:49:23Z',
    deletedAt: undefined,
    additionalInfo: 'est',
    skillId: 2,
    centreId: 40,
    profileId: 77,
    applicationIds: undefined,
  },
  {
    id: 654,
    vacancy: 10,
    hired: 9,
    startsAt: '2020-12-12T06:46:52Z',
    endsAt: '2021-07-24T17:46:52Z',
    daysOff: [10, 5],
    createdAt: '2021-07-20T21:40:38Z',
    deletedAt: '2020-12-31T07:32:12Z',
    additionalInfo: 'interdum',
    skillId: 1,
    centreId: 2,
    profileId: 27,
    applicationIds: [16, 12, 48, 31, 12, 5],
  },
  {
    id: 655,
    vacancy: 5,
    hired: 0,
    startsAt: '2020-11-17T08:55:55Z',
    endsAt: '2022-01-17T18:55:55Z',
    daysOff: undefined,
    createdAt: '2020-08-30T14:44:39Z',
    deletedAt: '2021-03-14T19:05:26Z',
    additionalInfo: 'vitae mattis',
    skillId: 3,
    centreId: 44,
    profileId: 81,
    applicationIds: undefined,
  },
  {
    id: 656,
    vacancy: 1,
    hired: 0,
    startsAt: '2021-01-19T14:54:57Z',
    endsAt: '2021-03-01T22:54:57Z',
    daysOff: undefined,
    createdAt: '2021-06-26T17:18:39Z',
    deletedAt: '2021-06-25T18:06:38Z',
    additionalInfo: 'tincidunt',
    skillId: 1,
    centreId: 45,
    profileId: 96,
    applicationIds: undefined,
  },
  {
    id: 657,
    vacancy: 7,
    hired: 7,
    startsAt: '2021-01-19T11:14:21Z',
    endsAt: '2021-09-07T22:14:21Z',
    daysOff: [8, 6],
    createdAt: '2021-02-08T21:57:22Z',
    deletedAt: '2021-01-03T23:24:26Z',
    additionalInfo: 'id',
    skillId: 2,
    centreId: 30,
    profileId: 16,
    applicationIds: [14, 50, 14, 24, 36, 50],
  },
  {
    id: 658,
    vacancy: 10,
    hired: 2,
    startsAt: '2021-06-05T02:09:58Z',
    endsAt: '2022-07-28T08:09:58Z',
    daysOff: [6, 6],
    createdAt: '2021-03-26T11:36:58Z',
    deletedAt: '2021-06-10T21:29:12Z',
    additionalInfo: 'sagittis sapien',
    skillId: 2,
    centreId: 32,
    profileId: 50,
    applicationIds: [26, 49, 23, 37, 9, 13],
  },
  {
    id: 659,
    vacancy: 8,
    hired: 7,
    startsAt: '2021-02-08T00:55:51Z',
    endsAt: '2022-02-17T07:55:51Z',
    daysOff: undefined,
    createdAt: '2021-01-05T20:02:36Z',
    deletedAt: '2021-04-22T10:11:45Z',
    additionalInfo: 'condimentum',
    skillId: 2,
    centreId: 12,
    profileId: 98,
    applicationIds: undefined,
  },
  {
    id: 660,
    vacancy: 8,
    hired: 8,
    startsAt: '2020-08-29T00:24:15Z',
    endsAt: '2020-12-18T07:24:15Z',
    daysOff: undefined,
    createdAt: '2020-11-06T17:21:24Z',
    deletedAt: '2021-04-25T04:37:05Z',
    additionalInfo: 'sed accumsan',
    skillId: 3,
    centreId: 32,
    profileId: 64,
    applicationIds: undefined,
  },
  {
    id: 661,
    vacancy: 7,
    hired: 5,
    startsAt: '2021-05-16T07:18:01Z',
    endsAt: '2023-02-05T14:18:01Z',
    daysOff: undefined,
    createdAt: '2021-01-06T03:03:50Z',
    deletedAt: '2020-08-07T20:41:57Z',
    additionalInfo: 'amet undefineda',
    skillId: 2,
    centreId: 30,
    profileId: 85,
    applicationIds: undefined,
  },
  {
    id: 662,
    vacancy: 9,
    hired: 0,
    startsAt: '2020-12-07T20:00:49Z',
    endsAt: '2022-08-26T04:00:49Z',
    daysOff: undefined,
    createdAt: '2021-03-25T18:10:36Z',
    deletedAt: '2020-10-27T00:34:09Z',
    additionalInfo: 'pulvinar',
    skillId: 2,
    centreId: 33,
    profileId: 19,
    applicationIds: undefined,
  },
  {
    id: 663,
    vacancy: 5,
    hired: 1,
    startsAt: '2020-09-10T12:12:31Z',
    endsAt: '2021-08-16T19:12:31Z',
    daysOff: [8, 7],
    createdAt: '2020-10-05T10:24:06Z',
    deletedAt: '2020-07-31T18:40:04Z',
    additionalInfo: 'est congue',
    skillId: 1,
    centreId: 6,
    profileId: 24,
    applicationIds: [36, 34, 10, 39, 36, 28],
  },
  {
    id: 664,
    vacancy: 5,
    hired: 5,
    startsAt: '2021-02-15T08:39:56Z',
    endsAt: '2023-03-17T16:39:56Z',
    daysOff: [9, 7],
    createdAt: '2021-01-15T22:05:59Z',
    deletedAt: '2021-03-23T01:51:15Z',
    additionalInfo: 'est risus',
    skillId: 2,
    centreId: 25,
    profileId: 53,
    applicationIds: [17, 38, 6, 8, 8, 13],
  },
  {
    id: 665,
    vacancy: 5,
    hired: 3,
    startsAt: '2020-08-23T14:49:30Z',
    endsAt: '2021-02-12T18:49:30Z',
    daysOff: undefined,
    createdAt: '2020-08-04T15:47:06Z',
    deletedAt: '2020-09-22T08:11:00Z',
    additionalInfo: 'diam id',
    skillId: 1,
    centreId: 3,
    profileId: 2,
    applicationIds: undefined,
  },
  {
    id: 666,
    vacancy: 3,
    hired: 1,
    startsAt: '2020-11-07T05:57:54Z',
    endsAt: '2022-03-21T09:57:54Z',
    daysOff: [3, 2],
    createdAt: '2021-03-28T12:48:35Z',
    deletedAt: undefined,
    additionalInfo: 'nunc proin',
    skillId: 1,
    centreId: 44,
    profileId: 37,
    applicationIds: [47, 50, 24, 19, 44, 45],
  },
  {
    id: 667,
    vacancy: 8,
    hired: 0,
    startsAt: '2021-06-03T20:33:51Z',
    endsAt: '2021-11-01T04:33:51Z',
    daysOff: [9, 7],
    createdAt: '2020-09-16T17:11:58Z',
    deletedAt: '2021-03-14T16:08:21Z',
    additionalInfo: 'a',
    skillId: 3,
    centreId: 25,
    profileId: 49,
    applicationIds: [31, 42, 31, 48, 38, 11],
  },
  {
    id: 668,
    vacancy: 9,
    hired: 7,
    startsAt: '2020-11-11T17:23:02Z',
    endsAt: '2022-05-01T22:23:02Z',
    daysOff: undefined,
    createdAt: '2020-08-13T04:27:58Z',
    deletedAt: '2021-06-07T04:40:05Z',
    additionalInfo: 'in consequat',
    skillId: 1,
    centreId: 22,
    profileId: 56,
    applicationIds: undefined,
  },
  {
    id: 669,
    vacancy: 4,
    hired: 3,
    startsAt: '2020-10-18T09:21:16Z',
    endsAt: '2021-03-10T14:21:16Z',
    daysOff: undefined,
    createdAt: '2020-11-20T20:25:53Z',
    deletedAt: undefined,
    additionalInfo: 'in tempor',
    skillId: 2,
    centreId: 12,
    profileId: 48,
    applicationIds: undefined,
  },
  {
    id: 670,
    vacancy: 6,
    hired: 6,
    startsAt: '2021-04-15T05:06:08Z',
    endsAt: '2022-05-11T08:06:08Z',
    daysOff: [9, 3],
    createdAt: '2020-09-15T12:17:31Z',
    deletedAt: undefined,
    additionalInfo: 'magna bibendum',
    skillId: 1,
    centreId: 40,
    profileId: 75,
    applicationIds: [46, 33, 28, 22, 40, 10],
  },
  {
    id: 671,
    vacancy: 5,
    hired: 5,
    startsAt: '2021-03-08T20:56:55Z',
    endsAt: '2022-09-06T04:56:55Z',
    daysOff: undefined,
    createdAt: '2020-07-23T23:25:17Z',
    deletedAt: '2020-09-03T17:09:19Z',
    additionalInfo: 'ut erat',
    skillId: 2,
    centreId: 44,
    profileId: 60,
    applicationIds: undefined,
  },
  {
    id: 672,
    vacancy: 8,
    hired: 4,
    startsAt: '2021-06-04T12:11:47Z',
    endsAt: '2022-06-08T18:11:47Z',
    daysOff: [1, 2],
    createdAt: '2020-12-29T16:54:32Z',
    deletedAt: '2021-03-14T06:24:43Z',
    additionalInfo: 'ultrices',
    skillId: 3,
    centreId: 34,
    profileId: 97,
    applicationIds: [25, 35, 32, 15, 16, 13],
  },
  {
    id: 673,
    vacancy: 6,
    hired: 6,
    startsAt: '2021-03-29T19:13:11Z',
    endsAt: '2022-03-08T01:13:11Z',
    daysOff: undefined,
    createdAt: '2021-05-06T19:11:42Z',
    deletedAt: undefined,
    additionalInfo: 'vestibulum',
    skillId: 2,
    centreId: 47,
    profileId: 41,
    applicationIds: undefined,
  },
  {
    id: 674,
    vacancy: 9,
    hired: 6,
    startsAt: '2021-01-15T20:15:08Z',
    endsAt: '2021-05-06T01:15:08Z',
    daysOff: [2, 3],
    createdAt: '2020-08-01T20:26:40Z',
    deletedAt: undefined,
    additionalInfo: 'leo',
    skillId: 3,
    centreId: 35,
    profileId: 27,
    applicationIds: [38, 43, 37, 38, 9, 22],
  },
  {
    id: 675,
    vacancy: 9,
    hired: 6,
    startsAt: '2021-07-09T17:46:51Z',
    endsAt: '2022-01-18T05:46:51Z',
    daysOff: undefined,
    createdAt: '2021-05-27T03:47:01Z',
    deletedAt: undefined,
    additionalInfo: 'in',
    skillId: 2,
    centreId: 10,
    profileId: 89,
    applicationIds: undefined,
  },
  {
    id: 676,
    vacancy: 6,
    hired: 6,
    startsAt: '2020-09-12T00:11:46Z',
    endsAt: '2022-02-12T12:11:46Z',
    daysOff: [4, 5],
    createdAt: '2021-07-02T22:45:49Z',
    deletedAt: '2021-06-20T08:20:48Z',
    additionalInfo: 'sapien ut',
    skillId: 3,
    centreId: 19,
    profileId: 28,
    applicationIds: [11, 12, 50, 11, 43, 25],
  },
  {
    id: 677,
    vacancy: 3,
    hired: 0,
    startsAt: '2021-01-07T18:50:35Z',
    endsAt: '2022-03-06T23:50:35Z',
    daysOff: undefined,
    createdAt: '2020-11-30T00:13:34Z',
    deletedAt: '2021-05-18T21:00:35Z',
    additionalInfo: 'orci',
    skillId: 2,
    centreId: 24,
    profileId: 34,
    applicationIds: undefined,
  },
  {
    id: 678,
    vacancy: 8,
    hired: 6,
    startsAt: '2020-09-28T00:40:11Z',
    endsAt: '2021-08-22T10:40:11Z',
    daysOff: undefined,
    createdAt: '2020-11-05T12:07:07Z',
    deletedAt: undefined,
    additionalInfo: 'consequat lectus',
    skillId: 2,
    centreId: 46,
    profileId: 39,
    applicationIds: undefined,
  },
  {
    id: 679,
    vacancy: 5,
    hired: 1,
    startsAt: '2021-05-02T08:03:15Z',
    endsAt: '2022-04-16T11:03:15Z',
    daysOff: undefined,
    createdAt: '2021-07-20T11:07:43Z',
    deletedAt: '2021-07-02T18:24:01Z',
    additionalInfo: 'eget',
    skillId: 3,
    centreId: 15,
    profileId: 7,
    applicationIds: undefined,
  },
  {
    id: 680,
    vacancy: 5,
    hired: 2,
    startsAt: '2020-09-02T02:42:51Z',
    endsAt: '2021-07-13T08:42:51Z',
    daysOff: undefined,
    createdAt: '2021-02-22T10:02:06Z',
    deletedAt: '2020-10-24T11:18:15Z',
    additionalInfo: 'sed magna',
    skillId: 2,
    centreId: 41,
    profileId: 38,
    applicationIds: undefined,
  },
  {
    id: 681,
    vacancy: 9,
    hired: 5,
    startsAt: '2020-12-15T12:37:34Z',
    endsAt: '2022-11-26T15:37:34Z',
    daysOff: undefined,
    createdAt: '2021-03-22T07:48:07Z',
    deletedAt: '2020-10-08T11:03:05Z',
    additionalInfo: 'aliquet massa',
    skillId: 3,
    centreId: 47,
    profileId: 79,
    applicationIds: undefined,
  },
  {
    id: 682,
    vacancy: 4,
    hired: 0,
    startsAt: '2020-08-11T16:39:14Z',
    endsAt: '2022-01-16T23:39:14Z',
    daysOff: undefined,
    createdAt: '2020-09-16T12:44:57Z',
    deletedAt: '2021-04-09T12:44:09Z',
    additionalInfo: 'nec',
    skillId: 2,
    centreId: 45,
    profileId: 61,
    applicationIds: undefined,
  },
  {
    id: 683,
    vacancy: 6,
    hired: 5,
    startsAt: '2020-08-02T14:33:25Z',
    endsAt: '2021-08-12T21:33:25Z',
    daysOff: [3, 6],
    createdAt: '2020-12-23T18:04:22Z',
    deletedAt: '2021-01-12T03:18:08Z',
    additionalInfo: 'sed',
    skillId: 2,
    centreId: 25,
    profileId: 45,
    applicationIds: [20, 7, 22, 30, 24, 4],
  },
  {
    id: 684,
    vacancy: 9,
    hired: 0,
    startsAt: '2021-05-22T10:35:34Z',
    endsAt: '2021-11-08T18:35:34Z',
    daysOff: undefined,
    createdAt: '2021-07-16T23:05:46Z',
    deletedAt: '2021-06-24T05:15:20Z',
    additionalInfo: 'ipsum primis',
    skillId: 2,
    centreId: 25,
    profileId: 50,
    applicationIds: undefined,
  },
  {
    id: 685,
    vacancy: 9,
    hired: 8,
    startsAt: '2020-11-16T07:20:10Z',
    endsAt: '2022-04-01T15:20:10Z',
    daysOff: undefined,
    createdAt: '2020-11-06T07:11:04Z',
    deletedAt: '2021-04-11T14:15:15Z',
    additionalInfo: 'congue',
    skillId: 2,
    centreId: 13,
    profileId: 95,
    applicationIds: undefined,
  },
  {
    id: 686,
    vacancy: 1,
    hired: 0,
    startsAt: '2020-08-06T02:17:25Z',
    endsAt: '2021-01-29T11:17:25Z',
    daysOff: [7, 4],
    createdAt: '2021-01-30T09:08:16Z',
    deletedAt: '2020-08-06T11:03:23Z',
    additionalInfo: 'primis in',
    skillId: 1,
    centreId: 9,
    profileId: 86,
    applicationIds: [4, 32, 45, 46, 21, 41],
  },
  {
    id: 687,
    vacancy: 4,
    hired: 3,
    startsAt: '2020-08-12T14:20:45Z',
    endsAt: '2022-01-26T17:20:45Z',
    daysOff: undefined,
    createdAt: '2021-03-31T21:37:19Z',
    deletedAt: undefined,
    additionalInfo: 'enim in',
    skillId: 3,
    centreId: 42,
    profileId: 49,
    applicationIds: undefined,
  },
  {
    id: 688,
    vacancy: 8,
    hired: 8,
    startsAt: '2021-04-25T10:19:30Z',
    endsAt: '2022-08-22T16:19:30Z',
    daysOff: undefined,
    createdAt: '2021-05-23T00:23:22Z',
    deletedAt: '2021-06-02T17:25:01Z',
    additionalInfo: 'adipiscing',
    skillId: 1,
    centreId: 47,
    profileId: 31,
    applicationIds: undefined,
  },
  {
    id: 689,
    vacancy: 9,
    hired: 7,
    startsAt: '2020-08-10T03:01:50Z',
    endsAt: '2022-03-11T06:01:50Z',
    daysOff: [10, 6],
    createdAt: '2020-08-04T23:23:46Z',
    deletedAt: undefined,
    additionalInfo: 'aenean auctor',
    skillId: 2,
    centreId: 27,
    profileId: 39,
    applicationIds: [45, 28, 10, 41, 36, 21],
  },
  {
    id: 690,
    vacancy: 1,
    hired: 0,
    startsAt: '2020-10-11T04:22:48Z',
    endsAt: '2022-04-24T15:22:48Z',
    daysOff: undefined,
    createdAt: '2020-09-02T08:44:45Z',
    deletedAt: '2021-07-03T16:44:22Z',
    additionalInfo: 'condimentum neque',
    skillId: 3,
    centreId: 26,
    profileId: 7,
    applicationIds: undefined,
  },
  {
    id: 691,
    vacancy: 4,
    hired: 1,
    startsAt: '2020-08-04T15:33:00Z',
    endsAt: '2021-05-16T03:33:00Z',
    daysOff: [4, 8],
    createdAt: '2020-12-04T15:53:58Z',
    deletedAt: undefined,
    additionalInfo: 'a pede',
    skillId: 1,
    centreId: 43,
    profileId: 10,
    applicationIds: [22, 22, 38, 12, 31, 41],
  },
  {
    id: 692,
    vacancy: 7,
    hired: 7,
    startsAt: '2021-01-10T12:45:51Z',
    endsAt: '2021-11-11T21:45:51Z',
    daysOff: undefined,
    createdAt: '2021-04-25T16:41:36Z',
    deletedAt: undefined,
    additionalInfo: 'lorem',
    skillId: 2,
    centreId: 4,
    profileId: 43,
    applicationIds: undefined,
  },
  {
    id: 693,
    vacancy: 3,
    hired: 1,
    startsAt: '2020-11-07T02:35:13Z',
    endsAt: '2022-01-01T14:35:13Z',
    daysOff: [10, 2],
    createdAt: '2020-09-13T09:58:59Z',
    deletedAt: '2021-02-03T09:30:00Z',
    additionalInfo: 'donec',
    skillId: 2,
    centreId: 33,
    profileId: 32,
    applicationIds: [35, 15, 17, 38, 1, 5],
  },
  {
    id: 694,
    vacancy: 3,
    hired: 3,
    startsAt: '2020-11-27T21:30:15Z',
    endsAt: '2022-05-25T07:30:15Z',
    daysOff: [7, 6],
    createdAt: '2021-03-23T08:56:23Z',
    deletedAt: undefined,
    additionalInfo: 'tellus nisi',
    skillId: 2,
    centreId: 14,
    profileId: 78,
    applicationIds: [49, 23, 19, 11, 3, 16],
  },
  {
    id: 695,
    vacancy: 5,
    hired: 4,
    startsAt: '2021-02-17T23:18:41Z',
    endsAt: '2021-12-28T03:18:41Z',
    daysOff: undefined,
    createdAt: '2021-04-21T18:06:26Z',
    deletedAt: '2021-05-25T03:10:29Z',
    additionalInfo: 'dapibus dolor',
    skillId: 3,
    centreId: 16,
    profileId: 42,
    applicationIds: undefined,
  },
  {
    id: 696,
    vacancy: 9,
    hired: 5,
    startsAt: '2020-09-05T10:58:28Z',
    endsAt: '2021-02-19T20:58:28Z',
    daysOff: [3, 10],
    createdAt: '2021-01-03T19:43:03Z',
    deletedAt: '2020-08-07T01:26:36Z',
    additionalInfo: 'dapibus dolor',
    skillId: 1,
    centreId: 13,
    profileId: 3,
    applicationIds: [28, 11, 36, 16, 39, 48],
  },
  {
    id: 697,
    vacancy: 10,
    hired: 7,
    startsAt: '2020-10-15T13:05:26Z',
    endsAt: '2022-03-07T01:05:26Z',
    daysOff: [8, 2],
    createdAt: '2020-08-20T23:00:04Z',
    deletedAt: '2020-12-18T11:48:09Z',
    additionalInfo: 'turpis donec',
    skillId: 2,
    centreId: 26,
    profileId: 83,
    applicationIds: [12, 24, 14, 37, 2, 48],
  },
  {
    id: 698,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-10-10T05:23:01Z',
    endsAt: '2022-08-04T13:23:01Z',
    daysOff: undefined,
    createdAt: '2021-02-02T19:51:19Z',
    deletedAt: '2020-10-04T08:01:01Z',
    additionalInfo: 'amet consectetuer',
    skillId: 1,
    centreId: 25,
    profileId: 62,
    applicationIds: undefined,
  },
  {
    id: 699,
    vacancy: 10,
    hired: 3,
    startsAt: '2021-03-17T02:07:37Z',
    endsAt: '2023-01-28T05:07:37Z',
    daysOff: undefined,
    createdAt: '2020-09-19T17:28:57Z',
    deletedAt: '2021-07-13T14:48:32Z',
    additionalInfo: 'volutpat',
    skillId: 2,
    centreId: 1,
    profileId: 73,
    applicationIds: undefined,
  },
  {
    id: 700,
    vacancy: 10,
    hired: 10,
    startsAt: '2020-10-17T01:12:08Z',
    endsAt: '2021-08-06T08:12:08Z',
    daysOff: [6, 7],
    createdAt: '2021-05-26T03:15:53Z',
    deletedAt: '2021-03-09T08:29:23Z',
    additionalInfo: 'imperdiet et',
    skillId: 2,
    centreId: 8,
    profileId: 12,
    applicationIds: [18, 43, 23, 25, 39, 41],
  },
  {
    id: 701,
    vacancy: 6,
    hired: 5,
    startsAt: '2021-06-18T12:43:37Z',
    endsAt: '2021-11-25T20:43:37Z',
    daysOff: undefined,
    createdAt: '2021-01-07T00:18:08Z',
    deletedAt: undefined,
    additionalInfo: 'nascetur',
    skillId: 2,
    centreId: 27,
    profileId: 81,
    applicationIds: undefined,
  },
  {
    id: 702,
    vacancy: 9,
    hired: 5,
    startsAt: '2021-03-04T07:10:09Z',
    endsAt: '2022-02-25T13:10:09Z',
    daysOff: undefined,
    createdAt: '2020-09-30T03:00:23Z',
    deletedAt: '2020-09-22T04:10:15Z',
    additionalInfo: 'odio',
    skillId: 3,
    centreId: 34,
    profileId: 43,
    applicationIds: undefined,
  },
  {
    id: 703,
    vacancy: 8,
    hired: 2,
    startsAt: '2020-09-24T18:57:44Z',
    endsAt: '2022-06-17T00:57:44Z',
    daysOff: undefined,
    createdAt: '2020-08-26T03:49:12Z',
    deletedAt: '2021-05-25T09:04:16Z',
    additionalInfo: 'turpis',
    skillId: 3,
    centreId: 28,
    profileId: 21,
    applicationIds: undefined,
  },
  {
    id: 704,
    vacancy: 4,
    hired: 1,
    startsAt: '2020-09-26T01:07:06Z',
    endsAt: '2022-09-22T08:07:06Z',
    daysOff: undefined,
    createdAt: '2020-08-24T18:35:01Z',
    deletedAt: '2020-07-28T16:45:41Z',
    additionalInfo: 'hac',
    skillId: 1,
    centreId: 7,
    profileId: 99,
    applicationIds: undefined,
  },
  {
    id: 705,
    vacancy: 1,
    hired: 0,
    startsAt: '2021-03-27T03:09:24Z',
    endsAt: '2022-04-05T14:09:24Z',
    daysOff: undefined,
    createdAt: '2021-04-09T06:48:22Z',
    deletedAt: '2021-01-14T09:23:06Z',
    additionalInfo: 'diam',
    skillId: 1,
    centreId: 36,
    profileId: 100,
    applicationIds: undefined,
  },
  {
    id: 706,
    vacancy: 7,
    hired: 4,
    startsAt: '2021-05-07T23:50:18Z',
    endsAt: '2021-11-24T09:50:18Z',
    daysOff: undefined,
    createdAt: '2021-05-23T04:46:08Z',
    deletedAt: '2020-11-06T05:01:30Z',
    additionalInfo: 'nisi',
    skillId: 3,
    centreId: 44,
    profileId: 10,
    applicationIds: undefined,
  },
  {
    id: 707,
    vacancy: 10,
    hired: 4,
    startsAt: '2021-01-10T22:33:50Z',
    endsAt: '2021-08-08T04:33:50Z',
    daysOff: [5, 2],
    createdAt: '2021-06-04T00:37:08Z',
    deletedAt: '2021-05-15T19:11:29Z',
    additionalInfo: 'fusce consequat',
    skillId: 3,
    centreId: 9,
    profileId: 36,
    applicationIds: [38, 6, 36, 2, 49, 49],
  },
  {
    id: 708,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-10-12T03:03:33Z',
    endsAt: '2022-03-08T07:03:33Z',
    daysOff: [2, 9],
    createdAt: '2020-10-07T11:27:03Z',
    deletedAt: '2021-02-26T22:51:00Z',
    additionalInfo: 'vel',
    skillId: 2,
    centreId: 26,
    profileId: 3,
    applicationIds: [32, 33, 38, 25, 24, 42],
  },
  {
    id: 709,
    vacancy: 4,
    hired: 3,
    startsAt: '2020-12-23T20:37:34Z',
    endsAt: '2023-01-12T08:37:34Z',
    daysOff: [6, 5],
    createdAt: '2020-09-07T12:46:46Z',
    deletedAt: '2020-11-11T17:25:28Z',
    additionalInfo: 'mi',
    skillId: 1,
    centreId: 24,
    profileId: 93,
    applicationIds: [19, 42, 9, 43, 5, 50],
  },
  {
    id: 710,
    vacancy: 4,
    hired: 3,
    startsAt: '2021-06-01T02:56:00Z',
    endsAt: '2022-06-29T05:56:00Z',
    daysOff: undefined,
    createdAt: '2020-11-11T17:43:50Z',
    deletedAt: '2021-03-30T13:01:15Z',
    additionalInfo: 'in',
    skillId: 3,
    centreId: 14,
    profileId: 81,
    applicationIds: undefined,
  },
  {
    id: 711,
    vacancy: 9,
    hired: 0,
    startsAt: '2021-04-04T13:03:35Z',
    endsAt: '2022-11-22T16:03:35Z',
    daysOff: undefined,
    createdAt: '2021-03-06T09:46:11Z',
    deletedAt: '2021-07-04T06:27:04Z',
    additionalInfo: 'mi pede',
    skillId: 3,
    centreId: 30,
    profileId: 12,
    applicationIds: undefined,
  },
  {
    id: 712,
    vacancy: 2,
    hired: 1,
    startsAt: '2020-11-29T16:11:58Z',
    endsAt: '2021-12-08T02:11:58Z',
    daysOff: undefined,
    createdAt: '2020-12-19T07:49:42Z',
    deletedAt: undefined,
    additionalInfo: 'in blandit',
    skillId: 2,
    centreId: 17,
    profileId: 67,
    applicationIds: undefined,
  },
  {
    id: 713,
    vacancy: 8,
    hired: 0,
    startsAt: '2021-04-06T16:32:24Z',
    endsAt: '2022-04-06T21:32:24Z',
    daysOff: undefined,
    createdAt: '2021-04-26T02:07:53Z',
    deletedAt: '2020-09-06T17:31:41Z',
    additionalInfo: 'felis',
    skillId: 3,
    centreId: 35,
    profileId: 19,
    applicationIds: undefined,
  },
  {
    id: 714,
    vacancy: 3,
    hired: 2,
    startsAt: '2021-02-09T13:34:47Z',
    endsAt: '2021-05-06T01:34:47Z',
    daysOff: undefined,
    createdAt: '2021-04-06T04:49:11Z',
    deletedAt: '2021-02-22T12:51:57Z',
    additionalInfo: 'tincidunt',
    skillId: 1,
    centreId: 45,
    profileId: 65,
    applicationIds: undefined,
  },
  {
    id: 715,
    vacancy: 5,
    hired: 0,
    startsAt: '2020-12-02T01:31:20Z',
    endsAt: '2021-07-30T05:31:20Z',
    daysOff: undefined,
    createdAt: '2020-12-24T08:05:11Z',
    deletedAt: '2020-12-21T18:09:34Z',
    additionalInfo: 'sapien cum',
    skillId: 3,
    centreId: 1,
    profileId: 62,
    applicationIds: undefined,
  },
  {
    id: 716,
    vacancy: 6,
    hired: 0,
    startsAt: '2020-11-28T17:21:42Z',
    endsAt: '2022-02-07T05:21:42Z',
    daysOff: [3, 1],
    createdAt: '2020-12-26T12:45:39Z',
    deletedAt: '2020-10-14T20:00:30Z',
    additionalInfo: 'convallis',
    skillId: 3,
    centreId: 9,
    profileId: 54,
    applicationIds: [50, 38, 6, 46, 17, 36],
  },
  {
    id: 717,
    vacancy: 7,
    hired: 7,
    startsAt: '2020-08-28T13:32:47Z',
    endsAt: '2021-12-04T16:32:47Z',
    daysOff: [10, 10],
    createdAt: '2020-08-15T09:35:41Z',
    deletedAt: '2020-08-05T07:57:18Z',
    additionalInfo: 'in consequat',
    skillId: 2,
    centreId: 18,
    profileId: 72,
    applicationIds: [32, 37, 34, 34, 42, 46],
  },
  {
    id: 718,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-08-14T13:22:42Z',
    endsAt: '2022-06-09T21:22:42Z',
    daysOff: undefined,
    createdAt: '2021-05-25T03:13:40Z',
    deletedAt: '2021-02-13T04:24:41Z',
    additionalInfo: 'velit donec',
    skillId: 2,
    centreId: 34,
    profileId: 25,
    applicationIds: undefined,
  },
  {
    id: 719,
    vacancy: 5,
    hired: 2,
    startsAt: '2021-06-11T07:04:41Z',
    endsAt: '2021-10-04T18:04:41Z',
    daysOff: [4, 8],
    createdAt: '2021-01-09T18:05:49Z',
    deletedAt: '2021-04-19T07:15:44Z',
    additionalInfo: 'tristique',
    skillId: 2,
    centreId: 11,
    profileId: 60,
    applicationIds: [8, 15, 6, 26, 21, 5],
  },
  {
    id: 720,
    vacancy: 4,
    hired: 4,
    startsAt: '2021-07-14T02:01:47Z',
    endsAt: '2021-08-28T08:01:47Z',
    daysOff: undefined,
    createdAt: '2020-11-23T06:23:53Z',
    deletedAt: '2021-05-06T08:59:17Z',
    additionalInfo: 'lorem integer',
    skillId: 2,
    centreId: 35,
    profileId: 98,
    applicationIds: undefined,
  },
  {
    id: 721,
    vacancy: 1,
    hired: 0,
    startsAt: '2021-01-18T10:49:23Z',
    endsAt: '2022-04-05T14:49:23Z',
    daysOff: [10, 5],
    createdAt: '2021-01-06T21:51:46Z',
    deletedAt: '2020-11-26T03:26:58Z',
    additionalInfo: 'nec',
    skillId: 2,
    centreId: 22,
    profileId: 80,
    applicationIds: [19, 35, 29, 47, 34, 48],
  },
  {
    id: 722,
    vacancy: 6,
    hired: 5,
    startsAt: '2021-05-31T05:14:28Z',
    endsAt: '2022-08-30T17:14:28Z',
    daysOff: undefined,
    createdAt: '2021-04-07T23:10:30Z',
    deletedAt: '2021-04-07T01:59:49Z',
    additionalInfo: 'vel lectus',
    skillId: 3,
    centreId: 7,
    profileId: 29,
    applicationIds: undefined,
  },
  {
    id: 723,
    vacancy: 5,
    hired: 4,
    startsAt: '2020-12-23T16:14:11Z',
    endsAt: '2021-03-15T02:14:11Z',
    daysOff: undefined,
    createdAt: '2020-12-30T13:52:42Z',
    deletedAt: '2020-09-09T15:00:59Z',
    additionalInfo: 'erat',
    skillId: 3,
    centreId: 43,
    profileId: 68,
    applicationIds: undefined,
  },
  {
    id: 724,
    vacancy: 7,
    hired: 3,
    startsAt: '2021-05-20T15:36:35Z',
    endsAt: '2022-06-13T23:36:35Z',
    daysOff: undefined,
    createdAt: '2020-09-29T14:05:41Z',
    deletedAt: '2021-01-15T15:51:41Z',
    additionalInfo: 'curae mauris',
    skillId: 2,
    centreId: 9,
    profileId: 47,
    applicationIds: undefined,
  },
  {
    id: 725,
    vacancy: 2,
    hired: 1,
    startsAt: '2021-04-02T18:08:20Z',
    endsAt: '2021-06-08T05:08:20Z',
    daysOff: undefined,
    createdAt: '2021-01-03T12:44:46Z',
    deletedAt: '2020-09-21T06:17:11Z',
    additionalInfo: 'eu',
    skillId: 1,
    centreId: 8,
    profileId: 84,
    applicationIds: undefined,
  },
  {
    id: 726,
    vacancy: 10,
    hired: 4,
    startsAt: '2020-10-09T03:29:04Z',
    endsAt: '2022-06-16T10:29:04Z',
    daysOff: [8, 8],
    createdAt: '2021-05-25T03:57:14Z',
    deletedAt: '2021-02-11T09:09:02Z',
    additionalInfo: 'primis in',
    skillId: 1,
    centreId: 6,
    profileId: 48,
    applicationIds: [39, 36, 27, 15, 43, 45],
  },
  {
    id: 727,
    vacancy: 9,
    hired: 1,
    startsAt: '2020-11-04T08:32:37Z',
    endsAt: '2021-11-30T18:32:37Z',
    daysOff: undefined,
    createdAt: '2021-02-07T14:37:50Z',
    deletedAt: undefined,
    additionalInfo: 'vestibulum proin',
    skillId: 2,
    centreId: 26,
    profileId: 37,
    applicationIds: undefined,
  },
  {
    id: 728,
    vacancy: 2,
    hired: 1,
    startsAt: '2020-10-09T03:29:03Z',
    endsAt: '2020-11-28T15:29:03Z',
    daysOff: [1, 6],
    createdAt: '2021-06-11T14:22:48Z',
    deletedAt: '2020-08-02T21:18:49Z',
    additionalInfo: 'sapien dignissim',
    skillId: 1,
    centreId: 42,
    profileId: 33,
    applicationIds: [14, 41, 12, 35, 4, 31],
  },
  {
    id: 729,
    vacancy: 1,
    hired: 0,
    startsAt: '2020-08-29T18:01:07Z',
    endsAt: '2021-05-31T06:01:07Z',
    daysOff: undefined,
    createdAt: '2021-05-08T07:17:38Z',
    deletedAt: '2020-08-11T17:08:42Z',
    additionalInfo: 'donec',
    skillId: 3,
    centreId: 47,
    profileId: 9,
    applicationIds: undefined,
  },
  {
    id: 730,
    vacancy: 2,
    hired: 1,
    startsAt: '2021-04-07T05:46:22Z',
    endsAt: '2022-06-29T12:46:22Z',
    daysOff: [1, 10],
    createdAt: '2021-06-13T11:03:22Z',
    deletedAt: undefined,
    additionalInfo: 'duis bibendum',
    skillId: 1,
    centreId: 32,
    profileId: 45,
    applicationIds: [22, 15, 27, 44, 4, 17],
  },
  {
    id: 731,
    vacancy: 1,
    hired: 0,
    startsAt: '2021-03-11T10:56:50Z',
    endsAt: '2021-09-02T20:56:50Z',
    daysOff: undefined,
    createdAt: '2020-12-16T14:39:20Z',
    deletedAt: '2021-06-20T05:33:05Z',
    additionalInfo: 'leo maecenas',
    skillId: 1,
    centreId: 33,
    profileId: 25,
    applicationIds: undefined,
  },
  {
    id: 732,
    vacancy: 10,
    hired: 10,
    startsAt: '2020-09-16T18:28:21Z',
    endsAt: '2021-06-06T04:28:21Z',
    daysOff: undefined,
    createdAt: '2020-11-22T14:09:45Z',
    deletedAt: '2021-05-06T20:28:58Z',
    additionalInfo: 'sollicitudin mi',
    skillId: 2,
    centreId: 26,
    profileId: 97,
    applicationIds: undefined,
  },
  {
    id: 733,
    vacancy: 6,
    hired: 2,
    startsAt: '2021-06-13T09:35:16Z',
    endsAt: '2022-02-11T16:35:16Z',
    daysOff: undefined,
    createdAt: '2020-11-15T11:38:23Z',
    deletedAt: '2021-03-15T17:44:12Z',
    additionalInfo: 'vivamus in',
    skillId: 2,
    centreId: 35,
    profileId: 60,
    applicationIds: undefined,
  },
  {
    id: 734,
    vacancy: 4,
    hired: 1,
    startsAt: '2020-08-05T22:49:04Z',
    endsAt: '2022-03-24T10:49:04Z',
    daysOff: undefined,
    createdAt: '2021-05-14T12:53:09Z',
    deletedAt: '2020-11-16T15:26:56Z',
    additionalInfo: 'id luctus',
    skillId: 2,
    centreId: 43,
    profileId: 49,
    applicationIds: undefined,
  },
  {
    id: 735,
    vacancy: 8,
    hired: 5,
    startsAt: '2020-11-05T21:39:13Z',
    endsAt: '2021-11-18T04:39:13Z',
    daysOff: undefined,
    createdAt: '2021-06-02T02:27:11Z',
    deletedAt: '2021-05-21T21:45:42Z',
    additionalInfo: 'ultrices aliquet',
    skillId: 1,
    centreId: 40,
    profileId: 7,
    applicationIds: undefined,
  },
  {
    id: 736,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-10-28T10:13:06Z',
    endsAt: '2022-02-24T22:13:06Z',
    daysOff: undefined,
    createdAt: '2020-09-29T23:47:27Z',
    deletedAt: '2020-09-19T22:56:24Z',
    additionalInfo: 'adipiscing',
    skillId: 2,
    centreId: 18,
    profileId: 94,
    applicationIds: undefined,
  },
  {
    id: 737,
    vacancy: 9,
    hired: 3,
    startsAt: '2021-02-14T06:02:34Z',
    endsAt: '2021-07-19T14:02:34Z',
    daysOff: undefined,
    createdAt: '2020-09-14T14:51:14Z',
    deletedAt: '2020-09-12T05:33:08Z',
    additionalInfo: 'rhoncus',
    skillId: 3,
    centreId: 24,
    profileId: 10,
    applicationIds: undefined,
  },
  {
    id: 738,
    vacancy: 5,
    hired: 1,
    startsAt: '2020-12-31T12:44:05Z',
    endsAt: '2022-07-18T22:44:05Z',
    daysOff: undefined,
    createdAt: '2020-12-04T23:52:57Z',
    deletedAt: undefined,
    additionalInfo: 'sapien quis',
    skillId: 2,
    centreId: 22,
    profileId: 50,
    applicationIds: undefined,
  },
  {
    id: 739,
    vacancy: 9,
    hired: 3,
    startsAt: '2020-11-26T18:26:08Z',
    endsAt: '2022-12-18T03:26:08Z',
    daysOff: undefined,
    createdAt: '2020-12-01T05:54:11Z',
    deletedAt: undefined,
    additionalInfo: 'tellus nisi',
    skillId: 1,
    centreId: 6,
    profileId: 26,
    applicationIds: undefined,
  },
  {
    id: 740,
    vacancy: 7,
    hired: 2,
    startsAt: '2020-08-23T12:44:26Z',
    endsAt: '2021-02-03T16:44:26Z',
    daysOff: undefined,
    createdAt: '2021-06-07T17:54:27Z',
    deletedAt: '2021-01-01T17:22:32Z',
    additionalInfo: 'convallis',
    skillId: 2,
    centreId: 11,
    profileId: 56,
    applicationIds: undefined,
  },
  {
    id: 741,
    vacancy: 9,
    hired: 5,
    startsAt: '2021-06-04T01:16:30Z',
    endsAt: '2023-02-06T04:16:30Z',
    daysOff: undefined,
    createdAt: '2020-12-30T09:35:52Z',
    deletedAt: '2020-11-13T16:01:18Z',
    additionalInfo: 'undefineda',
    skillId: 3,
    centreId: 47,
    profileId: 42,
    applicationIds: undefined,
  },
  {
    id: 742,
    vacancy: 10,
    hired: 4,
    startsAt: '2020-09-06T10:51:50Z',
    endsAt: '2021-06-06T21:51:50Z',
    daysOff: undefined,
    createdAt: '2020-08-12T11:45:49Z',
    deletedAt: '2020-09-06T09:53:12Z',
    additionalInfo: 'volutpat erat',
    skillId: 1,
    centreId: 33,
    profileId: 60,
    applicationIds: undefined,
  },
  {
    id: 743,
    vacancy: 2,
    hired: 1,
    startsAt: '2020-12-04T20:37:29Z',
    endsAt: '2022-06-20T04:37:29Z',
    daysOff: undefined,
    createdAt: '2020-08-31T23:29:21Z',
    deletedAt: '2021-04-24T05:37:03Z',
    additionalInfo: 'vulputate',
    skillId: 1,
    centreId: 39,
    profileId: 56,
    applicationIds: undefined,
  },
  {
    id: 744,
    vacancy: 5,
    hired: 2,
    startsAt: '2020-08-14T19:32:21Z',
    endsAt: '2022-04-20T00:32:21Z',
    daysOff: undefined,
    createdAt: '2021-03-30T13:43:40Z',
    deletedAt: '2021-05-24T06:41:48Z',
    additionalInfo: 'cubilia curae',
    skillId: 3,
    centreId: 7,
    profileId: 74,
    applicationIds: undefined,
  },
  {
    id: 745,
    vacancy: 10,
    hired: 10,
    startsAt: '2020-09-12T15:12:39Z',
    endsAt: '2022-06-03T23:12:39Z',
    daysOff: undefined,
    createdAt: '2021-07-05T01:01:03Z',
    deletedAt: '2020-08-06T09:43:08Z',
    additionalInfo: 'undefineda facilisi',
    skillId: 2,
    centreId: 35,
    profileId: 26,
    applicationIds: undefined,
  },
  {
    id: 746,
    vacancy: 1,
    hired: 1,
    startsAt: '2021-01-30T02:59:01Z',
    endsAt: '2021-12-06T05:59:01Z',
    daysOff: undefined,
    createdAt: '2021-05-08T23:51:09Z',
    deletedAt: '2021-05-21T01:10:20Z',
    additionalInfo: 'aenean auctor',
    skillId: 2,
    centreId: 10,
    profileId: 36,
    applicationIds: undefined,
  },
  {
    id: 747,
    vacancy: 9,
    hired: 8,
    startsAt: '2021-03-31T20:31:48Z',
    endsAt: '2022-03-25T07:31:48Z',
    daysOff: undefined,
    createdAt: '2020-08-14T12:27:48Z',
    deletedAt: undefined,
    additionalInfo: 'elementum pellentesque',
    skillId: 1,
    centreId: 15,
    profileId: 65,
    applicationIds: undefined,
  },
  {
    id: 748,
    vacancy: 10,
    hired: 8,
    startsAt: '2020-08-24T01:01:13Z',
    endsAt: '2021-05-28T05:01:13Z',
    daysOff: undefined,
    createdAt: '2021-07-08T13:12:23Z',
    deletedAt: '2020-12-04T19:18:49Z',
    additionalInfo: 'cras in',
    skillId: 1,
    centreId: 41,
    profileId: 50,
    applicationIds: undefined,
  },
  {
    id: 749,
    vacancy: 5,
    hired: 1,
    startsAt: '2020-08-09T05:56:03Z',
    endsAt: '2021-02-20T14:56:03Z',
    daysOff: undefined,
    createdAt: '2021-02-01T04:20:53Z',
    deletedAt: undefined,
    additionalInfo: 'rhoncus aliquam',
    skillId: 2,
    centreId: 46,
    profileId: 65,
    applicationIds: undefined,
  },
  {
    id: 750,
    vacancy: 7,
    hired: 2,
    startsAt: '2020-11-01T06:47:23Z',
    endsAt: '2022-10-27T13:47:23Z',
    daysOff: undefined,
    createdAt: '2021-03-07T05:00:19Z',
    deletedAt: '2021-04-16T11:16:40Z',
    additionalInfo: 'diam erat',
    skillId: 1,
    centreId: 3,
    profileId: 72,
    applicationIds: undefined,
  },
  {
    id: 751,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-12-02T21:30:30Z',
    endsAt: '2021-02-04T09:30:30Z',
    daysOff: undefined,
    createdAt: '2021-07-11T22:40:30Z',
    deletedAt: undefined,
    additionalInfo: 'sit',
    skillId: 2,
    centreId: 29,
    profileId: 80,
    applicationIds: undefined,
  },
  {
    id: 752,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-11-29T13:09:48Z',
    endsAt: '2021-12-02T01:09:48Z',
    daysOff: undefined,
    createdAt: '2020-11-13T14:28:43Z',
    deletedAt: undefined,
    additionalInfo: 'dolor morbi',
    skillId: 1,
    centreId: 45,
    profileId: 23,
    applicationIds: undefined,
  },
  {
    id: 753,
    vacancy: 10,
    hired: 10,
    startsAt: '2021-07-14T23:52:26Z',
    endsAt: '2023-07-27T03:52:26Z',
    daysOff: undefined,
    createdAt: '2020-08-31T15:46:06Z',
    deletedAt: '2020-11-27T03:45:35Z',
    additionalInfo: 'ultrices aliquet',
    skillId: 1,
    centreId: 50,
    profileId: 37,
    applicationIds: undefined,
  },
  {
    id: 754,
    vacancy: 4,
    hired: 2,
    startsAt: '2020-12-13T23:12:36Z',
    endsAt: '2022-03-12T08:12:36Z',
    daysOff: undefined,
    createdAt: '2021-03-06T02:54:00Z',
    deletedAt: '2020-12-05T05:51:01Z',
    additionalInfo: 'morbi a',
    skillId: 3,
    centreId: 46,
    profileId: 59,
    applicationIds: undefined,
  },
  {
    id: 755,
    vacancy: 3,
    hired: 0,
    startsAt: '2020-09-15T14:25:49Z',
    endsAt: '2021-05-24T23:25:49Z',
    daysOff: undefined,
    createdAt: '2021-03-29T18:11:21Z',
    deletedAt: '2020-08-29T07:54:47Z',
    additionalInfo: 'ante ipsum',
    skillId: 1,
    centreId: 39,
    profileId: 49,
    applicationIds: undefined,
  },
  {
    id: 756,
    vacancy: 10,
    hired: 8,
    startsAt: '2021-03-05T08:34:24Z',
    endsAt: '2021-08-11T19:34:24Z',
    daysOff: undefined,
    createdAt: '2020-10-08T22:30:34Z',
    deletedAt: '2021-05-12T13:20:27Z',
    additionalInfo: 'praesent blandit',
    skillId: 1,
    centreId: 44,
    profileId: 36,
    applicationIds: undefined,
  },
  {
    id: 757,
    vacancy: 7,
    hired: 1,
    startsAt: '2020-11-04T21:07:05Z',
    endsAt: '2022-08-12T00:07:05Z',
    daysOff: [1, 6],
    createdAt: '2021-02-11T03:50:01Z',
    deletedAt: '2020-07-29T17:44:16Z',
    additionalInfo: 'dapibus dolor',
    skillId: 1,
    centreId: 38,
    profileId: 24,
    applicationIds: [13, 46, 9, 34, 46, 38],
  },
  {
    id: 758,
    vacancy: 6,
    hired: 1,
    startsAt: '2021-01-15T12:52:20Z',
    endsAt: '2021-03-12T21:52:20Z',
    daysOff: undefined,
    createdAt: '2021-03-07T09:53:50Z',
    deletedAt: '2021-01-31T00:13:51Z',
    additionalInfo: 'ac tellus',
    skillId: 1,
    centreId: 46,
    profileId: 94,
    applicationIds: undefined,
  },
  {
    id: 759,
    vacancy: 9,
    hired: 1,
    startsAt: '2021-01-30T12:29:32Z',
    endsAt: '2022-06-23T15:29:32Z',
    daysOff: [5, 2],
    createdAt: '2021-04-20T22:36:14Z',
    deletedAt: '2020-07-27T03:41:47Z',
    additionalInfo: 'cursus id',
    skillId: 2,
    centreId: 28,
    profileId: 32,
    applicationIds: [20, 16, 50, 26, 22, 7],
  },
  {
    id: 760,
    vacancy: 6,
    hired: 4,
    startsAt: '2020-12-30T12:54:15Z',
    endsAt: '2021-12-10T15:54:15Z',
    daysOff: undefined,
    createdAt: '2020-11-03T12:02:59Z',
    deletedAt: '2021-05-15T22:20:44Z',
    additionalInfo: 'consectetuer',
    skillId: 1,
    centreId: 20,
    profileId: 59,
    applicationIds: undefined,
  },
  {
    id: 761,
    vacancy: 7,
    hired: 3,
    startsAt: '2021-06-07T17:26:50Z',
    endsAt: '2022-12-25T22:26:50Z',
    daysOff: undefined,
    createdAt: '2020-12-15T02:31:49Z',
    deletedAt: undefined,
    additionalInfo: 'faucibus',
    skillId: 3,
    centreId: 34,
    profileId: 65,
    applicationIds: undefined,
  },
  {
    id: 762,
    vacancy: 3,
    hired: 1,
    startsAt: '2020-10-06T16:11:04Z',
    endsAt: '2021-02-12T20:11:04Z',
    daysOff: undefined,
    createdAt: '2021-04-17T19:46:27Z',
    deletedAt: '2021-03-17T14:50:35Z',
    additionalInfo: 'ac lobortis',
    skillId: 2,
    centreId: 38,
    profileId: 29,
    applicationIds: undefined,
  },
  {
    id: 763,
    vacancy: 2,
    hired: 1,
    startsAt: '2020-11-09T00:59:12Z',
    endsAt: '2022-05-13T07:59:12Z',
    daysOff: undefined,
    createdAt: '2021-07-19T05:13:47Z',
    deletedAt: '2021-03-22T00:52:40Z',
    additionalInfo: 'libero',
    skillId: 3,
    centreId: 11,
    profileId: 1,
    applicationIds: undefined,
  },
  {
    id: 764,
    vacancy: 2,
    hired: 0,
    startsAt: '2020-11-10T00:26:41Z',
    endsAt: '2021-02-07T12:26:41Z',
    daysOff: [1, 3],
    createdAt: '2021-07-09T21:29:17Z',
    deletedAt: '2021-05-23T20:30:35Z',
    additionalInfo: 'duis ac',
    skillId: 2,
    centreId: 7,
    profileId: 78,
    applicationIds: [20, 40, 20, 7, 10, 46],
  },
  {
    id: 765,
    vacancy: 6,
    hired: 5,
    startsAt: '2021-06-08T06:56:49Z',
    endsAt: '2022-08-30T13:56:49Z',
    daysOff: undefined,
    createdAt: '2020-11-12T17:06:29Z',
    deletedAt: '2021-04-15T16:33:21Z',
    additionalInfo: 'aliquam',
    skillId: 1,
    centreId: 4,
    profileId: 33,
    applicationIds: undefined,
  },
  {
    id: 766,
    vacancy: 4,
    hired: 4,
    startsAt: '2021-06-28T23:59:03Z',
    endsAt: '2021-10-13T06:59:03Z',
    daysOff: undefined,
    createdAt: '2021-01-13T22:21:57Z',
    deletedAt: '2021-05-01T00:09:55Z',
    additionalInfo: 'odio',
    skillId: 1,
    centreId: 49,
    profileId: 27,
    applicationIds: undefined,
  },
  {
    id: 767,
    vacancy: 6,
    hired: 4,
    startsAt: '2020-07-25T08:16:06Z',
    endsAt: '2021-01-11T20:16:06Z',
    daysOff: undefined,
    createdAt: '2021-02-22T03:02:23Z',
    deletedAt: '2020-11-23T01:15:55Z',
    additionalInfo: 'tortor',
    skillId: 1,
    centreId: 42,
    profileId: 68,
    applicationIds: undefined,
  },
  {
    id: 768,
    vacancy: 5,
    hired: 2,
    startsAt: '2021-06-22T19:37:22Z',
    endsAt: '2023-03-21T07:37:22Z',
    daysOff: undefined,
    createdAt: '2020-09-19T03:22:05Z',
    deletedAt: '2021-04-19T12:02:04Z',
    additionalInfo: 'lacinia nisi',
    skillId: 3,
    centreId: 13,
    profileId: 82,
    applicationIds: undefined,
  },
  {
    id: 769,
    vacancy: 4,
    hired: 2,
    startsAt: '2020-07-25T19:42:12Z',
    endsAt: '2020-10-15T00:42:12Z',
    daysOff: undefined,
    createdAt: '2021-04-17T21:18:07Z',
    deletedAt: undefined,
    additionalInfo: 'magna',
    skillId: 1,
    centreId: 44,
    profileId: 38,
    applicationIds: undefined,
  },
  {
    id: 770,
    vacancy: 8,
    hired: 5,
    startsAt: '2020-09-12T02:15:13Z',
    endsAt: '2022-01-01T07:15:13Z',
    daysOff: undefined,
    createdAt: '2021-04-22T07:41:59Z',
    deletedAt: '2021-01-21T21:18:11Z',
    additionalInfo: 'morbi vel',
    skillId: 1,
    centreId: 43,
    profileId: 94,
    applicationIds: undefined,
  },
  {
    id: 771,
    vacancy: 10,
    hired: 6,
    startsAt: '2020-07-23T08:53:27Z',
    endsAt: '2021-08-08T16:53:27Z',
    daysOff: [7, 9],
    createdAt: '2020-07-28T18:47:25Z',
    deletedAt: '2020-10-07T12:40:53Z',
    additionalInfo: 'libero',
    skillId: 1,
    centreId: 30,
    profileId: 27,
    applicationIds: [17, 9, 6, 14, 26, 2],
  },
  {
    id: 772,
    vacancy: 5,
    hired: 5,
    startsAt: '2021-02-12T05:55:11Z',
    endsAt: '2022-08-14T08:55:11Z',
    daysOff: undefined,
    createdAt: '2021-01-30T01:18:10Z',
    deletedAt: '2021-05-05T22:35:37Z',
    additionalInfo: 'convallis',
    skillId: 1,
    centreId: 16,
    profileId: 22,
    applicationIds: undefined,
  },
  {
    id: 773,
    vacancy: 6,
    hired: 0,
    startsAt: '2021-06-02T21:26:00Z',
    endsAt: '2021-09-12T06:26:00Z',
    daysOff: [3, 2],
    createdAt: '2021-05-24T22:20:45Z',
    deletedAt: '2020-07-23T09:46:08Z',
    additionalInfo: 'adipiscing elit',
    skillId: 3,
    centreId: 25,
    profileId: 82,
    applicationIds: [15, 50, 18, 37, 27, 5],
  },
  {
    id: 774,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-12-20T02:41:21Z',
    endsAt: '2021-09-30T06:41:21Z',
    daysOff: undefined,
    createdAt: '2020-11-23T08:20:43Z',
    deletedAt: '2021-05-03T17:08:07Z',
    additionalInfo: 'dolor',
    skillId: 2,
    centreId: 36,
    profileId: 37,
    applicationIds: undefined,
  },
  {
    id: 775,
    vacancy: 5,
    hired: 3,
    startsAt: '2021-04-01T23:39:07Z',
    endsAt: '2021-05-06T02:39:07Z',
    daysOff: undefined,
    createdAt: '2020-08-22T08:54:05Z',
    deletedAt: '2021-01-09T16:47:31Z',
    additionalInfo: 'et eros',
    skillId: 1,
    centreId: 35,
    profileId: 67,
    applicationIds: undefined,
  },
  {
    id: 776,
    vacancy: 2,
    hired: 2,
    startsAt: '2020-11-14T15:55:09Z',
    endsAt: '2022-08-14T00:55:09Z',
    daysOff: [4, 6],
    createdAt: '2021-07-04T18:50:10Z',
    deletedAt: '2020-08-16T18:30:12Z',
    additionalInfo: 'undefinedam sit',
    skillId: 3,
    centreId: 17,
    profileId: 61,
    applicationIds: [35, 44, 23, 21, 44, 35],
  },
  {
    id: 777,
    vacancy: 10,
    hired: 7,
    startsAt: '2021-01-14T04:38:32Z',
    endsAt: '2021-07-04T16:38:32Z',
    daysOff: undefined,
    createdAt: '2021-01-18T02:10:44Z',
    deletedAt: '2020-08-07T19:32:01Z',
    additionalInfo: 'lacinia erat',
    skillId: 3,
    centreId: 43,
    profileId: 56,
    applicationIds: undefined,
  },
  {
    id: 778,
    vacancy: 9,
    hired: 9,
    startsAt: '2020-10-28T15:07:51Z',
    endsAt: '2020-12-25T19:07:51Z',
    daysOff: [4, 5],
    createdAt: '2021-02-11T10:34:15Z',
    deletedAt: undefined,
    additionalInfo: 'faucibus orci',
    skillId: 2,
    centreId: 26,
    profileId: 29,
    applicationIds: [39, 43, 31, 4, 2, 28],
  },
  {
    id: 779,
    vacancy: 3,
    hired: 2,
    startsAt: '2021-03-01T16:24:25Z',
    endsAt: '2022-05-14T19:24:25Z',
    daysOff: undefined,
    createdAt: '2020-09-27T05:46:00Z',
    deletedAt: '2021-05-17T11:23:32Z',
    additionalInfo: 'sollicitudin ut',
    skillId: 3,
    centreId: 13,
    profileId: 4,
    applicationIds: undefined,
  },
  {
    id: 780,
    vacancy: 8,
    hired: 7,
    startsAt: '2021-05-24T10:45:25Z',
    endsAt: '2021-08-20T20:45:25Z',
    daysOff: undefined,
    createdAt: '2020-08-28T22:06:31Z',
    deletedAt: undefined,
    additionalInfo: 'porta volutpat',
    skillId: 2,
    centreId: 44,
    profileId: 16,
    applicationIds: undefined,
  },
  {
    id: 781,
    vacancy: 3,
    hired: 3,
    startsAt: '2021-05-31T09:21:03Z',
    endsAt: '2023-06-21T18:21:03Z',
    daysOff: undefined,
    createdAt: '2020-12-14T10:24:08Z',
    deletedAt: undefined,
    additionalInfo: 'eu felis',
    skillId: 2,
    centreId: 28,
    profileId: 52,
    applicationIds: undefined,
  },
  {
    id: 782,
    vacancy: 6,
    hired: 2,
    startsAt: '2021-03-04T06:49:09Z',
    endsAt: '2023-01-05T17:49:09Z',
    daysOff: undefined,
    createdAt: '2021-03-09T18:04:06Z',
    deletedAt: undefined,
    additionalInfo: 'lectus pellentesque',
    skillId: 2,
    centreId: 29,
    profileId: 9,
    applicationIds: undefined,
  },
  {
    id: 783,
    vacancy: 4,
    hired: 1,
    startsAt: '2021-03-18T12:14:20Z',
    endsAt: '2022-06-24T20:14:20Z',
    daysOff: undefined,
    createdAt: '2020-12-18T20:33:29Z',
    deletedAt: '2021-06-05T07:12:59Z',
    additionalInfo: 'ligula nec',
    skillId: 3,
    centreId: 30,
    profileId: 15,
    applicationIds: undefined,
  },
  {
    id: 784,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-12-22T01:06:43Z',
    endsAt: '2021-05-08T05:06:43Z',
    daysOff: [4, 5],
    createdAt: '2021-01-08T19:25:38Z',
    deletedAt: '2021-01-15T09:46:48Z',
    additionalInfo: 'vel',
    skillId: 3,
    centreId: 17,
    profileId: 21,
    applicationIds: [3, 46, 34, 38, 47, 34],
  },
  {
    id: 785,
    vacancy: 2,
    hired: 0,
    startsAt: '2020-09-21T08:23:25Z',
    endsAt: '2021-02-27T19:23:25Z',
    daysOff: undefined,
    createdAt: '2020-09-01T08:25:47Z',
    deletedAt: '2020-09-29T01:23:15Z',
    additionalInfo: 'vestibulum',
    skillId: 1,
    centreId: 35,
    profileId: 72,
    applicationIds: undefined,
  },
  {
    id: 786,
    vacancy: 2,
    hired: 1,
    startsAt: '2020-12-30T17:55:51Z',
    endsAt: '2021-10-11T21:55:51Z',
    daysOff: undefined,
    createdAt: '2021-05-22T06:06:16Z',
    deletedAt: '2021-07-09T08:43:43Z',
    additionalInfo: 'volutpat',
    skillId: 3,
    centreId: 26,
    profileId: 88,
    applicationIds: undefined,
  },
  {
    id: 787,
    vacancy: 6,
    hired: 0,
    startsAt: '2020-11-16T18:33:16Z',
    endsAt: '2021-02-05T03:33:16Z',
    daysOff: [10, 10],
    createdAt: '2021-04-13T22:37:19Z',
    deletedAt: '2020-10-25T09:36:45Z',
    additionalInfo: 'quam',
    skillId: 1,
    centreId: 44,
    profileId: 61,
    applicationIds: [22, 37, 42, 1, 4, 27],
  },
  {
    id: 788,
    vacancy: 4,
    hired: 2,
    startsAt: '2021-01-19T23:05:35Z',
    endsAt: '2023-02-01T11:05:35Z',
    daysOff: undefined,
    createdAt: '2020-10-24T15:46:28Z',
    deletedAt: undefined,
    additionalInfo: 'eros',
    skillId: 1,
    centreId: 34,
    profileId: 36,
    applicationIds: undefined,
  },
  {
    id: 789,
    vacancy: 6,
    hired: 2,
    startsAt: '2021-04-04T06:10:24Z',
    endsAt: '2021-05-10T18:10:24Z',
    daysOff: [3, 3],
    createdAt: '2020-09-17T23:12:23Z',
    deletedAt: '2021-02-19T12:12:22Z',
    additionalInfo: 'nunc nisl',
    skillId: 2,
    centreId: 30,
    profileId: 24,
    applicationIds: [40, 25, 28, 46, 37, 38],
  },
  {
    id: 790,
    vacancy: 1,
    hired: 1,
    startsAt: '2021-07-03T23:24:40Z',
    endsAt: '2022-10-17T04:24:40Z',
    daysOff: undefined,
    createdAt: '2020-11-01T21:24:04Z',
    deletedAt: '2021-02-03T10:23:03Z',
    additionalInfo: 'penatibus et',
    skillId: 1,
    centreId: 29,
    profileId: 64,
    applicationIds: undefined,
  },
  {
    id: 791,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-10-03T12:22:42Z',
    endsAt: '2021-07-02T18:22:42Z',
    daysOff: [10, 2],
    createdAt: '2020-08-03T00:08:24Z',
    deletedAt: '2020-10-11T08:10:22Z',
    additionalInfo: 'vel',
    skillId: 3,
    centreId: 5,
    profileId: 28,
    applicationIds: [20, 36, 11, 27, 17, 4],
  },
  {
    id: 792,
    vacancy: 9,
    hired: 1,
    startsAt: '2021-02-21T23:24:02Z',
    endsAt: '2022-03-03T07:24:02Z',
    daysOff: undefined,
    createdAt: '2021-07-10T19:41:42Z',
    deletedAt: '2021-05-26T13:25:26Z',
    additionalInfo: 'nisl nunc',
    skillId: 2,
    centreId: 16,
    profileId: 48,
    applicationIds: undefined,
  },
  {
    id: 793,
    vacancy: 2,
    hired: 1,
    startsAt: '2020-09-12T01:52:17Z',
    endsAt: '2021-12-24T07:52:17Z',
    daysOff: undefined,
    createdAt: '2021-03-28T09:13:06Z',
    deletedAt: '2021-04-04T04:04:40Z',
    additionalInfo: 'commodo placerat',
    skillId: 2,
    centreId: 27,
    profileId: 11,
    applicationIds: undefined,
  },
  {
    id: 794,
    vacancy: 8,
    hired: 8,
    startsAt: '2021-04-28T21:11:39Z',
    endsAt: '2021-07-03T05:11:39Z',
    daysOff: undefined,
    createdAt: '2020-11-23T03:42:36Z',
    deletedAt: '2021-06-08T21:29:36Z',
    additionalInfo: 'in',
    skillId: 3,
    centreId: 42,
    profileId: 50,
    applicationIds: undefined,
  },
  {
    id: 795,
    vacancy: 3,
    hired: 1,
    startsAt: '2021-05-02T21:46:20Z',
    endsAt: '2022-02-10T07:46:20Z',
    daysOff: undefined,
    createdAt: '2020-10-19T17:03:05Z',
    deletedAt: undefined,
    additionalInfo: 'sapien cum',
    skillId: 1,
    centreId: 49,
    profileId: 27,
    applicationIds: undefined,
  },
  {
    id: 796,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-10-11T00:41:47Z',
    endsAt: '2020-11-17T03:41:47Z',
    daysOff: undefined,
    createdAt: '2021-06-17T18:20:02Z',
    deletedAt: '2021-01-20T05:31:40Z',
    additionalInfo: 'vel',
    skillId: 3,
    centreId: 44,
    profileId: 28,
    applicationIds: undefined,
  },
  {
    id: 797,
    vacancy: 3,
    hired: 2,
    startsAt: '2021-03-14T08:59:51Z',
    endsAt: '2022-09-05T17:59:51Z',
    daysOff: undefined,
    createdAt: '2020-08-21T23:55:34Z',
    deletedAt: undefined,
    additionalInfo: 'tortor',
    skillId: 1,
    centreId: 4,
    profileId: 98,
    applicationIds: undefined,
  },
  {
    id: 798,
    vacancy: 4,
    hired: 3,
    startsAt: '2020-12-23T13:58:06Z',
    endsAt: '2022-08-18T21:58:06Z',
    daysOff: undefined,
    createdAt: '2021-04-01T10:56:10Z',
    deletedAt: undefined,
    additionalInfo: 'justo',
    skillId: 3,
    centreId: 13,
    profileId: 34,
    applicationIds: undefined,
  },
  {
    id: 799,
    vacancy: 4,
    hired: 0,
    startsAt: '2020-10-24T23:41:43Z',
    endsAt: '2022-09-18T07:41:43Z',
    daysOff: undefined,
    createdAt: '2020-08-25T06:06:21Z',
    deletedAt: undefined,
    additionalInfo: 'hac',
    skillId: 3,
    centreId: 9,
    profileId: 2,
    applicationIds: undefined,
  },
  {
    id: 800,
    vacancy: 4,
    hired: 4,
    startsAt: '2020-12-08T07:07:51Z',
    endsAt: '2021-06-09T10:07:51Z',
    daysOff: undefined,
    createdAt: '2020-08-21T23:45:55Z',
    deletedAt: '2021-07-19T10:35:00Z',
    additionalInfo: 'at nibh',
    skillId: 2,
    centreId: 23,
    profileId: 35,
    applicationIds: undefined,
  },
  {
    id: 801,
    vacancy: 8,
    hired: 0,
    startsAt: '2021-02-13T19:34:41Z',
    endsAt: '2023-01-20T03:34:41Z',
    daysOff: [2, 2],
    createdAt: '2020-12-08T15:29:10Z',
    deletedAt: '2021-01-13T14:38:45Z',
    additionalInfo: 'tristique',
    skillId: 3,
    centreId: 8,
    profileId: 46,
    applicationIds: [40, 38, 33, 7, 1, 35],
  },
  {
    id: 802,
    vacancy: 5,
    hired: 2,
    startsAt: '2021-01-08T09:32:37Z',
    endsAt: '2022-12-16T15:32:37Z',
    daysOff: undefined,
    createdAt: '2021-03-04T12:42:28Z',
    deletedAt: '2021-03-03T23:30:02Z',
    additionalInfo: 'morbi',
    skillId: 1,
    centreId: 48,
    profileId: 30,
    applicationIds: undefined,
  },
  {
    id: 803,
    vacancy: 7,
    hired: 4,
    startsAt: '2020-12-15T14:50:32Z',
    endsAt: '2022-07-10T01:50:32Z',
    daysOff: undefined,
    createdAt: '2020-08-25T11:07:48Z',
    deletedAt: undefined,
    additionalInfo: 'nec',
    skillId: 2,
    centreId: 18,
    profileId: 74,
    applicationIds: undefined,
  },
  {
    id: 804,
    vacancy: 6,
    hired: 6,
    startsAt: '2020-12-15T10:50:53Z',
    endsAt: '2021-11-30T22:50:53Z',
    daysOff: [4, 8],
    createdAt: '2020-11-21T23:08:45Z',
    deletedAt: '2020-08-07T11:08:15Z',
    additionalInfo: 'massa',
    skillId: 1,
    centreId: 6,
    profileId: 34,
    applicationIds: [42, 15, 42, 46, 50, 23],
  },
  {
    id: 805,
    vacancy: 10,
    hired: 5,
    startsAt: '2021-06-26T08:01:55Z',
    endsAt: '2022-05-11T20:01:55Z',
    daysOff: [9, 1],
    createdAt: '2021-03-28T20:14:40Z',
    deletedAt: '2021-05-21T11:21:27Z',
    additionalInfo: 'undefineda suscipit',
    skillId: 2,
    centreId: 31,
    profileId: 98,
    applicationIds: [43, 17, 43, 13, 15, 22],
  },
  {
    id: 806,
    vacancy: 3,
    hired: 3,
    startsAt: '2021-06-05T09:44:08Z',
    endsAt: '2021-12-03T14:44:08Z',
    daysOff: undefined,
    createdAt: '2020-08-17T18:55:36Z',
    deletedAt: '2020-10-21T12:28:09Z',
    additionalInfo: 'aenean sit',
    skillId: 1,
    centreId: 42,
    profileId: 7,
    applicationIds: undefined,
  },
  {
    id: 807,
    vacancy: 5,
    hired: 3,
    startsAt: '2021-03-31T14:17:40Z',
    endsAt: '2022-12-28T17:17:40Z',
    daysOff: undefined,
    createdAt: '2021-06-26T03:33:56Z',
    deletedAt: '2021-05-06T21:44:17Z',
    additionalInfo: 'turpis a',
    skillId: 1,
    centreId: 39,
    profileId: 22,
    applicationIds: undefined,
  },
  {
    id: 808,
    vacancy: 2,
    hired: 2,
    startsAt: '2021-03-08T05:35:54Z',
    endsAt: '2021-09-24T11:35:54Z',
    daysOff: [6, 9],
    createdAt: '2020-07-31T08:56:00Z',
    deletedAt: '2020-10-22T13:43:57Z',
    additionalInfo: 'vestibulum',
    skillId: 1,
    centreId: 4,
    profileId: 72,
    applicationIds: [21, 31, 17, 28, 9, 8],
  },
  {
    id: 809,
    vacancy: 10,
    hired: 0,
    startsAt: '2021-04-17T13:32:15Z',
    endsAt: '2022-02-23T16:32:15Z',
    daysOff: [7, 8],
    createdAt: '2020-10-12T08:42:55Z',
    deletedAt: '2021-06-09T15:09:37Z',
    additionalInfo: 'platea',
    skillId: 3,
    centreId: 37,
    profileId: 89,
    applicationIds: [48, 20, 33, 38, 8, 36],
  },
  {
    id: 810,
    vacancy: 8,
    hired: 7,
    startsAt: '2020-12-28T21:31:27Z',
    endsAt: '2021-03-31T00:31:27Z',
    daysOff: undefined,
    createdAt: '2021-02-09T05:16:54Z',
    deletedAt: '2021-01-08T06:24:46Z',
    additionalInfo: 'risus praesent',
    skillId: 1,
    centreId: 35,
    profileId: 42,
    applicationIds: undefined,
  },
  {
    id: 811,
    vacancy: 7,
    hired: 3,
    startsAt: '2020-11-14T07:40:48Z',
    endsAt: '2021-08-10T18:40:48Z',
    daysOff: undefined,
    createdAt: '2020-07-26T23:33:49Z',
    deletedAt: '2021-06-07T00:41:32Z',
    additionalInfo: 'facilisi cras',
    skillId: 3,
    centreId: 10,
    profileId: 21,
    applicationIds: undefined,
  },
  {
    id: 812,
    vacancy: 2,
    hired: 0,
    startsAt: '2020-11-16T15:15:02Z',
    endsAt: '2021-10-26T21:15:02Z',
    daysOff: undefined,
    createdAt: '2020-09-18T15:16:49Z',
    deletedAt: '2020-11-21T02:05:55Z',
    additionalInfo: 'primis in',
    skillId: 2,
    centreId: 24,
    profileId: 9,
    applicationIds: undefined,
  },
  {
    id: 813,
    vacancy: 6,
    hired: 5,
    startsAt: '2020-10-18T04:08:05Z',
    endsAt: '2022-02-20T14:08:05Z',
    daysOff: undefined,
    createdAt: '2021-07-13T00:29:40Z',
    deletedAt: '2020-10-03T23:04:05Z',
    additionalInfo: 'donec ut',
    skillId: 1,
    centreId: 35,
    profileId: 16,
    applicationIds: undefined,
  },
  {
    id: 814,
    vacancy: 10,
    hired: 7,
    startsAt: '2020-08-19T23:57:23Z',
    endsAt: '2022-04-14T05:57:23Z',
    daysOff: [4, 3],
    createdAt: '2021-03-05T14:02:57Z',
    deletedAt: '2020-10-19T22:02:03Z',
    additionalInfo: 'mollis molestie',
    skillId: 1,
    centreId: 5,
    profileId: 81,
    applicationIds: [11, 21, 50, 10, 9, 37],
  },
  {
    id: 815,
    vacancy: 9,
    hired: 2,
    startsAt: '2021-03-21T08:41:59Z',
    endsAt: '2023-03-14T17:41:59Z',
    daysOff: undefined,
    createdAt: '2021-06-04T13:43:35Z',
    deletedAt: '2020-11-11T19:28:37Z',
    additionalInfo: 'rutrum',
    skillId: 3,
    centreId: 24,
    profileId: 35,
    applicationIds: undefined,
  },
  {
    id: 816,
    vacancy: 10,
    hired: 6,
    startsAt: '2020-12-27T15:05:01Z',
    endsAt: '2023-01-18T01:05:01Z',
    daysOff: [8, 4],
    createdAt: '2020-12-17T21:01:12Z',
    deletedAt: '2020-08-08T10:06:56Z',
    additionalInfo: 'turpis donec',
    skillId: 1,
    centreId: 6,
    profileId: 15,
    applicationIds: [3, 19, 14, 42, 6, 23],
  },
  {
    id: 817,
    vacancy: 5,
    hired: 4,
    startsAt: '2021-01-07T00:59:31Z',
    endsAt: '2022-04-30T12:59:31Z',
    daysOff: undefined,
    createdAt: '2020-11-30T18:07:42Z',
    deletedAt: '2021-05-27T17:54:14Z',
    additionalInfo: 'consectetuer',
    skillId: 3,
    centreId: 13,
    profileId: 18,
    applicationIds: undefined,
  },
  {
    id: 818,
    vacancy: 10,
    hired: 5,
    startsAt: '2021-03-09T12:34:25Z',
    endsAt: '2022-01-29T21:34:25Z',
    daysOff: undefined,
    createdAt: '2020-12-17T04:28:42Z',
    deletedAt: undefined,
    additionalInfo: 'leo',
    skillId: 3,
    centreId: 15,
    profileId: 38,
    applicationIds: undefined,
  },
  {
    id: 819,
    vacancy: 10,
    hired: 7,
    startsAt: '2021-04-22T06:36:12Z',
    endsAt: '2023-03-25T17:36:12Z',
    daysOff: undefined,
    createdAt: '2021-03-06T05:56:10Z',
    deletedAt: undefined,
    additionalInfo: 'adipiscing elit',
    skillId: 3,
    centreId: 4,
    profileId: 30,
    applicationIds: undefined,
  },
  {
    id: 820,
    vacancy: 10,
    hired: 6,
    startsAt: '2021-01-18T16:32:39Z',
    endsAt: '2021-03-29T22:32:39Z',
    daysOff: undefined,
    createdAt: '2021-02-20T20:22:54Z',
    deletedAt: '2021-04-30T08:29:12Z',
    additionalInfo: 'consectetuer adipiscing',
    skillId: 1,
    centreId: 48,
    profileId: 49,
    applicationIds: undefined,
  },
  {
    id: 821,
    vacancy: 6,
    hired: 5,
    startsAt: '2020-11-06T10:18:20Z',
    endsAt: '2022-04-10T20:18:20Z',
    daysOff: undefined,
    createdAt: '2020-08-06T12:18:38Z',
    deletedAt: '2021-05-01T13:49:27Z',
    additionalInfo: 'ipsum dolor',
    skillId: 2,
    centreId: 17,
    profileId: 10,
    applicationIds: undefined,
  },
  {
    id: 822,
    vacancy: 7,
    hired: 2,
    startsAt: '2020-10-02T11:43:06Z',
    endsAt: '2022-08-20T16:43:06Z',
    daysOff: undefined,
    createdAt: '2021-03-10T13:46:35Z',
    deletedAt: '2020-10-29T09:11:32Z',
    additionalInfo: 'amet erat',
    skillId: 1,
    centreId: 35,
    profileId: 51,
    applicationIds: undefined,
  },
  {
    id: 823,
    vacancy: 2,
    hired: 1,
    startsAt: '2020-08-18T21:30:16Z',
    endsAt: '2022-02-12T04:30:16Z',
    daysOff: [1, 7],
    createdAt: '2021-04-30T10:37:39Z',
    deletedAt: '2021-02-03T10:42:56Z',
    additionalInfo: 'ultrices posuere',
    skillId: 3,
    centreId: 44,
    profileId: 50,
    applicationIds: [8, 15, 17, 30, 17, 13],
  },
  {
    id: 824,
    vacancy: 1,
    hired: 0,
    startsAt: '2021-03-26T07:33:39Z',
    endsAt: '2022-05-11T12:33:39Z',
    daysOff: [4, 1],
    createdAt: '2020-09-29T01:25:50Z',
    deletedAt: '2021-02-13T11:34:30Z',
    additionalInfo: 'undefinedam sit',
    skillId: 2,
    centreId: 45,
    profileId: 79,
    applicationIds: [22, 9, 18, 43, 27, 5],
  },
  {
    id: 825,
    vacancy: 7,
    hired: 3,
    startsAt: '2020-10-29T20:00:56Z',
    endsAt: '2022-03-24T06:00:56Z',
    daysOff: undefined,
    createdAt: '2020-10-11T17:54:06Z',
    deletedAt: '2020-11-15T11:18:39Z',
    additionalInfo: 'ultrices enim',
    skillId: 2,
    centreId: 31,
    profileId: 5,
    applicationIds: undefined,
  },
  {
    id: 826,
    vacancy: 5,
    hired: 3,
    startsAt: '2020-10-13T06:41:46Z',
    endsAt: '2021-07-25T17:41:46Z',
    daysOff: [6, 2],
    createdAt: '2021-04-13T22:36:37Z',
    deletedAt: '2021-03-06T06:47:36Z',
    additionalInfo: 'eu',
    skillId: 3,
    centreId: 38,
    profileId: 32,
    applicationIds: [6, 16, 35, 32, 28, 14],
  },
  {
    id: 827,
    vacancy: 8,
    hired: 4,
    startsAt: '2021-02-01T10:36:08Z',
    endsAt: '2022-04-03T21:36:08Z',
    daysOff: undefined,
    createdAt: '2021-04-22T05:38:26Z',
    deletedAt: '2021-07-13T03:48:08Z',
    additionalInfo: 'quis',
    skillId: 2,
    centreId: 24,
    profileId: 57,
    applicationIds: undefined,
  },
  {
    id: 828,
    vacancy: 5,
    hired: 3,
    startsAt: '2020-11-24T02:05:45Z',
    endsAt: '2021-03-14T08:05:45Z',
    daysOff: undefined,
    createdAt: '2021-06-14T07:34:34Z',
    deletedAt: '2021-02-12T14:01:17Z',
    additionalInfo: 'nisl',
    skillId: 2,
    centreId: 27,
    profileId: 27,
    applicationIds: undefined,
  },
  {
    id: 829,
    vacancy: 6,
    hired: 5,
    startsAt: '2021-02-11T19:36:03Z',
    endsAt: '2021-03-23T04:36:03Z',
    daysOff: [6, 2],
    createdAt: '2020-08-12T01:19:55Z',
    deletedAt: '2021-06-30T22:05:24Z',
    additionalInfo: 'undefineda sed',
    skillId: 3,
    centreId: 1,
    profileId: 32,
    applicationIds: [16, 13, 11, 7, 29, 43],
  },
  {
    id: 830,
    vacancy: 10,
    hired: 10,
    startsAt: '2021-06-20T23:42:26Z',
    endsAt: '2022-01-20T06:42:26Z',
    daysOff: [9, 1],
    createdAt: '2020-09-15T06:07:59Z',
    deletedAt: '2021-01-04T09:55:28Z',
    additionalInfo: 'condimentum',
    skillId: 2,
    centreId: 47,
    profileId: 14,
    applicationIds: [28, 14, 9, 31, 4, 47],
  },
  {
    id: 831,
    vacancy: 7,
    hired: 7,
    startsAt: '2020-10-30T03:38:36Z',
    endsAt: '2022-09-26T08:38:36Z',
    daysOff: undefined,
    createdAt: '2020-07-28T23:43:05Z',
    deletedAt: '2020-09-16T17:08:00Z',
    additionalInfo: 'orci',
    skillId: 2,
    centreId: 43,
    profileId: 30,
    applicationIds: undefined,
  },
  {
    id: 832,
    vacancy: 9,
    hired: 6,
    startsAt: '2020-11-06T15:24:11Z',
    endsAt: '2022-07-05T21:24:11Z',
    daysOff: undefined,
    createdAt: '2020-07-30T20:22:41Z',
    deletedAt: '2020-10-23T18:35:11Z',
    additionalInfo: 'faucibus',
    skillId: 2,
    centreId: 41,
    profileId: 3,
    applicationIds: undefined,
  },
  {
    id: 833,
    vacancy: 4,
    hired: 0,
    startsAt: '2020-09-10T13:55:28Z',
    endsAt: '2022-07-15T20:55:28Z',
    daysOff: undefined,
    createdAt: '2021-03-24T04:53:30Z',
    deletedAt: '2021-04-13T18:44:23Z',
    additionalInfo: 'adipiscing elit',
    skillId: 1,
    centreId: 26,
    profileId: 84,
    applicationIds: undefined,
  },
  {
    id: 834,
    vacancy: 8,
    hired: 3,
    startsAt: '2020-12-20T15:14:23Z',
    endsAt: '2021-09-23T19:14:23Z',
    daysOff: undefined,
    createdAt: '2021-02-18T09:58:01Z',
    deletedAt: undefined,
    additionalInfo: 'at',
    skillId: 2,
    centreId: 14,
    profileId: 31,
    applicationIds: undefined,
  },
  {
    id: 835,
    vacancy: 2,
    hired: 2,
    startsAt: '2021-01-18T19:52:20Z',
    endsAt: '2022-08-05T05:52:20Z',
    daysOff: [10, 3],
    createdAt: '2021-05-07T20:24:02Z',
    deletedAt: undefined,
    additionalInfo: 'elit proin',
    skillId: 3,
    centreId: 10,
    profileId: 83,
    applicationIds: [23, 25, 7, 2, 47, 2],
  },
  {
    id: 836,
    vacancy: 7,
    hired: 7,
    startsAt: '2020-08-12T17:38:58Z',
    endsAt: '2022-03-24T20:38:58Z',
    daysOff: [9, 4],
    createdAt: '2020-07-28T08:33:03Z',
    deletedAt: '2020-11-09T13:16:27Z',
    additionalInfo: 'pharetra',
    skillId: 3,
    centreId: 41,
    profileId: 21,
    applicationIds: [38, 48, 8, 24, 21, 5],
  },
  {
    id: 837,
    vacancy: 6,
    hired: 3,
    startsAt: '2020-11-25T20:10:06Z',
    endsAt: '2022-04-26T07:10:06Z',
    daysOff: undefined,
    createdAt: '2020-12-25T17:07:57Z',
    deletedAt: '2020-10-02T01:58:39Z',
    additionalInfo: 'lacus curabitur',
    skillId: 3,
    centreId: 14,
    profileId: 63,
    applicationIds: undefined,
  },
  {
    id: 838,
    vacancy: 10,
    hired: 9,
    startsAt: '2021-06-12T23:27:59Z',
    endsAt: '2023-02-22T08:27:59Z',
    daysOff: undefined,
    createdAt: '2020-12-17T12:18:26Z',
    deletedAt: '2020-08-13T08:05:16Z',
    additionalInfo: 'enim lorem',
    skillId: 2,
    centreId: 23,
    profileId: 92,
    applicationIds: undefined,
  },
  {
    id: 839,
    vacancy: 5,
    hired: 5,
    startsAt: '2020-12-30T23:40:35Z',
    endsAt: '2021-07-04T07:40:35Z',
    daysOff: undefined,
    createdAt: '2020-09-23T23:28:53Z',
    deletedAt: undefined,
    additionalInfo: 'ultrices',
    skillId: 2,
    centreId: 19,
    profileId: 56,
    applicationIds: undefined,
  },
  {
    id: 840,
    vacancy: 2,
    hired: 0,
    startsAt: '2021-03-19T01:25:55Z',
    endsAt: '2022-07-20T11:25:55Z',
    daysOff: undefined,
    createdAt: '2021-07-07T10:05:46Z',
    deletedAt: '2021-04-26T04:56:52Z',
    additionalInfo: 'morbi',
    skillId: 1,
    centreId: 18,
    profileId: 21,
    applicationIds: undefined,
  },
  {
    id: 841,
    vacancy: 7,
    hired: 2,
    startsAt: '2021-07-21T05:56:15Z',
    endsAt: '2023-08-01T14:56:15Z',
    daysOff: [10, 8],
    createdAt: '2020-11-21T06:18:29Z',
    deletedAt: '2021-04-20T19:32:41Z',
    additionalInfo: 'undefineda',
    skillId: 3,
    centreId: 1,
    profileId: 17,
    applicationIds: [4, 24, 31, 41, 22, 1],
  },
  {
    id: 842,
    vacancy: 4,
    hired: 0,
    startsAt: '2020-12-08T13:22:51Z',
    endsAt: '2022-11-13T00:22:51Z',
    daysOff: undefined,
    createdAt: '2021-06-22T10:05:26Z',
    deletedAt: undefined,
    additionalInfo: 'duis',
    skillId: 2,
    centreId: 33,
    profileId: 47,
    applicationIds: undefined,
  },
  {
    id: 843,
    vacancy: 7,
    hired: 0,
    startsAt: '2020-12-13T17:53:51Z',
    endsAt: '2022-01-25T05:53:51Z',
    daysOff: undefined,
    createdAt: '2021-06-09T06:26:22Z',
    deletedAt: '2021-04-15T03:45:41Z',
    additionalInfo: 'vestibulum sed',
    skillId: 1,
    centreId: 41,
    profileId: 53,
    applicationIds: undefined,
  },
  {
    id: 844,
    vacancy: 8,
    hired: 8,
    startsAt: '2021-05-26T12:11:15Z',
    endsAt: '2023-01-30T19:11:15Z',
    daysOff: [8, 2],
    createdAt: '2021-07-19T07:46:07Z',
    deletedAt: '2021-04-13T05:26:51Z',
    additionalInfo: 'a',
    skillId: 1,
    centreId: 8,
    profileId: 32,
    applicationIds: [40, 11, 34, 42, 44, 48],
  },
  {
    id: 845,
    vacancy: 4,
    hired: 1,
    startsAt: '2020-12-27T08:37:44Z',
    endsAt: '2022-04-30T12:37:44Z',
    daysOff: undefined,
    createdAt: '2021-07-17T18:50:46Z',
    deletedAt: '2021-03-17T14:34:59Z',
    additionalInfo: 'elit proin',
    skillId: 2,
    centreId: 15,
    profileId: 98,
    applicationIds: undefined,
  },
  {
    id: 846,
    vacancy: 10,
    hired: 7,
    startsAt: '2021-07-20T13:41:24Z',
    endsAt: '2022-04-21T21:41:24Z',
    daysOff: undefined,
    createdAt: '2021-01-01T22:05:32Z',
    deletedAt: '2021-05-31T00:12:42Z',
    additionalInfo: 'nunc',
    skillId: 3,
    centreId: 13,
    profileId: 73,
    applicationIds: undefined,
  },
  {
    id: 847,
    vacancy: 5,
    hired: 5,
    startsAt: '2021-02-23T09:53:48Z',
    endsAt: '2022-06-23T16:53:48Z',
    daysOff: undefined,
    createdAt: '2021-03-21T09:13:14Z',
    deletedAt: '2020-08-20T06:34:16Z',
    additionalInfo: 'lacus curabitur',
    skillId: 1,
    centreId: 7,
    profileId: 100,
    applicationIds: undefined,
  },
  {
    id: 848,
    vacancy: 8,
    hired: 2,
    startsAt: '2021-03-15T14:11:11Z',
    endsAt: '2023-01-29T19:11:11Z',
    daysOff: undefined,
    createdAt: '2020-11-08T04:14:40Z',
    deletedAt: '2021-02-28T21:12:00Z',
    additionalInfo: 'posuere cubilia',
    skillId: 3,
    centreId: 30,
    profileId: 25,
    applicationIds: undefined,
  },
  {
    id: 849,
    vacancy: 3,
    hired: 2,
    startsAt: '2020-12-27T22:37:13Z',
    endsAt: '2021-09-04T04:37:13Z',
    daysOff: undefined,
    createdAt: '2021-03-25T01:10:38Z',
    deletedAt: '2021-05-16T14:28:15Z',
    additionalInfo: 'non',
    skillId: 3,
    centreId: 30,
    profileId: 13,
    applicationIds: undefined,
  },
  {
    id: 850,
    vacancy: 3,
    hired: 0,
    startsAt: '2020-09-21T03:58:11Z',
    endsAt: '2020-12-21T15:58:11Z',
    daysOff: undefined,
    createdAt: '2021-04-18T08:24:56Z',
    deletedAt: '2020-09-02T07:19:23Z',
    additionalInfo: 'ipsum',
    skillId: 2,
    centreId: 4,
    profileId: 50,
    applicationIds: undefined,
  },
  {
    id: 851,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-10-06T04:59:33Z',
    endsAt: '2022-05-31T16:59:33Z',
    daysOff: undefined,
    createdAt: '2020-12-10T21:29:24Z',
    deletedAt: '2020-10-17T06:56:24Z',
    additionalInfo: 'lorem quisque',
    skillId: 2,
    centreId: 15,
    profileId: 39,
    applicationIds: undefined,
  },
  {
    id: 852,
    vacancy: 4,
    hired: 4,
    startsAt: '2020-11-17T08:28:09Z',
    endsAt: '2021-07-08T17:28:09Z',
    daysOff: [1, 2],
    createdAt: '2021-01-16T06:12:45Z',
    deletedAt: '2021-06-19T07:14:20Z',
    additionalInfo: 'vivamus tortor',
    skillId: 1,
    centreId: 29,
    profileId: 59,
    applicationIds: [45, 48, 3, 42, 4, 20],
  },
  {
    id: 853,
    vacancy: 8,
    hired: 3,
    startsAt: '2021-03-24T05:17:23Z',
    endsAt: '2021-05-23T09:17:23Z',
    daysOff: undefined,
    createdAt: '2020-11-19T13:51:43Z',
    deletedAt: undefined,
    additionalInfo: 'pede',
    skillId: 3,
    centreId: 50,
    profileId: 52,
    applicationIds: undefined,
  },
  {
    id: 854,
    vacancy: 10,
    hired: 9,
    startsAt: '2021-05-04T21:14:36Z',
    endsAt: '2022-11-02T06:14:36Z',
    daysOff: undefined,
    createdAt: '2020-11-30T04:11:57Z',
    deletedAt: '2020-12-12T23:05:23Z',
    additionalInfo: 'libero',
    skillId: 2,
    centreId: 3,
    profileId: 61,
    applicationIds: undefined,
  },
  {
    id: 855,
    vacancy: 2,
    hired: 0,
    startsAt: '2020-10-12T16:09:23Z',
    endsAt: '2022-08-02T20:09:23Z',
    daysOff: undefined,
    createdAt: '2021-04-20T02:15:54Z',
    deletedAt: '2020-12-08T02:22:02Z',
    additionalInfo: 'etiam faucibus',
    skillId: 1,
    centreId: 47,
    profileId: 68,
    applicationIds: undefined,
  },
  {
    id: 856,
    vacancy: 2,
    hired: 1,
    startsAt: '2021-04-27T16:48:15Z',
    endsAt: '2022-04-13T22:48:15Z',
    daysOff: undefined,
    createdAt: '2020-12-19T06:51:55Z',
    deletedAt: '2020-11-06T10:27:07Z',
    additionalInfo: 'nec',
    skillId: 3,
    centreId: 6,
    profileId: 63,
    applicationIds: undefined,
  },
  {
    id: 857,
    vacancy: 3,
    hired: 0,
    startsAt: '2020-10-03T16:16:09Z',
    endsAt: '2022-02-08T02:16:09Z',
    daysOff: undefined,
    createdAt: '2021-01-14T10:02:08Z',
    deletedAt: undefined,
    additionalInfo: 'eget',
    skillId: 2,
    centreId: 47,
    profileId: 50,
    applicationIds: undefined,
  },
  {
    id: 858,
    vacancy: 3,
    hired: 2,
    startsAt: '2021-06-15T22:14:26Z',
    endsAt: '2023-04-01T01:14:26Z',
    daysOff: undefined,
    createdAt: '2021-02-08T13:57:34Z',
    deletedAt: '2021-04-21T03:30:12Z',
    additionalInfo: 'metus arcu',
    skillId: 1,
    centreId: 17,
    profileId: 89,
    applicationIds: undefined,
  },
  {
    id: 859,
    vacancy: 4,
    hired: 4,
    startsAt: '2020-11-26T02:39:25Z',
    endsAt: '2021-11-11T14:39:25Z',
    daysOff: undefined,
    createdAt: '2021-03-28T09:05:42Z',
    deletedAt: '2020-08-20T05:18:02Z',
    additionalInfo: 'metus',
    skillId: 2,
    centreId: 39,
    profileId: 71,
    applicationIds: undefined,
  },
  {
    id: 860,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-10-29T03:33:23Z',
    endsAt: '2021-07-18T07:33:23Z',
    daysOff: undefined,
    createdAt: '2021-06-15T01:56:27Z',
    deletedAt: '2021-03-08T05:02:05Z',
    additionalInfo: 'sed',
    skillId: 2,
    centreId: 30,
    profileId: 24,
    applicationIds: undefined,
  },
  {
    id: 861,
    vacancy: 2,
    hired: 0,
    startsAt: '2020-11-17T22:58:57Z',
    endsAt: '2021-02-14T03:58:57Z',
    daysOff: undefined,
    createdAt: '2020-09-26T11:56:21Z',
    deletedAt: '2020-11-09T19:16:16Z',
    additionalInfo: 'et magnis',
    skillId: 1,
    centreId: 11,
    profileId: 72,
    applicationIds: undefined,
  },
  {
    id: 862,
    vacancy: 4,
    hired: 1,
    startsAt: '2020-09-30T13:22:53Z',
    endsAt: '2021-12-02T23:22:53Z',
    daysOff: undefined,
    createdAt: '2021-07-19T13:12:24Z',
    deletedAt: '2021-06-19T11:22:22Z',
    additionalInfo: 'vel',
    skillId: 3,
    centreId: 16,
    profileId: 83,
    applicationIds: undefined,
  },
  {
    id: 863,
    vacancy: 3,
    hired: 2,
    startsAt: '2020-09-23T13:39:35Z',
    endsAt: '2021-11-27T18:39:35Z',
    daysOff: undefined,
    createdAt: '2020-12-13T04:02:11Z',
    deletedAt: undefined,
    additionalInfo: 'nibh',
    skillId: 3,
    centreId: 39,
    profileId: 16,
    applicationIds: undefined,
  },
  {
    id: 864,
    vacancy: 7,
    hired: 4,
    startsAt: '2020-08-24T04:45:19Z',
    endsAt: '2020-11-25T07:45:19Z',
    daysOff: undefined,
    createdAt: '2021-05-16T03:20:20Z',
    deletedAt: '2021-02-13T19:59:39Z',
    additionalInfo: 'primis',
    skillId: 3,
    centreId: 23,
    profileId: 44,
    applicationIds: undefined,
  },
  {
    id: 865,
    vacancy: 3,
    hired: 2,
    startsAt: '2020-11-07T18:04:51Z',
    endsAt: '2022-05-25T02:04:51Z',
    daysOff: undefined,
    createdAt: '2021-03-13T02:13:20Z',
    deletedAt: '2021-01-19T16:11:36Z',
    additionalInfo: 'donec odio',
    skillId: 1,
    centreId: 5,
    profileId: 30,
    applicationIds: undefined,
  },
  {
    id: 866,
    vacancy: 1,
    hired: 0,
    startsAt: '2020-08-09T11:52:11Z',
    endsAt: '2021-05-17T23:52:11Z',
    daysOff: undefined,
    createdAt: '2021-01-05T09:07:59Z',
    deletedAt: undefined,
    additionalInfo: 'metus aenean',
    skillId: 2,
    centreId: 32,
    profileId: 36,
    applicationIds: undefined,
  },
  {
    id: 867,
    vacancy: 2,
    hired: 2,
    startsAt: '2020-09-10T04:39:38Z',
    endsAt: '2021-05-27T10:39:38Z',
    daysOff: [4, 10],
    createdAt: '2020-08-18T20:23:05Z',
    deletedAt: '2020-08-08T07:12:04Z',
    additionalInfo: 'rhoncus aliquet',
    skillId: 2,
    centreId: 29,
    profileId: 47,
    applicationIds: [28, 12, 30, 8, 49, 36],
  },
  {
    id: 868,
    vacancy: 10,
    hired: 4,
    startsAt: '2020-09-19T04:58:07Z',
    endsAt: '2021-08-11T09:58:07Z',
    daysOff: undefined,
    createdAt: '2021-02-19T09:50:13Z',
    deletedAt: '2021-05-17T21:41:49Z',
    additionalInfo: 'in',
    skillId: 2,
    centreId: 3,
    profileId: 87,
    applicationIds: undefined,
  },
  {
    id: 869,
    vacancy: 8,
    hired: 2,
    startsAt: '2020-08-28T23:51:12Z',
    endsAt: '2021-02-05T03:51:12Z',
    daysOff: [4, 1],
    createdAt: '2021-03-17T18:26:13Z',
    deletedAt: '2020-10-09T20:26:19Z',
    additionalInfo: 'ridiculus',
    skillId: 1,
    centreId: 11,
    profileId: 98,
    applicationIds: [20, 22, 25, 43, 15, 44],
  },
  {
    id: 870,
    vacancy: 5,
    hired: 3,
    startsAt: '2020-12-30T22:12:08Z',
    endsAt: '2021-12-26T02:12:08Z',
    daysOff: undefined,
    createdAt: '2021-01-26T05:24:40Z',
    deletedAt: undefined,
    additionalInfo: 'nec',
    skillId: 3,
    centreId: 30,
    profileId: 13,
    applicationIds: undefined,
  },
  {
    id: 871,
    vacancy: 5,
    hired: 2,
    startsAt: '2020-10-10T21:08:22Z',
    endsAt: '2021-04-28T09:08:22Z',
    daysOff: [6, 10],
    createdAt: '2020-12-04T07:27:25Z',
    deletedAt: undefined,
    additionalInfo: 'maecenas',
    skillId: 3,
    centreId: 3,
    profileId: 43,
    applicationIds: [28, 29, 28, 18, 48, 37],
  },
  {
    id: 872,
    vacancy: 6,
    hired: 6,
    startsAt: '2021-04-30T07:47:16Z',
    endsAt: '2021-07-01T11:47:16Z',
    daysOff: [1, 8],
    createdAt: '2021-01-23T10:31:42Z',
    deletedAt: '2020-08-16T14:28:23Z',
    additionalInfo: 'imperdiet et',
    skillId: 2,
    centreId: 47,
    profileId: 18,
    applicationIds: [41, 17, 41, 19, 37, 31],
  },
  {
    id: 873,
    vacancy: 5,
    hired: 1,
    startsAt: '2020-12-18T07:15:49Z',
    endsAt: '2022-02-11T16:15:49Z',
    daysOff: undefined,
    createdAt: '2021-02-01T20:18:12Z',
    deletedAt: '2020-12-26T02:53:48Z',
    additionalInfo: 'lorem integer',
    skillId: 2,
    centreId: 23,
    profileId: 60,
    applicationIds: undefined,
  },
  {
    id: 874,
    vacancy: 7,
    hired: 5,
    startsAt: '2021-06-04T14:25:36Z',
    endsAt: '2021-11-21T00:25:36Z',
    daysOff: undefined,
    createdAt: '2021-05-26T09:34:54Z',
    deletedAt: undefined,
    additionalInfo: 'faucibus',
    skillId: 3,
    centreId: 4,
    profileId: 1,
    applicationIds: undefined,
  },
  {
    id: 875,
    vacancy: 7,
    hired: 3,
    startsAt: '2021-01-06T13:00:45Z',
    endsAt: '2022-05-15T01:00:45Z',
    daysOff: undefined,
    createdAt: '2021-03-31T12:54:08Z',
    deletedAt: '2021-07-10T10:53:25Z',
    additionalInfo: 'faucibus cursus',
    skillId: 2,
    centreId: 43,
    profileId: 70,
    applicationIds: undefined,
  },
  {
    id: 876,
    vacancy: 1,
    hired: 1,
    startsAt: '2021-06-05T11:23:54Z',
    endsAt: '2023-02-25T19:23:54Z',
    daysOff: undefined,
    createdAt: '2021-02-10T08:54:13Z',
    deletedAt: '2021-03-19T07:31:51Z',
    additionalInfo: 'erat',
    skillId: 2,
    centreId: 36,
    profileId: 86,
    applicationIds: undefined,
  },
  {
    id: 877,
    vacancy: 1,
    hired: 1,
    startsAt: '2020-12-04T16:12:12Z',
    endsAt: '2022-05-26T19:12:12Z',
    daysOff: [9, 6],
    createdAt: '2021-02-04T05:53:28Z',
    deletedAt: undefined,
    additionalInfo: 'in hac',
    skillId: 1,
    centreId: 20,
    profileId: 100,
    applicationIds: [50, 28, 44, 34, 8, 18],
  },
  {
    id: 878,
    vacancy: 1,
    hired: 1,
    startsAt: '2021-01-13T11:53:45Z',
    endsAt: '2021-08-25T16:53:45Z',
    daysOff: undefined,
    createdAt: '2021-06-11T00:51:41Z',
    deletedAt: '2021-03-20T00:34:39Z',
    additionalInfo: 'libero',
    skillId: 1,
    centreId: 18,
    profileId: 8,
    applicationIds: undefined,
  },
  {
    id: 879,
    vacancy: 3,
    hired: 3,
    startsAt: '2020-09-08T03:52:15Z',
    endsAt: '2021-10-16T10:52:15Z',
    daysOff: undefined,
    createdAt: '2020-08-22T10:38:41Z',
    deletedAt: '2020-09-27T14:31:17Z',
    additionalInfo: 'at dolor',
    skillId: 2,
    centreId: 20,
    profileId: 14,
    applicationIds: undefined,
  },
  {
    id: 880,
    vacancy: 4,
    hired: 1,
    startsAt: '2020-10-27T07:16:11Z',
    endsAt: '2022-09-12T11:16:11Z',
    daysOff: undefined,
    createdAt: '2021-04-05T07:51:04Z',
    deletedAt: '2021-02-19T19:28:03Z',
    additionalInfo: 'eget',
    skillId: 1,
    centreId: 27,
    profileId: 31,
    applicationIds: undefined,
  },
  {
    id: 881,
    vacancy: 2,
    hired: 0,
    startsAt: '2020-11-03T20:20:23Z',
    endsAt: '2022-11-26T05:20:23Z',
    daysOff: undefined,
    createdAt: '2021-03-20T04:47:10Z',
    deletedAt: '2020-10-04T22:36:10Z',
    additionalInfo: 'in blandit',
    skillId: 2,
    centreId: 44,
    profileId: 15,
    applicationIds: undefined,
  },
  {
    id: 882,
    vacancy: 5,
    hired: 0,
    startsAt: '2021-05-21T12:02:53Z',
    endsAt: '2023-02-15T23:02:53Z',
    daysOff: undefined,
    createdAt: '2021-02-04T00:23:48Z',
    deletedAt: undefined,
    additionalInfo: 'eu',
    skillId: 3,
    centreId: 50,
    profileId: 57,
    applicationIds: undefined,
  },
  {
    id: 883,
    vacancy: 10,
    hired: 4,
    startsAt: '2020-11-04T02:14:43Z',
    endsAt: '2021-05-29T09:14:43Z',
    daysOff: undefined,
    createdAt: '2020-11-26T18:40:22Z',
    deletedAt: '2020-10-14T14:33:43Z',
    additionalInfo: 'non',
    skillId: 2,
    centreId: 17,
    profileId: 96,
    applicationIds: undefined,
  },
  {
    id: 884,
    vacancy: 10,
    hired: 4,
    startsAt: '2020-08-05T11:31:29Z',
    endsAt: '2021-07-07T20:31:29Z',
    daysOff: undefined,
    createdAt: '2020-11-06T16:34:16Z',
    deletedAt: '2021-05-31T18:30:05Z',
    additionalInfo: 'mus etiam',
    skillId: 1,
    centreId: 9,
    profileId: 75,
    applicationIds: undefined,
  },
  {
    id: 885,
    vacancy: 3,
    hired: 0,
    startsAt: '2020-09-15T18:20:57Z',
    endsAt: '2021-11-21T04:20:57Z',
    daysOff: undefined,
    createdAt: '2021-01-11T06:14:38Z',
    deletedAt: undefined,
    additionalInfo: 'vivamus vestibulum',
    skillId: 2,
    centreId: 21,
    profileId: 49,
    applicationIds: undefined,
  },
  {
    id: 886,
    vacancy: 6,
    hired: 5,
    startsAt: '2020-08-12T09:55:26Z',
    endsAt: '2022-01-29T16:55:26Z',
    daysOff: [2, 7],
    createdAt: '2021-04-30T22:15:37Z',
    deletedAt: '2020-12-16T15:13:35Z',
    additionalInfo: 'dapibus',
    skillId: 2,
    centreId: 47,
    profileId: 36,
    applicationIds: [18, 13, 23, 37, 33, 11],
  },
  {
    id: 887,
    vacancy: 2,
    hired: 2,
    startsAt: '2021-03-04T22:37:39Z',
    endsAt: '2022-09-29T10:37:39Z',
    daysOff: undefined,
    createdAt: '2020-10-11T10:00:45Z',
    deletedAt: '2021-02-13T06:16:16Z',
    additionalInfo: 'ultrices libero',
    skillId: 3,
    centreId: 38,
    profileId: 76,
    applicationIds: undefined,
  },
  {
    id: 888,
    vacancy: 7,
    hired: 4,
    startsAt: '2021-05-05T14:24:03Z',
    endsAt: '2022-11-02T00:24:03Z',
    daysOff: [8, 10],
    createdAt: '2020-10-26T19:31:33Z',
    deletedAt: '2020-11-23T09:34:32Z',
    additionalInfo: 'turpis integer',
    skillId: 1,
    centreId: 35,
    profileId: 92,
    applicationIds: [50, 24, 3, 36, 44, 30],
  },
  {
    id: 889,
    vacancy: 8,
    hired: 1,
    startsAt: '2020-09-27T13:05:53Z',
    endsAt: '2021-07-06T16:05:53Z',
    daysOff: undefined,
    createdAt: '2020-12-30T23:49:43Z',
    deletedAt: '2021-06-16T14:03:42Z',
    additionalInfo: 'suspendisse',
    skillId: 2,
    centreId: 41,
    profileId: 65,
    applicationIds: undefined,
  },
  {
    id: 890,
    vacancy: 7,
    hired: 2,
    startsAt: '2020-08-04T03:57:02Z',
    endsAt: '2022-04-30T06:57:02Z',
    daysOff: undefined,
    createdAt: '2021-04-16T10:07:09Z',
    deletedAt: '2021-05-21T01:10:08Z',
    additionalInfo: 'metus',
    skillId: 3,
    centreId: 33,
    profileId: 15,
    applicationIds: undefined,
  },
  {
    id: 891,
    vacancy: 6,
    hired: 1,
    startsAt: '2021-05-07T08:49:24Z',
    endsAt: '2022-01-09T12:49:24Z',
    daysOff: [2, 2],
    createdAt: '2021-05-24T11:56:07Z',
    deletedAt: '2021-04-12T18:54:33Z',
    additionalInfo: 'consequat lectus',
    skillId: 1,
    centreId: 10,
    profileId: 72,
    applicationIds: [42, 18, 10, 24, 36, 23],
  },
  {
    id: 892,
    vacancy: 8,
    hired: 0,
    startsAt: '2021-05-18T04:26:42Z',
    endsAt: '2021-10-13T12:26:42Z',
    daysOff: [10, 2],
    createdAt: '2021-05-09T18:20:51Z',
    deletedAt: undefined,
    additionalInfo: 'placerat',
    skillId: 2,
    centreId: 3,
    profileId: 46,
    applicationIds: [8, 35, 10, 31, 5, 37],
  },
  {
    id: 893,
    vacancy: 2,
    hired: 0,
    startsAt: '2020-09-06T23:19:27Z',
    endsAt: '2021-08-24T06:19:27Z',
    daysOff: undefined,
    createdAt: '2020-12-19T14:07:26Z',
    deletedAt: '2020-10-11T23:32:12Z',
    additionalInfo: 'erat volutpat',
    skillId: 3,
    centreId: 14,
    profileId: 21,
    applicationIds: undefined,
  },
  {
    id: 894,
    vacancy: 7,
    hired: 5,
    startsAt: '2020-09-29T20:59:24Z',
    endsAt: '2021-01-23T07:59:24Z',
    daysOff: [2, 9],
    createdAt: '2021-07-13T01:38:34Z',
    deletedAt: '2021-06-06T15:47:40Z',
    additionalInfo: 'lectus in',
    skillId: 3,
    centreId: 17,
    profileId: 67,
    applicationIds: [41, 39, 25, 16, 38, 40],
  },
  {
    id: 895,
    vacancy: 6,
    hired: 1,
    startsAt: '2021-03-07T18:36:10Z',
    endsAt: '2021-12-29T06:36:10Z',
    daysOff: undefined,
    createdAt: '2021-03-28T15:39:40Z',
    deletedAt: undefined,
    additionalInfo: 'sed augue',
    skillId: 2,
    centreId: 9,
    profileId: 25,
    applicationIds: undefined,
  },
  {
    id: 896,
    vacancy: 9,
    hired: 0,
    startsAt: '2020-07-30T02:49:00Z',
    endsAt: '2022-02-28T10:49:00Z',
    daysOff: undefined,
    createdAt: '2021-04-02T06:16:04Z',
    deletedAt: '2021-03-12T00:21:52Z',
    additionalInfo: 'nisl',
    skillId: 2,
    centreId: 11,
    profileId: 20,
    applicationIds: undefined,
  },
  {
    id: 897,
    vacancy: 7,
    hired: 7,
    startsAt: '2020-12-01T16:00:35Z',
    endsAt: '2021-02-28T21:00:35Z',
    daysOff: undefined,
    createdAt: '2020-11-07T21:19:39Z',
    deletedAt: '2021-06-18T17:58:13Z',
    additionalInfo: 'vivamus',
    skillId: 3,
    centreId: 15,
    profileId: 77,
    applicationIds: undefined,
  },
  {
    id: 898,
    vacancy: 10,
    hired: 1,
    startsAt: '2020-10-23T15:15:42Z',
    endsAt: '2022-04-12T00:15:42Z',
    daysOff: undefined,
    createdAt: '2020-08-30T23:43:57Z',
    deletedAt: '2020-10-30T17:20:40Z',
    additionalInfo: 'at',
    skillId: 1,
    centreId: 15,
    profileId: 95,
    applicationIds: undefined,
  },
  {
    id: 899,
    vacancy: 6,
    hired: 4,
    startsAt: '2021-04-26T12:38:45Z',
    endsAt: '2022-11-29T00:38:45Z',
    daysOff: [9, 7],
    createdAt: '2021-04-09T08:58:35Z',
    deletedAt: '2020-12-15T13:00:58Z',
    additionalInfo: 'sodales sed',
    skillId: 2,
    centreId: 49,
    profileId: 37,
    applicationIds: [21, 33, 28, 3, 38, 45],
  },
  {
    id: 900,
    vacancy: 2,
    hired: 0,
    startsAt: '2020-09-25T01:21:44Z',
    endsAt: '2020-11-27T13:21:44Z',
    daysOff: [6, 8],
    createdAt: '2020-08-05T07:09:05Z',
    deletedAt: '2021-01-18T21:20:04Z',
    additionalInfo: 'undefineda',
    skillId: 1,
    centreId: 36,
    profileId: 3,
    applicationIds: [34, 23, 7, 18, 19, 22],
  },
  {
    id: 901,
    vacancy: 8,
    hired: 4,
    startsAt: '2020-11-16T19:43:11Z',
    endsAt: '2021-09-23T06:43:11Z',
    daysOff: undefined,
    createdAt: '2020-08-16T20:59:13Z',
    deletedAt: '2021-02-07T11:23:09Z',
    additionalInfo: 'ante vel',
    skillId: 3,
    centreId: 26,
    profileId: 13,
    applicationIds: undefined,
  },
  {
    id: 902,
    vacancy: 4,
    hired: 1,
    startsAt: '2021-06-28T12:08:17Z',
    endsAt: '2021-09-09T21:08:17Z',
    daysOff: undefined,
    createdAt: '2021-01-15T04:03:43Z',
    deletedAt: undefined,
    additionalInfo: 'mauris',
    skillId: 3,
    centreId: 5,
    profileId: 38,
    applicationIds: undefined,
  },
  {
    id: 903,
    vacancy: 8,
    hired: 7,
    startsAt: '2021-01-06T11:32:20Z',
    endsAt: '2021-10-17T19:32:20Z',
    daysOff: undefined,
    createdAt: '2021-02-09T00:35:18Z',
    deletedAt: undefined,
    additionalInfo: 'ipsum dolor',
    skillId: 1,
    centreId: 1,
    profileId: 94,
    applicationIds: undefined,
  },
  {
    id: 904,
    vacancy: 2,
    hired: 1,
    startsAt: '2021-01-12T13:43:00Z',
    endsAt: '2022-01-08T17:43:00Z',
    daysOff: undefined,
    createdAt: '2020-11-12T00:44:36Z',
    deletedAt: '2020-12-24T14:41:32Z',
    additionalInfo: 'eu sapien',
    skillId: 3,
    centreId: 6,
    profileId: 86,
    applicationIds: undefined,
  },
  {
    id: 905,
    vacancy: 5,
    hired: 2,
    startsAt: '2020-11-17T01:05:41Z',
    endsAt: '2022-09-13T13:05:41Z',
    daysOff: [1, 5],
    createdAt: '2021-03-06T08:54:30Z',
    deletedAt: '2020-11-23T01:54:41Z',
    additionalInfo: 'morbi',
    skillId: 2,
    centreId: 25,
    profileId: 22,
    applicationIds: [35, 35, 11, 2, 34, 25],
  },
  {
    id: 906,
    vacancy: 7,
    hired: 3,
    startsAt: '2021-05-23T16:58:15Z',
    endsAt: '2022-07-22T22:58:15Z',
    daysOff: undefined,
    createdAt: '2021-04-03T12:35:38Z',
    deletedAt: '2020-10-11T02:14:56Z',
    additionalInfo: 'in hac',
    skillId: 2,
    centreId: 43,
    profileId: 46,
    applicationIds: undefined,
  },
  {
    id: 907,
    vacancy: 1,
    hired: 1,
    startsAt: '2021-04-28T17:05:01Z',
    endsAt: '2022-01-18T21:05:01Z',
    daysOff: undefined,
    createdAt: '2021-02-11T07:13:14Z',
    deletedAt: '2021-01-21T20:34:10Z',
    additionalInfo: 'libero undefinedam',
    skillId: 2,
    centreId: 15,
    profileId: 73,
    applicationIds: undefined,
  },
  {
    id: 908,
    vacancy: 8,
    hired: 1,
    startsAt: '2021-07-10T05:23:08Z',
    endsAt: '2023-08-08T16:23:08Z',
    daysOff: undefined,
    createdAt: '2021-02-23T17:34:34Z',
    deletedAt: undefined,
    additionalInfo: 'facilisi cras',
    skillId: 1,
    centreId: 32,
    profileId: 56,
    applicationIds: undefined,
  },
  {
    id: 909,
    vacancy: 5,
    hired: 0,
    startsAt: '2020-12-10T13:44:15Z',
    endsAt: '2021-08-17T16:44:15Z',
    daysOff: undefined,
    createdAt: '2021-01-09T01:39:22Z',
    deletedAt: undefined,
    additionalInfo: 'vitae consectetuer',
    skillId: 1,
    centreId: 50,
    profileId: 87,
    applicationIds: undefined,
  },
  {
    id: 910,
    vacancy: 7,
    hired: 3,
    startsAt: '2021-03-19T07:28:04Z',
    endsAt: '2023-04-01T12:28:04Z',
    daysOff: [4, 10],
    createdAt: '2020-11-12T14:25:03Z',
    deletedAt: undefined,
    additionalInfo: 'sociis natoque',
    skillId: 3,
    centreId: 39,
    profileId: 17,
    applicationIds: [47, 43, 15, 46, 49, 49],
  },
  {
    id: 911,
    vacancy: 9,
    hired: 8,
    startsAt: '2021-06-04T21:50:25Z',
    endsAt: '2021-07-25T00:50:25Z',
    daysOff: [4, 5],
    createdAt: '2021-07-11T14:59:34Z',
    deletedAt: '2021-03-24T18:46:32Z',
    additionalInfo: 'rutrum',
    skillId: 1,
    centreId: 48,
    profileId: 21,
    applicationIds: [34, 14, 19, 47, 13, 34],
  },
  {
    id: 912,
    vacancy: 1,
    hired: 0,
    startsAt: '2021-07-13T19:46:37Z',
    endsAt: '2023-06-16T23:46:37Z',
    daysOff: undefined,
    createdAt: '2020-11-09T17:20:25Z',
    deletedAt: undefined,
    additionalInfo: 'justo aliquam',
    skillId: 3,
    centreId: 35,
    profileId: 43,
    applicationIds: undefined,
  },
  {
    id: 913,
    vacancy: 2,
    hired: 1,
    startsAt: '2021-02-15T22:11:33Z',
    endsAt: '2023-03-03T04:11:33Z',
    daysOff: [8, 4],
    createdAt: '2021-01-20T21:13:44Z',
    deletedAt: '2020-11-08T03:11:26Z',
    additionalInfo: 'eleifend donec',
    skillId: 1,
    centreId: 19,
    profileId: 60,
    applicationIds: [1, 19, 26, 22, 50, 20],
  },
  {
    id: 914,
    vacancy: 1,
    hired: 1,
    startsAt: '2021-04-28T07:09:09Z',
    endsAt: '2021-07-02T14:09:09Z',
    daysOff: undefined,
    createdAt: '2021-01-01T09:06:06Z',
    deletedAt: undefined,
    additionalInfo: 'vel enim',
    skillId: 3,
    centreId: 49,
    profileId: 53,
    applicationIds: undefined,
  },
  {
    id: 915,
    vacancy: 8,
    hired: 3,
    startsAt: '2021-01-24T16:12:03Z',
    endsAt: '2022-07-02T04:12:03Z',
    daysOff: undefined,
    createdAt: '2021-05-09T12:49:06Z',
    deletedAt: undefined,
    additionalInfo: 'cras',
    skillId: 2,
    centreId: 24,
    profileId: 79,
    applicationIds: undefined,
  },
  {
    id: 916,
    vacancy: 10,
    hired: 6,
    startsAt: '2020-12-07T08:54:59Z',
    endsAt: '2023-01-02T13:54:59Z',
    daysOff: undefined,
    createdAt: '2021-01-15T09:54:59Z',
    deletedAt: '2021-05-07T16:21:06Z',
    additionalInfo: 'ligula nec',
    skillId: 3,
    centreId: 5,
    profileId: 91,
    applicationIds: undefined,
  },
  {
    id: 917,
    vacancy: 5,
    hired: 3,
    startsAt: '2020-12-10T22:45:33Z',
    endsAt: '2021-03-05T09:45:33Z',
    daysOff: undefined,
    createdAt: '2020-12-16T23:04:01Z',
    deletedAt: '2020-10-23T10:46:54Z',
    additionalInfo: 'maecenas',
    skillId: 2,
    centreId: 13,
    profileId: 62,
    applicationIds: undefined,
  },
  {
    id: 918,
    vacancy: 7,
    hired: 7,
    startsAt: '2021-01-26T08:33:54Z',
    endsAt: '2021-10-05T14:33:54Z',
    daysOff: undefined,
    createdAt: '2021-02-18T17:48:47Z',
    deletedAt: '2021-04-21T01:52:36Z',
    additionalInfo: 'facilisi cras',
    skillId: 3,
    centreId: 25,
    profileId: 53,
    applicationIds: undefined,
  },
  {
    id: 919,
    vacancy: 3,
    hired: 1,
    startsAt: '2020-09-20T08:15:46Z',
    endsAt: '2021-05-07T20:15:46Z',
    daysOff: [7, 10],
    createdAt: '2020-08-17T01:55:21Z',
    deletedAt: '2021-04-28T05:05:39Z',
    additionalInfo: 'in',
    skillId: 1,
    centreId: 16,
    profileId: 54,
    applicationIds: [5, 38, 27, 14, 9, 10],
  },
  {
    id: 920,
    vacancy: 3,
    hired: 3,
    startsAt: '2021-03-15T00:55:48Z',
    endsAt: '2022-02-05T08:55:48Z',
    daysOff: undefined,
    createdAt: '2021-03-05T18:37:18Z',
    deletedAt: '2021-03-05T12:37:41Z',
    additionalInfo: 'undefineda tempus',
    skillId: 3,
    centreId: 42,
    profileId: 84,
    applicationIds: undefined,
  },
  {
    id: 921,
    vacancy: 2,
    hired: 1,
    startsAt: '2021-07-17T23:20:07Z',
    endsAt: '2023-08-11T07:20:07Z',
    daysOff: undefined,
    createdAt: '2021-07-19T16:24:19Z',
    deletedAt: undefined,
    additionalInfo: 'tortor',
    skillId: 3,
    centreId: 1,
    profileId: 15,
    applicationIds: undefined,
  },
  {
    id: 922,
    vacancy: 2,
    hired: 2,
    startsAt: '2020-10-05T11:08:03Z',
    endsAt: '2021-01-25T19:08:03Z',
    daysOff: undefined,
    createdAt: '2021-01-28T02:17:47Z',
    deletedAt: '2020-07-27T13:29:37Z',
    additionalInfo: 'dui',
    skillId: 3,
    centreId: 48,
    profileId: 6,
    applicationIds: undefined,
  },
  {
    id: 923,
    vacancy: 4,
    hired: 2,
    startsAt: '2021-07-22T18:08:04Z',
    endsAt: '2023-04-20T21:08:04Z',
    daysOff: [2, 7],
    createdAt: '2021-02-18T19:38:12Z',
    deletedAt: '2021-06-30T22:05:56Z',
    additionalInfo: 'primis in',
    skillId: 2,
    centreId: 17,
    profileId: 49,
    applicationIds: [6, 23, 38, 25, 12, 27],
  },
  {
    id: 924,
    vacancy: 9,
    hired: 8,
    startsAt: '2021-06-12T06:19:56Z',
    endsAt: '2023-02-21T14:19:56Z',
    daysOff: undefined,
    createdAt: '2021-01-07T05:10:14Z',
    deletedAt: '2020-12-19T06:04:22Z',
    additionalInfo: 'semper interdum',
    skillId: 2,
    centreId: 3,
    profileId: 17,
    applicationIds: undefined,
  },
  {
    id: 925,
    vacancy: 6,
    hired: 5,
    startsAt: '2021-02-07T19:55:36Z',
    endsAt: '2022-01-21T06:55:36Z',
    daysOff: [4, 2],
    createdAt: '2020-10-06T21:43:25Z',
    deletedAt: '2020-12-12T13:46:38Z',
    additionalInfo: 'felis ut',
    skillId: 3,
    centreId: 47,
    profileId: 26,
    applicationIds: [31, 26, 37, 35, 2, 33],
  },
  {
    id: 926,
    vacancy: 4,
    hired: 3,
    startsAt: '2021-05-12T00:04:37Z',
    endsAt: '2022-02-17T03:04:37Z',
    daysOff: [10, 10],
    createdAt: '2021-02-03T21:21:19Z',
    deletedAt: '2020-11-19T20:49:17Z',
    additionalInfo: 'ac leo',
    skillId: 2,
    centreId: 34,
    profileId: 41,
    applicationIds: [20, 37, 5, 16, 6, 48],
  },
  {
    id: 927,
    vacancy: 5,
    hired: 1,
    startsAt: '2020-07-31T20:05:38Z',
    endsAt: '2021-02-11T01:05:38Z',
    daysOff: undefined,
    createdAt: '2021-03-02T11:01:23Z',
    deletedAt: undefined,
    additionalInfo: 'turpis',
    skillId: 3,
    centreId: 15,
    profileId: 1,
    applicationIds: undefined,
  },
  {
    id: 928,
    vacancy: 9,
    hired: 1,
    startsAt: '2021-04-21T01:11:52Z',
    endsAt: '2022-07-05T07:11:52Z',
    daysOff: undefined,
    createdAt: '2021-03-10T17:27:27Z',
    deletedAt: '2021-03-28T13:47:06Z',
    additionalInfo: 'proin interdum',
    skillId: 3,
    centreId: 24,
    profileId: 51,
    applicationIds: undefined,
  },
  {
    id: 929,
    vacancy: 5,
    hired: 4,
    startsAt: '2020-10-03T02:17:58Z',
    endsAt: '2021-07-16T05:17:58Z',
    daysOff: undefined,
    createdAt: '2020-08-21T05:01:41Z',
    deletedAt: undefined,
    additionalInfo: 'undefineda ut',
    skillId: 2,
    centreId: 25,
    profileId: 92,
    applicationIds: undefined,
  },
  {
    id: 930,
    vacancy: 2,
    hired: 1,
    startsAt: '2021-06-24T02:48:21Z',
    endsAt: '2021-12-14T07:48:21Z',
    daysOff: undefined,
    createdAt: '2021-01-17T11:06:55Z',
    deletedAt: undefined,
    additionalInfo: 'eu magna',
    skillId: 1,
    centreId: 1,
    profileId: 7,
    applicationIds: undefined,
  },
  {
    id: 931,
    vacancy: 3,
    hired: 0,
    startsAt: '2021-05-22T16:40:39Z',
    endsAt: '2023-04-11T01:40:39Z',
    daysOff: undefined,
    createdAt: '2021-02-24T18:42:22Z',
    deletedAt: '2020-08-22T06:16:49Z',
    additionalInfo: 'risus auctor',
    skillId: 2,
    centreId: 30,
    profileId: 89,
    applicationIds: undefined,
  },
  {
    id: 932,
    vacancy: 6,
    hired: 3,
    startsAt: '2020-10-14T08:33:27Z',
    endsAt: '2022-03-03T19:33:27Z',
    daysOff: undefined,
    createdAt: '2020-12-14T00:52:19Z',
    deletedAt: '2021-03-05T09:22:15Z',
    additionalInfo: 'convallis tortor',
    skillId: 1,
    centreId: 20,
    profileId: 56,
    applicationIds: undefined,
  },
  {
    id: 933,
    vacancy: 6,
    hired: 3,
    startsAt: '2021-04-08T17:35:49Z',
    endsAt: '2022-07-07T23:35:49Z',
    daysOff: [8, 9],
    createdAt: '2021-05-22T10:54:58Z',
    deletedAt: '2021-04-07T07:59:48Z',
    additionalInfo: 'cras',
    skillId: 1,
    centreId: 18,
    profileId: 33,
    applicationIds: [36, 24, 50, 18, 20, 50],
  },
  {
    id: 934,
    vacancy: 10,
    hired: 10,
    startsAt: '2020-11-09T07:44:42Z',
    endsAt: '2021-11-11T10:44:42Z',
    daysOff: undefined,
    createdAt: '2021-02-14T13:33:40Z',
    deletedAt: '2021-06-14T03:25:54Z',
    additionalInfo: 'amet',
    skillId: 3,
    centreId: 32,
    profileId: 33,
    applicationIds: undefined,
  },
  {
    id: 935,
    vacancy: 2,
    hired: 0,
    startsAt: '2021-04-02T19:24:18Z',
    endsAt: '2022-08-18T22:24:18Z',
    daysOff: undefined,
    createdAt: '2020-12-01T15:41:14Z',
    deletedAt: '2020-08-15T10:04:23Z',
    additionalInfo: 'tellus undefineda',
    skillId: 3,
    centreId: 39,
    profileId: 4,
    applicationIds: undefined,
  },
  {
    id: 936,
    vacancy: 4,
    hired: 4,
    startsAt: '2020-12-16T19:39:26Z',
    endsAt: '2022-08-11T00:39:26Z',
    daysOff: undefined,
    createdAt: '2020-08-26T15:51:29Z',
    deletedAt: undefined,
    additionalInfo: 'ipsum',
    skillId: 1,
    centreId: 36,
    profileId: 31,
    applicationIds: undefined,
  },
  {
    id: 937,
    vacancy: 6,
    hired: 5,
    startsAt: '2021-03-29T18:09:05Z',
    endsAt: '2021-10-22T05:09:05Z',
    daysOff: [5, 4],
    createdAt: '2021-03-22T18:55:33Z',
    deletedAt: '2021-01-13T23:17:50Z',
    additionalInfo: 'rutrum rutrum',
    skillId: 2,
    centreId: 22,
    profileId: 4,
    applicationIds: [28, 21, 22, 30, 48, 10],
  },
  {
    id: 938,
    vacancy: 8,
    hired: 2,
    startsAt: '2020-11-03T22:58:08Z',
    endsAt: '2022-01-19T04:58:08Z',
    daysOff: undefined,
    createdAt: '2020-08-31T03:48:52Z',
    deletedAt: '2021-05-27T11:55:20Z',
    additionalInfo: 'nisl',
    skillId: 1,
    centreId: 30,
    profileId: 51,
    applicationIds: undefined,
  },
  {
    id: 939,
    vacancy: 2,
    hired: 0,
    startsAt: '2020-11-16T22:54:26Z',
    endsAt: '2022-02-28T07:54:26Z',
    daysOff: undefined,
    createdAt: '2021-02-01T21:23:45Z',
    deletedAt: '2020-11-24T01:34:45Z',
    additionalInfo: 'ullamcorper augue',
    skillId: 1,
    centreId: 28,
    profileId: 1,
    applicationIds: undefined,
  },
  {
    id: 940,
    vacancy: 10,
    hired: 2,
    startsAt: '2021-02-01T09:12:56Z',
    endsAt: '2022-05-06T14:12:56Z',
    daysOff: undefined,
    createdAt: '2021-03-17T13:10:20Z',
    deletedAt: undefined,
    additionalInfo: 'viverra',
    skillId: 1,
    centreId: 3,
    profileId: 73,
    applicationIds: undefined,
  },
  {
    id: 941,
    vacancy: 5,
    hired: 3,
    startsAt: '2021-02-17T05:50:14Z',
    endsAt: '2021-07-16T17:50:14Z',
    daysOff: [10, 5],
    createdAt: '2021-03-07T00:12:33Z',
    deletedAt: '2021-02-24T20:40:08Z',
    additionalInfo: 'donec quis',
    skillId: 2,
    centreId: 5,
    profileId: 32,
    applicationIds: [48, 7, 6, 3, 33, 10],
  },
  {
    id: 942,
    vacancy: 4,
    hired: 2,
    startsAt: '2021-03-08T10:07:23Z',
    endsAt: '2022-02-27T18:07:23Z',
    daysOff: undefined,
    createdAt: '2020-11-25T03:57:52Z',
    deletedAt: '2020-09-21T09:19:59Z',
    additionalInfo: 'suspendisse potenti',
    skillId: 2,
    centreId: 30,
    profileId: 49,
    applicationIds: undefined,
  },
  {
    id: 943,
    vacancy: 3,
    hired: 3,
    startsAt: '2021-04-07T06:00:57Z',
    endsAt: '2021-06-26T18:00:57Z',
    daysOff: [10, 1],
    createdAt: '2020-11-11T19:22:49Z',
    deletedAt: undefined,
    additionalInfo: 'suscipit',
    skillId: 1,
    centreId: 42,
    profileId: 12,
    applicationIds: [46, 12, 35, 19, 50, 34],
  },
  {
    id: 944,
    vacancy: 7,
    hired: 2,
    startsAt: '2021-02-07T04:27:57Z',
    endsAt: '2021-08-28T12:27:57Z',
    daysOff: undefined,
    createdAt: '2021-03-25T04:31:48Z',
    deletedAt: '2021-01-25T07:46:19Z',
    additionalInfo: 'in',
    skillId: 2,
    centreId: 19,
    profileId: 54,
    applicationIds: undefined,
  },
  {
    id: 945,
    vacancy: 7,
    hired: 4,
    startsAt: '2021-06-17T03:24:49Z',
    endsAt: '2021-09-07T09:24:49Z',
    daysOff: undefined,
    createdAt: '2021-06-05T06:49:30Z',
    deletedAt: undefined,
    additionalInfo: 'cursus id',
    skillId: 2,
    centreId: 17,
    profileId: 44,
    applicationIds: undefined,
  },
  {
    id: 946,
    vacancy: 3,
    hired: 2,
    startsAt: '2020-11-05T05:34:58Z',
    endsAt: '2022-07-13T17:34:58Z',
    daysOff: undefined,
    createdAt: '2021-07-21T15:05:45Z',
    deletedAt: '2020-09-05T02:24:15Z',
    additionalInfo: 'fusce',
    skillId: 3,
    centreId: 19,
    profileId: 42,
    applicationIds: undefined,
  },
  {
    id: 947,
    vacancy: 1,
    hired: 1,
    startsAt: '2021-02-28T05:29:07Z',
    endsAt: '2022-11-25T17:29:07Z',
    daysOff: undefined,
    createdAt: '2020-10-25T21:21:47Z',
    deletedAt: '2020-09-08T02:48:15Z',
    additionalInfo: 'ut',
    skillId: 3,
    centreId: 10,
    profileId: 2,
    applicationIds: undefined,
  },
  {
    id: 948,
    vacancy: 3,
    hired: 3,
    startsAt: '2021-01-02T18:44:56Z',
    endsAt: '2021-07-21T05:44:56Z',
    daysOff: [5, 4],
    createdAt: '2020-10-12T16:49:07Z',
    deletedAt: '2021-01-30T17:18:57Z',
    additionalInfo: 'sapien in',
    skillId: 2,
    centreId: 8,
    profileId: 12,
    applicationIds: [20, 44, 9, 50, 4, 36],
  },
  {
    id: 949,
    vacancy: 9,
    hired: 2,
    startsAt: '2021-04-23T16:42:22Z',
    endsAt: '2023-03-09T01:42:22Z',
    daysOff: undefined,
    createdAt: '2021-03-31T14:34:57Z',
    deletedAt: undefined,
    additionalInfo: 'augue luctus',
    skillId: 2,
    centreId: 38,
    profileId: 20,
    applicationIds: undefined,
  },
  {
    id: 950,
    vacancy: 9,
    hired: 9,
    startsAt: '2020-09-17T01:53:16Z',
    endsAt: '2021-03-04T06:53:16Z',
    daysOff: [9, 8],
    createdAt: '2020-09-09T01:55:01Z',
    deletedAt: undefined,
    additionalInfo: 'in',
    skillId: 3,
    centreId: 21,
    profileId: 24,
    applicationIds: [40, 11, 34, 48, 44, 27],
  },
  {
    id: 951,
    vacancy: 3,
    hired: 0,
    startsAt: '2021-01-27T05:42:39Z',
    endsAt: '2021-10-13T15:42:39Z',
    daysOff: undefined,
    createdAt: '2021-05-15T19:39:48Z',
    deletedAt: '2021-07-13T07:42:31Z',
    additionalInfo: 'massa',
    skillId: 3,
    centreId: 35,
    profileId: 84,
    applicationIds: undefined,
  },
  {
    id: 952,
    vacancy: 2,
    hired: 2,
    startsAt: '2020-09-12T10:10:56Z',
    endsAt: '2022-08-07T15:10:56Z',
    daysOff: [7, 5],
    createdAt: '2020-09-19T17:48:55Z',
    deletedAt: '2020-08-11T18:53:56Z',
    additionalInfo: 'habitasse',
    skillId: 2,
    centreId: 6,
    profileId: 62,
    applicationIds: [27, 46, 27, 3, 27, 39],
  },
  {
    id: 953,
    vacancy: 7,
    hired: 7,
    startsAt: '2021-06-21T03:42:53Z',
    endsAt: '2022-07-25T06:42:53Z',
    daysOff: [4, 6],
    createdAt: '2020-11-25T15:41:27Z',
    deletedAt: '2020-08-09T02:36:33Z',
    additionalInfo: 'sit',
    skillId: 2,
    centreId: 23,
    profileId: 27,
    applicationIds: [6, 33, 20, 8, 40, 43],
  },
  {
    id: 954,
    vacancy: 9,
    hired: 3,
    startsAt: '2021-06-06T19:21:27Z',
    endsAt: '2021-12-19T01:21:27Z',
    daysOff: undefined,
    createdAt: '2020-11-06T18:19:05Z',
    deletedAt: '2020-08-29T05:02:53Z',
    additionalInfo: 'elementum ligula',
    skillId: 2,
    centreId: 13,
    profileId: 44,
    applicationIds: undefined,
  },
  {
    id: 955,
    vacancy: 3,
    hired: 3,
    startsAt: '2020-08-18T14:56:34Z',
    endsAt: '2021-08-14T19:56:34Z',
    daysOff: [10, 8],
    createdAt: '2021-06-19T13:45:44Z',
    deletedAt: undefined,
    additionalInfo: 'semper est',
    skillId: 3,
    centreId: 16,
    profileId: 66,
    applicationIds: [10, 44, 19, 19, 11, 47],
  },
  {
    id: 956,
    vacancy: 7,
    hired: 7,
    startsAt: '2021-02-20T01:25:26Z',
    endsAt: '2021-04-16T05:25:26Z',
    daysOff: [8, 7],
    createdAt: '2020-12-03T10:52:18Z',
    deletedAt: '2021-01-15T03:25:16Z',
    additionalInfo: 'mauris enim',
    skillId: 2,
    centreId: 10,
    profileId: 67,
    applicationIds: [20, 45, 29, 33, 27, 40],
  },
  {
    id: 957,
    vacancy: 1,
    hired: 1,
    startsAt: '2021-06-04T07:35:50Z',
    endsAt: '2021-10-07T16:35:50Z',
    daysOff: undefined,
    createdAt: '2021-04-07T18:06:54Z',
    deletedAt: '2020-11-27T18:06:40Z',
    additionalInfo: 'convallis nunc',
    skillId: 1,
    centreId: 45,
    profileId: 21,
    applicationIds: undefined,
  },
  {
    id: 958,
    vacancy: 10,
    hired: 0,
    startsAt: '2021-01-30T02:16:55Z',
    endsAt: '2021-04-25T08:16:55Z',
    daysOff: undefined,
    createdAt: '2021-07-18T05:00:54Z',
    deletedAt: '2021-06-27T05:39:15Z',
    additionalInfo: 'eros',
    skillId: 3,
    centreId: 41,
    profileId: 13,
    applicationIds: undefined,
  },
  {
    id: 959,
    vacancy: 7,
    hired: 6,
    startsAt: '2020-09-15T13:31:54Z',
    endsAt: '2022-02-02T23:31:54Z',
    daysOff: undefined,
    createdAt: '2020-09-03T11:17:16Z',
    deletedAt: undefined,
    additionalInfo: 'odio',
    skillId: 1,
    centreId: 19,
    profileId: 5,
    applicationIds: undefined,
  },
  {
    id: 960,
    vacancy: 2,
    hired: 1,
    startsAt: '2021-05-22T09:06:32Z',
    endsAt: '2021-12-08T16:06:32Z',
    daysOff: undefined,
    createdAt: '2020-10-01T07:46:47Z',
    deletedAt: '2020-12-03T16:35:05Z',
    additionalInfo: 'cursus vestibulum',
    skillId: 3,
    centreId: 18,
    profileId: 87,
    applicationIds: undefined,
  },
  {
    id: 961,
    vacancy: 10,
    hired: 6,
    startsAt: '2021-01-29T18:19:52Z',
    endsAt: '2022-08-22T02:19:52Z',
    daysOff: undefined,
    createdAt: '2020-08-29T10:13:33Z',
    deletedAt: '2020-09-17T18:10:43Z',
    additionalInfo: 'adipiscing elit',
    skillId: 2,
    centreId: 30,
    profileId: 5,
    applicationIds: undefined,
  },
  {
    id: 962,
    vacancy: 3,
    hired: 3,
    startsAt: '2021-06-10T20:24:06Z',
    endsAt: '2022-04-10T04:24:06Z',
    daysOff: [6, 9],
    createdAt: '2021-03-29T22:13:06Z',
    deletedAt: '2021-01-06T16:34:05Z',
    additionalInfo: 'sit amet',
    skillId: 3,
    centreId: 49,
    profileId: 26,
    applicationIds: [28, 41, 40, 21, 25, 27],
  },
  {
    id: 963,
    vacancy: 8,
    hired: 2,
    startsAt: '2021-07-04T01:37:36Z',
    endsAt: '2022-07-20T05:37:36Z',
    daysOff: undefined,
    createdAt: '2021-06-06T11:11:58Z',
    deletedAt: '2021-04-23T11:34:46Z',
    additionalInfo: 'placerat ante',
    skillId: 3,
    centreId: 21,
    profileId: 24,
    applicationIds: undefined,
  },
  {
    id: 964,
    vacancy: 1,
    hired: 1,
    startsAt: '2021-05-03T05:40:16Z',
    endsAt: '2022-02-23T13:40:16Z',
    daysOff: [3, 5],
    createdAt: '2021-05-08T05:58:11Z',
    deletedAt: '2021-04-15T10:07:45Z',
    additionalInfo: 'amet',
    skillId: 3,
    centreId: 44,
    profileId: 60,
    applicationIds: [20, 47, 47, 37, 8, 46],
  },
  {
    id: 965,
    vacancy: 1,
    hired: 1,
    startsAt: '2021-04-23T08:49:37Z',
    endsAt: '2023-01-03T16:49:37Z',
    daysOff: undefined,
    createdAt: '2021-01-19T22:49:31Z',
    deletedAt: '2021-04-19T14:07:43Z',
    additionalInfo: 'tristique tortor',
    skillId: 3,
    centreId: 9,
    profileId: 42,
    applicationIds: undefined,
  },
  {
    id: 966,
    vacancy: 7,
    hired: 2,
    startsAt: '2021-03-18T00:58:27Z',
    endsAt: '2021-04-30T10:58:27Z',
    daysOff: undefined,
    createdAt: '2021-03-10T18:04:53Z',
    deletedAt: '2020-12-25T07:47:14Z',
    additionalInfo: 'diam',
    skillId: 3,
    centreId: 17,
    profileId: 77,
    applicationIds: undefined,
  },
  {
    id: 967,
    vacancy: 5,
    hired: 5,
    startsAt: '2020-11-07T04:29:54Z',
    endsAt: '2021-02-03T13:29:54Z',
    daysOff: undefined,
    createdAt: '2021-02-10T11:14:12Z',
    deletedAt: '2021-05-12T12:03:42Z',
    additionalInfo: 'sapien varius',
    skillId: 2,
    centreId: 30,
    profileId: 20,
    applicationIds: undefined,
  },
  {
    id: 968,
    vacancy: 1,
    hired: 1,
    startsAt: '2021-05-25T07:48:17Z',
    endsAt: '2021-09-27T11:48:17Z',
    daysOff: undefined,
    createdAt: '2020-12-27T00:29:58Z',
    deletedAt: '2021-07-19T14:49:40Z',
    additionalInfo: 'posuere',
    skillId: 1,
    centreId: 28,
    profileId: 95,
    applicationIds: undefined,
  },
  {
    id: 969,
    vacancy: 4,
    hired: 4,
    startsAt: '2021-01-15T15:52:13Z',
    endsAt: '2022-02-05T20:52:13Z',
    daysOff: undefined,
    createdAt: '2020-08-13T13:53:36Z',
    deletedAt: '2020-11-18T18:50:42Z',
    additionalInfo: 'amet',
    skillId: 1,
    centreId: 16,
    profileId: 9,
    applicationIds: undefined,
  },
  {
    id: 970,
    vacancy: 10,
    hired: 6,
    startsAt: '2020-10-08T22:07:14Z',
    endsAt: '2021-08-23T08:07:14Z',
    daysOff: undefined,
    createdAt: '2021-04-08T09:31:17Z',
    deletedAt: '2021-06-24T18:03:29Z',
    additionalInfo: 'porttitor',
    skillId: 3,
    centreId: 31,
    profileId: 79,
    applicationIds: undefined,
  },
  {
    id: 971,
    vacancy: 10,
    hired: 7,
    startsAt: '2021-02-10T20:29:49Z',
    endsAt: '2022-11-20T03:29:49Z',
    daysOff: undefined,
    createdAt: '2021-03-26T01:21:00Z',
    deletedAt: '2021-02-23T12:39:14Z',
    additionalInfo: 'velit donec',
    skillId: 3,
    centreId: 45,
    profileId: 65,
    applicationIds: undefined,
  },
  {
    id: 972,
    vacancy: 3,
    hired: 3,
    startsAt: '2020-08-12T15:35:37Z',
    endsAt: '2021-08-19T21:35:37Z',
    daysOff: [1, 10],
    createdAt: '2021-01-02T12:00:29Z',
    deletedAt: '2021-02-20T05:54:54Z',
    additionalInfo: 'non',
    skillId: 3,
    centreId: 23,
    profileId: 20,
    applicationIds: [9, 12, 39, 27, 48, 11],
  },
  {
    id: 973,
    vacancy: 10,
    hired: 4,
    startsAt: '2020-10-30T15:59:40Z',
    endsAt: '2021-11-23T22:59:40Z',
    daysOff: undefined,
    createdAt: '2021-02-27T03:10:44Z',
    deletedAt: '2020-12-28T19:03:25Z',
    additionalInfo: 'donec',
    skillId: 2,
    centreId: 34,
    profileId: 47,
    applicationIds: undefined,
  },
  {
    id: 974,
    vacancy: 6,
    hired: 6,
    startsAt: '2020-12-07T05:37:57Z',
    endsAt: '2022-08-16T14:37:57Z',
    daysOff: undefined,
    createdAt: '2021-04-19T05:20:17Z',
    deletedAt: '2021-05-27T08:43:30Z',
    additionalInfo: 'sed vel',
    skillId: 1,
    centreId: 2,
    profileId: 18,
    applicationIds: undefined,
  },
  {
    id: 975,
    vacancy: 9,
    hired: 3,
    startsAt: '2021-02-26T04:40:19Z',
    endsAt: '2022-01-12T12:40:19Z',
    daysOff: undefined,
    createdAt: '2020-09-14T06:16:37Z',
    deletedAt: '2020-11-10T18:33:40Z',
    additionalInfo: 'velit',
    skillId: 1,
    centreId: 29,
    profileId: 97,
    applicationIds: undefined,
  },
  {
    id: 976,
    vacancy: 6,
    hired: 6,
    startsAt: '2021-01-21T17:41:39Z',
    endsAt: '2021-06-06T22:41:39Z',
    daysOff: [3, 9],
    createdAt: '2021-03-02T21:27:52Z',
    deletedAt: '2020-08-29T03:53:35Z',
    additionalInfo: 'metus',
    skillId: 1,
    centreId: 11,
    profileId: 47,
    applicationIds: [28, 27, 26, 36, 29, 36],
  },
  {
    id: 977,
    vacancy: 2,
    hired: 1,
    startsAt: '2020-11-14T06:05:58Z',
    endsAt: '2022-04-20T11:05:58Z',
    daysOff: [1, 7],
    createdAt: '2021-03-11T23:02:02Z',
    deletedAt: '2020-08-04T10:20:26Z',
    additionalInfo: 'ut tellus',
    skillId: 1,
    centreId: 2,
    profileId: 31,
    applicationIds: [8, 11, 2, 16, 50, 5],
  },
  {
    id: 978,
    vacancy: 4,
    hired: 3,
    startsAt: '2020-07-29T19:52:03Z',
    endsAt: '2020-11-14T05:52:03Z',
    daysOff: undefined,
    createdAt: '2020-11-29T06:39:32Z',
    deletedAt: '2021-02-19T09:19:59Z',
    additionalInfo: 'nunc',
    skillId: 1,
    centreId: 34,
    profileId: 77,
    applicationIds: undefined,
  },
  {
    id: 979,
    vacancy: 2,
    hired: 0,
    startsAt: '2020-12-12T14:27:50Z',
    endsAt: '2021-05-28T02:27:50Z',
    daysOff: undefined,
    createdAt: '2021-04-19T15:29:08Z',
    deletedAt: '2020-09-26T13:23:27Z',
    additionalInfo: 'eleifend',
    skillId: 2,
    centreId: 39,
    profileId: 17,
    applicationIds: undefined,
  },
  {
    id: 980,
    vacancy: 2,
    hired: 1,
    startsAt: '2021-05-03T09:00:55Z',
    endsAt: '2023-02-15T17:00:55Z',
    daysOff: [7, 5],
    createdAt: '2021-07-02T16:11:04Z',
    deletedAt: '2021-02-01T22:11:42Z',
    additionalInfo: 'amet',
    skillId: 1,
    centreId: 6,
    profileId: 96,
    applicationIds: [28, 32, 46, 47, 7, 44],
  },
  {
    id: 981,
    vacancy: 2,
    hired: 0,
    startsAt: '2020-12-30T00:37:49Z',
    endsAt: '2022-01-27T11:37:49Z',
    daysOff: undefined,
    createdAt: '2020-11-17T14:48:22Z',
    deletedAt: '2020-09-10T11:05:12Z',
    additionalInfo: 'donec pharetra',
    skillId: 3,
    centreId: 44,
    profileId: 60,
    applicationIds: undefined,
  },
  {
    id: 982,
    vacancy: 1,
    hired: 1,
    startsAt: '2021-01-19T14:53:24Z',
    endsAt: '2023-02-07T20:53:24Z',
    daysOff: undefined,
    createdAt: '2021-05-17T12:55:06Z',
    deletedAt: '2020-10-23T23:14:42Z',
    additionalInfo: 'a',
    skillId: 3,
    centreId: 11,
    profileId: 73,
    applicationIds: undefined,
  },
  {
    id: 983,
    vacancy: 7,
    hired: 4,
    startsAt: '2021-07-09T05:38:05Z',
    endsAt: '2023-06-13T10:38:05Z',
    daysOff: [2, 7],
    createdAt: '2021-01-11T06:23:37Z',
    deletedAt: '2021-02-23T18:03:10Z',
    additionalInfo: 'magna',
    skillId: 3,
    centreId: 40,
    profileId: 20,
    applicationIds: [18, 16, 12, 18, 46, 35],
  },
  {
    id: 984,
    vacancy: 8,
    hired: 7,
    startsAt: '2020-12-06T20:34:59Z',
    endsAt: '2021-10-20T06:34:59Z',
    daysOff: undefined,
    createdAt: '2020-10-18T00:37:14Z',
    deletedAt: undefined,
    additionalInfo: 'nisi',
    skillId: 3,
    centreId: 38,
    profileId: 4,
    applicationIds: undefined,
  },
  {
    id: 985,
    vacancy: 3,
    hired: 0,
    startsAt: '2021-06-11T01:11:50Z',
    endsAt: '2022-11-16T08:11:50Z',
    daysOff: undefined,
    createdAt: '2021-05-12T23:56:47Z',
    deletedAt: '2021-06-13T05:14:54Z',
    additionalInfo: 'sociis',
    skillId: 2,
    centreId: 9,
    profileId: 8,
    applicationIds: undefined,
  },
  {
    id: 986,
    vacancy: 8,
    hired: 3,
    startsAt: '2020-11-01T20:27:17Z',
    endsAt: '2021-10-18T06:27:17Z',
    daysOff: undefined,
    createdAt: '2021-01-27T07:08:05Z',
    deletedAt: '2020-11-16T10:20:43Z',
    additionalInfo: 'lacinia erat',
    skillId: 3,
    centreId: 15,
    profileId: 65,
    applicationIds: undefined,
  },
  {
    id: 987,
    vacancy: 7,
    hired: 2,
    startsAt: '2021-07-13T06:20:40Z',
    endsAt: '2022-04-26T14:20:40Z',
    daysOff: undefined,
    createdAt: '2020-09-28T21:50:11Z',
    deletedAt: '2020-09-26T17:48:16Z',
    additionalInfo: 'turpis enim',
    skillId: 2,
    centreId: 9,
    profileId: 59,
    applicationIds: undefined,
  },
  {
    id: 988,
    vacancy: 10,
    hired: 8,
    startsAt: '2020-12-31T15:51:18Z',
    endsAt: '2021-12-30T03:51:18Z',
    daysOff: [1, 4],
    createdAt: '2021-05-26T02:45:04Z',
    deletedAt: '2021-03-03T22:02:34Z',
    additionalInfo: 'consequat morbi',
    skillId: 2,
    centreId: 28,
    profileId: 96,
    applicationIds: [24, 38, 25, 18, 35, 30],
  },
  {
    id: 989,
    vacancy: 6,
    hired: 3,
    startsAt: '2021-05-12T20:36:47Z',
    endsAt: '2022-04-22T00:36:47Z',
    daysOff: [6, 4],
    createdAt: '2021-03-25T23:33:49Z',
    deletedAt: '2020-09-09T11:35:01Z',
    additionalInfo: 'ultrices aliquet',
    skillId: 1,
    centreId: 6,
    profileId: 100,
    applicationIds: [42, 50, 33, 8, 2, 47],
  },
  {
    id: 990,
    vacancy: 1,
    hired: 0,
    startsAt: '2021-07-20T08:20:22Z',
    endsAt: '2022-02-03T11:20:22Z',
    daysOff: undefined,
    createdAt: '2020-09-09T11:44:47Z',
    deletedAt: undefined,
    additionalInfo: 'congue diam',
    skillId: 2,
    centreId: 48,
    profileId: 51,
    applicationIds: undefined,
  },
  {
    id: 991,
    vacancy: 10,
    hired: 9,
    startsAt: '2021-06-06T13:59:13Z',
    endsAt: '2023-04-06T21:59:13Z',
    daysOff: undefined,
    createdAt: '2020-09-28T09:52:23Z',
    deletedAt: '2020-09-02T22:14:25Z',
    additionalInfo: 'quis libero',
    skillId: 1,
    centreId: 26,
    profileId: 62,
    applicationIds: undefined,
  },
  {
    id: 992,
    vacancy: 4,
    hired: 3,
    startsAt: '2020-12-03T07:19:02Z',
    endsAt: '2022-12-04T15:19:02Z',
    daysOff: undefined,
    createdAt: '2020-09-10T07:57:54Z',
    deletedAt: '2021-05-11T17:02:09Z',
    additionalInfo: 'eget',
    skillId: 1,
    centreId: 37,
    profileId: 27,
    applicationIds: undefined,
  },
  {
    id: 993,
    vacancy: 5,
    hired: 1,
    startsAt: '2021-06-25T05:54:00Z',
    endsAt: '2021-07-29T16:54:00Z',
    daysOff: [4, 3],
    createdAt: '2021-06-05T11:02:19Z',
    deletedAt: '2020-12-19T02:26:19Z',
    additionalInfo: 'et',
    skillId: 2,
    centreId: 46,
    profileId: 67,
    applicationIds: [10, 49, 14, 41, 49, 42],
  },
  {
    id: 994,
    vacancy: 7,
    hired: 0,
    startsAt: '2021-03-22T02:53:38Z',
    endsAt: '2022-01-12T14:53:38Z',
    daysOff: undefined,
    createdAt: '2021-05-15T12:04:50Z',
    deletedAt: undefined,
    additionalInfo: 'non',
    skillId: 3,
    centreId: 34,
    profileId: 56,
    applicationIds: undefined,
  },
  {
    id: 995,
    vacancy: 7,
    hired: 5,
    startsAt: '2021-03-22T13:20:20Z',
    endsAt: '2022-10-28T21:20:20Z',
    daysOff: undefined,
    createdAt: '2021-02-03T20:29:22Z',
    deletedAt: '2020-08-06T08:32:56Z',
    additionalInfo: 'vitae quam',
    skillId: 2,
    centreId: 39,
    profileId: 86,
    applicationIds: undefined,
  },
  {
    id: 996,
    vacancy: 7,
    hired: 6,
    startsAt: '2020-11-14T02:15:21Z',
    endsAt: '2022-02-09T10:15:21Z',
    daysOff: undefined,
    createdAt: '2020-08-15T07:40:04Z',
    deletedAt: '2020-12-12T16:47:00Z',
    additionalInfo: 'pretium iaculis',
    skillId: 1,
    centreId: 33,
    profileId: 48,
    applicationIds: undefined,
  },
  {
    id: 997,
    vacancy: 7,
    hired: 5,
    startsAt: '2020-08-20T13:51:51Z',
    endsAt: '2022-01-30T16:51:51Z',
    daysOff: undefined,
    createdAt: '2020-10-05T17:49:16Z',
    deletedAt: '2020-07-31T05:46:59Z',
    additionalInfo: 'imperdiet et',
    skillId: 1,
    centreId: 40,
    profileId: 5,
    applicationIds: undefined,
  },
  {
    id: 998,
    vacancy: 4,
    hired: 2,
    startsAt: '2021-03-13T07:03:01Z',
    endsAt: '2023-02-28T19:03:01Z',
    daysOff: [10, 8],
    createdAt: '2020-10-18T11:32:35Z',
    deletedAt: undefined,
    additionalInfo: 'lectus vestibulum',
    skillId: 2,
    centreId: 11,
    profileId: 4,
    applicationIds: [37, 21, 26, 48, 45, 9],
  },
  {
    id: 999,
    vacancy: 5,
    hired: 1,
    startsAt: '2021-05-24T10:44:24Z',
    endsAt: '2021-12-29T14:44:24Z',
    daysOff: [1, 8],
    createdAt: '2021-01-26T14:50:29Z',
    deletedAt: '2020-08-14T13:47:02Z',
    additionalInfo: 'metus aenean',
    skillId: 3,
    centreId: 31,
    profileId: 59,
    applicationIds: [23, 22, 28, 6, 7, 43],
  },
  {
    id: 1000,
    vacancy: 2,
    hired: 1,
    startsAt: '2020-10-25T02:35:42Z',
    endsAt: '2021-08-17T13:35:42Z',
    daysOff: [8, 2],
    createdAt: '2021-01-02T07:47:25Z',
    deletedAt: '2021-01-01T11:40:06Z',
    additionalInfo: 'ut',
    skillId: 1,
    centreId: 24,
    profileId: 38,
    applicationIds: [17, 18, 46, 3, 38, 10],
  },
];
