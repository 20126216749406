import {Job} from '../../models';
import {job} from '../mockDB';
import {API} from '../types';

interface Response {
  job: Job;
}

export function getJobFromIdAPI(id: string): API<Response> {
  return {
    getData: async client => {
      const {data} = await client({
        url: '/jobs/' + id,
        method: 'GET',
      });
      return {data: {job: data}};
    },
    getMockData: () => {
      return {job: job};
    },
  };
}
