import {ApplicationReview, Job, Profile} from '../../models';
import {API} from '../types';
import {jobs} from '../mockDB';

type Params = {
  _start: number;
  _limit: number;
  _sort: string;
  applicationProfileId: number;
  ratings?: number[];
};

interface Response {
  list: (ApplicationReview & {profile: Profile; job: Job})[];
  total: number;
}

export function getApplicationReviewsAPI(params: Params): API<Response> {
  const urlParams = {
    ...params,
    ...(params.ratings && {ratings: params.ratings.join(',')}),
  };
  return {
    getData: async client => {
      const {data} = await client({
        url: '/application-reviews',
        method: 'GET',
        params: urlParams,
      });
      return {data: data};
    },
    getMockData: () => {
      return {
        list: [
          {
            id: 1,
            rating: 1,
            profileId: 1,
            centreId: 1,
            applicationProfileId: 1,
            createdAt: '2021-09-13T10:33:13.901Z',
            profile: {
              id: 1,
              role: 'HR',
              name: 'Name',
              createdAt: '2021-09-13T10:33:13.901Z',
              updatedAt: '2021-09-13T10:33:13.901Z',
            },
            job: jobs[0],
          },
        ],

        total: 0,
      };
    },
  };
}
