import {setScreen} from '../../services/router';
import {strings} from '../../services/language';
import TimesheetList from './TimesheetList/TimesheetList';

setScreen('/timesheets', params => {
  return (
    <>
      <div className="main-header">
        <h1>{strings('Timesheets')}</h1>
      </div>
      <TimesheetList payrollId={params.payrollId} />
    </>
  );
});
