import styles from './JobDetailsContent.module.scss';
import {ReactComponent as SearchIcon} from '../../../assets/img/search.svg';
import {ReactComponent as MoreIcon} from '../../../assets/img/more.svg';
import {ReactComponent as RemoveIcon} from '../../../assets/img/remove.svg';
import {ReactComponent as DuplicateIcon} from '../../../assets/img/duplicate.svg';
import {
  Breadcrumb,
  Input,
  Select,
  PcfDatePicker,
  PcfTimePicker,
  Button,
  Badge,
  Modal,
  NavTabs,
} from '../../../components';
import ReactMarkdown from 'react-markdown';
import {strings} from '../../../services/language';
import {useJobDetailsContentLogic} from './logics';
import StaffDetails from '../StaffDetails/StaffDetails';
import Timesheets from '../Timesheets/Timesheets';
import {formatISOString} from '../../../services/utils';
import {Job, JobStatus} from '../../../services/models';
import {APIState} from '../../../services/api';
import {useLoading} from '../../../services/hooks';
import {jobStatus} from '../../jobs/JobPostings/logics';

type Props = {
  initJob: Job;
  jobApi: APIState<{job: Job}, []>;
};

export default function JobDetailsContent({initJob, jobApi}: Props) {
  const {
    job,
    setJob,
    linkList,
    centreList,
    bindCentre,
    role,
    bindRole,
    bindPaxNo,
    bindAdditionalInfo,
    schedule,
    workingHours,
    onSubmitJob,
    skillOptions,
    selectedSkill,
    jobModalRef,
    resultModalRef,
    removeJobRef,
    removeJob,
    duplicateJob,
    editJobApi,
    showMoreRef,
    skills,
    jobSkill,
    showMore,
    isChanged,
  } = useJobDetailsContentLogic(initJob);

  useLoading(jobApi.loading);

  const generalContent = (
    <div className={styles.container}>
      <div className={styles.inputItem}>
        <Select
          topLabel={strings('Centre')}
          border
          defaultLabel={strings('Search Centre')}
          rightIcon={
            <SearchIcon
              style={{
                width: 20,
                height: 20,
                fill: 'var(--blue-accent)',
              }}
            />
          }
          divider
          searchable
          disabled={!(job.status === 'open')}
          optionList={centreList}
          {...bindCentre}
        />
      </div>
      <div className={styles.inputItem}>
        <Select
          topLabel={strings('Role')}
          border
          defaultLabel={strings('Select Role')}
          disabled={!(job.status === 'open')}
          optionList={skillOptions}
          {...bindRole}
        />
        {role.value && (
          <>
            <div
              className={styles.viewJob}
              onClick={() => jobModalRef.current.showModal()}
            >
              {strings('View Job Description')}
            </div>
            <Modal
              ref={jobModalRef}
              width={536}
              bodyMaxHeight={315}
              header={
                selectedSkill ? (
                  <>
                    <div className={styles.jobModalTitle}>
                      <img
                        src={selectedSkill.icon}
                        alt={selectedSkill.name}
                        height={36}
                      />
                      <h2>{selectedSkill.name}</h2>
                    </div>
                    <div className={styles.jobModalLabel}>
                      {strings('Job Description')}
                    </div>
                  </>
                ) : (
                  ''
                )
              }
              body={
                selectedSkill ? (
                  <div className={styles.jobModalContent}>
                    <ReactMarkdown
                      children={selectedSkill.description}
                    ></ReactMarkdown>
                  </div>
                ) : (
                  ''
                )
              }
              footer={
                <Button
                  label={strings('Done')}
                  type="danger"
                  handleClick={() => jobModalRef.current.hideModal()}
                />
              }
            />
          </>
        )}
      </div>
      <div className={styles.inputItem}>
        <Input
          id="paxNo"
          type="number"
          label={strings('Number of Pax')}
          placeholder={strings('Enter Pax No.')}
          disabled={!(job.status === 'open')}
          {...bindPaxNo}
        />
      </div>
      <div className={styles.inputItem}>
        <PcfDatePicker
          label={strings('Schedule')}
          placeholder={strings('Select Dates')}
          type="multipleInWeek"
          clearButton={false}
          selectedNumber
          value={schedule.schedule}
          error={schedule.scheduleError}
          disabled={!(job.status === 'open')}
          onSelectDate={selectedDate => schedule.setSchedule([...selectedDate])}
        />
      </div>
      <div className={styles.inputItem}>
        <PcfTimePicker
          step={1}
          label={strings('Working Hours')}
          placeholder={strings('Start Time')}
          placeholderTo={strings('End Time')}
          error={workingHours.workingHoursErrorFrom}
          errorTo={workingHours.workingHoursErrorTo}
          fromValueInit={workingHours.workingHours.from}
          toValueInit={workingHours.workingHours.to}
          disabled={!(job.status === 'open')}
          onSelectValue={value => workingHours.setWorkingHours(value)}
        />
      </div>
      <div className={styles.inputItem}>
        <Input
          id="additionalInfo"
          type="textarea"
          label={strings('Additional Information')}
          optionalLabel={strings('Optional')}
          placeholder={strings('Any important details?')}
          disabled={!(job.status === 'open')}
          {...bindAdditionalInfo}
        />
      </div>
      {job.status === 'open' && (
        <div className={styles.buttonWrapper}>
          <Button
            label={strings('Discard Changes')}
            type="transparent"
            handleClick={() => history.go(0)}
          />
          <Button
            label={strings('Save Changes')}
            type="danger"
            disabled={!isChanged}
            handleClick={onSubmitJob}
          />
        </div>
      )}

      <Modal
        ref={resultModalRef}
        header={undefined}
        body={
          <div>
            <div>
              {editJobApi.error
                ? editJobApi.error
                : editJobApi.data?.job
                ? strings('Update Job Successfully')
                : ''}
            </div>
          </div>
        }
        footer={
          <Button
            label={strings('Done')}
            type="danger"
            handleClick={() => {
              resultModalRef.current.hideModal();
              history.go(0);
            }}
          />
        }
        showClose={false}
      />

      <Modal
        ref={removeJobRef}
        header={<h2>{strings('Remove Job Posting')}</h2>}
        body={<div>{strings('Are you sure you want to remove this job?')}</div>}
        footer={
          <>
            <Button
              label={strings('Cancel')}
              type="transparent"
              handleClick={() => {
                removeJobRef.current.hideModal();
              }}
            />
            <Button
              label={strings('Ok')}
              type="danger"
              handleClick={() => {
                removeJob();
              }}
            />
          </>
        }
      />
    </div>
  );

  return (
    <>
      <div className="main-header">
        <Breadcrumb linkList={linkList} />
        {job && skills && (
          <div className={styles.header}>
            <img src={jobSkill.icon} alt={jobSkill.name} height={72} />
            <div className={styles.headerWrapper}>
              <div className={styles.headerRow}>
                <h2 className={styles.jobName}>
                  {job.vacancy + ' ' + jobSkill.name}
                </h2>
                {job.status !== 'removed' && (
                  <div
                    ref={showMoreRef}
                    className={styles.moreToggle}
                    onClick={() => showMore.setShowMore(!showMore.showMore)}
                  >
                    <MoreIcon />
                    {showMore.showMore && (
                      <div
                        className={styles.moreButton}
                        onClick={() => {
                          if (
                            job.status === 'open' ||
                            job.status === 'upcoming'
                          ) {
                            removeJobRef.current.showModal();
                          }
                          if (job.status === 'completed') {
                            duplicateJob();
                          }
                        }}
                      >
                        {job.status === 'completed' ? (
                          <>
                            <DuplicateIcon /> {strings('Duplicate Job Posting')}
                          </>
                        ) : (
                          <>
                            <RemoveIcon /> {strings('Remove Job Posting')}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className={styles.headerRow}>
                <Badge
                  type={'jobStatus'}
                  status={(jobStatus(job).status || '') as JobStatus}
                />
                <div className={styles.jobInfoText}>{`${strings('Job ID')}: ${
                  job.id
                }`}</div>
                <div className={styles.jobInfoText}>{`${strings(
                  'Published on'
                )} ${formatISOString(job.createdAt, 'DD/MM/YY')}`}</div>
              </div>
            </div>
          </div>
        )}
      </div>
      {job.status !== 'expired' && (
        <NavTabs
          activeIndex={1}
          navTabList={[
            {
              id: 'other',
              label:
                job.status === 'open'
                  ? strings('Staff Details')
                  : job.status === 'upcoming'
                  ? strings('Hired Staff Details')
                  : strings('Timesheets'),
              content:
                job.status === 'ongoing' || job.status === 'completed' ? (
                  <Timesheets applications={job.applications} />
                ) : (
                  <StaffDetails
                    skills={skills}
                    initJob={job}
                    onUpdateJob={newJob => setJob(newJob)}
                  />
                ),
            },
            {
              id: 'general',
              label: strings('General'),
              content: generalContent,
            },
          ]}
        />
      )}
      {job.status === 'expired' && generalContent}
    </>
  );
}
