import {useRef, useState} from 'react';
import {strings} from '../../services/language';
import {
  getProfileByIdAPI,
  deleteProfileAPI,
  useAPI,
  uploadPhotoAPI,
} from '../../services/api';
import {useSelector} from '../../services/store';

export function useStaffDetailsLogic(staffId: number) {
  const [showMore, setShowMore] = useState(false);
  const [file, setFile] = useState<File | undefined>(undefined);

  const breadcrumbList = [
    {
      title: strings('PCF Staff'),
      route: '/pcf-staffs',
    },
    {
      title: strings('Staff Profile'),
      active: true,
    },
  ];

  const deleteModalRef = useRef<any>(null);
  const resultModalRef = useRef<any>(null);
  const uploadModalRef = useRef<any>(null);

  const getStaffApi = useAPI(() => getProfileByIdAPI(staffId));
  const deleteStaffApi = useAPI(() => deleteProfileAPI(staffId));
  const uploadPhotoApi = useAPI(uploadPhotoAPI);

  const profile = useSelector(s => s.profile);

  return {
    breadcrumbList,
    showMore: {showMore, setShowMore},
    getStaffApi,
    staffProfile: getStaffApi.data,
    deleteModalRef,
    resultModalRef,
    uploadModalRef,
    deleteStaffApi,
    uploadPhotoApi,
    profile,
    file,
    setFile,
  };
}
