import styles from './LoginWrapper.module.scss';

type LoginWrapperProps = {
  children: JSX.Element;
};

export function LoginWrapper({children}: LoginWrapperProps) {
  return (
    <div className={styles.container}>
      <div className={styles.logoWrapper}></div>
      <div className={styles.formWrapper}>{children}</div>
    </div>
  );
}
