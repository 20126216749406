import {setScreen} from '../../services/router';
import {strings} from '../../services/language';
import NotificationList from './NotificationList';

setScreen('/notifications', () => {
  return (
    <>
      <div className="main-header">
        <h1>{strings('Announcements')}</h1>
      </div>
      <NotificationList />
    </>
  );
});
