import {useEffect, useState} from 'react';

export * from './centre';
export * from './skill';
export * from './dateTimePicker';

/**
 * Hook that handle clicks outside of the passed ref
 */
export function useClickOutside(
  ref: any,
  handleClickOut: () => void,
  handleClickIn?: () => void
) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        handleClickOut && handleClickOut();
      } else {
        handleClickIn && handleClickIn();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

/**
 * Hook that show hide Loader
 */
export function useLoading(show: boolean) {
  useEffect(() => {
    if (show) {
      document.body.classList.add('loading-show');
    } else {
      document.body.classList.remove('loading-show');
    }
  }, [show]);
}

/**
 * Hook that simplify input handling
 */
export function useInput(
  initialValue: string = '',
  handleChangePlus?: (name: string, value: string) => void,
  willSetError: boolean = true
) {
  const initValue = initialValue || '';
  const [value, setValue] = useState(initialValue || '');
  const [error, setError] = useState<string | undefined>('');
  const [changed, setChanged] = useState(false);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    willSetError && setError('');
    setValue(event.target.value);
    setChanged(initValue !== event.target.value);
    handleChangePlus && handleChangePlus(event.target.name, event.target.value);
  };

  return {
    states: {
      value,
      setValue,
      error,
      setError,
      handleChange,
      changed,
    },
    bind: {
      value,
      error,
      handleChange,
    },
  };
}

/**
 * Hook that simplify select handling
 */
export function useSelect(initialValue: any = '') {
  const initValue = initialValue || '';
  const [value, setValue] = useState(initialValue || '');
  const [error, setError] = useState('');
  const [changed, setChanged] = useState(false);

  const onSelectValue = (selectedValues: any[]) => {
    setError('');
    setValue(selectedValues[0] || '');
    setChanged(initValue !== selectedValues[0]);
  };

  return {
    states: {
      value,
      setValue,
      error,
      setError,
      onSelectValue,
      changed,
      setChanged,
    },
    bind: {
      value,
      error,
      onSelectValue,
    },
  };
}

/**
 * Hook that simplify multiple select handling
 */
export function useMultipleSelect(initialValue: any[] = []) {
  const initValue = initialValue;
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState('');
  const [changed, setChanged] = useState(false);

  const onSelectValue = (selectedValues: any[]) => {
    setError('');
    setValue(selectedValues);
    setChanged(!compareArray(selectedValues, initValue));
  };

  const compareArray = (arr1: any[], arr2: any[]) => {
    if (arr1.length !== arr2.length) return false;
    return arr1.reduce(
      (isEqual: boolean, item) => isEqual && arr2.includes(item),
      true
    );
  };

  return {
    states: {
      value,
      setValue,
      error,
      setError,
      onSelectValue,
      changed,
      setChanged,
    },
    bind: {
      value,
      error,
      onSelectValue,
    },
  };
}

/**
 * Hook that handle press Enter
 */
export function usePressEnter(handleFunction: () => void, deps: any[] = []) {
  useEffect(() => {
    function handlePressEnter(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        handleFunction();
      }
    }
    document.addEventListener('keydown', handlePressEnter);
    return () => {
      document.removeEventListener('keydown', handlePressEnter);
    };
  }, deps);
}
