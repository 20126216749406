import {strings} from '../../../services/language';
import {Centre, Job, Skill} from '../../../services/models';
import {Badge} from '../../../components';
import styles from './JobHistory.module.scss';
import {useEffect, useMemo, useState} from 'react';
import {Column} from 'react-table';
import {ReactComponent as ChevronIcon} from '../../../assets/img/chevron.svg';
import {useHistory} from 'react-router-dom';
import {TableInstance, Cell} from 'react-table';
import {createSkillOptions} from '../../../services/helpers';
import {formatISOString} from '../../../services/utils';
import {getJobsStaffAPI, useAPI, useResult} from '../../../services/api';
import {useLoading, useSyncCentres} from '../../../services/hooks';
import moment from 'moment-timezone';

const _limit = 8;

export function useJobHistoryLogic(profileId: number, skills: Skill[]) {
  const history = useHistory();
  const hiddenColumns = ['jobId', 'role', 'fromDate', 'toDate'];

  const [jobs, setJobs] = useState<Job[]>([]);
  const [total, setTotal] = useState(0);
  const [manualFilterSort, setManualFilterSort] = useState<any>(undefined);
  const [role, setRole] = useState<number[]>([]);
  const [district, setDistrict] = useState<string[]>([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [searchField, setSearchField] = useState('all');
  const {centres} = useSyncCentres();

  useEffect(() => {
    if ((startDate && endDate) || (!startDate && !endDate)) {
      setManualFilterSort([]);
    }
  }, [role, district, startDate, endDate, searchField]);

  const getJobsApi = useAPI(getJobsStaffAPI);

  function fetchData(pageIndex: number) {
    getJobsApi.request({
      _start: pageIndex * _limit,
      _limit: _limit,
      _sort: 'id:DESC',
      applicationProfileId: profileId,
      searchField,
      ...(searchValue && {query: searchValue}),
      ...(role.length > 0 && {skillIds: role}),
      ...(district.length > 0 && {districtGroups: district}),
      ...(startDate && {
        startsAt_lt: moment(startDate)
          .startOf('day')
          .tz('Asia/Singapore')
          .toISOString(),
      }),
      ...(endDate && {
        endsAt_gt: moment(endDate)
          .endOf('day')
          .tz('Asia/Singapore')
          .toISOString(),
      }),
    });
  }

  useResult(getJobsApi.data, data => {
    setJobs(data.list);
    if (data.total !== total) {
      setTotal(data.total);
    }
  });

  useLoading(getJobsApi.loading);

  const searchFilterList = useMemo(
    () => [
      {
        content: 'Job',
        value: 'job',
      },
      {
        content: 'Job ID',
        value: 'jobId',
      },
      {
        content: 'Centre',
        value: 'centre',
      },
    ],
    []
  );
  const skillOptions = useMemo(() => createSkillOptions(skills), [skills]);

  const columns: Column[] = useMemo(() => createColumns(), []);
  const data: JobData[] = useMemo(
    () => createRowsData(jobs, skills, centres, history),
    [jobs, skills, centres]
  );

  return {
    _limit,
    history,
    hiddenColumns,
    jobs,
    setJobs,
    total,
    setTotal,
    manualFilterSort,
    setManualFilterSort,
    role,
    setRole,
    district,
    setDistrict,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    searchValue,
    setSearchValue,
    searchField,
    setSearchField,
    centres,
    searchFilterList,
    skillOptions,
    columns,
    data,
    fetchData,
  };
}

interface JobData {
  job: JSX.Element;
  status: string;
  schedule: string;
  centre: string;
  district: string;

  button: JSX.Element;
}

function createColumns() {
  return [
    {
      Header: strings('Job'),
      accessor: 'job',
    },
    {
      Header: strings('Status'),
      accessor: 'status',
      Cell: (props: TableInstance & Cell) => (
        <Badge type={'jobStatus'} status={props.value} />
      ),
      filter: 'selectOptions',
    },
    {
      Header: strings('Schedule'),
      accessor: 'schedule',
    },
    {
      Header: strings('Centre'),
      accessor: 'centre',
    },
    {
      Header: strings('District'),
      accessor: 'district',
    },
    {
      Header: '',
      accessor: 'button',
    },
  ];
}

function createRowsData(
  jobs: Job[],
  skills: Skill[],
  centres: Centre[],
  history: any
): JobData[] {
  return jobs.map(job => {
    const jobSkill = skills.filter(skill => skill.id === job.skillId)[0];
    const centre = centres.filter(centre => centre.id === job.centreId)[0];

    return {
      job: (
        <div className={styles.jobCell}>
          <img
            className={styles.skillIcon}
            src={jobSkill ? jobSkill.icon : ''}
            alt={jobSkill ? jobSkill.name : ''}
            height={40}
          />
          <div>
            <div>{`${job.vacancy} ${jobSkill ? jobSkill.name : ''}`}</div>
            {job.id && <div className={styles.jobCellInfo}>{job.id}</div>}
          </div>
        </div>
      ),
      status: job.status || '',
      schedule: `${formatISOString(job.startsAt, 'DD/MM/YY')}-${formatISOString(
        job.endsAt,
        'DD/MM/YY'
      )}`,
      centre: centre ? centre.name : '',
      district: centre ? centre.districtGroup : '',
      button: (
        <div
          className={styles.buttonCell}
          onClick={e => {
            history.push('/jobs/' + job.id);
            if (e) {
              e.stopPropagation();
            }
          }}
        >
          <ChevronIcon
            style={{stroke: 'var(--blue-accent)', transform: 'rotate(-90deg)'}}
          />
        </div>
      ),
    };
  });
}
