import {useLoginLogic} from '../logics';
import {
  LoginWrapper,
  Input,
  CheckBox,
  Button,
  InputOTP,
} from '../../../components';
import {strings} from '../../../services/language';
import styles from './LoginForm.module.scss';

export default function LoginForm() {
  const {
    email,
    bindEmail,
    emailChecked,
    emailApi,
    onSubmitPassword,
    onSubmitEmail,
    isRemember,
    setIsRemember,
    isSent,
    countDown,
    otpString,
    setOtpString,
    otpError,
    setOtpError,
    onResendOTP,
  } = useLoginLogic();

  const enterEmailForm = (
    <>
      <Input
        id="email"
        label={strings('Email or Phone Number')}
        placeholder={strings('Enter Email or Phone Number')}
        {...bindEmail}
      />
      <div className={styles.loginControl}>
        <CheckBox
          label={strings('Remember me')}
          id="remember"
          name="remember"
          checked={isRemember}
          handleChange={e => {
            setIsRemember(e.target.checked);
          }}
        />
        <Button
          label={strings('Log In')}
          type="danger"
          disabled={emailApi.loading}
          handleClick={onSubmitEmail}
        />
      </div>
    </>
  );

  const enterPasswordForm = (
    <>
      <InputOTP
        label={strings(
          'Enter the One Time Password (OTP) that was sent to your mobile number',
          email.value
        )}
        error={otpError}
        handleOTPChange={otpString => {
          setOtpString(otpString);
          setOtpError('');
        }}
      />

      <div className={styles.loginControl}>
        {isSent ? (
          <div className={styles.resendCount}>
            {strings('Resend OTP in %0 secs', countDown)}
          </div>
        ) : (
          <div className={styles.resend} onClick={onResendOTP}>
            {strings('Resend OTP')}
          </div>
        )}
        <Button
          label={strings('Submit')}
          type="danger"
          disabled={otpString.length < 6}
          handleClick={onSubmitPassword}
        />
      </div>
    </>
  );

  return (
    <LoginWrapper>
      <div className={styles.formContainer}>
        {!emailChecked ? enterEmailForm : enterPasswordForm}
      </div>
    </LoginWrapper>
  );
}
