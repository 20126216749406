import {API} from '../types';

interface Params {
  identifier: string;
}

interface Response {
  success: boolean;
}

export function authSendOtpAPI(params: Params): API<Response> {
  return {
    getData: async client => {
      const {data} = await client({
        url: '/auth/send-otp',
        method: 'POST',
        data: params,
      });
      return {data: data};
    },
    getMockData: () => {
      return {success: true};
    },
  };
}
