import {strings} from '../../language';
import {IdentifierStatus} from '../../models';
import {profiles, users} from '../mockDB';
import {API} from '../types';

interface Params {
  identifier: string;
  isMobile: boolean;
}

interface Response {
  status: IdentifierStatus;
}

export function authEmailAPI(params: Params): API<Response> {
  return {
    getData: async client => {
      const {data} = await client({
        url: '/auth/check-identifier',
        method: 'POST',
        data: params,
      });
      return {data: data};
    },
    getMockData: () => {
      const user = users.find(u => u.identifier == params.identifier)!;
      const profile = profiles.find(u => u.email == params.identifier)!;
      if (user && profile) {
        return {status: 'emailLogin'};
      }
      throw strings(
        'Your email address or phone number is not connected to any account. Please try again.'
      );
    },
  };
}
