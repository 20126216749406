import styles from './Reviews.module.scss';
import {useEffect, useMemo, useState} from 'react';
import {Cell, Column, TableInstance} from 'react-table';
import {ReactComponent as StarIcon} from '../../../assets/img/star.svg';
import {strings} from '../../../services/language';
import {useHistory} from 'react-router-dom';
import {useAPI, useResult} from '../../../services/api';
import {getApplicationReviewsAPI} from '../../../services/api/apis/getApplicationReviewsAPI';
import {useLoading} from '../../../services/hooks';
import {ApplicationReview, Job, Profile, Skill} from '../../../services/models';
import {staffRoles} from '../../../services/helpers';
import {formatISOString} from '../../../services/utils';

const _limit = 8;

export function useReviewsLogic(profileId: number, skills: Skill[]) {
  const [starFilter, setStarFilter] = useState<number[]>([]);
  const [sortRule, setSortRule] = useState('id:DESC');
  const [manualFilterSort, setManualFilterSort] = useState<any>(undefined);
  const [total, setTotal] = useState(0);
  const [reviews, setReviews] = useState<
    (ApplicationReview & {profile: Profile; job: Job})[]
  >([]);

  useEffect(() => {
    setManualFilterSort([]);
  }, [starFilter, sortRule]);

  const getReviewsApi = useAPI(getApplicationReviewsAPI);

  function fetchData(pageIndex: number) {
    getReviewsApi.request({
      _start: pageIndex * _limit,
      _limit: _limit,
      _sort: sortRule,
      applicationProfileId: profileId,
      ...(starFilter.length > 0 && {ratings: starFilter}),
    });
  }

  useResult(getReviewsApi.data, data => {
    setReviews(data.list);
    if (data.total !== total) {
      setTotal(data.total);
    }
  });

  useLoading(getReviewsApi.loading);

  const starOptions = useMemo(
    () =>
      Array(6)
        .fill('')
        .map((_, index) => ({
          content: strings('%0 stars', index),
          value: index,
        })),
    []
  );
  const sortList = useMemo(
    () => [
      {
        content: strings('Latest First'),
        value: 'id:DESC',
      },
      {
        content: strings('Ratings - Highest first'),
        value: 'rating:DESC',
      },
      {
        content: strings('Ratings - Lowest first'),
        value: 'rating:ASC',
      },
    ],
    []
  );

  const history = useHistory();
  const columns: Column[] = useMemo(() => createColumns(), []);
  const data: StaffData[] = useMemo(
    () => createRowsData(history, reviews, skills),
    [reviews]
  );

  const hiddenColumns = ['date'];

  return {
    starOptions,
    sortList,
    starFilter,
    setStarFilter,
    sortRule,
    setSortRule,
    columns,
    data,
    hiddenColumns,
    manualFilterSort,
    fetchData,
    total,
    _limit,
  };
}

interface StaffData {
  reviewer: JSX.Element;
  rating: number;
  job: JSX.Element;
  message: string;
  publishDate: JSX.Element;

  date: Date;
}

function createColumns() {
  return [
    {
      Header: strings('Reviewer'),
      accessor: 'reviewer',
    },
    {
      Header: strings('Rating'),
      accessor: 'rating',
      Cell: (props: TableInstance & Cell) => (
        <div>
          <span>{props.value}</span>{' '}
          <StarIcon style={{width: 14, height: 14}} />
        </div>
      ),
    },
    {
      Header: strings('Job'),
      accessor: 'job',
    },
    {
      Header: strings('Message'),
      accessor: 'message',
    },
    {
      Header: strings('Publish Date'),
      accessor: 'publishDate',
    },
    {
      Header: '',
      accessor: 'date',
      sortType: 'datetime',
    },
  ];
}

function createRowsData(
  history: any,
  reviews: (ApplicationReview & {profile: Profile; job: Job})[],
  skills: Skill[]
): StaffData[] {
  return reviews.map((review, index) => {
    const jobSkill = skills.filter(skill => skill.id === review.job.skillId)[0];

    return {
      reviewer: (
        <div className={styles.userCell}>
          <div
            className={styles.avatar}
            style={{
              backgroundImage: review.profile.photo
                ? `url(${review.profile.photo})`
                : 'none',
            }}
          ></div>
          <div className={styles.userRight}>
            <div>User name</div>
            <div className={styles.greyText}>
              <span className={styles.infoText}>
                {staffRoles[review.profile.role]}
              </span>
              <span className={styles.infoText}>{`${strings('User ID')}: ${
                review.profile.id
              }`}</span>
            </div>
          </div>
        </div>
      ),
      rating: review.rating,
      job: (
        <div className={styles.userCell}>
          <img
            className={styles.skillIcon}
            src={jobSkill?.icon || ''}
            alt={jobSkill?.name || ''}
            height={40}
          />
          <div className={styles.userRight}>
            <div>{`${review.job.vacancy} ${jobSkill?.name || ''}`}</div>
            <div className={styles.greyText}>{review.job.id}</div>
          </div>
        </div>
      ),
      message: review.comments || '',
      publishDate: (
        <div className={styles.dateCell}>
          {formatISOString(review.createdAt, 'DD MMM YYYY')}
        </div>
      ),
      date: new Date(),
    };
  });
}
