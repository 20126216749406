import {Job} from '../../models';
import {jobs} from '../mockDB';
import {API} from '../types';

type CreateJobParams = {
  vacancy: string;
  startsAt: string;
  endsAt: string;
  skillId: number;
  centreId: number;
  daysOff?: number[];
  additionalInfo?: string;
};

interface Response {
  job: Job;
}

export function createJobAPI(params: CreateJobParams): API<Response> {
  return {
    getData: async client => {
      const {data} = await client({
        url: '/jobs',
        method: 'POST',
        data: params,
      });
      return {data: {job: data}};
    },
    getMockData: () => {
      return {job: jobs[0]};
    },
  };
}
