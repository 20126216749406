import {Notification} from '../../models';
import {API} from '../types';

type Params = {
  title: string;
  body?: string;
  scheduledAt: string;
  skillIds?: number[];
  districtNumbers?: number[];
  additionalInfo?: string;
  image?: File;
};

interface Response {
  notification: Notification;
}

export function createNotificationAPI(params: Params): API<Response> {
  const formData = new FormData();
  Object.keys(params).forEach(key => {
    const value = params[key as keyof Params]!;
    formData.append(key, Array.isArray(value) ? value.join(',') : value);
  });

  return {
    getData: async client => {
      const {data} = await client({
        url: '/notifications',
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return {data: {notification: data}};
    },
    getMockData: () => {
      return {
        notification: {
          id: 1,
          title: 'string',
          createdAt: 'string',
          scheduledAt: '',
          status: 'published',
        },
      };
    },
  };
}
