import {selectEnv} from '../env';
import storage from 'redux-persist/lib/storage';

const USE_MOCK_STORAGE = selectEnv({
  dev: false,
  default: false,
});

function setItem(persistKey: string, value: any) {
  const key = persistKey.replace('persist:', '');
  if (USE_MOCK_STORAGE) {
    return Promise.reject();
  }
  return storage.setItem(key, value);
}

function getItem(persistKey: string) {
  const key = persistKey.replace('persist:', '');
  if (USE_MOCK_STORAGE) {
    return Promise.reject();
  }
  return storage.getItem(key);
}

function removeItem(persistKey: string) {
  const key = persistKey.replace('persist:', '');
  if (USE_MOCK_STORAGE) {
    return Promise.reject();
  }
  return storage.removeItem(key);
}

export default {setItem, getItem, removeItem};
