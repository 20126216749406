import {useEffect} from 'react';
import {getNotificationFromIdAPI, useAPI} from '../../services/api';
import {useLoading} from '../../services/hooks';
import {setScreen} from '../../services/router';
import NotificationDetails from './NotificationDetails';

setScreen('/notifications/:id', params => {
  const getNotiApi = useAPI(getNotificationFromIdAPI);
  useEffect(() => {
    getNotiApi.request({id: params.id});
  }, []);
  useLoading(getNotiApi.loading);

  return getNotiApi.data?.notification ? (
    <NotificationDetails notification={getNotiApi.data?.notification} />
  ) : (
    <div></div>
  );
});
