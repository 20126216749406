import {useEffect, useMemo, useRef, useState} from 'react';
import {strings} from '../../services/language';
import {Job, Skill} from '../../services/models';
import {
  useInput,
  useLoading,
  useSelect,
  useSyncCentres,
  useSyncSkills,
} from '../../services/hooks';
import {createSkillOptions} from '../../services/helpers';
import moment from 'moment';
import {createJobAPI, useAPI} from '../../services/api';
import {useHistory} from 'react-router-dom';
import {toRoute} from '../../services/router';

export function useCreateJobLogic(job?: Job) {
  const {states: centre, bind: bindCentre} = useSelect(job?.centreId);
  const {states: role, bind: bindRole} = useSelect(job?.skillId);
  const {states: paxNo, bind: bindPaxNo} = useInput(
    job ? job.vacancy.toString() : ''
  );
  const {states: additionalInfo, bind: bindAdditionalInfo} = useInput(
    job?.additionalInfo
  );

  const [schedule, setSchedule] = useState<Date[]>([]);
  const [scheduleError, setScheduleError] = useState('');
  const [workingHours, setWorkingHours] = useState({
    from: job
      ? moment(job.startsAt).set({date: 6, month: 9, year: 1996}).toISOString()
      : '',
    to: job
      ? moment(job.endsAt).set({date: 6, month: 9, year: 1996}).toISOString()
      : '',
  });
  const [workingHoursErrorFrom, setWorkingHoursErrorFrom] = useState('');
  const [workingHoursErrorTo, setWorkingHoursErrorTo] = useState('');
  const [selectedSkill, setSelectedSkill] = useState<Skill | undefined>(
    undefined
  );

  const history = useHistory();
  const jobModalRef = useRef<any>(null);
  const resultModalRef = useRef<any>(null);

  const {skills} = useSyncSkills();
  const {centres} = useSyncCentres();
  const createJobApi = useAPI(createJobAPI);

  const skillOptions = useMemo(() => createSkillOptions(skills), [skills]);

  useEffect(() => {
    if (skills.length > 0) {
      setSelectedSkill(skills.filter(skill => skill.id == role.value)[0]);
    }
  }, [role.value]);

  useEffect(() => {
    if (
      (createJobApi.data?.job || createJobApi.error) &&
      !createJobApi.loading
    ) {
      resultModalRef.current.showModal();
    }
  }, [createJobApi.loading]);

  const centreList = useMemo(
    () =>
      centres.map(centre => ({
        content: centre.name,
        value: centre.id,
        text: centre.name,
      })),
    centres
  );

  const linkList = [
    {
      title: strings('Job Postings'),
      route: '/jobs',
    },
    {
      title: strings('Create Job Posting'),
      active: true,
    },
  ];

  const validateForm = () => {
    !centre.value
      ? centre.setError(strings('Please select a centre'))
      : centre.setError('');
    !role.value
      ? role.setError(strings('Please select a role'))
      : role.setError('');
    paxNo.value === ''
      ? paxNo.setError(strings('Please enter a valid number'))
      : paxNo.setError('');
    schedule.length === 0
      ? setScheduleError(strings('Please select a date range'))
      : setScheduleError('');
    workingHours.from === ''
      ? setWorkingHoursErrorFrom(strings('Please enter a start time'))
      : setWorkingHoursErrorFrom('');
    workingHours.to === ''
      ? setWorkingHoursErrorTo(strings('Please enter a end time'))
      : setWorkingHoursErrorTo('');

    return (
      !!centre.value &&
      !!role.value &&
      !!paxNo.value &&
      schedule.length > 0 &&
      !!workingHours.from &&
      !!workingHours.to
    );
  };

  function onSubmitJob() {
    const isValid = validateForm();

    if (isValid) {
      createJobApi.request({
        vacancy: paxNo.value,
        startsAt: concatDateAndTime(schedule[0], workingHours.from),
        endsAt: concatDateAndTime(
          schedule[schedule.length - 1],
          workingHours.to
        ),
        skillId: parseInt(role.value),
        centreId: parseInt(centre.value),
        ...(getDaysOff(schedule).length > 0 && {daysOff: getDaysOff(schedule)}),
        ...(additionalInfo.value && {additionalInfo: additionalInfo.value}),
      });
    }
  }

  function resetForm() {
    centre.setValue('');
    role.setValue('');
    paxNo.setValue('');
    setSchedule([]);
    setWorkingHours({from: '', to: ''});
    additionalInfo.setValue('');
    setSelectedSkill(undefined);
  }

  const goToJobs = () => {
    history.push(toRoute('/jobs'));
  };

  useLoading(createJobApi.loading);

  return {
    linkList,
    centreList,
    bindCentre,
    role,
    bindRole,
    bindPaxNo,
    bindAdditionalInfo,
    schedule: {schedule, setSchedule, scheduleError},
    workingHours: {
      workingHours,
      setWorkingHours,
      workingHoursErrorFrom,
      workingHoursErrorTo,
    },
    onSubmitJob,
    resetForm,
    goToJobs,
    skillOptions,
    selectedSkill,
    jobModalRef,
    resultModalRef,
    createJobApi,
    isWrongHour:
      moment(concatDateAndTime(schedule[0], workingHours.from)) <
      moment().add(3, 'hours'),
  };
}

function concatDateAndTime(date: Date, time: string) {
  const dateMoment = moment(date);
  const timeMoment = moment(time);

  return moment({
    hour: timeMoment.hour(),
    minute: timeMoment.minute(),
    date: dateMoment.date(),
    month: dateMoment.month(),
    year: dateMoment.year(),
  }).toISOString();
}

function getDaysOff(days: Date[]) {
  const daysMoment = days.map(d => moment(d));
  const startDate = daysMoment[0];
  const endDate = daysMoment[daysMoment.length - 1];
  const dateRange: moment.Moment[] = [];

  const date = startDate.clone();
  while (date <= endDate) {
    dateRange.push(date.clone());
    date.add(1, 'days');
  }

  return dateRange.reduce(
    (arr, d, i) => (includesDay(daysMoment, d) ? arr : [...arr, i]),
    [] as number[]
  );
}

function includesDay(arr: moment.Moment[], d: moment.Moment) {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].isSame(d, 'day')) {
      return true;
    }
  }
  return false;
}
