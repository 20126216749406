import styles from './NotificationDetails.module.scss';
import {
  Breadcrumb,
  Input,
  Select,
  PcfDatePicker,
  PcfTimePicker,
  Button,
  Modal,
  CheckBox,
  Radio,
  FileUpload,
  Badge,
} from '../../components';
import {ReactComponent as ErrorIcon} from '../../assets/img/error.svg';
import {ReactComponent as TrashIcon} from '../../assets/img/trash.svg';
import {strings} from '../../services/language';
import {useNotificationLogic} from './logics';
import {Notification} from '../../services/models';

export default function NotificationDetails({
  notification,
}: {
  notification: Notification;
}) {
  const {
    linkList,
    postalDistrictOptions,
    skillOptions,
    resultModalRef,
    editNotiApi,
    moreDetails,
    publishNow,
    bindTitle,
    bindAdditionalInfo,
    bindRole,
    bindDistrict,
    bindPublishDate,
    bindPublishTime,
    bindPreviewText,
    showRecipientsError,
    status,
    thumbnail,
    removedFile,
    detectChange,
    setRemovedFile,
    setMoreDetails,
    setFile,
    onSubmitForm,
    setPublishNow,
  } = useNotificationLogic(notification);

  return (
    <>
      <div className="main-header">
        <Breadcrumb linkList={linkList} />
        <div className={styles.headerWrapper}>
          <h2 className={styles.header}>{strings('Notification Details')}</h2>
          <Badge type={'notificationStatus'} status={status} />
        </div>
      </div>
      <div className={styles.container}>
        <h3>{strings('General Details')}</h3>
        <div className={styles.inputItem}>
          <Input
            id="title"
            label={strings('Title')}
            placeholder={strings('Enter Title')}
            maxLength={85}
            disabled={status === 'published'}
            {...bindTitle}
          />
        </div>
        <div className={`${styles.inputItem} ${styles.timeWrapper}`}>
          <PcfDatePicker
            label={strings('Publish Date')}
            placeholder={strings('Select Date')}
            type="single"
            clearButton={false}
            disabled={publishNow || status === 'published'}
            {...bindPublishDate}
          />

          <PcfTimePicker
            type="single"
            label={strings('Publish Time')}
            placeholder={strings('Select Time')}
            disabled={publishNow || status === 'published'}
            {...bindPublishTime}
          />

          <CheckBox
            label={strings('Publish Now')}
            id="publishNow"
            name="publishNow"
            checked={publishNow}
            handleChange={() => setPublishNow(!publishNow)}
            disabled={status === 'published'}
          />
        </div>
        <hr />
        <h3>{strings('Recipients')}</h3>
        {showRecipientsError && (
          <div className={styles.errorWrapper}>
            <ErrorIcon />
            <span>{strings('Please select a role or a district')}</span>
          </div>
        )}
        <div className={`${styles.inputItem} ${styles.recipientsWrapper}`}>
          <Select
            topLabel={strings('Role')}
            border
            defaultLabel={strings('Select Role')}
            optionList={skillOptions}
            multipleSelect
            allToggle
            allItem
            disabled={status === 'published'}
            {...bindRole}
          />

          <Select
            topLabel={strings('District')}
            border
            defaultLabel={strings('District')}
            optionList={postalDistrictOptions}
            multipleSelect
            allToggle
            allItem
            divider
            disabled={status === 'published'}
            {...bindDistrict}
          />
        </div>
        <hr />
        <h3>{strings('Content')}</h3>
        <div className={styles.inputItem}>
          <Input
            id="additionalInfo"
            type="textarea"
            label={strings('Preview Text')}
            placeholder={strings(
              'Preview Text are displayed at the push notification.'
            )}
            maxLength={122}
            disabled={status === 'published'}
            {...bindPreviewText}
          />
        </div>
        <div className={styles.inputItem}>
          <div className={styles.radioItem}>
            <Radio
              label={strings('Only Push Notification')}
              id="moreDetails"
              value={false}
              checked={moreDetails === false}
              handleChange={() => {
                setMoreDetails(false);
              }}
              disabled={status === 'published'}
            />
          </div>
          <Radio
            label={strings('More Details')}
            id="moreDetails"
            value={true}
            checked={moreDetails === true}
            handleChange={() => {
              setMoreDetails(true);
            }}
            disabled={status === 'published'}
          />
        </div>
        {moreDetails && (
          <div>
            <div className={styles.inputItem}>
              <Input
                id="additionalInfo"
                type="textarea"
                label={strings('Additional Information')}
                optionalLabel={strings('Optional')}
                placeholder={strings('Any addtional details?')}
                disabled={status === 'published'}
                {...bindAdditionalInfo}
              />
            </div>
            <div className={styles.inputItem}>
              <label className={styles.inputLabel}>
                <span>{strings('Attachment')}</span>
                <span className={styles.optionalLabel}>
                  {strings('Optional')}
                </span>
              </label>
              {thumbnail && !removedFile ? (
                <div>
                  <div className={styles.thumbnailWrapper}>
                    <img src={thumbnail} />
                    {status === 'queued' && (
                      <div
                        className={styles.removeIcon}
                        onClick={() => setRemovedFile(true)}
                      >
                        <TrashIcon />
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <FileUpload
                  disabled={status === 'published'}
                  onSelectFiles={files => {
                    const filesList = Object.values(files);
                    filesList.length > 0
                      ? setFile(filesList[0])
                      : setFile(undefined);
                  }}
                />
              )}
            </div>
          </div>
        )}
        {status === 'queued' && (
          <div className={styles.buttonWrapper}>
            <Button
              label={strings('Discard Changes')}
              type="transparent"
              handleClick={() => {
                history.go(0);
              }}
            />
            <Button
              label={strings('Save Changes')}
              type="danger"
              disabled={!detectChange}
              handleClick={onSubmitForm}
            />
          </div>
        )}

        <Modal
          ref={resultModalRef}
          header={undefined}
          body={
            <div>
              <div>
                {editNotiApi.error
                  ? editNotiApi.error
                  : editNotiApi.data?.notification
                  ? strings('Update Notification Successfully')
                  : ''}
              </div>
            </div>
          }
          footer={
            <>
              <Button
                label={strings('Done')}
                type="danger"
                handleClick={() => {
                  resultModalRef.current.hideModal();
                }}
              />
            </>
          }
        />
      </div>
    </>
  );
}
