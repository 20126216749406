export function downloadCsv(filename: string, content: string) {
  const link = document.createElement('a');
  const blob = new Blob([content], {type: 'text/csv'});
  const url = URL.createObjectURL(blob);
  link.href = url;
  link.download = filename;
  link.click();
}

export function getFilenameFromHeaders(headers: any) {
  let filename = 'filename';
  const disposition = headers['content-disposition'];
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return filename;
}
