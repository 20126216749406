import styles from './AutoSubmission.module.scss';
import {strings} from '../../../services/language';
import {useEffect, useMemo, useRef, useState} from 'react';
import {Column} from 'react-table';
import {ReactComponent as MoreBlackIcon} from '../../../assets/img/more-black.svg';
import {ReactComponent as EditIcon} from '../../../assets/img/edit.svg';
import {ReactComponent as TrashIcon} from '../../../assets/img/trash.svg';
import {
  createPayrollScheduleAPI,
  deletePayrollScheduleAPI,
  editPayrollScheduleAPI,
  getPayrollSchedulesAPI,
  useAPI,
  useResult,
} from '../../../services/api';
import {
  useLoading,
  usePickSingleDate,
  usePickSingleTime,
} from '../../../services/hooks';
import {PayrollSchedule} from '../../../services/models';
import {formatISOString} from '../../../services/utils';
import moment from 'moment';

const _limit = 8;

export function useScheduleLogic() {
  const [total, setTotal] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [manualFilterSort, setManualFilterSort] = useState<any>(undefined);
  const [scheduleList, setScheduleList] = useState<PayrollSchedule[]>([]);
  const [startDate, setStartDate] = useState<Date | undefined>(undefined);
  const [endDate, setEndDate] = useState<Date | undefined>(undefined);
  const [selectedSchedule, setSelectedSchedule] = useState<
    {id: number; scheduledAt: string} | undefined
  >(undefined);
  const {states: addDate, bind: bindAddDate} = usePickSingleDate();
  const {states: addTime, bind: bindAddTime} = usePickSingleTime();
  const {states: editDate, bind: bindEditDate} = usePickSingleDate();
  const {states: editTime, bind: bindEditTime} = usePickSingleTime();

  const [showMores, setShowMores] = useState<{[key: string]: boolean}>(() => {
    let obj = {};
    scheduleList.forEach((schedule, index) => {
      obj = {...obj, [index]: false};
    });
    return obj;
  });

  useEffect(() => {
    setManualFilterSort([]);
  }, [startDate, endDate]);

  const addDateTimeModalRef = useRef<any>(null);
  const editDateTimeModalRef = useRef<any>(null);
  const deleteModalRef = useRef<any>(null);

  const scheduleListApi = useAPI(getPayrollSchedulesAPI);
  const createScheduleApi = useAPI(createPayrollScheduleAPI);
  const editScheduleApi = useAPI(editPayrollScheduleAPI);
  const deleteScheduleApi = useAPI(deletePayrollScheduleAPI);

  function fetchData(pageIndex: number) {
    setCurrentIndex(pageIndex);
    scheduleListApi.request({
      _start: pageIndex * _limit,
      _limit: _limit,
      _sort: 'id:DESC',
      ...(startDate && {scheduled_from: startDate.toISOString()}),
      ...(endDate && {scheduled_to: endDate.toISOString()}),
    });
  }

  useResult(scheduleListApi.data, data => {
    if (data.total !== total) {
      setTotal(data.total);
    }
    if (data.list) {
      setScheduleList(data.list);
    }
  });

  useResult(createScheduleApi.data, data => {
    if (data.schedule) {
      addDateTimeModalRef.current.hideModal();
      fetchData(0);
    }
  });

  useEffect(() => {
    if (!createScheduleApi.loading && createScheduleApi.error) {
      addDate.setError(
        strings('A date from this month has already been added')
      );
    }
  }, [createScheduleApi.loading]);

  useResult(editScheduleApi.data, data => {
    if (data.schedule) {
      editDateTimeModalRef.current.hideModal();
      fetchData(currentIndex);
    }
  });

  useEffect(() => {
    if (!editScheduleApi.loading && editScheduleApi.error) {
      editDate.setError(
        strings('A date from this month has already been added')
      );
    }
  }, [editScheduleApi.loading]);

  useEffect(() => {
    if (selectedSchedule) {
      editDate.setValue(new Date(selectedSchedule.scheduledAt));
      editTime.setValue(selectedSchedule.scheduledAt);
    }
  }, [selectedSchedule]);

  useResult(deleteScheduleApi.data, data => {
    if (data.success) {
      deleteModalRef.current.hideModal();
      fetchData(currentIndex);
    }
  });

  const columns: Column[] = useMemo(() => createColumns(), []);
  const data: ScheduleData[] = useMemo(
    () =>
      createData(
        scheduleList,
        showMores,
        setShowMores,
        editDateTimeModalRef,
        deleteModalRef,
        setSelectedSchedule
      ),
    [showMores, scheduleList]
  );

  function validateAdd() {
    !addDate.value
      ? addDate.setError(strings('Please select a date'))
      : addDate.setError('');
    !addTime.value
      ? addTime.setError(strings('Please select a time'))
      : addTime.setError('');

    return !!addDate.value && !!addTime.value;
  }

  function addSchedule() {
    if (validateAdd()) {
      createScheduleApi.request({
        scheduledAt: concatDateAndTime(addDate.value!, addTime.value),
      });
    }
  }

  function validateEdit() {
    !editDate.value
      ? editDate.setError(strings('Please select a date'))
      : editDate.setError('');
    !editTime.value
      ? editTime.setError(strings('Please select a time'))
      : editTime.setError('');

    return !!editDate.value && !!editTime.value;
  }

  function editSchedule() {
    if (validateEdit()) {
      editScheduleApi.request({
        id: selectedSchedule!.id,
        scheduledAt: concatDateAndTime(editDate.value!, editTime.value),
      });
    }
  }

  function deleteSchedule() {
    deleteScheduleApi.request({
      id: selectedSchedule!.id,
    });
  }

  useLoading(
    scheduleListApi.loading ||
      createScheduleApi.loading ||
      editScheduleApi.loading ||
      deleteScheduleApi.loading
  );

  return {
    addDateTimeModalRef,
    editDateTimeModalRef,
    deleteModalRef,
    columns,
    data,
    manualFilterSort,
    total,
    _limit,
    fetchData,
    setStartDate,
    setEndDate,
    bindAddDate,
    bindAddTime,
    bindEditDate,
    bindEditTime,
    addSchedule,
    editSchedule,
    deleteSchedule,
    selectedScheduleText: selectedSchedule
      ? formatISOString(selectedSchedule.scheduledAt, 'DD/MM/YY hh:mmA')
      : '',
  };
}

interface ScheduleData {
  date: string;
  time: string;
  button: JSX.Element;
}

function createColumns() {
  return [
    {
      Header: strings('Date'),
      accessor: 'date',
    },
    {
      Header: strings('Time'),
      accessor: 'time',
    },
    {
      Header: '',
      accessor: 'button',
    },
  ];
}

function createData(
  scheduleList: PayrollSchedule[],
  showMores: {[key: string]: boolean},
  setShowMores: React.Dispatch<
    React.SetStateAction<{
      [key: string]: boolean;
    }>
  >,
  editDateTimeModalRef: React.MutableRefObject<any>,
  deleteModalRef: React.MutableRefObject<any>,
  setSelectedSchedule: React.Dispatch<
    React.SetStateAction<
      | {
          id: number;
          scheduledAt: string;
        }
      | undefined
    >
  >
): ScheduleData[] {
  return scheduleList.map((schedule, index) => ({
    date: formatISOString(schedule.scheduledAt, 'DD/MM/YY'),
    time: formatISOString(schedule.scheduledAt, 'hh:mmA'),
    button: (
      <div className={styles.buttonCell}>
        <div
          className={styles.moreToggle}
          tabIndex={0}
          onBlur={() => {
            setShowMores({...showMores, [index]: false});
          }}
          onClick={() => {
            setSelectedSchedule({
              id: schedule.id,
              scheduledAt: schedule.scheduledAt,
            });
            setShowMores({...showMores, [index]: !showMores[index]});
          }}
        >
          <MoreBlackIcon />
          {showMores[index] && (
            <div className={styles.moreDropdown}>
              <div
                className={styles.moreButton}
                onClick={() => editDateTimeModalRef.current.showModal()}
              >
                <EditIcon /> {strings('Edit Date and Time')}
              </div>
              <div
                className={styles.moreButton}
                onClick={() => deleteModalRef.current.showModal()}
              >
                <TrashIcon /> {strings('Delete Date and Time')}
              </div>
            </div>
          )}
        </div>
      </div>
    ),
  }));
}

function concatDateAndTime(date: Date, time: string) {
  const dateMoment = moment(date);
  const timeMoment = moment(time);

  return moment({
    hour: timeMoment.hour(),
    minute: timeMoment.minute(),
    date: dateMoment.date(),
    month: dateMoment.month(),
    year: dateMoment.year(),
  }).toISOString();
}
