import styles from './Badge.module.scss';
import {
  JobStatus,
  Skill,
  PayrollStatus,
  TimesheetStatus,
} from '../../services/models';
import {useEffect, useState} from 'react';
import {strings} from '../../services/language';

type BadgeProps =
  | {
      type: 'jobStatus';
      status: JobStatus | undefined;
    }
  | {
      type: 'skill';
      kind: 'skillIconText' | 'skillIcon';
      height?: number;
      skill: Skill;
    }
  | {
      type: 'payrollStatus';
      status: PayrollStatus | undefined;
    }
  | {
      type: 'timesheetStatus';
      status: TimesheetStatus | undefined;
    }
  | {
      type: 'notificationStatus';
      status: 'queued' | 'published';
    };

export function Badge(props: BadgeProps) {
  const [styleClass, setStyleClass] = useState('');
  const [content, setContent] = useState('');
  const [icon, setIcon] = useState<Skill['icon'] | undefined>(undefined);
  const [style, setStyle] = useState<React.CSSProperties | undefined>(
    undefined
  );

  useEffect(() => {
    switch (props.type) {
      case 'jobStatus':
        switch (props.status) {
          case 'open':
            setContent(strings('Open'));
            setStyleClass('lightGreen');
            break;
          case 'ongoing':
            setContent(strings('Ongoing'));
            setStyleClass('brightBlue');
            break;
          case 'upcoming':
            setContent(strings('Upcoming'));
            setStyleClass('brightBlue');
            break;
          case 'completed':
            setContent(strings('Completed'));
            setStyleClass('jobPast');
            break;
          case 'removed':
            setContent(strings('Removed'));
            setStyleClass('jobPast');
            break;
          case 'expired':
            setContent(strings('Expired'));
            setStyleClass('jobPast');
            break;

          default:
            break;
        }
        break;

      case 'payrollStatus':
        switch (props.status) {
          case 'pending':
            setContent(strings('Pending'));
            setStyleClass('orangeRed');
            break;
          case 'processing':
            setContent(strings('Processing'));
            setStyleClass('brightBlue');
            break;
          case 'paid':
            setContent(strings('Paid'));
            setStyleClass('lightGreen');
            break;

          default:
            break;
        }
        break;

      case 'timesheetStatus':
        switch (props.status) {
          case 'waitingEP':
            setContent(strings('Pending EP Approval'));
            setStyleClass('cyanBlue');
            break;
          case 'waitingCP':
            setContent(strings('Not Submitted by CP'));
            setStyleClass('orangeRed');
            break;
          case 'waitingClockOut':
            setContent(strings('Waiting Clock Out'));
            setStyleClass('brightBlue');
            break;
          case 'approvedEP':
            setContent(strings('Approved by EP'));
            setStyleClass('lightGreen');
            break;
          case 'notPresent':
            setContent(strings('Not Present'));
            setStyleClass('yellow');
            break;
          case 'rejected':
            setContent(strings('Rejected'));
            setStyleClass('jobPast');
            break;

          default:
            break;
        }
        break;

      case 'skill':
        if (props.skill) {
          setStyle({
            backgroundColor: props.skill.bgColor,
            color: props.skill.color,
          });
          setStyleClass(props.kind);

          switch (props.kind) {
            case 'skillIconText':
              setContent(props.skill.name);
              setIcon(props.skill.smallIcon);
              break;
            default:
              setStyle({
                backgroundColor: props.skill.bgColor,
                color: props.skill.color,
                width: props.height,
                height: props.height,
              });
              setIcon(props.skill.icon);
              break;
          }
        }
        break;

      case 'notificationStatus':
        switch (props.status) {
          case 'queued':
            setContent(strings('Queued'));
            setStyleClass('yellow');
            break;
          case 'published':
            setContent(strings('Published'));
            setStyleClass('lightGreen');
            break;

          default:
            break;
        }
        break;

      default:
        break;
    }
  }, [props]);

  return (
    <span className={`${styles.container} ${styles[styleClass]}`} style={style}>
      {props.type === 'skill' && icon && (
        <img
          src={icon}
          alt={icon}
          height={
            props.kind === 'skillIconText' || !props.height
              ? 14
              : (props.height * 7) / 12
          }
        />
      )}
      {content}
    </span>
  );
}
