import {setScreen} from '../../services/router';
import styles from './Payrolls.module.scss';
import {strings} from '../../services/language';
import {NavTabs, Button} from '../../components';
import PayrollRecords from './PayrollRecords/PayrollRecords';
import AutoSubmission from './AutoSubmission/AutoSubmission';

setScreen('/payrolls', params => {
  return (
    <>
      <div className="main-header">
        <div className={styles.header}>
          <div className={styles.title}>
            <h1>{strings('Payroll')}</h1>
            <div className={styles.greyText}>
              {strings(
                'A month’s payroll would consist of the Timesheets that were approved by the EP from the start and end of the month. At 5pm of every first day of the next month, these timesheets would be auto submitted to the FTP to be processed.'
              )}
            </div>
          </div>
          <Button type="outline" label={strings('Go to FTP Records')} />
        </div>
      </div>
      <NavTabs
        navTabList={[
          {
            id: 'payrollRecords',
            label: strings('Payroll Records'),
            content: <PayrollRecords />,
          },
          {
            id: 'autoSubmission',
            label: strings('Auto-Submission Schedule'),
            content: <AutoSubmission />,
          },
        ]}
      />
    </>
  );
});
