import {setScreen} from '../../services/router';
import {strings} from '../../services/language';
import StaffList from './StaffList/StaffList';

setScreen('/pcf-staffs', () => {
  return (
    <>
      <div className="main-header">
        <h1>{strings('PCF Staff')}</h1>
      </div>
      <StaffList />
    </>
  );
});
