import {useLocation} from 'react-router-dom';
import {Job} from '../../services/models';
import {setScreen} from '../../services/router';
import JobCreateContent from './JobCreateContent/JobCreateContent';

setScreen('/jobs/create-job-posting', () => {
  const location = useLocation();
  const state = location.state as {job: Job} | undefined;

  return <JobCreateContent job={state?.job} />;
});
