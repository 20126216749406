import {useMemo} from 'react';
import {Column} from 'react-table';
import {Input, Select, Table} from '../../../components';
import {createSkillOptions, locationOptions} from '../../../services/helpers';
import {strings} from '../../../services/language';
import {Profile, Qualification, Skill} from '../../../services/models';
import {formatISOString} from '../../../services/utils';
import styles from './General.module.scss';

type Props = {
  profile: Profile;
  skills: Skill[];
};

export default function General({profile, skills}: Props) {
  const skillOptions = useMemo(() => createSkillOptions(skills), [skills]);
  const districtsText = useMemo(() => {
    let text = '';
    profile.districtNumbers?.forEach((d, i, a) => {
      const district = locationOptions.filter(l => l.value === d);
      if (district.length > 0) {
        text += district[0].content + '.' + (i === a.length - 1 ? '' : '\n');
      }
    });
    return text;
  }, [profile]);

  const columns: Column[] = useMemo(() => createColumns(), []);
  const data: any[] = useMemo(
    () => createRowsData(profile.qualifications),
    [profile.qualifications]
  );
  return (
    <div className={styles.container}>
      <div className={styles.infoWrapper}>
        <div className={styles.inputItem}>
          <Select
            topLabel={strings('Role')}
            border
            defaultLabel={strings('Select Role')}
            value={profile.skillId}
            optionList={skillOptions}
            disabled
          />
        </div>
        <div className={styles.inputItem}>
          <Input
            id="email"
            value={profile.email}
            disabled
            type="text"
            handleChange={() => {}}
            label={strings('Email Address')}
          />
        </div>
        <div className={styles.inputItem}>
          <Input
            id="contactNumberexpiry"
            value={profile.phone}
            disabled
            type="text"
            handleChange={() => {}}
            label={strings('Contact Number')}
          />
        </div>
        <div className={styles.inputItem}>
          <Input
            id="expiry"
            value={
              profile.medicalReportDate
                ? formatISOString(profile.medicalReportDate, 'DD/MM/YY')
                : ''
            }
            disabled
            type="text"
            handleChange={() => {}}
            label={strings('Medical Report Date')}
          />
        </div>
        <div className={styles.inputItem}>
          <Input
            id="districts"
            value={districtsText}
            disabled
            type="textarea"
            handleChange={() => {}}
            label={
              strings('Notification Preferences') + ' - ' + strings('Districts')
            }
          />
        </div>
      </div>
      {profile.qualifications && (
        <div className={styles.qualificationsWrapper}>
          <div className={styles.label}>{strings('Qualifications')}</div>
          <Table columns={columns} data={data} />
        </div>
      )}
    </div>
  );
}

function createColumns() {
  return [
    {
      Header: '',
      accessor: 'index',
    },
    {
      Header: strings('Title'),
      accessor: 'title',
    },
  ];
}

function createRowsData(qualifications: Qualification[] | undefined): any[] {
  return qualifications
    ? qualifications.map((q, index) => ({
        index: <span className={styles.greyText}>{index + 1}</span>,
        title: q.name,
      }))
    : [];
}
