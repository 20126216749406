import {setScreen} from '../../services/router';
import {strings} from '../../services/language';
import {useCentreDetailsLogic} from './logics';
import styles from './CentreDetails.module.scss';
import {Breadcrumb, NavTabs} from '../../components';
import Reviews from './Reviews/Reviews';
import General from './General/General';
import {useLoading} from '../../services/hooks';

setScreen('/centres/:id', params => {
  const {breadcrumbList, skillsApi, skills, centre} = useCentreDetailsLogic(
    params.id
  );

  useLoading(skillsApi.loading);

  return centre ? (
    <>
      <div className="main-header">
        <Breadcrumb linkList={breadcrumbList} />
        <h2 className={styles.header}>{centre.name}</h2>
      </div>
      <NavTabs
        navTabList={[
          {
            id: 'reviews',
            label: strings('Reviews'),
            content:
              skills && skills.length > 0 ? (
                <Reviews centre={centre} skills={skills} />
              ) : (
                <div></div>
              ),
          },
          {
            id: 'general',
            label: strings('General'),
            content: <General centre={centre} />,
          },
        ]}
      />
    </>
  ) : (
    <div></div>
  );
});
