export const locationOptions = [
  {
    value: 'Central',
    content: 'Central',
    isGroup: true,
    class: 'optionGroupItem',
  },
  {
    value: 1,
    content: "Raffles Place, Cecil, Marina, People's Park",
    group: 'Central',
  },
  {
    value: 2,
    content: 'Anson, Tanjong Pagar',
    group: 'Central',
  },
  {
    value: 3,
    content: 'Queenstown, Tiong Bahru',
    group: 'Central',
  },
  {
    value: 4,
    content: 'Telok Blangah, Harbourfront',
    group: 'Central',
  },
  {
    value: 6,
    content: 'High Street, Beach Road (part)',
    group: 'Central',
  },
  {
    value: 7,
    content: 'Middle Road, Golden Mile',
    group: 'Central',
  },
  {
    value: 8,
    content: 'Little India',
    group: 'Central',
  },
  {
    value: 9,
    content: 'Orchard, Cairnhill, River Valley',
    group: 'Central',
  },
  {
    value: 10,
    content: 'Ardmore, Bukit Timah, Holland Road, Tanglin',
    group: 'Central',
  },
  {
    value: 11,
    content: 'Watten Estate, Novena, Thomson',
    group: 'Central',
  },
  {
    value: 12,
    content: 'Balestier, Toa Payoh, Serangoon',
    group: 'Central',
  },
  {
    value: 13,
    content: 'Macpherson, Braddell',
    group: 'Central',
  },
  {
    value: 14,
    content: 'Geylang, Eunos',
    group: 'Central',
  },
  {
    value: 21,
    content: 'Upper Bukit Timah, Clementi Park, Ulu Pandan',
    group: 'Central',
  },
  {
    value: 26,
    content: 'Upper Thomson, Springleaf',
    group: 'Central',
  },
  {
    value: 'West',
    content: 'West',
    isGroup: true,
    class: 'optionGroupItem',
  },
  {
    value: 5,
    content: 'Pasir Panjang, Hong Leong Garden, Clementi New Town',
    group: 'West',
  },
  {
    value: 22,
    content: 'Jurong',
    group: 'West',
  },
  {
    value: 23,
    content: 'Hillview, Dairy Farm, Bukit Panjang, Choa Chu Kang',
    group: 'West',
  },
  {
    value: 'North',
    content: 'North',
    isGroup: true,
    class: 'optionGroupItem',
  },
  {
    value: 24,
    content: 'Lim Chu Kang, Tengah',
    group: 'North',
  },
  {
    value: 25,
    content: 'Kranji, Woodgrove',
    group: 'North',
  },
  {
    value: 27,
    content: 'Yishun, Sembawang',
    group: 'North',
  },
  {
    value: 'North East',
    content: 'North East',
    isGroup: true,
    class: 'optionGroupItem',
  },
  {
    value: 19,
    content: 'Serangoon Garden, Hougang, Punggol',
    group: 'North East',
  },
  {
    value: 20,
    content: 'Bishan, Ang Mo Kio',
    group: 'North East',
  },
  {
    value: 28,
    content: 'Seletar',
    group: 'North East',
  },
  {
    value: 'East',
    content: 'East',
    isGroup: true,
    class: 'optionGroupItem',
  },
  {
    value: 15,
    content: 'Katong, Joo Chiat, Amber Road',
    group: 'East',
  },
  {
    value: 16,
    content: 'Bedok, Upper East Cost, Eastwood, Kew Drive',
    group: 'East',
  },
  {
    value: 17,
    content: 'Loyang, Changi',
    group: 'East',
  },
  {
    value: 18,
    content: 'Tampines, Pasir Ris',
    group: 'East',
  },
];
