import styles from './Reviews.module.scss';
import {Table, Select, Button, Badge} from '../../../components';
import {ReactComponent as StarIcon} from '../../../assets/img/star.svg';
import {ReactComponent as StarOutlineIcon} from '../../../assets/img/star-outline.svg';
import {ReactComponent as SortIcon} from '../../../assets/img/sort.svg';
import {strings} from '../../../services/language';
import {useReviewsLogic} from './logics';
import {Profile, Skill} from '../../../services/models';

type ReviewsProps = {profile: Profile; skills: Skill[]};

export default function Reviews({profile, skills}: ReviewsProps) {
  const {
    starOptions,
    sortList,
    setStarFilter,
    sortRule,
    setSortRule,
    columns,
    data,
    hiddenColumns,
    manualFilterSort,
    fetchData,
    total,
    _limit,
  } = useReviewsLogic(profile.id, skills);

  return (
    <div className={styles.container}>
      <div className={styles.ratingWrapper}>
        <h3>
          <span>{`${strings('Ratings')}: ${profile.averageRating || 0} `}</span>{' '}
          <StarIcon style={{width: 15, height: 15}} />
          <span className={styles.ratingNoText}>{`${
            profile.totalReviews || 0
          } ${strings('Ratings')}`}</span>
        </h3>
      </div>
      <div className={styles.filtersContainer}>
        <div className={styles.buttonContainer}>
          <div className={styles.selectFilters}>
            <Select
              fixedLabel={<StarOutlineIcon />}
              optionList={starOptions}
              defaultLabel={strings('All')}
              multipleSelect
              allToggle
              allItem
              onSelectValue={values => setStarFilter(values)}
            />
            <div className={styles.sortWrapper}>
              <Select
                fixedLabel={<SortIcon />}
                optionList={sortList}
                value={sortRule}
                onSelectValue={values => setSortRule(values[0])}
              />
            </div>
          </div>
        </div>
      </div>
      <Table
        columns={columns}
        data={data}
        rowClick
        hiddenColumns={hiddenColumns}
        manualFilterSort={manualFilterSort}
        pageCountNumber={Math.ceil(total / _limit)}
        fetchData={fetchData}
      />
    </div>
  );
}
