import {useState, useEffect} from 'react';
import {createStore, DeepPartial} from 'redux';
import {persistStore} from 'redux-persist';
import State from './state';
import reducer, {actionTypes} from './reducer';

const devTools: any = (window as any).__REDUX_DEVTOOLS_EXTENSION__;
const store = createStore(reducer, devTools && devTools());

export const persistor = persistStore(store);

export const getState = store.getState;

export const subscribe = store.subscribe;

export function updateState(newState: DeepPartial<State>) {
  store.dispatch({type: actionTypes.update, payload: newState});
}

export function setState(newState: Partial<State>) {
  store.dispatch({type: actionTypes.set, payload: newState});
}

export function useSelector<T>(fn: (s: State) => T): T {
  const [state, setState] = useState(() => fn(store.getState()));
  useEffect(() => {
    return store.subscribe(() => {
      const newState = fn(store.getState());
      newState != state && setState(newState);
    });
  }, [state]);
  return state;
}
