import styles from './StaffCreate.module.scss';
import {Button, Input, Modal, Select, Table} from '../../../components';
import {strings} from '../../../services/language';
import {useEffect, useMemo} from 'react';
import {ReactComponent as SearchIcon} from '../../../assets/img/search.svg';
import {ReactComponent as LocationIcon} from '../../../assets/img/location.svg';
import {ReactComponent as ErrorIcon} from '../../../assets/img/error.svg';
import {
  districtOptions,
  staffRoleCreateOptions,
} from '../../../services/helpers';
import {useStaffCreateLogic} from './logics';
import {useLoading} from '../../../services/hooks';

export default function StaffCreate() {
  const {
    searchValue,
    setSearchValue,
    bindStaffName,
    bindStaffPhone,
    bindStaffEmail,
    staffRole,
    bindStaffRole,
    centreList,
    setCentreList,
    selectedCentresColumns,
    selectedCentresData,
    editCentreModalRef,
    resultModalRef,
    centreError,
    recordNumber,
    setRecordNumber,
    selectingCentres,
    setSelectingCentres,
    allCentresColumns,
    allCentresData,
    onSubmit,
    createProfileApi,
    resetForm,
    selectFilters,
    setSelectFilters,
  } = useStaffCreateLogic();

  useEffect(() => {
    if (
      (createProfileApi.data?.success || createProfileApi.error) &&
      !createProfileApi.loading
    ) {
      resultModalRef.current.showModal();
    }
  }, [createProfileApi.loading]);

  useLoading(createProfileApi.loading);

  const centresTableEl = useMemo(
    () => (
      <Table
        columns={allCentresColumns}
        data={allCentresData}
        pageNumber={allCentresData.length}
        tbodyMaxHeight={330}
        rowSelectAtLast
        hiddenColumns={['id']}
        filters={['centre']}
        filterQuery={searchValue}
        columnsFilters={selectFilters}
        handleSelectRow={(selectedFlatRows, selectedRowIds) => {
          const curCentreIds = selectingCentres.map(c => c.id);
          const curCentres = selectingCentres.filter(c =>
            Object.keys(selectedRowIds).includes(c.id)
          );
          const newCentres = selectedFlatRows.filter(
            c => !curCentreIds.includes(c.id)
          );
          const mergedCentres = [...curCentres, ...newCentres];
          selectingCentres.length !== mergedCentres.length &&
            setSelectingCentres(mergedCentres);
        }}
        onChangeRowLength={rowLength => setRecordNumber(rowLength)}
      />
    ),
    [
      allCentresColumns,
      allCentresData,
      searchValue,
      selectingCentres,
      selectFilters,
    ]
  );

  return (
    <div className={styles.container}>
      <div className={styles.fieldItem}>
        <Input label={strings('Name')} id="staffName" {...bindStaffName} />
      </div>
      <div className={styles.fieldItem}>
        <Input
          label={strings('Email Address')}
          id="staffEmail"
          {...bindStaffEmail}
        />
      </div>
      <div className={styles.fieldItem}>
        <Input
          label={strings('Contact Number')}
          id="staffPhone"
          {...bindStaffPhone}
        />
      </div>
      <div className={styles.fieldItem}>
        <Select
          defaultLabel={strings('Select Role')}
          topLabel={strings('Role')}
          border
          optionList={staffRoleCreateOptions}
          {...bindStaffRole}
          value={staffRole.value}
          onSelectValue={(selectedValues: any[]) => {
            staffRole.setError('');
            staffRole.setValue(selectedValues[0] || '');
            selectedValues[0] === 'HR' ? setCentreList([]) : null;
          }}
        />
      </div>
      <div className={styles.fieldItem}>
        <div
          className={`${styles.labelText} ${
            ['', 'HR'].includes(staffRole.value) ? styles.disabledText : ''
          }`}
        >
          {strings('Centres')}
        </div>
        {centreList.length > 0 && (
          <div className={styles.centresWrapper}>
            <Table
              hiddenColumns={['id']}
              columns={selectedCentresColumns}
              data={selectedCentresData}
            />
          </div>
        )}
      </div>
      <Button
        type="outline"
        label={
          centreList.length > 0
            ? strings('Edit Centres')
            : strings('Add Centres')
        }
        disabled={['', 'HR'].includes(staffRole.value)}
        handleClick={() => editCentreModalRef.current.showModal()}
      />
      {centreError !== '' && (
        <div className={styles.errorWrapper}>
          <ErrorIcon />
          <span>{centreError}</span>
        </div>
      )}
      <div className={styles.buttonWrapper}>
        <Button
          type="transparent"
          label={strings('Cancel')}
          handleClick={() => history.back()}
        />
        <Button
          type="danger"
          label={strings('Create')}
          handleClick={() => onSubmit()}
        />
      </div>

      <Modal
        ref={editCentreModalRef}
        width={652}
        header={<h2>{strings('Add Centres on Profile')}</h2>}
        body={
          <div className={styles.bodyModal}>
            <div className={styles.filters}>
              <div className={styles.searchInput}>
                <SearchIcon
                  className={styles.searchIcon}
                  style={{fill: 'var(--grey-text)'}}
                />
                <input
                  type="text"
                  name="searchQueryCentre"
                  id="searchQueryCentre"
                  value={searchValue}
                  onChange={e => setSearchValue(e.target.value)}
                  placeholder={strings('Search Centre')}
                />
              </div>
              <Select
                fixedLabel={<LocationIcon />}
                defaultLabel={strings('District')}
                optionList={districtOptions}
                multipleSelect
                searchable
                allToggle
                allItem
                divider
                value={selectFilters['district']}
                onSelectValue={values => {
                  setSelectFilters({district: values});
                }}
              />
            </div>
            <div className={styles.recordText}>
              <span className={styles.greyText}>
                {strings('Showing %0 Records', recordNumber)}
              </span>
              <span className={styles.blueText}>
                {strings('%0 Record selected', selectingCentres.length)}
              </span>
            </div>
            {centresTableEl}
          </div>
        }
        footer={
          <>
            <Button
              label={strings('Cancel')}
              type="transparent"
              handleClick={() => {
                const arr1 =
                  selectingCentres.length > centreList.length
                    ? selectingCentres
                    : centreList;
                const arr2 =
                  selectingCentres.length <= centreList.length
                    ? selectingCentres
                    : centreList;
                const centreIdList = arr2.map(centreRow => centreRow.id);
                const newSelecting = arr1.filter(
                  centreRow => !centreIdList.includes(centreRow.id)
                );
                newSelecting.forEach(row => {
                  row.toggleRowSelected();
                });
                editCentreModalRef.current.hideModal();
              }}
            />
            <Button
              label={strings('Done')}
              type="danger"
              handleClick={() => {
                setCentreList(selectingCentres);
                editCentreModalRef.current.hideModal();
              }}
            />
          </>
        }
        onHideFunc={() => {
          !!selectFilters['district'].length &&
            setSelectFilters({district: []});
          !!searchValue && setSearchValue('');
        }}
      />

      <Modal
        ref={resultModalRef}
        header={undefined}
        body={
          <div>
            <div>
              {createProfileApi.error
                ? createProfileApi.error
                : createProfileApi.data?.success
                ? strings('Create PCF Staff Profile Successfully')
                : ''}
            </div>
          </div>
        }
        footer={
          <>
            <Button
              label={strings('Done')}
              type="danger"
              handleClick={() => {
                !createProfileApi.error &&
                  createProfileApi.data?.success &&
                  resetForm();
                resultModalRef.current.hideModal();
              }}
            />
          </>
        }
      />
    </div>
  );
}
