import styles from './PcfTimePicker.module.scss';
import {ReactComponent as ClockIcon} from '../../assets/img/clock.svg';
import moment from 'moment';
import {Select, OptionItem} from '../../components';
import {useEffect, useState} from 'react';
import {getHoursDiff} from '../../services/utils';

type PcfTimePickerProps = {
  type?: 'single' | 'range';
  label?: string;
  placeholder?: string;
  placeholderTo?: string;
  fromValueInit?: string;
  toValueInit?: string;
  error?: string;
  errorTo?: string;
  disabled?: boolean;
  step?: number;
  onSelectValue?: (selectedValues: {from: string; to: string}) => void;
};

export function PcfTimePicker({
  type = 'range',
  label,
  placeholder,
  placeholderTo,
  fromValueInit,
  toValueInit,
  error = '',
  errorTo = '',
  disabled = false,
  step = 15,
  onSelectValue = () => {},
}: PcfTimePickerProps) {
  const [fromValue, setFromValue] = useState(() =>
    fromValueInit ? convertDate(fromValueInit) : ''
  );
  const [toValue, setToValue] = useState(() =>
    toValueInit ? convertDate(toValueInit) : ''
  );

  useEffect(() => {
    setFromValue(fromValueInit ? convertDate(fromValueInit) : '');
    setToValue(toValueInit ? convertDate(toValueInit) : '');
  }, [fromValueInit, toValueInit]);

  return (
    <div className={styles.container}>
      {label && (
        <div className={styles.label}>
          <span>{label}</span>
          {fromValue && toValue && (
            <span className={styles.greyText}>{`${getHoursDiff(
              fromValue,
              toValue
            )} hrs`}</span>
          )}
        </div>
      )}
      <div className={styles.pickersWrapper}>
        <div className={styles.picker}>
          <Select
            border
            defaultLabel={placeholder}
            rightIcon={
              <ClockIcon
                style={{
                  fill: disabled
                    ? 'var(--grey-border-2)'
                    : 'var(--blue-accent)',
                  stroke: disabled
                    ? 'var(--grey-border-2)'
                    : 'var(--blue-accent)',
                }}
              />
            }
            searchable
            maxHeight={242}
            width={164}
            value={fromValue}
            optionList={createTimeList(step)}
            error={error}
            disabled={disabled}
            onSelectValue={value => {
              setFromValue(value[0] || '');
              onSelectValue({from: value[0] || '', to: toValue});
            }}
          />
        </div>
        {type === 'range' && (
          <>
            <span className={styles.toText}>to</span>
            <div className={styles.pickerWrapper}>
              <div className={styles.picker}>
                <Select
                  border
                  defaultLabel={placeholderTo}
                  rightIcon={
                    <ClockIcon
                      style={{
                        fill: disabled
                          ? 'var(--grey-border-2)'
                          : 'var(--blue-accent)',
                        stroke: disabled
                          ? 'var(--grey-border-2)'
                          : 'var(--blue-accent)',
                      }}
                    />
                  }
                  searchable
                  maxHeight={242}
                  width={164}
                  value={toValue}
                  optionList={createTimeList(step)}
                  error={errorTo}
                  disabled={disabled}
                  onSelectValue={value => {
                    setToValue(value[0] || '');
                    onSelectValue({from: fromValue, to: value[0] || ''});
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function createTimeList(step: number) {
  const items: OptionItem[] = [];
  new Array(24).fill('').forEach((_, index) => {
    for (let minuteIndex = 0; minuteIndex < 60 / step; minuteIndex++) {
      const minute = minuteIndex * step;
      items.push({
        content: moment({hour: index, minute}).format('h:mmA'),
        value: moment({
          hour: index,
          minute,
          day: 6,
          month: 9,
          year: 1996,
        }).toISOString(),
        text: moment({hour: index, minute}).format('h:mmA'),
      });
    }
  });
  return items;
}

function convertDate(date: string) {
  const momentDate = moment(date);
  return moment({
    hour: momentDate.hour(),
    minute: momentDate.minute(),
    day: 6,
    month: 9,
    year: 1996,
  }).toISOString();
}
