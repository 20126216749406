import {Payroll, Profile} from '../../models';
import {profiles} from '../mockDB';
import {API} from '../types';

type Params = {
  _start: number;
  _limit: number;
  _sort: string;
  query?: string;
  searchField?: string;
  payrollPeriods?: string[];
  statuses?: string[];
  centreDistrictGroups?: string[];
};
interface Response {
  list: (Payroll & {profile: Profile})[];
  total: number;
}

export function getPayrollsAPI(params: Params): API<Response> {
  const urlParams = {
    ...params,
    ...(params.payrollPeriods && {
      payrollPeriods: params.payrollPeriods.join(','),
    }),
    ...(params.statuses && {statuses: params.statuses.join(',')}),
    ...(params.centreDistrictGroups && {
      centreDistrictGroups: params.centreDistrictGroups.join(','),
    }),
  };

  return {
    getData: async client => {
      const {data} = await client({
        url: '/payrolls',
        method: 'GET',
        params: urlParams,
      });
      return {data: data};
    },
    getMockData: () => {
      return {
        list: [
          {
            id: 11,
            period: '2021-12',
            status: 'pending',
            amount: 15,
            workedDuration: 300,
            payrollRun: undefined,
            centreId: 1,
            profileId: 68,
            paidAt: undefined,
            sentAt: undefined,
            createdAt: '2021-12-13T03:44:08.838Z',
            updatedAt: '2021-12-13T03:44:08.838Z',
            periodStart: '2021-12-12',
            periodEnd: '2021-12-12',
            payslipId: undefined,
            profile: profiles[0],
          },
        ],
        total: 1,
      };
    },
  };
}
