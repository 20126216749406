import {PayrollSchedule} from '../../models';
import {API} from '../types';

type Params = {
  _start: number;
  _limit: number;
  _sort: string;
  scheduled_from?: string;
  scheduled_to?: string;
};

interface Response {
  list: PayrollSchedule[];
  total: number;
}

export function getPayrollSchedulesAPI(params: Params): API<Response> {
  return {
    getData: async client => {
      const {data} = await client({
        url: '/payroll-schedules',
        method: 'GET',
        params,
      });
      return {data: data};
    },
    getMockData: () => {
      return {
        list: [
          {
            id: 1,
            scheduledAt: '',
          },
        ],
        total: 1,
      };
    },
  };
}
