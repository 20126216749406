import {API} from '../types';

type Params = {id: string; description: string}[];

interface Response {
  success: boolean;
}

export function editSkillAPI(params: Params): API<Response> {
  return {
    getData: async client => {
      let data1: any;

      await Promise.all(
        params.map(async skill => {
          const {data} = await client({
            url: `/skills/${skill.id}`,
            method: 'PUT',
            data: {
              description: skill.description,
            },
          });
          data1 = data;
        })
      );
      return {data: data1};
    },
    getMockData: () => {
      return {success: false};
    },
  };
}
