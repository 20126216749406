import styles from './NotificationList.module.scss';
import {Table, Button, Modal, Select, PcfDatePicker} from '../../components';
import {strings} from '../../services/language';
import {toRoute} from '../../services/router';
import ReactMarkdown from 'react-markdown';
import {useNotificationListLogic} from './logics';
import {ReactComponent as SearchIcon} from '../../assets/img/search.svg';
import {ReactComponent as WorkIcon} from '../../assets/img/work.svg';
import {ReactComponent as TagIcon} from '../../assets/img/tag.svg';
import {ReactComponent as LocationIcon} from '../../assets/img/location.svg';
import {notiStatusOptions, postalDistrictOptions} from '../../services/helpers';

export default function NotificationList() {
  const {
    history,
    columns,
    data,
    total,
    _limit,
    fetchData,
    deleteModalRef,
    resultModalRef,
    selectedNoti,
    deleteNotiApi,
    bindStatus,
    bindRole,
    bindDistrict,
    setStartDate,
    setEndDate,
    skillOptions,
    searchValue,
    setSearchValue,
    manualFilterSort,
  } = useNotificationListLogic();

  return (
    <div className={styles.container}>
      <div className={styles.filtersContainer}>
        <div className={styles.searchWrapper}>
          <div className={styles.searchInput}>
            <SearchIcon
              className={styles.searchIcon}
              style={{fill: 'var(--grey-text)'}}
            />
            <input
              type="text"
              name="searchQuery"
              id="searchQuery"
              value={searchValue}
              onChange={e => setSearchValue(e.target.value)}
              placeholder={strings('Search Announcements')}
            />
          </div>
          <span className={styles.numberRecord}>{`${total} ${strings(
            'Records'
          )}`}</span>
        </div>
        <div className={styles.buttonContainer}>
          <div className={styles.selectFilters}>
            <PcfDatePicker
              placeholder={strings('Schedule')}
              onSelectDate={value => {
                setStartDate(value.from || '');
                setEndDate(value.to || '');
              }}
            />
            <Select
              fixedLabel={<TagIcon />}
              defaultLabel={strings('Status')}
              optionList={notiStatusOptions}
              multipleSelect
              allToggle
              allItem
              {...bindStatus}
            />
            <Select
              fixedLabel={
                <WorkIcon
                  style={{
                    stroke: 'var(--blue-accent)',
                    width: '16px',
                    height: '16px',
                  }}
                />
              }
              defaultLabel={strings('Roles')}
              optionList={skillOptions}
              multipleSelect
              allToggle
              allItem
              {...bindRole}
            />

            <Select
              fixedLabel={<LocationIcon />}
              defaultLabel={strings('District')}
              optionList={postalDistrictOptions}
              multipleSelect
              searchable
              allToggle
              allItem
              divider
              {...bindDistrict}
            />
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              label={strings('Create')}
              handleClick={() =>
                history.push(toRoute('/notifications/create-new-notification'))
              }
            />
          </div>
        </div>
      </div>
      <Table
        columns={columns}
        data={data}
        rowClick
        pageInput
        manualQuery={searchValue}
        manualFilterSort={manualFilterSort}
        pageCountNumber={Math.ceil(total / _limit)}
        fetchData={fetchData}
      />
      <Modal
        ref={deleteModalRef}
        header={<h2>{strings('Delete Announcement') + '?'}</h2>}
        body={
          <div>
            <div>
              {
                <ReactMarkdown
                  children={strings(
                    'You are deleting announcement ‘%0’.',
                    `**${selectedNoti.title}**`
                  )}
                />
              }
            </div>
            <br />
            <div>{strings('This action cannot be undone.')}</div>
          </div>
        }
        footer={
          <>
            <Button
              label={strings('Cancel')}
              type="transparent"
              handleClick={() => deleteModalRef.current.hideModal()}
            />
            <Button
              label={strings('Delete')}
              type="danger"
              handleClick={() => {
                selectedNoti.id && deleteNotiApi.request(selectedNoti.id);
                deleteModalRef.current.hideModal();
              }}
            />
          </>
        }
      />
      <Modal
        ref={resultModalRef}
        showClose={false}
        body={
          <div>
            <div>
              {deleteNotiApi.error
                ? deleteNotiApi.error
                : deleteNotiApi.data?.success
                ? strings('Delete Announcement Successfully')
                : ''}
            </div>
          </div>
        }
        footer={
          <>
            <Button
              label={strings('Done')}
              type="danger"
              handleClick={() => {
                resultModalRef.current.hideModal();
              }}
            />
          </>
        }
      />
    </div>
  );
}
