import styles from './StaffDetails.module.scss';
import {Skill, Job, Application} from '../../../services/models';
import {Column} from 'react-table';
import {Table, Button, Badge, Modal} from '../../../components';
import {useEffect, useMemo, useRef, useState} from 'react';
import {strings} from '../../../services/language';
import {ReactComponent as ChevronIcon} from '../../../assets/img/chevron.svg';
import {ReactComponent as StarIcon} from '../../../assets/img/star.svg';
import {useLoading} from '../../../services/hooks';
import {useAPI, useResult, verifyApplication} from '../../../services/api';
import {useHistory} from 'react-router-dom';

type StaffDetailsProps = {
  skills: Skill[];
  initJob: Job;
  onUpdateJob?: (job: Job) => void;
};

interface ApplicantData {
  rowId: JSX.Element;
  user: JSX.Element;
  role: JSX.Element;
  experience: JSX.Element;
  reviews: JSX.Element;
  buttons: JSX.Element;
}

export default function StaffDetails({
  skills,
  initJob,
  onUpdateJob = () => {},
}: StaffDetailsProps) {
  const [job, setJob] = useState(initJob);
  const [pendingApplications, setPendingApplications] = useState<Application[]>(
    []
  );
  const [approvedApplications, setApprovedApplications] = useState<
    Application[]
  >([]);
  const [actionType, setActionType] = useState<'reject' | 'accept' | ''>('');
  const [selectingUser, setSelectingUser] = useState<
    {applicationId: number; userName: string} | undefined
  >(undefined);

  const history = useHistory();
  const jobSkill = skills.filter(skill => skill.id === initJob.skillId)[0];

  useEffect(() => {
    setPendingApplications(
      job.applications?.filter(a => a.status === 'pending') || []
    );
    setApprovedApplications(
      job.applications?.filter(a => a.status === 'approved') || []
    );
  }, [job]);

  const actionModalRef = useRef<any>(null);

  const columns: Column[] = useMemo(() => createColumns(), []);
  const pendingData: ApplicantData[] = useMemo(
    () =>
      createPendingData(
        history,
        pendingApplications,
        skills,
        actionModalRef,
        setActionType,
        setSelectingUser
      ),
    [pendingApplications, skills, actionModalRef]
  );
  const approvedData: ApplicantData[] = useMemo(
    () => createApprovedData(history, approvedApplications, skills),
    [approvedApplications, skills]
  );

  const verifyApplicationApi = useAPI(verifyApplication);

  useResult(verifyApplicationApi.data, data => {
    setJob(data.job);
    onUpdateJob(data.job);
  });

  useLoading(verifyApplicationApi.loading);

  return (
    <div className={styles.container}>
      {approvedApplications.length > 0 && (
        <>
          {job.hired < job.vacancy && <h3>{strings('Hired')}</h3>}
          <Table columns={columns} data={approvedData} rowClick />
        </>
      )}
      {pendingApplications.length > 0 && (
        <>
          <h3>{strings('Applicants')}</h3>
          <Table columns={columns} data={pendingData} rowClick />
        </>
      )}
      <Modal
        ref={actionModalRef}
        header={
          <h2>
            {actionType === 'reject'
              ? strings('Reject Applicant?')
              : strings('Accept Applicant?')}
          </h2>
        }
        body={
          actionType === 'reject' ? (
            <div>
              <div>
                {strings(
                  'You are rejecting %0 for the job ‘%1’.',
                  selectingUser?.userName,
                  job.vacancy + ' ' + jobSkill?.name
                )}
              </div>
              <br />
              <div>
                {strings(
                  'You would no longer see this applicant for the job posting after rejecting.'
                )}
              </div>
              <br />
              <div>{strings('This action cannot be undone.')}</div>
            </div>
          ) : (
            <div>
              <div>
                {strings(
                  'You are accepting %0 for the job ‘%1’. Please ensure that the requirements are met.',
                  selectingUser?.userName,
                  job.vacancy + ' ' + jobSkill?.name
                )}
              </div>
              <br />
              {job.hired === job.vacancy - 1 && (
                <>
                  <div>
                    {strings(
                      'By accepting, the job vacancies will be filled. All other applicants would be rejected. The job posting would also no longer accept any applications.'
                    )}
                  </div>
                  <br />
                </>
              )}
              <div>{strings('This action cannot be undone.')}</div>
            </div>
          )
        }
        footer={
          <>
            <Button
              label={strings('Cancel')}
              type="transparent"
              handleClick={() => actionModalRef.current.hideModal()}
            />
            <Button
              label={
                actionType === 'reject' ? strings('Reject') : strings('Accept')
              }
              type="danger"
              handleClick={() => {
                actionType === 'reject'
                  ? verifyApplicationApi.request({
                      jobId: job?.id,
                      verifyArr: [
                        {
                          applicationId: selectingUser?.applicationId!,
                          approve: false,
                        },
                      ],
                    })
                  : job.hired === job.vacancy - 1 &&
                    pendingApplications.length > 1
                  ? verifyApplicationApi.request({
                      jobId: job?.id,
                      verifyArr: pendingApplications.map(p => ({
                        applicationId: p.id,
                        approve:
                          p.id !== selectingUser?.applicationId! ? false : true,
                      })),
                    })
                  : verifyApplicationApi.request({
                      jobId: job?.id,
                      verifyArr: [
                        {
                          applicationId: selectingUser?.applicationId!,
                          approve: true,
                        },
                      ],
                    });
                actionModalRef.current.hideModal();
              }}
            />
          </>
        }
      />
    </div>
  );
}

function createColumns() {
  return [
    {
      Header: '',
      accessor: 'rowId',
    },
    {
      Header: strings('User'),
      accessor: 'user',
    },
    {
      Header: strings('Role'),
      accessor: 'role',
    },
    {
      Header: strings('Experience'),
      accessor: 'experience',
    },
    {
      Header: strings('Reviews'),
      accessor: 'reviews',
    },
    {
      Header: '',
      accessor: 'buttons',
    },
  ];
}

function createPendingData(
  history: any,
  pendingApplications: Application[],
  skills: StaffDetailsProps['skills'],
  actionModalRef: React.MutableRefObject<any>,
  setActionType: React.Dispatch<React.SetStateAction<'' | 'reject' | 'accept'>>,
  setSelectingUser: React.Dispatch<
    React.SetStateAction<
      | {
          applicationId: number;
          userName: string;
        }
      | undefined
    >
  >
) {
  return pendingApplications.map((app, index) => ({
    rowId: <span className={styles.greyText}>{index + 1}</span>,
    user: (
      <div className={styles.userCell}>
        <div
          className={styles.avatar}
          style={{
            backgroundImage: app.profile.photo
              ? `url(${app.profile.photo})`
              : 'none',
          }}
        ></div>
        <div className={styles.userRight}>
          <div>{app.profile.name}</div>
          <div className={styles.greyText}>{app.profile.id}</div>
        </div>
      </div>
    ),
    role:
      skills && app.profile.skillId ? (
        <Badge
          type={'skill'}
          kind="skillIconText"
          skill={skills.filter(s => s.id === app.profile.skillId)[0]}
        />
      ) : (
        <div></div>
      ),
    experience: (
      <div>
        <div>4 years</div>
        <div>32 jobs</div>
      </div>
    ),
    reviews: (
      <div>
        <div>
          {app.profile.averageRating || 0} <StarIcon />
        </div>
        <div className={styles.greyText}>{`${
          app.profile.totalReviews || 0
        } ${strings('Reviews').toLowerCase()}`}</div>
      </div>
    ),
    buttons: (
      <div
        className={styles.buttonsCell}
        onClick={e => {
          history.push('/relief-staffs/' + app.profile.id);
          if (e) {
            e.stopPropagation();
          }
        }}
      >
        <Button
          label={strings('Reject')}
          type="outline"
          handleClick={e => {
            e.stopPropagation();
            setSelectingUser({
              applicationId: app.id,
              userName: app.profile.name,
            });
            setActionType('reject');
            actionModalRef.current.showModal();
          }}
        />
        <Button
          label={strings('Accept')}
          type="primary"
          handleClick={e => {
            e.stopPropagation();
            setSelectingUser({
              applicationId: app.id,
              userName: app.profile.name,
            });
            setActionType('accept');
            actionModalRef.current.showModal();
          }}
        />
        <ChevronIcon
          style={{stroke: 'var(--blue-accent)', transform: 'rotate(-90deg)'}}
        />
      </div>
    ),
  }));
}

function createApprovedData(
  history: any,
  approvedApplications: Application[],
  skills: StaffDetailsProps['skills']
) {
  return approvedApplications.map((app, index) => ({
    rowId: <span className={styles.greyText}>{index + 1}</span>,
    user: (
      <div className={styles.userCell}>
        <div
          className={styles.avatar}
          style={{
            backgroundImage: app.profile.photo
              ? `url(${app.profile.photo})`
              : 'none',
          }}
        ></div>
        <div className={styles.userRight}>
          <div>{app.profile.name}</div>
          <div className={styles.greyText}>{app.profile.id}</div>
        </div>
      </div>
    ),
    role:
      skills && app.profile.skillId ? (
        <Badge
          type={'skill'}
          kind="skillIconText"
          skill={skills.filter(s => s.id === app.profile.skillId)[0]}
        />
      ) : (
        <div></div>
      ),
    experience: (
      <div>
        <div>4 years</div>
        <div>32 jobs</div>
      </div>
    ),
    reviews: (
      <div>
        <div>
          {app.profile.averageRating || 0} <StarIcon />
        </div>
        <div className={styles.greyText}>{`${
          app.profile.totalReviews || 0
        } ${strings('Reviews').toLowerCase()}`}</div>
      </div>
    ),
    buttons: (
      <div
        className={styles.buttonsCell}
        onClick={e => {
          history.push('/relief-staffs/' + app.profile.id);
          if (e) {
            e.stopPropagation();
          }
        }}
      >
        <ChevronIcon
          style={{stroke: 'var(--blue-accent)', transform: 'rotate(-90deg)'}}
        />
      </div>
    ),
  }));
}
