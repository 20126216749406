import {PayrollSchedule} from '../../models';
import {API} from '../types';

type Params = {
  id: number;
  scheduledAt: string;
};

type Payload = {
  id?: number;
  scheduledAt: string;
};

interface Response {
  schedule: PayrollSchedule;
}

export function editPayrollScheduleAPI(params: Params): API<Response> {
  const payload: Payload = {...params};
  delete payload.id;
  return {
    getData: async client => {
      const {data} = await client({
        url: `/payroll-schedules/${params.id}`,
        method: 'PUT',
        data: payload,
      });
      return {data: {schedule: data}};
    },
    getMockData: () => {
      return {
        schedule: {
          id: 1,
          scheduledAt: '',
        },
      };
    },
  };
}
