import styles from './Sidebar.module.scss';
import {NavLink, useHistory} from 'react-router-dom';
import {toRoute} from '../../services/router';
import {ReactComponent as WorkIcon} from '../../assets/img/work.svg';
import {ReactComponent as PeopleIcon} from '../../assets/img/people.svg';
import {ReactComponent as TieIcon} from '../../assets/img/tie.svg';
import {ReactComponent as NotificationsIcon} from '../../assets/img/notifications.svg';
import {ReactComponent as MoneyIcon} from '../../assets/img/money.svg';
import {ReactComponent as ClockIcon} from '../../assets/img/clock.svg';
import {ReactComponent as CentreIcon} from '../../assets/img/centre.svg';
import SimpleBar from 'simplebar-react';
import {strings} from '../../services/language';
import {useEffect, useRef, useState} from 'react';
import {Button, Modal} from '..';
import {
  getState,
  setState,
  updateState,
  useSelector,
} from '../../services/store';
import moment from 'moment';
import {authRefreshTokenAPI, useAPI, useResult} from '../../services/api';

export function Sidebar() {
  const [animationClass, setAnimationClass] = useState('');
  const [showPanel, setShowPanel] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const history = useHistory();
  const profile = useSelector(s => s.profile);
  const refreshToken = getState().secure.refreshToken;
  const expiry = getState().secure.expiry || 0;

  const logoutModalRef = useRef<any>(null);
  const expiredSessionModalRef = useRef<any>(null);

  const refreshTokenApi = useAPI(authRefreshTokenAPI);

  useResult(refreshTokenApi.data, auth => {
    updateState({
      user: auth.user,
      profile: auth.profile,
      secure: {
        token: auth.token,
        refreshToken: auth.refreshToken,
        expiry: moment().add(55, 'minute').unix(),
      },
    });
  });

  useEffect(() => {
    if (refreshTokenApi.error && window.navigator.onLine) {
      expiredSessionModalRef.current.showModal();
      setIsExpired(true);
    }
  }, [refreshTokenApi.loading]);

  useEffect(() => {
    const expireTimeout = setTimeout(() => {
      refreshTokenApi.request({refreshToken: refreshToken!});
    }, (expiry - moment().unix() - 5) * 1000);

    return () => {
      clearTimeout(expireTimeout);
    };
  }, [expiry]);

  useEffect(() => {
    const timer = isExpired
      ? setInterval(() => {
          setCountdown(countdown - 1);
        }, 1000)
      : undefined;

    countdown === 0 && logout();

    return () => clearInterval(timer!);
  }, [isExpired, countdown]);

  function logout() {
    setState({user: {}, profile: {}, secure: {}});
    history.push(toRoute('/login'));
  }

  const handleShow = () => {
    setShowPanel(true);
    setAnimationClass(styles.showHideAnimation);
  };
  const handleHide = () => {
    setAnimationClass('');
    setTimeout(() => {
      setShowPanel(false);
    }, 200);
  };

  return (
    <div className={styles.container}>
      <SimpleBar style={{height: '100%'}}>
        <div className={styles.content}>
          <div className={styles.logo}></div>
          <div className={styles.wrapper}>
            <ul className={styles.navList}>
              <li>
                <NavLink
                  to={toRoute('/jobs')}
                  activeClassName={styles.active}
                  className={styles.navItem}
                  title={strings('Jobs')}
                >
                  <WorkIcon style={{stroke: 'var(--white)'}} />
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={toRoute('/centres')}
                  activeClassName={styles.active}
                  className={styles.navItem}
                  title={strings('Centres')}
                >
                  <CentreIcon />
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={toRoute('/relief-staffs')}
                  activeClassName={styles.active}
                  className={styles.navItem}
                  title={strings('Relief Staff')}
                >
                  <PeopleIcon />
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={toRoute('/pcf-staffs')}
                  activeClassName={styles.active}
                  className={styles.navItem}
                  title={strings('PCF Staff')}
                >
                  <TieIcon />
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={toRoute('/notifications')}
                  activeClassName={styles.active}
                  className={styles.navItem}
                  title={strings('Announcements')}
                >
                  <NotificationsIcon />
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={toRoute('/payrolls')}
                  activeClassName={styles.active}
                  className={styles.navItem}
                  title={strings('Payroll')}
                >
                  <MoneyIcon />
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={toRoute('/timesheets')}
                  activeClassName={styles.active}
                  className={styles.navItem}
                  title={strings('Timesheets')}
                >
                  <ClockIcon
                    style={{
                      width: 28,
                      height: 28,
                      fill: 'var(--white)',
                      stroke: 'var(--white)',
                    }}
                  />
                </NavLink>
              </li>
            </ul>
            <div
              className={styles.userButton}
              style={{
                backgroundImage: profile.photo
                  ? `url(${profile.photo})`
                  : 'none',
              }}
              tabIndex={0}
              onBlur={() => {
                setTimeout(() => {
                  handleHide();
                }, 100);
              }}
              onClick={() => {
                if (showPanel) {
                  handleHide();
                } else {
                  handleShow();
                }
              }}
            ></div>
          </div>
        </div>
      </SimpleBar>
      {showPanel && (
        <div className={`${styles.accountPanel} ${animationClass}`}>
          <div
            className={styles.actionItem}
            onClick={() => {
              profile.id &&
                (history.push(`/pcf-staffs/${profile.id}`), history.go(0));
            }}
          >
            {strings('Profile')}
          </div>
          <div
            className={styles.actionItem}
            onClick={() => {
              logoutModalRef.current.showModal();
            }}
          >
            {strings('Log Out')}
          </div>
        </div>
      )}
      <Modal
        ref={logoutModalRef}
        header={<h2>{strings('Log Out')}</h2>}
        body={<div>{strings('Are you sure you want to log out?')}</div>}
        footer={
          <>
            <Button
              label={strings('Cancel')}
              type="transparent"
              handleClick={() => {
                logoutModalRef.current.hideModal();
              }}
            />
            <Button
              label={strings('Ok')}
              type="danger"
              handleClick={() => {
                logout();
              }}
            />
          </>
        }
      />
      <Modal
        ref={expiredSessionModalRef}
        showClose={false}
        header={<h2>{strings('Expired Session')}</h2>}
        body={
          <div>
            <div>
              {strings('Your session has expired. Please log in again.')}
            </div>
            <br />
            <div>
              {strings(
                'You will be redirected to login page in %0 seconds.',
                countdown
              )}
            </div>
          </div>
        }
        footer={
          <>
            <Button
              label={strings('Ok')}
              type="danger"
              handleClick={() => {
                logout();
              }}
            />
          </>
        }
      />
    </div>
  );
}
